import { FC } from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import { COLUMN_DEFINITION_TYPE_CUSTOM_COLUMN_DEFINITION, TAB_KEY_COLUMN_CUSTOMIZER_COMMENT_COLUMN, TAB_KEY_COLUMN_CUSTOMIZER_CUSTOM_COLUMN } from './constants';
import { CustomColumnDefinition, ExchangeCustomColumnDefinition, ExchangeRemarksColumnDefinition, RemarksColumnDefinition } from './custom_types';
import { getUniqueValueForCustomColumnConstant } from './Helpers';


interface AdditionalColumnsRepositoryProps {
    additionalColumnsRepoRemarkColumnsArray: ExchangeRemarksColumnDefinition[],
    additionalColumnsRepoCustomColumnsArray: ExchangeCustomColumnDefinition[],
    customColumnsArray: CustomColumnDefinition[],
    setCustomColumnsArray: any,
    remarksColumnsArray: RemarksColumnDefinition[],
    setRemarksColumnsArray: any,
    setActiveCustomColumnTabKey: any,
    setActiveRemarksColumnTabKey: any,
    setActiveTabKeyColumnDefinitionModal: any,
}

export const AdditionalColumnsRepository: FC<AdditionalColumnsRepositoryProps> = ({
    additionalColumnsRepoRemarkColumnsArray,
    additionalColumnsRepoCustomColumnsArray,
    customColumnsArray,
    setCustomColumnsArray,
    remarksColumnsArray,
    setRemarksColumnsArray,
    setActiveCustomColumnTabKey,
    setActiveRemarksColumnTabKey,
    setActiveTabKeyColumnDefinitionModal,
}) => {

    // @ts-ignore
    let combinedArray = additionalColumnsRepoRemarkColumnsArray.concat(additionalColumnsRepoCustomColumnsArray);
    // @ts-ignore
    combinedArray = combinedArray.sort((a, b) => (a.nameInFrontend > b.nameInFrontend) ? 1 : -1);


    let currentColumnsNameInFrontendArray : string[] = [];
    for (let i=0; i<customColumnsArray.length; i++) {
        currentColumnsNameInFrontendArray.push(customColumnsArray[i].nameInFrontend);
    }
    for (let i=0; i<remarksColumnsArray.length; i++) {
        currentColumnsNameInFrontendArray.push(remarksColumnsArray[i].nameInFrontend);
    }


    // Function to move columnDefinitionItem one position up
    const applyAdditionalColumnDefintion = (columnDefinitionConstant : string, columnDefinitionType: number) => {
 
        //alert('columnDefinitionConstant:'+columnDefinitionConstant);

        let selectedCD = null;

        if (columnDefinitionType === COLUMN_DEFINITION_TYPE_CUSTOM_COLUMN_DEFINITION) {
            selectedCD = additionalColumnsRepoCustomColumnsArray.find(
                cd => cd.columnDefinitionConstant === columnDefinitionConstant
            );
        } else {
            selectedCD = additionalColumnsRepoRemarkColumnsArray.find(
                cd => cd.columnDefinitionConstant === columnDefinitionConstant
            );
        }

        if (selectedCD) {
            const uniqueColumnDefinitionConstant = getUniqueValueForCustomColumnConstant(
                selectedCD.nameInFrontend,
                selectedCD.columnDefinitionType,
                customColumnsArray,
                remarksColumnsArray
            );  
            selectedCD.columnDefinitionConstant = uniqueColumnDefinitionConstant;

            if (selectedCD.columnDefinitionType === COLUMN_DEFINITION_TYPE_CUSTOM_COLUMN_DEFINITION) {
                let newCustomColumnsArray = Object.assign([], customColumnsArray);
                // @ts-ignore
                newCustomColumnsArray.push(selectedCD);
                setCustomColumnsArray(newCustomColumnsArray);
                setActiveTabKeyColumnDefinitionModal(TAB_KEY_COLUMN_CUSTOMIZER_CUSTOM_COLUMN);
                setActiveCustomColumnTabKey(uniqueColumnDefinitionConstant);

            } else {
                let newRemarkColumnsArray = Object.assign([], remarksColumnsArray);
                // @ts-ignore
                newRemarkColumnsArray.push(selectedCD);
                setRemarksColumnsArray(newRemarkColumnsArray);
                setActiveTabKeyColumnDefinitionModal(TAB_KEY_COLUMN_CUSTOMIZER_COMMENT_COLUMN);
                setActiveRemarksColumnTabKey(uniqueColumnDefinitionConstant);
            }
        }
    };

    return (
        <>
            <Accordion>
                <Card>
                    <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        Vordefinierte Spaltendefinitionen
                    </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <>
                            {/* @ts-ignore */}
                            { combinedArray.map((cd: any, columnPresetFileIndex: number) => 
                                (currentColumnsNameInFrontendArray.indexOf(cd.nameInFrontend)===-1) ? (
                                    <Button
                                        key={'splash-screen-modal-column-preset-button'+columnPresetFileIndex}
                                        size='sm'
                                        className='column-preset-select-button'
                                        variant='outline-secondary'
                                        onClick={() => applyAdditionalColumnDefintion(cd.columnDefinitionConstant, cd.columnDefinitionType)}
                                    >
                                        <h4>{cd.nameInFrontend}</h4>
                                        <p>{cd.description}</p>
                                    </Button> 
                                ) : (
                                    <Button
                                        key={'splash-screen-modal-column-preset-button'+columnPresetFileIndex}
                                        size='sm'
                                        disabled={true}
                                        className='column-preset-select-button'
                                        variant='outline-secondary'
                                        onClick={() => applyAdditionalColumnDefintion(cd.columnDefinitionConstant, cd.columnDefinitionType)}
                                    >
                                        <h4>{cd.nameInFrontend}</h4>
                                        <p className='warning'>Eine Spalte mit diesem Namen existiert bereits</p>
                                    </Button> 
                                )
                            )}
                        </>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    )
};

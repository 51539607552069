import React, { FC, useState } from 'react';
import { Dropdown, Form, Tab, Tabs } from "react-bootstrap";
import { COLUMNS_ARRAY_MAP_FOR_IMAGE_WITH_ENRICHED_METADATA_OBJECT, COLUMN_DEFINITION_CONST_SOURCE_FILE, WARNING_CONDITIONS_ARRAY, WARNING_CONDITION_TYPE_DOES_MATCH_KEY, WARNING_CONDITION_TYPE_DOES_MATCH_NAME, WARNING_CONDITION_TYPE_DOES_NOT_MATCH_NAME } from './constants';
import { ColumnDefinition, ColumnsForViewObject, DataFilterObject, ImageWithEnrichedMetadataObject, Settings } from './custom_types';
import { KeywordsTable } from './KeywordsTable/KeywordsTable';

interface TabsWithSearchesProps {
    imageWithEnrichedMetadataObjectsArray_All: ImageWithEnrichedMetadataObject[],
    settings: Settings,
    selectedImageWithEnrichedMetadataObject: ImageWithEnrichedMetadataObject,
    columnsForViewArray: ColumnsForViewObject,
    columnForViewKey: string,
    relatedColumnsArray: ColumnDefinition[],
    renderObjectsMap: any,
}

export const TabsWithSearches: FC<TabsWithSearchesProps> = ({
    imageWithEnrichedMetadataObjectsArray_All,
    settings,
    selectedImageWithEnrichedMetadataObject,
    columnsForViewArray,
    columnForViewKey,
    relatedColumnsArray,
    renderObjectsMap,
}) => {
    const [activeTabKey, setActiveTabKey] = useState('key0' as string);

    const sourceFileValue = selectedImageWithEnrichedMetadataObject.baseColumns[COLUMN_DEFINITION_CONST_SOURCE_FILE];

    const changeActiveTab = (newTabKey: string): void => {
        setActiveTabKey(newTabKey);
    }

    console.log("TabsWithSearches - in main function");
    let tmpImageFilterAndResultsArray = [] as {imageArray: ImageWithEnrichedMetadataObject[], dataFilterObject: DataFilterObject}[];

    if (typeof tmpImageFilterAndResultsArray[sourceFileValue] === 'undefined') {
        tmpImageFilterAndResultsArray[sourceFileValue] = [];
    }

    relatedColumnsArray.map((columnDefinition: ColumnDefinition, columnDefinitionIndex: number) => {
        let imageWithMetadata1stLevelKey = COLUMNS_ARRAY_MAP_FOR_IMAGE_WITH_ENRICHED_METADATA_OBJECT[columnDefinition.columnDefinitionType];

        // @ts-ignore
        let resultsArray : ImageWithEnrichedMetadataObject[] = [];

        if (typeof selectedImageWithEnrichedMetadataObject[imageWithMetadata1stLevelKey][columnDefinition.columnDefinitionConstant] === 'object') {
            // @ts-ignore
            let re = new RegExp(selectedImageWithEnrichedMetadataObject[imageWithMetadata1stLevelKey][columnDefinition.columnDefinitionConstant][0]);

            // @ts-ignore
            resultsArray = imageWithEnrichedMetadataObjectsArray_All.filter(e=>
                (e.baseColumns.sourcefile !== selectedImageWithEnrichedMetadataObject.baseColumns.sourcefile) && 
                (e[imageWithMetadata1stLevelKey][columnDefinition.columnDefinitionConstant].join('').match(re)));
            // TODO: Remove join('') - this makes the search a bit unreliable - especially for LokNr. and and (uncomplete) dates

        } else if (selectedImageWithEnrichedMetadataObject[imageWithMetadata1stLevelKey][columnDefinition.columnDefinitionConstant]) { //type should be "string"
            let re = new RegExp(selectedImageWithEnrichedMetadataObject[imageWithMetadata1stLevelKey][columnDefinition.columnDefinitionConstant]);
            // @ts-ignore
            resultsArray = imageWithEnrichedMetadataObjectsArray_All.filter(e=>
                (e.baseColumns.sourcefile !== selectedImageWithEnrichedMetadataObject.baseColumns.sourcefile) && 
                (e[imageWithMetadata1stLevelKey][columnDefinition.columnDefinitionConstant].match(re)));

        }
        
        let valuesFromColumnOfSelectedFile = selectedImageWithEnrichedMetadataObject[imageWithMetadata1stLevelKey][columnDefinition.columnDefinitionConstant];
        debugger;

        if (typeof valuesFromColumnOfSelectedFile === 'object') {
            valuesFromColumnOfSelectedFile.map((valueFromColumn: string, valueFromColumnIndex: number) => {
                tmpImageFilterAndResultsArray[sourceFileValue].push({
                    'imageArray': resultsArray,
                    'dataFilterObject': {
                        column: columnDefinition,
                        conditionType: WARNING_CONDITION_TYPE_DOES_MATCH_KEY,
                        searchValue: valueFromColumn,
                    } as DataFilterObject,
                });
            });
        } else if (valuesFromColumnOfSelectedFile) { //type should be "string"

            tmpImageFilterAndResultsArray[sourceFileValue].push({
                'imageArray': resultsArray,
                'dataFilterObject': {
                    column: columnDefinition,
                    conditionType: WARNING_CONDITION_TYPE_DOES_MATCH_KEY,
                    searchValue: valuesFromColumnOfSelectedFile,
                } as DataFilterObject,
            });
        }
    }); 
    
    return (
        <>
            {
                (relatedColumnsArray.length===0) ?
                <p>Bitte definieren Sie die Spalten in den Spalteneinstellungen unter dem Tab "Spalten für verwandte Bilder" um diese Funktion nutzen zu können.</p>
                
                : (tmpImageFilterAndResultsArray[sourceFileValue].length) === 0 &&
                <p>Keine Eigenschaften gefunden, nach denen verwandte Bilder gefunden werden können.</p>
            }

        <Tabs className='tabs-with-searches' activeKey={activeTabKey} defaultActiveKey={0} onSelect={(key) => key ? changeActiveTab(key) : ''}>
            {tmpImageFilterAndResultsArray[sourceFileValue].map((imageFilterAndResultArray: {imageArray: ImageWithEnrichedMetadataObject[], dataFilterObject: DataFilterObject}, imageFilterAndResultsIndex: number) => 
                <Tab eventKey={`key${imageFilterAndResultsIndex}`} title={(imageFilterAndResultArray.dataFilterObject.column ? `${imageFilterAndResultArray.dataFilterObject.column.nameInFrontend} ` : '-')+'="'+imageFilterAndResultArray.dataFilterObject.searchValue+'" (' +imageFilterAndResultArray.imageArray.length+')' } tabClassName='tab-primary'>
                    {/* <Dropdown>
                        <Dropdown.Toggle
                            split
                            size='sm'
                            variant='secondary'
                            >
                            {imageFilterAndResultArray.dataFilterObject.column ? `${imageFilterAndResultArray.dataFilterObject.column.nameInFrontend} ` : '-'}
                        </Dropdown.Toggle>
                    </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle split size='sm' variant='secondary' id={`data_filter_row_${imageFilterAndResultsIndex}`}>
                            {`${WARNING_CONDITIONS_ARRAY[imageFilterAndResultArray.dataFilterObject.conditionType]} `}
                        </Dropdown.Toggle>
                    </Dropdown>
                    {[WARNING_CONDITION_TYPE_DOES_MATCH_NAME,WARNING_CONDITION_TYPE_DOES_NOT_MATCH_NAME].indexOf(WARNING_CONDITIONS_ARRAY[imageFilterAndResultArray.dataFilterObject.conditionType]) > -1 && (
                        <Form.Control
                            size="sm"
                            type="text"
                            value={imageFilterAndResultArray.dataFilterObject.searchValue}
                        />
                    )} */}
                    <KeywordsTable
                        idPrefix={`keyword_row_demo_keywords_table_${imageFilterAndResultsIndex}`}
                        settings={settings}
                        editPossible={false}
                        connectedToNodeServer={false}
                        connectedToDatabase={false}
                        imageWithEnrichedMetadataObjectsArray={imageFilterAndResultArray.imageArray}
                        columnsArray={columnsForViewArray[columnForViewKey]}
                        renderObjectsMap={renderObjectsMap}                        
                    />
                </Tab> 
            )}
        </Tabs>
        </>
    )
}

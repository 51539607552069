import { FC, useState } from "react";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { COLUMNS_ARRAY_MAP_FOR_IMAGE_WITH_ENRICHED_METADATA_OBJECT, COLUMNS_FOR_TABLE_VIEW_KEY, TAB_KEY_HELP_MODAL_DEMO, TAB_KEY_HELP_MODAL_EXCHANGE, TAB_KEY_HELP_MODAL_MANUALPAGE } from "../constants";
import { ColumnDefinition, ColumnsForViewObject, ImageWithEnrichedMetadataObject, Settings } from "../custom_types";
import { KeywordRowDemo } from "../DemoPages/KeywordRowDemo";
import { ManualPage } from "../DemoPages/ManualPage";


interface HelpModalProps {
    darkMode: boolean,
    showHelpModal: any, // function
    handleCloseHelpModal: any, // function
    settings: Settings,
    customColumnsArray: ColumnDefinition[],
    remarksColumnsArray: ColumnDefinition[],
    enrichImageWithMetadataObjectArray: any, 
    imageWithEnrichedMetadataObjectsArray_Filtered: ImageWithEnrichedMetadataObject[],
    columnsForViewArray: ColumnsForViewObject,
    renderObjectsMap: any,
}

export const HelpModal: FC<HelpModalProps> = ({
    darkMode,
    showHelpModal,
    handleCloseHelpModal,
    settings,
    customColumnsArray,
    remarksColumnsArray,
    enrichImageWithMetadataObjectArray,
    imageWithEnrichedMetadataObjectsArray_Filtered,
    columnsForViewArray,
    renderObjectsMap,
}) => {

    const changeActiveTab = (newTabKey: string | null): void => {
        if (newTabKey) {
          setActiveTabKey(newTabKey);
        }
    }

    const [activeTabKey, setActiveTabKey] = useState(TAB_KEY_HELP_MODAL_MANUALPAGE);

    const copyFilteredDataToClipboard = () => {
        console.log('copyFilteredDataToClipboard');
        // generate csv output
        let csvOutputHeaderRowArray = [];
        let csvOutputArray = [];
        
        for (let i=0; i<columnsForViewArray[COLUMNS_FOR_TABLE_VIEW_KEY].length; i++) {
         csvOutputHeaderRowArray.push(`"${columnsForViewArray[COLUMNS_FOR_TABLE_VIEW_KEY][i].nameInFrontend}"`);
        }
        csvOutputArray.push(csvOutputHeaderRowArray.join(';'));
     
        for (let i=0; i<imageWithEnrichedMetadataObjectsArray_Filtered.length; i++) {
         let csvOutputArrayForRow = [];
         for (let j=0; j<columnsForViewArray[COLUMNS_FOR_TABLE_VIEW_KEY].length; j++) {
           const cd = columnsForViewArray[COLUMNS_FOR_TABLE_VIEW_KEY][j] as ColumnDefinition;
           const imageWithMetadata1stLevelKey = COLUMNS_ARRAY_MAP_FOR_IMAGE_WITH_ENRICHED_METADATA_OBJECT[cd.columnDefinitionType];
           const tmpValue = imageWithEnrichedMetadataObjectsArray_Filtered[i][imageWithMetadata1stLevelKey][cd.columnDefinitionConstant];

           if (!tmpValue) {
                csvOutputArrayForRow.push(`""`);
           } else if (typeof tmpValue === 'object') {
             csvOutputArrayForRow.push(
               `"${tmpValue.join(',')}"`
             );
           } else {
             csvOutputArrayForRow.push(
               `"${(tmpValue ? tmpValue : '')}"`
             );
           }
         }
         csvOutputArray.push(csvOutputArrayForRow.join(';'));
        }
     
        // Copy the text inside the text field
        navigator.clipboard.writeText(csvOutputArray.join('\n'));
        alert("CSV wurde in die Zwischenablage kopiert");
    }

    return (
        <Modal size='xl' show={showHelpModal} onHide={handleCloseHelpModal} className={darkMode ? 'darkmode' : ''}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FontAwesomeIcon icon={faQuestion} /> Hilfe-Seite
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body-help">
                <Tabs activeKey={activeTabKey} defaultActiveKey={TAB_KEY_HELP_MODAL_MANUALPAGE} onSelect={(key) => changeActiveTab(key)}>
                    <Tab eventKey={TAB_KEY_HELP_MODAL_DEMO} title="Keyword Demo" tabClassName='tab-primary'>
                        <KeywordRowDemo
                            settings={settings}
                            customColumnsArray={customColumnsArray}
                            remarksColumnsArray={remarksColumnsArray}
                            enrichImageWithMetadataObjectArray={enrichImageWithMetadataObjectArray}
                            renderObjectsMap={renderObjectsMap}
                        />
                    </Tab>
                    <Tab eventKey={TAB_KEY_HELP_MODAL_MANUALPAGE} title="Handbuch" tabClassName='tab-primary'>
                        <ManualPage
                        />
                    </Tab>
                    <Tab eventKey={TAB_KEY_HELP_MODAL_EXCHANGE} title="Austausch" tabClassName='tab-primary'>
                        <Button
                            size='sm'
                            onClick={copyFilteredDataToClipboard}
                        >
                            <FontAwesomeIcon icon={faFileCsv} /> In der Tabellenansicht angezeigte Daten als CSV in die Zwischenablage kopieren
                        </Button>
                    </Tab>
                </Tabs>
            </Modal.Body>
        </Modal>
    );
}

import React, { FC } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from "react-bootstrap";
import { Pie } from 'react-chartjs-2';
import { PIE_CHART_BACKGROUND_COLORS_ARRAY, PIE_CHART_HOVER_COLORS_ARRAY, WARNING_CONDITION_TYPE_DOES_MATCH_KEY } from './constants';
import { ColumnDefinition, DataAnalysisCountObject, DataFilterObject } from './custom_types';
import { numberWithSeparator } from './Helpers';

interface DataAnalysisSectionPieChartsProps {
    headline: string,
    totalImages: number,
    columnsMap: Object,
    uniqueValuesArray: DataAnalysisCountObject[],
    setDataFilterObjectArray: any,
    columnDefinition: ColumnDefinition,
}

export const DataAnalysisSectionPieCharts: FC<DataAnalysisSectionPieChartsProps> = ({
    headline,
    totalImages,
    columnsMap,
    uniqueValuesArray,
    setDataFilterObjectArray,
    columnDefinition,
}) => {
    let trsUniqueArray: JSX.Element[] = [];

    // console.log('DataAnalysisSection: ' + keywordWith_Constant);
    // console.log(uniqueValuesArray);
    //uniqueValuesArray.forEach((s: DataAnalysisCountObject) => {

    const addAsFilter = (valueString: string) => {
        const newDataFilterObject : DataFilterObject[] = [{
            column: columnDefinition,
            conditionType: WARNING_CONDITION_TYPE_DOES_MATCH_KEY,
            searchValue: valueString,
        }] as DataFilterObject[];

        setDataFilterObjectArray(newDataFilterObject);

        // TODO: (when this code is activated) Solve problem that last set filter is applied - but not the most recently set filter
        //setTimeout(() => {applyFilterForDataSourceJSON()}, 1000);        
    }

    uniqueValuesArray.forEach((dataAnalysisCountObject: DataAnalysisCountObject, dataAnalysisCountObjectIndex: number) => {
        //let s = uniqueValuesArray[dataAnalysisCountObjectKey] as DataAnalysisCountObject;
        trsUniqueArray.push(
            <tr key={`data_analysis_section_${dataAnalysisCountObject.text}`}>
                <td>
                    <Button
                        size='sm'
                        variant='secondary'
                        onClick={() => addAsFilter(dataAnalysisCountObject.text)}
                    >
                        <FontAwesomeIcon icon={faSearch} />
                    </Button> {dataAnalysisCountObject.text}
                </td>
                <td>{numberWithSeparator(dataAnalysisCountObject.value)}</td>
            </tr>
        );
    });

    return (
        <Row>
            <Col md={6}>
                <Pie
                    data={{
                        labels: ['angegeben', 'nicht angegeben'],
                        datasets: [
                            {
                                backgroundColor: ['#ddd', '#333'],
                                hoverBackgroundColor: ['#ddd', '#333'],
                                data: [columnsMap[columnDefinition.columnDefinitionConstant], totalImages - columnsMap[columnDefinition.columnDefinitionConstant]],
                            }
                        ]
                    }}
                    options={{
                        plugins: {
                            legend: {
                                display: false,
                            },
                        },
                    }}
                />
            </Col>
            <Col md={6}>
                <Pie
                    data={{
                        labels: uniqueValuesArray.slice(0,5).map(a => a.text), // = TOP5
                        datasets: [
                            {
                                backgroundColor: PIE_CHART_BACKGROUND_COLORS_ARRAY,
                                hoverBackgroundColor: PIE_CHART_HOVER_COLORS_ARRAY,
                                data: uniqueValuesArray.slice(0,5).map(a => a.value), // = TOP5
                            }
                        ]
                    }}
                    options={{
                        plugins: {
                            legend: {
                                display: false,
                            },
                        }
                    }}
                />
            </Col>
        </Row>
    )
};

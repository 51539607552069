import { FC, useState } from 'react';
import { Settings } from "./custom_types";
import Button from "react-bootstrap/Button";
import {
    LOCAL_STORAGE_KEY_SETTING_COMPUTER_NAME,
    LOCAL_STORAGE_KEY_SETTING_DATABASE_HOST,
    LOCAL_STORAGE_KEY_SETTING_NODE_SERVER_HOST
} from "./constants";
import Form from "react-bootstrap/Form";
import { Alert, Badge, Col, Container, Row, Toast } from "react-bootstrap";

interface SettingsPageProps {
    settings: Settings,
    setSettings: any, // function
    connectedToDatabase: boolean,
    connectedToNodeServer: boolean,

    testDatabaseConnection: any, // function
    testNodeServerConnection: any, // function
    databaseHost: string,
    setDatabaseHost: any, // function
    nodeServerHost: string,
    setNodeServerHost: any, // function
}

export const SettingsPage: FC<SettingsPageProps> = ({
    settings,
    setSettings,
    connectedToDatabase,
    connectedToNodeServer,
    testDatabaseConnection,
    testNodeServerConnection,
    databaseHost,
    setDatabaseHost,
    nodeServerHost,
    setNodeServerHost,
}) => {
    const [computerName, setComputerName] = useState(settings.computerName);
    const [showSaveSuccessAlert, setShowSaveSuccessAlert] = useState(false);

    const saveSettings = () => {
        console.log('saveSettings');
        localStorage.setItem(LOCAL_STORAGE_KEY_SETTING_COMPUTER_NAME, computerName);
        localStorage.setItem(LOCAL_STORAGE_KEY_SETTING_DATABASE_HOST, databaseHost);
        localStorage.setItem(LOCAL_STORAGE_KEY_SETTING_NODE_SERVER_HOST, nodeServerHost);
        setSettings({
            computerName: settings.computerName,
            databaseHost: databaseHost,
            nodeServerHost: nodeServerHost,
        } as Settings)
    }

    return (
        <Container fluid>
            {showSaveSuccessAlert && (
                <Row className="justify-content-md-center">
                    <Col>
                        <Alert onClose={() => setShowSaveSuccessAlert(false)} dismissible key={'settings-page-success-modal'} variant='success'>
                            Einstellungen gespeichert
                        </Alert>
                    </Col>
                </Row>
            )}
            <Row className="justify-content-md-center">
                <Col md={4}>
                    <Toast className='datasource-toast'>
                        <Toast.Header
                            closeButton={false}
                        >
                            <Container>
                                <Row>
                                    <Col>
                                        <strong className="me-auto">Computername</strong>
                                    </Col>
                                </Row>
                            </Container>
                        </Toast.Header>
                        <Toast.Body>
                            <ul>
                                <li></li>
                            </ul>
                            <Form.Group controlId="settings-group">
                                <Form.Label>Computername</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={computerName}
                                    onChange={(event) => setComputerName(event.target.value)}
                                    placeholder="Computername"
                                />
                            </Form.Group>
                        </Toast.Body>
                    </Toast>

                </Col>
                <Col md={4}>
                    <Toast className='datasource-toast'>
                        <Toast.Header
                            closeButton={false}
                            onClick={() => { return null; }}
                        >
                            <Container>
                                <Row>
                                    <Col>
                                        <strong className="me-auto">Node-Server</strong>
                                        <Badge className='data-source-status-badge' variant={connectedToNodeServer ? 'success' : 'warning'}>{connectedToNodeServer ? ' verbunden' : 'nicht verbunden'}</Badge>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {(connectedToNodeServer === false) && (<Badge className='data-source-status-badge' variant='warning' >start_server.ps1 ausführen?</Badge>)}
                                    </Col>
                                </Row>
                            </Container>
                        </Toast.Header>
                        <Toast.Body>
                            <div>
                                <ul>
                                    <li>Wird für die Verbindung zum lokalen Dateisystem benötigt</li>
                                    <li>Muss verbunden sein um Bilder zu laden</li>
                                    <li>Muss verbunden sein um "IHKHSJSON-Datei mit kompletten Pfad" zu laden</li>
                                </ul>
                                <Form.Group controlId="settings-group3">
                                    <Form.Label>Node-Server-Host</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={nodeServerHost}
                                        onChange={(event) => setNodeServerHost(event.target.value)}
                                        placeholder="Node-Server-Host"
                                    />
                                    <Button
                                        onClick={() => testNodeServerConnection(nodeServerHost)}
                                        variant='secondary'
                                    >
                                        Verbindungstest
                                    </Button>
                                </Form.Group>
                            </div>
                        </Toast.Body>
                    </Toast>
                </Col>
                <Col md={4}>
                    <Toast className='datasource-toast'>
                        <Toast.Header closeButton={false}>
                            <Container>
                                <Row>
                                    <Col>
                                        <strong className="me-auto">Datenbank</strong>
                                        <Badge className='data-source-status-badge' variant={connectedToDatabase ? 'success' : 'warning'}>{connectedToDatabase ? 'verbunden' : 'nicht verbunden'}</Badge>
                                    </Col>
                                </Row>
                            </Container>
                        </Toast.Header>
                        <Toast.Body>
                            <ul>
                                <li>Muss für den Zugriff auf den kompletten Datenbestand verbunden sein.</li>
                                <li>Muss verbunden sein um Keyword-Korrekturen zu laden.</li>
                            </ul>
                            <Form.Group controlId="settings-group2">
                                <Form.Label>Datenbank-Host</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={databaseHost}
                                    onChange={(event) => setDatabaseHost(event.target.value)}
                                    placeholder="Datenbank-Host"
                                />
                                <Button
                                    onClick={() => testDatabaseConnection(databaseHost)}
                                    variant='secondary'
                                >
                                    Verbindungstest
                                </Button>
                            </Form.Group>
                        </Toast.Body>
                    </Toast>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button onClick={() => { saveSettings(); setShowSaveSuccessAlert(true); }}>
                        Einstellungen speichern
                    </Button>
                </Col>
            </Row>
        </Container>
    )
};

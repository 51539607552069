import { FC } from "react";
import { Dropdown, Table } from "react-bootstrap";
import { CompactPicker } from "react-color";

interface ColorSelectDropdownProps { 
    renderObjectKey: string,
    renderObjectsMap: any,
    updateColorFunction: any,
    selectedColor: string, // color as hex
}

export const ColorSelectDropdown: FC<ColorSelectDropdownProps> = ({
    renderObjectKey,
    renderObjectsMap,
    updateColorFunction, // updateColorFunction(renderObjectKey, color)
    selectedColor,
}) => {
    return (
        <>
            <Dropdown className='color-select-dropdown'>
                <Dropdown.Toggle size='sm' id='dropdown_render_object_select_with_preview' >
                    <Table>
                        <tbody>
                        <tr>
                            <td style={{backgroundColor:selectedColor}} className='color-td'>
                                
                            </td>
                            <td>ändern</td>
                        </tr>
                        </tbody>
                    </Table>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <CompactPicker
                        color={ renderObjectsMap[renderObjectKey].colorElement }
                        onChangeComplete={(color) => updateColorFunction(renderObjectKey, color) }
                    />
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
}

import { FC, useState } from 'react';

interface ManualPageProps {
    // settings: Settings,
}

export const ManualPage: FC<ManualPageProps> = ({
}) => {

    const [testString, setTestString] = useState('Person, Woman, Man, Camera, TV');
    return (
        <>
            <h4>Hinweise zur Bedienung</h4>
            <p>Um horizonal durch die Tabelle zu scrollen, müssen Sie die Umschalt-Taste (Shift) gedrückt halten und gleichzeitig das Mausrad drehen.</p>

            <p>Mit den Pfeiltasten links und rechts kann die Seite gewechselt werden.</p>

            <h4>Hinweise zur Anpassung</h4>
            <p>Die Anwendung ermöglicht das Ein- und Ausblenden von Informationen für die verschiedenen Ansichten. Dazu kann man in den Einstellungen im Bereich "Sortierung" die einzelnen Spalten de/aktivieren bzw. deren Anzeigereihenfolge ändern.</p>


            <h4>Hinweise zur Suche</h4>

            <h5>Leerzeichen</h5>
            <p>Bei der Suche sollten keine Leerzeichen eingetragen werden.</p>

            <h5>Umlaute</h5>
            <p>Die manuell vergebenen Stichwörter (Keywords) enthalten keine Umlaute. In wenigen Fällen wurde über die Spaltendefinitionen dieser Anwendung dafür gesorgt, dass Umlaute benutzt werden. Beispiele dafür sind z.B. Bilder aus Köln. In den Stichwörtern wurde "zK-" geschrieben. Durch eine Regel wird dies durch "Köln-" ersetzt.</p>
            <p>Bei der Filterung sollten auch nach den Begriffen ohne Umlaute gesucht werden.</p>
            <h5>Loknummern</h5>
            <p>Bei den DB/DR-Fahrzeugen wurden die Loknummer ohne die Prüfziffer notiert. Nur in den Fällen, wo die Loknummer schlecht lesbar war wurde die Loknummer inkl. der Prüfziffer aufgezeichnet.</p>
            <p>Bei den Lok-Nr.-Abgaben wurde in der Regel das 7-stellige Nummernschema, das 1968 eingeführt wurde verwendet. Dabei kann sein, dass auf der Lok die alte Nummer zu sehen ist.</p>
            <p>Bei der Suche sollte ohne Leerzeichen und ohne Prüfziffer gesucht werden.</p>
            <p>Bei DR, PKP und ÖBB enthalten die Loknummern einen Bindestrich zwischen der Baureihe und der Ordnungsnummer. Der Strich muss mit eingegeben werden.</p>
            <p>Bei Loknummern, die einen Punkt enthalten wurde dieser mit einem Bindestrich bei der Eingabe der Stichwörter ersetzt.</p>

            <h4>Allgemeine Hinweise</h4>

            <h5>Ort ungenau bzw. unbekannt</h5>
            <p>Wenn die Ortsangabe noch unvollständig ist, dann endet das Ort-Stichwort mit einem Bindestrich. Beispielsweise besagt "Muenchen-", dass ein Bild in München aufgenommen wurde - der genaue Stadtteil bzw. die Haltestelle ist jedoch unbekannt.</p>

            <h5>Datum ungenau bzw. unbekannt</h5>
            <p>Bei einigen gescannten Bildern ist das Aufnahmedatum nicht genau bekannt. Wenn ein Datum-Stichwort z.B. mit 010170 angegeben ist bedeutet es, dass das Bild im Jahr 1970 aufgenommen wurde.</p>
            <p>Analog dazu gibt es die Logik, dass der Monat bekannt ist, aber nicht der Tag. Dann ist im Datum-Stichwort z.B. 010570 angegeben - Dann ist das Bild im Mai 1970 aufgenommen.</p>
            <p>Als Ausnahme zu dieser allgemeinen Logik gibt es das Stichwort "yio". Ist dieses Stichwort vergeben, bedeutet es, dass das angegebene Datum korrekt ist. Wenn die Stichwörter "010570" und "yio" vergeben sind wurde das Bild am 1. Mai 1970 aufgenommen.</p>
        </>

    )
};

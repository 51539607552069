import { FC, useState } from "react";
import { Alert, Button, Card, Col, Container, Modal, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faImages, faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { ColumnPresetFile, DataFilePresetFile } from "../custom_types";
import { Dropzone } from "../Dropzone";
import { FILENAME_FOR_EMPTY_COLUMNS_PRESET } from "../constants";
import { numberWithSeparator } from "../Helpers";
import { QRCodeSVG } from "qrcode.react";

interface SplashScreenModalProps {
    darkMode: boolean,
    showSplashScreenModal: any,
    handleCloseSplashScreenModal: any, // function
    appHasData: boolean,
    replaceDirectoryInFilePath: any, //function
    loadDataFromFile: any,
    presetColumnFilesArray: ColumnPresetFile[],
    presetDataFilesArray: DataFilePresetFile[],
    connectedToDatabase: boolean,
    legalStringLinesArray: string[],
}

export const SplashScreenModal: FC<SplashScreenModalProps> = ({
    darkMode,
    showSplashScreenModal: showDataSourceSelectModal,
    handleCloseSplashScreenModal: handleCloseSplashScreenModal,
    loadDataFromFile,
    appHasData,
    presetColumnFilesArray,
    presetDataFilesArray,
    connectedToDatabase,
    legalStringLinesArray,
}) => {

    const SHOW_QR_TOOLTIP_DELAY_MS = 5000;
    const urlForDatabasePreset = `${window.location.origin}/?usedatabase=1&loadcustomcolumns=custom-columns-and-remarks-khs-neu-test.ihkhscustomcolumns`;
    const [dataPresetFileSelectedIndex, setDataPresetFileSelectedIndex] = useState(-1 as number);
    const [showQrTooltip, setShowQrTooltip] = useState(false);
    const DATA_PRESET_INDEX_DATABASE = -1;

    const markPresetDataFileButtonAsSelected = (dataPresetPresetFileIndex: number) => {
        // if (dataPresetPresetFileIndex === -1) {
        //     setDataSource(DATA_SOURCE_DATABASE);
        //     //setDataSourceOnlyFilesystemAndJSONFilename('');
        //     handleCloseSplashScreenModal();
        // } else {
        //     setDataPresetFileSelectedIndex(dataPresetPresetFileIndex);
        // }

        setDataPresetFileSelectedIndex(dataPresetPresetFileIndex);
    }

    const generateLinkForDataFilePresetFile = (dataFilePresetFile : DataFilePresetFile) => {
        //let returnUrl = '/';
        let tmpUrlOptionalPartsArray : string[] = [];

        // if (dataFilePresetFile.filename) {
        //     returnUrl = dataFilePresetFile.filename;
        // }

        if (dataFilePresetFile.filePathReplace1String || dataFilePresetFile.filePathReplace2String) {
            tmpUrlOptionalPartsArray.push(`frp1=${dataFilePresetFile.filePathReplace1String}`);
            tmpUrlOptionalPartsArray.push(`frp2=${dataFilePresetFile.filePathReplace2String}`);
        }

        if (dataFilePresetFile.columnPresetFile) {
            tmpUrlOptionalPartsArray.push(`loadcustomcolumns=${dataFilePresetFile.columnPresetFile}`);
        }

        if (dataFilePresetFile.folderGridViewImgFilenamePrefix) {
            //tmpUrlOptionalPartsArray.push(`loadcustomcolumns=${dataFilePresetFile.columnPresetFile}`);
        }


        

        if (dataFilePresetFile.ignorePathForfilePathReplacement) {
            tmpUrlOptionalPartsArray.push(`ignorePathForfilePathReplacement=1`);
        }

        if (dataFilePresetFile.fileExtReplaceString) {
            tmpUrlOptionalPartsArray.push(`fileExtReplaceString=${dataFilePresetFile.fileExtReplaceString}`);
        }

        if (tmpUrlOptionalPartsArray.length) {
            return `${window.location.origin}/?loadjson=${dataFilePresetFile.filename}&${tmpUrlOptionalPartsArray.join('&')}`;
        } else {
            return `${window.location.origin}/?loadjson=${dataFilePresetFile.filename}`;
        }
    }

    // Add links to presetDataFilesArray
    // start at 1 because DATA_PRESET_INDEX_DATABASE is 0
    for (let i=1; i<presetDataFilesArray.length; i++) {
        presetDataFilesArray[i]['linkUrl'] = generateLinkForDataFilePresetFile(presetDataFilesArray[i-1]);   
    }
    // For Database
    //presetDataFilesArray[-1]['linkUrl'] = `${window.location.origin}/?usedatabase=1`;

    let localColumnPresetFilesArray = [];
    localColumnPresetFilesArray.push({
        title: 'Ohne',
        filename: FILENAME_FOR_EMPTY_COLUMNS_PRESET,
        description: 'Nur Basis-Spalten mit den Metadaten aus dem ExifTool',
      } as ColumnPresetFile);
      localColumnPresetFilesArray.push(...presetColumnFilesArray);

    return (
        <Modal size='xl' className={darkMode ? 'darkmode splash-screen-modal' : 'splash-screen-modal'} show={showDataSourceSelectModal} onHide={() => handleCloseSplashScreenModal(appHasData)}>
            <Modal.Header closeButton={appHasData ? true : false}>
                <Modal.Title>
                    Willkommen
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                <Row>
                    <Col md={12}>
                        <h5>Was ist das hier?</h5>

                        <p>Das Ziel dieser Anwendung ist es Strassen- und Eisenbahnfreunden die Bildersammlung zugänglich zu machen. Es besteht die Absicht, einige Fehler in den Stichwörtern zu finden und unbekannte Daten aufzuklären. Diese Bilder finden Sie in der Sammlung "Ort unbekannt".</p>
                        <p>Zur Suche sind ein paar Hinweise im Handbuch notiert, dass unten links über das Fragezeichen erreichbar ist.</p>
                        <p>Viel Spaß beim Anschauen!</p>
                        <hr/>
                    </Col>
                    
                </Row>
                <Row>
                    <Col sm={6}>
                        {connectedToDatabase &&
                        <>
                            <h5>Datenbank</h5>
                            <Row key={`splash-screen-modal-row-${DATA_PRESET_INDEX_DATABASE}`}>
                                <Col lg={8}>
                                    <Button
                                        key={`splash-screen-modal-data-file-preset-button-${DATA_PRESET_INDEX_DATABASE}`}
                                        size='sm'
                                        className={`data-file-preset-select-button ${dataPresetFileSelectedIndex===DATA_PRESET_INDEX_DATABASE?'selected':''}`}
                                        variant='outline-secondary'
                                        onClick={(e) => {
                                            setShowQrTooltip(false);
                                            markPresetDataFileButtonAsSelected(DATA_PRESET_INDEX_DATABASE);
                                            setTimeout(() => {setShowQrTooltip(true)}, SHOW_QR_TOOLTIP_DELAY_MS);
                                        }}
                                    >
                                        <h4>Datenbank</h4>
                                        <p>Nutze Datenbank</p>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <FontAwesomeIcon icon={faImages} /> ohne Bilder
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </Table>
                                    </Button>
                                </Col>
                                <Col sm={4}>
                                    {(dataPresetFileSelectedIndex===DATA_PRESET_INDEX_DATABASE) && 
                                        <Card className='data-file-preset-select-card'>
                                            <Card.Body>
                                                <OverlayTrigger
                                                    placement='right'
                                                    show={showQrTooltip}
                                                    overlay={
                                                        <Tooltip id='tooltip-bottom'>
                                                            QR-Code klicken oder mit dem Smartphone abfotografieren und es dort öffnen
                                                        </Tooltip>
                                                    }
                                                    >
                                                    <a href={urlForDatabasePreset}>
                                                        <QRCodeSVG value={urlForDatabasePreset} />
                                                    </a>
                                                </OverlayTrigger>                                                
                                            </Card.Body>
                                        </Card>
                                    }
                                </Col>
                            </Row>
                        </>
                        }
                        <h5>Auswählbare Sammlungen</h5>
                        {
                            presetDataFilesArray.map((dataPresetPresetFile: DataFilePresetFile, dataPresetFileIndex: number) => 
                                <Row key={`splash-screen-modal-row-${dataPresetFileIndex}`}>
                                    <Col lg={8}>
                                        <Button
                                            key={`splash-screen-modal-data-file-preset-button-${dataPresetFileIndex}`}
                                            size='sm'
                                            className={`data-file-preset-select-button ${dataPresetFileSelectedIndex===dataPresetFileIndex?'selected':''}`}
                                            variant='outline-secondary'
                                            onClick={() => { document.location = generateLinkForDataFilePresetFile(dataPresetPresetFile) }}
                                        >
                                            <h4>{dataPresetPresetFile.title}</h4>
                                            <p>{dataPresetPresetFile.description}</p>
                                            <Table>
                                                <tbody>
                                                    <tr>
                                                        <td><FontAwesomeIcon icon={faCalendar} /> {dataPresetPresetFile.dateString}</td>
                                                        <td><FontAwesomeIcon icon={faLayerGroup} /> {numberWithSeparator(dataPresetPresetFile.amountOfFiles)} Einträge </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <FontAwesomeIcon icon={faImages} />{dataPresetPresetFile.hasImages ? ' inkl. Bilder' : ' ohne Bilder'}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Button> 
                                    </Col>
                                    <Col lg={4}>
                                    </Col>
                                </Row>
                            )
                        }
                    </Col>
                    <Col sm={5}>
                        <h5>Datei hochladen</h5>
                        <Dropzone
                            key='splash-screen-modal-dropzone'
                            loadDataFromFile={loadDataFromFile}
                        />

                        {legalStringLinesArray.length>=1 &&
                            <>
                                <h5>Impressum</h5>
                                <Alert variant='info'>
                                    {legalStringLinesArray.map((legalStringLine: string) => 
                                        <>
                                            {legalStringLine}<br/>
                                        </>
                                    )}
                                </Alert>
                            </>
                        }
                    </Col>
                    <Col sm={1}>
                    </Col>
                </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

import { FC } from "react";
import { RenderConfigObject } from "./custom_types";
import { Col, Dropdown, ListGroup, Row } from "react-bootstrap";
import { KeywordsTableTd } from "./KeywordsTable/KeywordsTableTd";


interface RenderObjectSelectWithPreviewProps { 
    renderConfigObject: RenderConfigObject,
    onClickFunction: any,
    renderObjectsMap: any,
    dataKeyColumnDefinitionIndex: number,
}

export const RenderObjectSelectWithPreview: FC<RenderObjectSelectWithPreviewProps> = ({
    renderConfigObject,
    onClickFunction,
    renderObjectsMap,
    dataKeyColumnDefinitionIndex,
}) => {
    return (
        <>
            <Dropdown>
                <Dropdown.Toggle split size='sm' variant='secondary' id='dropdown_render_object_select_with_preview'>
                    {renderConfigObject.name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {Object.keys(renderObjectsMap).map((renderObjectKey: string) => 
                        <ListGroup.Item
                            onClick={() => onClickFunction(renderObjectKey, dataKeyColumnDefinitionIndex)}
                        >
                            <Dropdown.Item>
                                <Row>
                                    <Col md={6}>
                                        {renderObjectsMap[renderObjectKey].name}
                                    </Col>
                                    <Col md={6}>
                                        <KeywordsTableTd
                                            //TODO: Add unique key here
                                            key={`keywords_row_keywords_table_td_4711`}
                                            contentArray={['example 1', 'example 2']}
                                            renderConfigObject={renderObjectsMap[renderObjectKey]}
                                            // @ts-ignore
                                            trimStringStart={0}
                                            // @ts-ignore
                                            trimStringEnd={0}
                                            width={200}
                                        />
                                    </Col>
                                </Row>
                            </Dropdown.Item>
                        </ListGroup.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
}

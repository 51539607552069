import { FC, Fragment, useState } from "react";
import { ColumnDefinition, RemarksColumnDefinition, RemarkDefinition, ConditionConfigType, ColumnsForViewObject, Settings, ImageWithMetadataObject, CustomColumnDefinition, ExchangeRemarksColumnDefinition, ExchangeCustomColumnDefinition } from "./custom_types";
import { Accordion, Alert, Button, Card, Col, Dropdown, Form, Row, Tab, Table, Tabs } from "react-bootstrap";
import { COLUMNS_FOR_COMPARE_VIEW_KEY, COLUMNS_FOR_DETAIL_VIEW_KEY, COLUMNS_FOR_FOLDER_VIEW_KEY, COLUMNS_FOR_GRID_VIEW_KEY, COLUMNS_FOR_TABLE_VIEW_KEY, COLUMNS_FOR_VIEWS_ARRAY, COLUMN_DEFINITION_CONST_FILENAME, COLUMN_DEFINITION_CONST_KEYWORDS, COLUMN_DEFINITION_TYPE_CUSTOM_COLUMN_DEFINITION, COLUMN_DEFINITION_TYPE_REMARKS_COLUMN_DEFINITION, DEFAULT_RENDER_CONFIG_OBJECT, DEFAULT_WITH_FOR_NEW_COLUMNS, emptyImageWithMetadataObject, FORMATFUNCTION_DEFAULT_KEY, REMARKS_COLUMN_TAB_KEY_ADD, RENDER_OBJECT_MAP_DEFAULT_KEY, requiredPropertiesOfImageWithMetadataObjectArray, WARNING_CONDITIONS_ARRAY, WARNING_CONDITION_TYPE_DOES_MATCH_KEY, WARNING_CONDITION_TYPE_DOES_MATCH_NAME, WARNING_CONDITION_TYPE_DOES_NOT_MATCH_NAME, WARNING_CONFIG_GROUP_CONNECT_TYPES } from "./constants";
import { getUniqueValueForCustomColumnConstant, getUniqueValueForRenderObjectsConstant } from "./Helpers";
import { faGlasses, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CombinedColumnSelect } from "./CombinedColumnSelect";
import { RemarksColumnsTableDemo } from "./RemarksColumnsTableDemo";
import { AdditionalColumnsRepository } from "./AdditionalColumnsRepository";


interface RemarksColumnsTableProps { 
    remarksColumnsArray: RemarksColumnDefinition[],
    setRemarksColumnsArray: any, // function
    customColumsArray: CustomColumnDefinition[],
    setCustomColumsArray: any,
    columnDefinitionArray: ColumnDefinition[],

    setImagesWithMetadataNeedsNewEnrichment: any,
    dataSource: number,
    columnsForViewArray: ColumnsForViewObject,
    setColumnsForViewArray: any,
    getColumnDefinitionByCustomDefinitionConstant: any,
    settings: Settings,
    enrichImageWithMetadataObjectArray: any,
    activeRemarksColumnTabKey: string,
    setActiveRemarksColumnTabKey: any,
    setActiveCustomColumnTabKey: any,
    setActiveTabKeyColumnDefinitionModal: any,
    renderObjectsMap: any,
    additionalColumnsRepoRemarkColumnsArray: ExchangeRemarksColumnDefinition[],
    additionalColumnsRepoCustomColumnsArray: ExchangeCustomColumnDefinition[],
}

export const RemarksColumnsTable: FC<RemarksColumnsTableProps> = ({
    columnDefinitionArray,
    customColumsArray,
    setCustomColumsArray,
    remarksColumnsArray,
    setRemarksColumnsArray,
    setImagesWithMetadataNeedsNewEnrichment,
    dataSource,
    columnsForViewArray,
    setColumnsForViewArray,
    getColumnDefinitionByCustomDefinitionConstant,
    settings,
    enrichImageWithMetadataObjectArray,
    activeRemarksColumnTabKey,
    setActiveRemarksColumnTabKey,
    setActiveCustomColumnTabKey,
    setActiveTabKeyColumnDefinitionModal,
    renderObjectsMap,
    additionalColumnsRepoCustomColumnsArray,
    additionalColumnsRepoRemarkColumnsArray,
}) => {    
    const regexTmpStrings = [] as string[];
    const [exampleImageWithMetadataObject, setExampleImageWithMetadataObject] = useState({...emptyImageWithMetadataObject})

    // @ts-ignore
    const updatePropertyInExampleImageWithMetadataObject = (newValue: string, columnDefinitionConstant: string) => {
        debugger;
        var newExampleImageWithMetadataObject: ImageWithMetadataObject = Object.assign({}, exampleImageWithMetadataObject);

        // Remove property when column is not required in ImageWithMetadataObject
        if (newValue === '' && requiredPropertiesOfImageWithMetadataObjectArray.indexOf(columnDefinitionConstant) === -1) {
            delete newExampleImageWithMetadataObject[columnDefinitionConstant];
        } else {
            if (columnDefinitionConstant === COLUMN_DEFINITION_CONST_KEYWORDS) {
                // @ts-ignore
                newExampleImageWithMetadataObject[columnDefinitionConstant] = newValue.replace(/ /g, '').split(',');
            } else {
                newExampleImageWithMetadataObject[columnDefinitionConstant] = newValue;
            }
        }
        setExampleImageWithMetadataObject(newExampleImageWithMetadataObject);
    }

    // @ts-ignore
    const updateNameInFrontend = (event) => {
        const remarksColumnIndexDataKey = event.target.getAttribute('data-key-remarks-column-index');
        const newRemarksColumnsArray : RemarksColumnDefinition[] = Object.assign([], remarksColumnsArray);
        const newNameInFrontend = event.target.value;
        newRemarksColumnsArray[remarksColumnIndexDataKey].nameInFrontend = event.target.value;
        
        var newColumnDefinitionConstant = getUniqueValueForCustomColumnConstant(
            newNameInFrontend,
            newRemarksColumnsArray[remarksColumnIndexDataKey].columnDefinitionType,
            customColumsArray,
            remarksColumnsArray
        );

        // Update entries in columnsForViewArray
        for (let i=0; i<COLUMNS_FOR_VIEWS_ARRAY.length; i++) {
            for (let j=0; j<columnsForViewArray[COLUMNS_FOR_VIEWS_ARRAY[i]].length; j++) {
                if (columnsForViewArray[COLUMNS_FOR_VIEWS_ARRAY[i]][j].columnDefinitionConstant === newRemarksColumnsArray[remarksColumnIndexDataKey].columnDefinitionConstant) {
                    columnsForViewArray[COLUMNS_FOR_VIEWS_ARRAY[i]][j].columnDefinitionConstant = newColumnDefinitionConstant;
                    columnsForViewArray[COLUMNS_FOR_VIEWS_ARRAY[i]][j].nameInFrontend = newNameInFrontend;
                } 
            }
        }

        newRemarksColumnsArray.map((remarksColumnObject: RemarksColumnDefinition) => {
            remarksColumnObject.remarksArray.map((remarkObject: RemarkDefinition) => {
                remarkObject.conditionConfigArray.map((conditionConfigObject: ConditionConfigType) => {
                    if (conditionConfigObject.columnDefinition.columnDefinitionConstant === newRemarksColumnsArray[remarksColumnIndexDataKey].columnDefinitionConstant) {
                        conditionConfigObject.columnDefinition.columnDefinitionConstant = newColumnDefinitionConstant;
                        conditionConfigObject.columnDefinition.nameInFrontend = newNameInFrontend;
                    }
                });
            });
        });
        newRemarksColumnsArray[remarksColumnIndexDataKey].columnDefinitionConstant = newColumnDefinitionConstant;
        setRemarksColumnsArray(newRemarksColumnsArray);
        changeActiveTab(newColumnDefinitionConstant);
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

    // @ts-ignore
    const updateRemarkString = (event) => {
        const remarksColumnIndexDataKey = event.target.getAttribute('data-key-remarks-column-index');
        const remarkIndexDataKey = event.target.getAttribute('data-key-remark-index');
        const newRemarksColumnsArray : RemarksColumnDefinition[] = Object.assign([], remarksColumnsArray);
        newRemarksColumnsArray[remarksColumnIndexDataKey].remarksArray[remarkIndexDataKey].remarkString = event.target.value;
        setRemarksColumnsArray(newRemarksColumnsArray);
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

    // @ts-ignore
    const selectWarningConditionType = (event) => {
        const remarksColumnIndexDataKey = event.target.getAttribute('data-key-remarks-column-index');
        const remarkIndexDataKey = event.target.getAttribute('data-key-remark-index');
        const conditionConfigArrayIndexDataKey = event.target.getAttribute('data-key-condition-config-array-index');
        const selectedWarningConditionTypeDataKey = event.target.getAttribute('data-key-selected-warning-condition-type');

        const newRemarksColumnsArray : RemarksColumnDefinition[] = Object.assign([], remarksColumnsArray);
        newRemarksColumnsArray[remarksColumnIndexDataKey].remarksArray[remarkIndexDataKey].conditionConfigArray[conditionConfigArrayIndexDataKey].warningConditionType = selectedWarningConditionTypeDataKey;
        setRemarksColumnsArray(newRemarksColumnsArray);
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

    // @ts-ignore
    const selectColumnDefinitionForRemark = (event) => {
        const remarksColumnIndexDataKey = event.target.getAttribute('data-key-remarks-column-index');
        const remarkIndexDataKey = event.target.getAttribute('data-key-remark-index');
        const conditionConfigArrayIndexDataKey = event.target.getAttribute('data-key-condition-config-array-index');
        const dataKeyColumnDefinitionConstant = event.target.getAttribute('data-key-column-definition-constant');
        
        var selectedColumnDefinition = getColumnDefinitionByCustomDefinitionConstant(dataKeyColumnDefinitionConstant);

        if (selectedColumnDefinition) {
            const newRemarksColumnsArray : RemarksColumnDefinition[] = Object.assign([], remarksColumnsArray);
            newRemarksColumnsArray[remarksColumnIndexDataKey].remarksArray[remarkIndexDataKey].conditionConfigArray[conditionConfigArrayIndexDataKey].columnDefinition = selectedColumnDefinition;
            setRemarksColumnsArray(newRemarksColumnsArray);
            setImagesWithMetadataNeedsNewEnrichment(true);
        }
    }

    // @ts-ignore
    const updateRegexString = (event) => {
        const remarksColumnIndexDataKey = event.target.getAttribute('data-key-remarks-column-index');
        const remarkIndexDataKey = event.target.getAttribute('data-key-remark-index');
        const conditionConfigArrayIndexDataKey = event.target.getAttribute('data-key-condition-config-array-index');

        // First sets regexTmpStrings
        //regexTmpStrings[customColumnIndexDataKey] = event.target.value;

        // Then checks if regex is valid and then
        // the real variable is set - otherwise the broken regex causes problems

        const newRemarksColumnsArray : RemarksColumnDefinition[] = Object.assign([], remarksColumnsArray);
        const conditionConfigObject = newRemarksColumnsArray[remarksColumnIndexDataKey].remarksArray[remarkIndexDataKey].conditionConfigArray[conditionConfigArrayIndexDataKey];

        conditionConfigObject.regexStringForCHECKING=event.target.value;
        conditionConfigObject.regexStringValid=false;
        try {
            new RegExp(event.target.value);
            conditionConfigObject.regexStringValid=true;
            conditionConfigObject.regexString=event.target.value;
            setRemarksColumnsArray(newRemarksColumnsArray);
        } catch (error) {
            setRemarksColumnsArray(newRemarksColumnsArray);
        }
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

    // @ts-ignore
    const toggleRemarkActive = (event) => {
        const newRemarksColumnsArray : RemarksColumnDefinition[] = Object.assign([], remarksColumnsArray);
        const remarksColumnIndexDataKey = parseInt(event.target.getAttribute('data-key-remarks-column-index'));
        const remarkIndexDataKey = parseInt(event.target.getAttribute('data-key-remark-index'));
        newRemarksColumnsArray[remarksColumnIndexDataKey].remarksArray[remarkIndexDataKey].active=!newRemarksColumnsArray[remarksColumnIndexDataKey].remarksArray[remarkIndexDataKey].active;
        console.log("new active value");
        console.log(newRemarksColumnsArray[remarksColumnIndexDataKey].remarksArray[remarkIndexDataKey].active);
        setRemarksColumnsArray(newRemarksColumnsArray);
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

     // @ts-ignore
     const addRemarksColumn = (event) => {
        const nameInFrontend = 'Bemerkungen '+remarksColumnsArray.length;
        const newCustomColumnContant = getUniqueValueForCustomColumnConstant(nameInFrontend, COLUMN_DEFINITION_TYPE_CUSTOM_COLUMN_DEFINITION, customColumsArray, remarksColumnsArray);

        const newRemarksColumnsArray : RemarksColumnDefinition[] = Object.assign([], remarksColumnsArray);
        const regexToAdd = '';
        regexTmpStrings.push(regexToAdd);
        let newRemarkColumn = {
            active: true,
            selectable: true,
            columnDefinitionType: COLUMN_DEFINITION_TYPE_REMARKS_COLUMN_DEFINITION,
            sortPosition: 1,
            width: DEFAULT_WITH_FOR_NEW_COLUMNS,
            nameInFrontend: nameInFrontend,
            columnDefinitionConstant: newCustomColumnContant,
            renderConfigsMapKey: Object.keys(renderObjectsMap)[0],
            formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY,
            remarksArray: [
                {
                active: true,
                remarkString: 'Bemerkung 1',
                conditionConnectType: WARNING_CONFIG_GROUP_CONNECT_TYPES[0],
                conditionConfigArray: [
                    {
                    columnDefinition: columnDefinitionArray.find(cd => cd.columnDefinitionConstant === COLUMN_DEFINITION_CONST_FILENAME),
                    warningConditionType: WARNING_CONDITION_TYPE_DOES_MATCH_KEY, //Index in WARNING_CONDITIONS_ARRAY
                    regexString: '',
                    },
                ] as ConditionConfigType[],
                } as RemarkDefinition,
            ],
        } as RemarksColumnDefinition;
        
        // Adds new column to the beginning of columnsForViewArray
        for (let i=0; i<COLUMNS_FOR_VIEWS_ARRAY.length; i++) {
            columnsForViewArray[COLUMNS_FOR_VIEWS_ARRAY[i]].unshift(Object.assign({}, newRemarkColumn as ColumnDefinition));
        }

        newRemarksColumnsArray.push(newRemarkColumn);
        setRemarksColumnsArray(newRemarksColumnsArray);
        setActiveRemarksColumnTabKey(newCustomColumnContant);
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

    // @ts-ignore
    const removeRemarksColumn = (event) => {
        const newRemarksColumnsArray : RemarksColumnDefinition[] = Object.assign([], remarksColumnsArray);
        const remarksColumnObjectIndex = event.currentTarget.getAttribute('data-key-remarks-column-object-index');
        var removedColumnDefinition = newRemarksColumnsArray.splice(remarksColumnObjectIndex, 1)[0];
        setRemarksColumnsArray(newRemarksColumnsArray);

        if (removedColumnDefinition) {
            let newColumnsForViewObject = {
                [COLUMNS_FOR_TABLE_VIEW_KEY]: [],
                [COLUMNS_FOR_GRID_VIEW_KEY]: [],
                [COLUMNS_FOR_COMPARE_VIEW_KEY]: [],
                [COLUMNS_FOR_DETAIL_VIEW_KEY]: [],
                [COLUMNS_FOR_FOLDER_VIEW_KEY]: [],
            };

            for (let i=0; i<COLUMNS_FOR_VIEWS_ARRAY.length; i++) {
                for (let j=0; j<columnsForViewArray[COLUMNS_FOR_VIEWS_ARRAY[i]].length; j++) {
                    if (columnsForViewArray[COLUMNS_FOR_VIEWS_ARRAY[i]][j].columnDefinitionConstant !== removedColumnDefinition.columnDefinitionConstant) {
                        newColumnsForViewObject[COLUMNS_FOR_VIEWS_ARRAY[i]].push(columnsForViewArray[COLUMNS_FOR_VIEWS_ARRAY[i]][j]);
                    } 
                }
            }
            setColumnsForViewArray(newColumnsForViewObject);
        }
    }

    // @ts-ignore
    const addRemarkToRemarkArray = (event) => {
        const remarksColumnIndexDataKey = event.currentTarget.getAttribute('data-key-remarks-column-index');
        const newRemarksColumnsArray : RemarksColumnDefinition[] = Object.assign([], remarksColumnsArray);
        newRemarksColumnsArray[remarksColumnIndexDataKey].remarksArray.push(
            {
                active: true,
                remarkString: 'Bemerkung ' + (newRemarksColumnsArray[remarksColumnIndexDataKey].remarksArray.length+1),
                conditionConnectType: WARNING_CONFIG_GROUP_CONNECT_TYPES[0],
                conditionConfigArray: [
                  {
                    columnDefinition: columnDefinitionArray.find(cd => cd.columnDefinitionConstant === COLUMN_DEFINITION_CONST_FILENAME),
                    warningConditionType: WARNING_CONDITION_TYPE_DOES_MATCH_KEY,
                    regexString: '',
                    regexStringForCHECKING: '',
                    regexStringValid: true,
                  },
                ] as ConditionConfigType[],
              } as RemarkDefinition,
        );
        setRemarksColumnsArray(newRemarksColumnsArray);
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

    // @ts-ignore
    const removeRemarkFromRemarkArray = (event) => {
        const newRemarksColumnsArray : RemarksColumnDefinition[] = Object.assign([], remarksColumnsArray);
        const remarksColumnObjectIndex = event.currentTarget.getAttribute('data-key-remarks-column-object-index');
        const remarkIndex = event.currentTarget.getAttribute('data-key-remark-index');

        (newRemarksColumnsArray[remarksColumnObjectIndex] as RemarksColumnDefinition).remarksArray.splice(remarkIndex, 1);
        setRemarksColumnsArray(newRemarksColumnsArray);
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

    // @ts-ignore
    const addConditionConfig = (event) => {
        const remarksColumnIndexDataKey = event.currentTarget.getAttribute('data-key-remarks-column-index');
        const remarkIndexDataKey = event.currentTarget.getAttribute('data-key-remark-index');
        const newRemarksColumnsArray : RemarksColumnDefinition[] = Object.assign([], remarksColumnsArray);

        newRemarksColumnsArray[remarksColumnIndexDataKey].remarksArray[remarkIndexDataKey].conditionConfigArray.push(
            {
            columnDefinition: columnDefinitionArray.find(cd => cd.columnDefinitionConstant === COLUMN_DEFINITION_CONST_FILENAME),
            warningConditionType: WARNING_CONDITION_TYPE_DOES_MATCH_KEY,
            regexString: '',
            regexStringForCHECKING: '',
            regexStringValid: true,
            } as ConditionConfigType,
        );
        setRemarksColumnsArray(newRemarksColumnsArray);
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

    // @ts-ignore
    const removeConditionConfig = (event) => {
        const newRemarksColumnsArray : RemarksColumnDefinition[] = Object.assign([], remarksColumnsArray);
        const remarksColumnObjectIndex = event.currentTarget.getAttribute('data-key-remarks-column-index');
        const remarkIndex = event.currentTarget.getAttribute('data-key-remark-index');
        const conditionConfigArrayIndex = event.currentTarget.getAttribute('data-key-condition-config-array-index');

        (newRemarksColumnsArray[remarksColumnObjectIndex] as RemarksColumnDefinition).remarksArray[remarkIndex].conditionConfigArray.splice(conditionConfigArrayIndex, 1);
        setRemarksColumnsArray(newRemarksColumnsArray);
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

    const toggleSearchReplaceIgnoreCaseForRegex = (remarksColumnIndex: number, remarkIndex: number, conditionConfigIndex: number) => {
        const newRemarksColumnsArray : RemarksColumnDefinition[] = Object.assign([], remarksColumnsArray);
        newRemarksColumnsArray[remarksColumnIndex].remarksArray[remarkIndex].conditionConfigArray[conditionConfigIndex].regexStringIgnoreCase = !newRemarksColumnsArray[remarksColumnIndex].remarksArray[remarkIndex].conditionConfigArray[conditionConfigIndex].regexStringIgnoreCase;
        setRemarksColumnsArray(newRemarksColumnsArray);
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

    const changeActiveTab = (newTabKey: string | null): void => {
        if (newTabKey) {
            setActiveRemarksColumnTabKey(newTabKey);
        }  
    }

    return (
        <>
            <Tabs activeKey={activeRemarksColumnTabKey} defaultActiveKey={REMARKS_COLUMN_TAB_KEY_ADD} onSelect={(key) => changeActiveTab(key)} className='additional-columns-second-layer-tabs'>
                <Tab eventKey={REMARKS_COLUMN_TAB_KEY_ADD} title="+" tabClassName='tab-primary' className='tab-with-margin additional-columns-second-layer-tab'>
                    <Alert variant='info'>
                    <p>
                        In dieser Ansicht kann z.B. eine Spalte mit Warnungen definiert werden.
                        Darin können beliebig viele Warnungstexte ausgegeben werden, wenn bestimmte Bedingungen erfüllt sind.
                    </p>
                    </Alert>
                    <Button
                        size='sm'
                        variant='secondary'
                        //data-key-custom-column-index={customColumnObjectIndex}
                        onClick={(event) => addRemarksColumn(event)}
                    >
                        Zusatzspalte hinzufügen
                    </Button>
                    <AdditionalColumnsRepository
                        additionalColumnsRepoRemarkColumnsArray={additionalColumnsRepoRemarkColumnsArray}
                        additionalColumnsRepoCustomColumnsArray={additionalColumnsRepoCustomColumnsArray}
                        customColumnsArray={customColumsArray}
                        setCustomColumnsArray={setCustomColumsArray}
                        remarksColumnsArray={remarksColumnsArray}
                        setRemarksColumnsArray={setRemarksColumnsArray}
                        setActiveCustomColumnTabKey={setActiveCustomColumnTabKey}
                        setActiveRemarksColumnTabKey={setActiveRemarksColumnTabKey}
                        setActiveTabKeyColumnDefinitionModal={setActiveTabKeyColumnDefinitionModal}
                    />
                </Tab>
                {remarksColumnsArray.map((remarksColumnObject: RemarksColumnDefinition, remarksColumnObjectIndex: number) => 
                    <Tab eventKey={remarksColumnObject.columnDefinitionConstant} title={remarksColumnObject.nameInFrontend} className='additional-columns-second-layer-tab'>
                    <br/>
                    <Accordion>
                        <Card>
                            <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                Demo
                            </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <RemarksColumnsTableDemo
                                    remarksColumnObject={remarksColumnObject}
                                    enrichImageWithMetadataObjectArray={enrichImageWithMetadataObjectArray}
                                    updatePropertyInExampleImageWithMetadataObject={updatePropertyInExampleImageWithMetadataObject}   
                                    exampleImageWithMetadataObject={exampleImageWithMetadataObject}                          
                                />
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    <Row>
                        <Col md={6}>
                            <Form.Label>Spaltenname</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                value={remarksColumnObject.nameInFrontend}
                                data-key-remarks-column-index={remarksColumnObjectIndex}
                                onChange={(event) => updateNameInFrontend(event)}
                            />
                        </Col>
                        <Col md={6}>
                            <Button
                                className="float-right"
                                size='sm'
                                variant='danger'
                                data-key-remarks-column-object-index={remarksColumnObjectIndex}
                                onClick={removeRemarksColumn}
                            >
                                <FontAwesomeIcon icon={faTrash} /> Bemerkungsspalte löschen
                            </Button>
                        </Col>
                    </Row>
                    <Table key={`remarks-columns-table-table-${remarksColumnObjectIndex}`}>
                    <tbody>
                        {remarksColumnObject.remarksArray.map((remark: RemarkDefinition, remarkIndex: number) => 
                            <Fragment key={`remarks-columns-table-fragment-remark-index-${remarkIndex}`}>
                            {remarkIndex>=1 && (
                                <tr className='narrow_tr'>
                                    <td className='remarks-column-highlight-1'></td>
                                    <td colSpan={5}></td>
                                </tr>
                            )}
                            <tr className='additional_column_definition_headline_tr'>
                                <td colSpan={3}>
                                    <h4>Definition der eines Eintrags</h4>
                                </td>
                                <td colSpan={1}>
                                    <Button
                                        size='sm'
                                        variant='primary'
                                        className='float-right'
                                        data-key-remarks-column-index={remarksColumnObjectIndex}
                                        onClick={addRemarkToRemarkArray}
                                    >
                                        <FontAwesomeIcon icon={faPlus} /> Eintrag
                                    </Button>
                                    <Button
                                        size='sm'
                                        variant='danger'
                                        className='float-right'
                                        data-key-remarks-column-object-index={remarksColumnObjectIndex}
                                        data-key-remark-index={remarkIndex}
                                        onClick={removeRemarkFromRemarkArray}
                                    >
                                        <FontAwesomeIcon icon={faTrash} /> Eintrag
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={3}>
                                    <Form.Group controlId={"checkbox" + remarksColumnObject.columnDefinitionConstant}>
                                        <Form.Check
                                            type="checkbox"
                                            onChange={(e) => toggleRemarkActive(e)}
                                            checked={remark.active}
                                            data-key-remark-index={remarkIndex}
                                            data-key-remarks-column-index={remarksColumnObjectIndex}
                                        />
                                        <Form.Label>Ausgabetext</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            value={remark.remarkString}
                                            data-key-remarks-column-index={remarksColumnObjectIndex}
                                            data-key-remark-index={remarkIndex}
                                            onChange={(event) => updateRemarkString(event)}
                                        />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Alert variant='info'>
                                        {`Hier ist der Eintrag "${remark.remarkString}" für die Bemerkungsspalte "${remarksColumnObject.nameInFrontend}" definiert. `}
                                        {remark.conditionConfigArray.length===1 ?    
                                            `Der Eintrag wird angezeigt wenn die unten definierte Bedingung erfüllt ist.` :
                                            `Der Eintrag wird angezeigt wenn die ${remark.conditionConfigArray.length} unten definierten Bedingungen erfüllt sind.`
                                        }
                                    </Alert>
                                </td>
                            </tr>
                            <tr>
                                <td className='remarks-column-highlight-2'>{<strong>Bedingungen</strong>}</td>
                                <td className='remarks-column-highlight-2'>{<strong>Spalte</strong>}</td>
                                <td className='remarks-column-highlight-2'>{<strong>Typ</strong>}</td>
                                <td className='remarks-column-highlight-2'></td>
                            </tr>
                            {remark.conditionConfigArray.map((conditionConfigArrayObject: ConditionConfigType, conditionConfigArrayObjectIndex: number) => 
                                <Fragment key={`remarks-columns-table-fragment-condition-config-array-object-index-${remarkIndex}-${conditionConfigArrayObjectIndex}`}>
                                    {conditionConfigArrayObjectIndex>=1 && 
                                        <tr className='narrow_tr_for_connection_type'>
                                            <td className='remarks-column-highlight-2'>
                                            </td>
                                            <td colSpan={4} className='remarks-column-highlight-2'>
                                                {(remark.conditionConnectType.name == 'and' && 'UND') }
                                                {(remark.conditionConnectType.name == 'or' && 'ODER') }
                                            </td>
                                        </tr>
                                    }
                                    <tr>
                                        <td className='remarks-column-highlight-3'>
                                            <Button
                                                size='sm'
                                                variant='primary'
                                                data-key-remarks-column-index={remarksColumnObjectIndex}
                                                data-key-remark-index={remarkIndex}
                                                onClick={addConditionConfig}
                                            >
                                                <FontAwesomeIcon icon={faPlus} /> Bedingung
                                            </Button>
                                            { remark.conditionConfigArray.length>=2 ?
                                                <Button
                                                    size='sm'
                                                    variant='danger'
                                                    data-key-remarks-column-index={remarksColumnObjectIndex}
                                                    data-key-remark-index={remarkIndex}
                                                    data-key-condition-config-array-index={conditionConfigArrayObjectIndex}
                                                    onClick={removeConditionConfig}
                                                >
                                                    <FontAwesomeIcon icon={faTrash} /> Bedingung
                                                </Button>
                                            : <></> }
                                        </td>
                                        <td className='remarks-column-highlight-3'>
                                            <CombinedColumnSelect
                                                remarksColumnsArray={remarksColumnsArray.filter(e => e.columnDefinitionConstant!==remarksColumnObject.columnDefinitionConstant)}
                                                customColumnsArray={customColumsArray}
                                                idPrefix='data_filter_row'
                                                indexForUniqueId={conditionConfigArrayObjectIndex.toString()}
                                                selectedColumnDefinition={conditionConfigArrayObject.columnDefinition}
                                                onClickFunction={selectColumnDefinitionForRemark}
                                                data-key-remarks-column-index={remarksColumnObjectIndex}
                                                data-key-condition-config-array-index={conditionConfigArrayObjectIndex}
                                                data-key-remark-index={remarkIndex}
                                                dataSource={dataSource}
                                            />
                                        </td>
                                        <td className='remarks-column-highlight-3'>
                                            <Dropdown>
                                                <Dropdown.Toggle split size='sm' variant='secondary' id={`dropdown_warning_conditions_${remarksColumnObjectIndex}_${conditionConfigArrayObjectIndex}`}>
                                                    {`${WARNING_CONDITIONS_ARRAY[conditionConfigArrayObject.warningConditionType]} `}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {Object.keys(WARNING_CONDITIONS_ARRAY).map((warningConditionKey: string) =>
                                                        <Dropdown.Item
                                                            eventKey={warningConditionKey}
                                                            data-key-remarks-column-index={remarksColumnObjectIndex}
                                                            data-key-condition-config-array-index={conditionConfigArrayObjectIndex}
                                                            data-key-remark-index={remarkIndex}
                                                            data-key-selected-warning-condition-type={warningConditionKey}
                                                            onClick={selectWarningConditionType}
                                                        >
                                                            {WARNING_CONDITIONS_ARRAY[warningConditionKey]}
                                                        </Dropdown.Item>
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                        <td className='remarks-column-highlight-3'>
                                            {[WARNING_CONDITION_TYPE_DOES_MATCH_NAME,WARNING_CONDITION_TYPE_DOES_NOT_MATCH_NAME].indexOf(WARNING_CONDITIONS_ARRAY[conditionConfigArrayObject.warningConditionType]) > -1 && (
                                                <>
                                                    <Form.Control
                                                        size="sm"   
                                                        type="text"
                                                        className={!conditionConfigArrayObject.regexStringValid ? 'regex-input-invalid' : ''}
                                                        value={conditionConfigArrayObject.regexStringForCHECKING}
                                                        data-key-remarks-column-index={remarksColumnObjectIndex}
                                                        data-key-condition-config-array-index={conditionConfigArrayObjectIndex}
                                                        data-key-remark-index={remarkIndex}
                                                        //onChange={(event) => setNodeServerHost(event.target.value)}
                                                        onChange={(event) => updateRegexString(event)}
                                                    />
                                                    <Form.Check 
                                                        type='checkbox'
                                                        checked={conditionConfigArrayObject.regexStringIgnoreCase}
                                                        onChange={() => toggleSearchReplaceIgnoreCaseForRegex(remarksColumnObjectIndex, remarkIndex, conditionConfigArrayObjectIndex)}
                                                        className='ignore-case-checkbox'
                                                        id={`default-checkbox`}
                                                        label='Groß- und Kleinschreibung ignorieren'
                                                    />
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                </Fragment>
                            )}  
                            </Fragment>
                        )}  
                    </tbody>
                    </Table>
                </Tab>
                )}
            </Tabs>

            {(remarksColumnsArray.length ===0 && (
                <Button
                    size='sm'
                    variant='secondary'
                    className='remarks-column-highlight-1'
                    onClick={addRemarksColumn}
                >
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            ))}
        </>
    );
}

import React, { FC } from "react";
import Alert from "react-bootstrap/Alert";

interface BaseTabContentComponentProps {
    headline?: string,
    mainContent: any, //React-Type
    errorString?: string,
    errorsArray?: string[],
}

export const BaseTabContentComponent: FC<BaseTabContentComponentProps> = ({
    headline,
    mainContent,
    errorString,
    errorsArray,
}) => {

    return (
        <div>
            { headline && <h1>{headline}</h1> }
            {mainContent}
            {errorString &&
            <Alert variant={"danger"}>
                <b>Errors:</b>
                {errorsArray}
            </Alert>
            }
        </div>
    );
}

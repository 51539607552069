import { FC } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import { DATA_SOURCE_DATABASE } from "../constants";
import { ColumnDefinition, ImageWithEnrichedMetadataObject, LoadingObject, PaginationObject } from "../custom_types";
import { DataAnalysis } from "../DataAnalysis";
import { LoadingProgress } from "../LoadingProgress";

interface DataAnalysisModalProps {
    darkMode: boolean,
    imageWithEnrichedMetadataObjectsArray: ImageWithEnrichedMetadataObject[],
    customColumnsArray: ColumnDefinition[],
    remarksColumnsArray: ColumnDefinition[],
    showDataAnalysisModal: any, // function
    setShowDataAnalysisModal: any, // function
    handleCloseDataAnalysisModal: any, // function
    mainPaginationObject: PaginationObject,
    dataSource: number,
    setDataFilterObjectArray: any,
    loadingObject: LoadingObject,
    increasePercentageOfLoadingObject: any,
    setLoadingCompleteForLoadingObject: any,
    applyFilterForDataSourceJSON: any,
}

export const DataAnalysisModal: FC<DataAnalysisModalProps> = ({
    darkMode,
    customColumnsArray,
    remarksColumnsArray,
    imageWithEnrichedMetadataObjectsArray,
    showDataAnalysisModal,
    setShowDataAnalysisModal,
    handleCloseDataAnalysisModal,
    mainPaginationObject,
    dataSource,
    setDataFilterObjectArray,
    loadingObject,
    increasePercentageOfLoadingObject,
    setLoadingCompleteForLoadingObject,
    applyFilterForDataSourceJSON,
}) => {

    return (
        <Modal size='xl' show={showDataAnalysisModal} onHide={handleCloseDataAnalysisModal} className={darkMode ? 'darkmode' : ''}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FontAwesomeIcon icon={faChartBar} /> Datenanalyse {dataSource === DATA_SOURCE_DATABASE && '(von aktueller Seite)'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {loadingObject.isLoading ?
                    <Row>
                        <Col>
                            <LoadingProgress loadingObject= {loadingObject}
                            >
                            </LoadingProgress>
                        </Col>
                    </Row>
                :
                <DataAnalysis
                    dataSource={dataSource}
                    imageWithEnrichedMetadataObjectsArray={imageWithEnrichedMetadataObjectsArray}
                    customColumnsArray={customColumnsArray}
                    remarksColumnsArray={remarksColumnsArray}
                    mainPaginationObject={mainPaginationObject}
                    setDataFilterObjectArray={setDataFilterObjectArray}
                    loadingObject={loadingObject}
                    increasePercentageOfLoadingObject={increasePercentageOfLoadingObject}
                    setLoadingCompleteForLoadingObject={setLoadingCompleteForLoadingObject}
                    setShowDataAnalysisModal={setShowDataAnalysisModal}
                    applyFilterForDataSourceJSON={applyFilterForDataSourceJSON}
                />
            }
            </Modal.Body>
        </Modal>
    );
}

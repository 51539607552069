import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {FC, useState} from "react";
import { Alert, Button, Card, Col, Dropdown, Form, Row, Tab, Table, Tabs } from "react-bootstrap";
import { ColorSelectDropdown } from "./ColorSelectDropdown";
import { COLUMNS_FOR_VIEWS_ARRAY, COLUMNS_FOR_VIEWS_MAP, DEFAULT_RENDER_CONFIG_OBJECT, RENDERCONTAINER_ARRAY, RENDER_CONTAINER_BADGE_FULL_WIDTH_KEY, RENDER_CONTAINER_BADGE_KEY, RENDER_OBJECT_CUSTOMIZER_TAB_KEY_ADD } from "./constants";
import { ColumnsForViewObject, RenderConfigObject, RenderContainer } from "./custom_types";
import { getUniqueValueForRenderObjectsConstant } from "./Helpers";
import { KeywordsTableTd } from "./KeywordsTable/KeywordsTableTd";

interface RenderObjectsCustomizerProps {
    renderObjectsMap: any,
    setRenderObjectsMap: any,
    columnsForViewArray: ColumnsForViewObject,
    setColumnsForViewArray: any,
}

export const RenderObjectsCustomizer: FC<RenderObjectsCustomizerProps> = ({
    renderObjectsMap,
    setRenderObjectsMap,
    columnsForViewArray,
    setColumnsForViewArray,
}) => {

    const [activeRenderObjectTabKey, setActiveRenderObjectTabKey] = useState(RENDER_OBJECT_CUSTOMIZER_TAB_KEY_ADD);

    const changeRenderConfigObject = (renderObjectKey: string, event: any) => {
        const selectedRenderContainerKey = event.target.getAttribute('data-key-selected-render-container-key');
        const newRenderObjectsMap = Object.assign({}, renderObjectsMap);
        newRenderObjectsMap[renderObjectKey].renderContainer = selectedRenderContainerKey;
        setRenderObjectsMap(newRenderObjectsMap);
    }

    const updateTextColor = (renderObjectKey: string, color: any) => {
        const newRenderObjectsMap = Object.assign({}, renderObjectsMap);
        newRenderObjectsMap[renderObjectKey].colorText = color.hex;
        setRenderObjectsMap(newRenderObjectsMap);
    };

    const updateElementColor = (renderObjectKey: string, color: any) => {
        const newRenderObjectsMap = Object.assign({}, renderObjectsMap);
        newRenderObjectsMap[renderObjectKey].colorElement = color.hex;
        setRenderObjectsMap(newRenderObjectsMap);
    };

    const updateBackgroundColor = (renderObjectKey: string, color: any|null) => {
        const newRenderObjectsMap = Object.assign({}, renderObjectsMap);
        newRenderObjectsMap[renderObjectKey].colorBackground = color ? color.hex : null;
        setRenderObjectsMap(newRenderObjectsMap);
    };

    const updateName = (renderObjectKey: string, event: any) => {
        const newRenderObjectsMap = Object.assign({}, renderObjectsMap);
        //newRenderObjectsMap[renderObjectKey].name = event.target.value;
        const oldConstant = newRenderObjectsMap[renderObjectKey].constant;
        const newConstant = getUniqueValueForRenderObjectsConstant(event.target.value, renderObjectsMap);

        if (oldConstant !== newConstant) {
            const newColumnsForViewArray = Object.assign({}, columnsForViewArray);
            for (let i=0; i<COLUMNS_FOR_VIEWS_ARRAY.length; i++) {
                for (let j=0; j<newColumnsForViewArray[COLUMNS_FOR_VIEWS_ARRAY[i]].length; j++) {
                    if (newColumnsForViewArray[COLUMNS_FOR_VIEWS_ARRAY[i]][j].renderConfigsMapKey === oldConstant) {
                        debugger;
                        newColumnsForViewArray[COLUMNS_FOR_VIEWS_ARRAY[i]][j].renderConfigsMapKey = newConstant;
                    } 
                }
            }
            setColumnsForViewArray(newColumnsForViewArray);
            setActiveRenderObjectTabKey(newConstant);
        }

        if (oldConstant === renderObjectKey) {
            newRenderObjectsMap[newConstant] = Object.assign({}, newRenderObjectsMap[renderObjectKey]);
            newRenderObjectsMap[newConstant].constant = newConstant;
            newRenderObjectsMap[newConstant].name = event.target.value;;
            delete newRenderObjectsMap[renderObjectKey];
            setRenderObjectsMap(newRenderObjectsMap);
        }
        
        //newRenderObjectsMap[renderObjectKey] = Object.assign({}, newRenderObjectsMap[renderObjectKey]);
        //newRenderObjectsMap[renderObjectKey].constant = newConstant;
        // TODO: renderObjectKey also needs an update!
    };

    const toggleBold = (renderObjectKey: string) => {
        const newRenderObjectsMap = Object.assign({}, renderObjectsMap);
        newRenderObjectsMap[renderObjectKey].bold = !newRenderObjectsMap[renderObjectKey].bold;
        setRenderObjectsMap(newRenderObjectsMap);
    }

    const toggleItalic = (renderObjectKey: string) => {
        const newRenderObjectsMap = Object.assign({}, renderObjectsMap);
        newRenderObjectsMap[renderObjectKey].italic = !newRenderObjectsMap[renderObjectKey].italic;
        setRenderObjectsMap(newRenderObjectsMap);
    }

    const toggleShowInList = (renderObjectKey: string) => {
        const newRenderObjectsMap = Object.assign({}, renderObjectsMap);
        newRenderObjectsMap[renderObjectKey].showInList = !newRenderObjectsMap[renderObjectKey].showInList;
        setRenderObjectsMap(newRenderObjectsMap);
    }

    const addRenderConfig = () => {
        const newRenderObjectsMap = Object.assign({}, renderObjectsMap);
        let newRenderConfigObject = Object.assign({}, DEFAULT_RENDER_CONFIG_OBJECT);
        const newConstant = getUniqueValueForRenderObjectsConstant(newRenderConfigObject.name, renderObjectsMap);
        newRenderConfigObject.constant = newConstant;
        newRenderObjectsMap[newConstant] = newRenderConfigObject;
        setRenderObjectsMap(newRenderObjectsMap);
    }

    const changeActiveTab = (newTabKey: string | null): void => {
        if (newTabKey) {
            setActiveRenderObjectTabKey(newTabKey);
        }  
    }

    const removeRenderObjectByKey = (renderObjectKey: string) => {

        const selectedRenderObject : RenderConfigObject = renderObjectsMap[renderObjectKey];

        // TODO: Check if the renderObject is used

        const usageStringsArray : string[] = [];
        //COLUMNS_FOR_VIEWS_ARRAY.map((columnForViewKey: string) => {
        for (var i=0; i<COLUMNS_FOR_VIEWS_ARRAY.length; i++) {
            for (var j=0; j<columnsForViewArray[COLUMNS_FOR_VIEWS_ARRAY[i]].length; j++) {
                if (selectedRenderObject.constant === columnsForViewArray[COLUMNS_FOR_VIEWS_ARRAY[i]][j].renderConfigsMapKey) {
                    usageStringsArray.push(`${COLUMNS_FOR_VIEWS_MAP[COLUMNS_FOR_VIEWS_ARRAY[i]]}: ${columnsForViewArray[COLUMNS_FOR_VIEWS_ARRAY[i]][j].nameInFrontend}`)
                }
            }    
        }

        if (usageStringsArray.length >=1 ) {
            alert(`Dieses Zellenaussehen kann derzeit nicht gelöscht werden, da es in foldenden Ansichten verwendet wird:\n\n ${usageStringsArray.join('\n')}`);
        } else {
            const newRenderObjectsMap = Object.assign({}, renderObjectsMap);
            delete newRenderObjectsMap[renderObjectKey];
            setRenderObjectsMap(newRenderObjectsMap);
        }
    }

    return (
        <>
            <Tabs activeKey={activeRenderObjectTabKey} defaultActiveKey={RENDER_OBJECT_CUSTOMIZER_TAB_KEY_ADD} onSelect={(key) => changeActiveTab(key)} className='additional-columns-second-layer-tabs'>
                <Tab eventKey={RENDER_OBJECT_CUSTOMIZER_TAB_KEY_ADD} title="+" tabClassName='tab-primary' className='tab-with-margin additional-columns-second-layer-tab'>
                    <Alert variant='info'>
                        Hier kann ein neues Zelleaussehen definiert werden. In der "Sortierung"-Ansicht kann das Zellenaussehen individuell für jede Ansicht eingestellt werden.
                    </Alert>
                    <Button
                        size='sm'
                        variant='secondary'
                        type='button'
                        onClick={addRenderConfig}
                    >
                        Neues Zellenaussehen
                    </Button>
                </Tab>
                {Object.keys(renderObjectsMap).map((renderObjectKey: string) => 
                    <Tab eventKey={renderObjectKey} title={renderObjectsMap[renderObjectKey].name} tabClassName='tab-primary' className='tab-with-margin additional-columns-second-layer-tab'>
                        <Row>
                            <Col md={12}>
                                <Button
                                    className="float-right"
                                    size='sm'
                                    variant='danger'
                                    
                                    onClick={() => removeRenderObjectByKey(renderObjectKey)}
                                >
                                    <FontAwesomeIcon icon={faTrash} /> Zellenaussehen löschen
                                </Button>
                            </Col>
                        </Row>
                        <Row className='render-objects-customizer-main-row'>
                            <Col md={4}>
                                <Form.Label><strong>Name</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    size="sm"
                                    value={renderObjectsMap[renderObjectKey].name}
                                    onChange={(event) => updateName(renderObjectKey, event)}
                                />
                                <Form.Label><strong>Text-Eigenschaften</strong></Form.Label>
                                <Form.Check
                                    label='Fettgedruckt'
                                    checked={renderObjectsMap[renderObjectKey].bold}
                                    onChange={(event) => toggleBold(renderObjectKey)}
                                />
                                <Form.Check
                                    label='Kursiv'
                                    checked={renderObjectsMap[renderObjectKey].italic}
                                    onChange={(event) => toggleItalic(renderObjectKey)}
                                />
                                <Form.Label><strong>Weitere Eigenschaften</strong></Form.Label>
                                <Form.Check
                                    label='Anzeige als Liste?'
                                    checked={renderObjectsMap[renderObjectKey].showInList}
                                    onChange={(event) => toggleShowInList(renderObjectKey)}
                                />
                                <Form.Label><strong>Anzeige</strong></Form.Label>
                                {RENDERCONTAINER_ARRAY.map((renderContainerObject: RenderContainer, renderContainerIndex: number) =>                                             
                                <>
                                    <Dropdown.Item
                                        active={renderContainerObject.key===renderObjectsMap[renderObjectKey].renderContainer}
                                        data-key-selected-render-container-key={renderContainerObject.key}
                                        onClick={(color) => changeRenderConfigObject(renderObjectKey, color)}
                                    >
                                        {renderContainerObject.name}
                                    </Dropdown.Item>
                                    </>
                                )}
                            </Col>
                            <Col md={4}>
                                {([RENDER_CONTAINER_BADGE_KEY, RENDER_CONTAINER_BADGE_FULL_WIDTH_KEY].indexOf(renderObjectsMap[renderObjectKey].renderContainer) !== -1) ?
                                    <>
                                        <Form.Label><strong>Badge-Farbe</strong></Form.Label>
                                        <ColorSelectDropdown
                                            renderObjectKey={renderObjectKey}
                                            renderObjectsMap={renderObjectsMap}
                                            selectedColor={renderObjectsMap[renderObjectKey].colorElement }
                                            updateColorFunction={updateElementColor}
                                        />
                                    </> : <></>
                                }
                                <Form.Label><strong>Hintergrund-Farbe</strong></Form.Label>
                                <Form.Check
                                        label='Hintergrund transparent'
                                        checked={!renderObjectsMap[renderObjectKey].colorBackground}
                                        onChange={() => updateBackgroundColor(renderObjectKey, null) }
                                />
                                <ColorSelectDropdown
                                    renderObjectKey={renderObjectKey}
                                    renderObjectsMap={renderObjectsMap}
                                    selectedColor={renderObjectsMap[renderObjectKey].colorBackground }
                                    updateColorFunction={updateBackgroundColor}
                                />
                                <Form.Label><strong>Text-Farbe</strong></Form.Label>
                                <ColorSelectDropdown
                                    renderObjectKey={renderObjectKey}
                                    renderObjectsMap={renderObjectsMap}
                                    selectedColor={renderObjectsMap[renderObjectKey].colorText }
                                    updateColorFunction={updateTextColor}
                                />
                            </Col>
                            <Col md={4}>
                                <Card style={{ width: '18rem' }}>
                                    <Card.Body>
                                        <Card.Title>Vorschau</Card.Title>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <KeywordsTableTd
                                                        key={`render_objects_customizer_keywords_table_td`}
                                                        contentArray={['foobar1', 'test']}
                                                        renderConfigObject={renderObjectsMap[renderObjectKey]}
                                                        trimStringStart={0}
                                                        trimStringEnd={0}
                                                        width={200}
                                                    />
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Tab>
                    
                )}
            </Tabs>
        </>
    );
}

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { CombinedColumnSelect } from "./CombinedColumnSelect";
import { initialBaseColumnDefinitionArray } from "./constants";
import { ColumnDefinition } from "./custom_types";


interface RelatedColumnSelectionProps {
    customColumnsArray: ColumnDefinition[],
    remarksColumnsArray: ColumnDefinition[],
    relatedColumnsArray: ColumnDefinition[],
    setRelatedColumnsArray: any,
    dataSource: number,
    getColumnDefinitionByCustomDefinitionConstant: any,
}

export const RelatedColumnSelection: FC<RelatedColumnSelectionProps> = ({
    customColumnsArray,
    remarksColumnsArray,
    relatedColumnsArray,
    setRelatedColumnsArray,
    dataSource,
    getColumnDefinitionByCustomDefinitionConstant,
}) => {
    const addRelatedColumn = () => {
        const newRelatedColumnsArray = Object.assign([], relatedColumnsArray);
        newRelatedColumnsArray.push(initialBaseColumnDefinitionArray[0]);
        setRelatedColumnsArray(newRelatedColumnsArray);
    }

    // @ts-ignore
    const selectRelatedColumn = (event) => {
        const newRelatedColumnsArray : ColumnDefinition[] = Object.assign([], relatedColumnsArray);
        const dataKeyColumnDefinitionConstant = event.target.getAttribute('data-key-column-definition-constant');
        const relatedColumnIndexDataKey = event.currentTarget.getAttribute('data-key-related-column-index');
        var selectedColumnDefinition = getColumnDefinitionByCustomDefinitionConstant(dataKeyColumnDefinitionConstant);

        if (selectedColumnDefinition) {
            newRelatedColumnsArray[relatedColumnIndexDataKey]=selectedColumnDefinition;
            setRelatedColumnsArray(newRelatedColumnsArray);
        }
    }

    return (
        <>
            <Row>
                <Col md={12}>
                    <Alert variant='info'>
                        <p>Welche Spalten sind relevant um verwandte Bilder zu finden?</p>
                        <p>Nach diesen Spalten wird in der Detailansicht gesucht.</p>
                    </Alert>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <Button
                      size='sm'
                      variant='secondary'
                      onClick={() => addRelatedColumn()}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Neue Spalte hinzufügen
                    </Button>
                </Col>
                <Col sm={4}>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <Form.Label>Spalten auswählen</Form.Label>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    {relatedColumnsArray.map((relatedCd: ColumnDefinition, relatedCdIndex: number) => 
                        <CombinedColumnSelect
                            dataSource={dataSource}
                            customColumnsArray={customColumnsArray}
                            remarksColumnsArray={remarksColumnsArray}
                            idPrefix='related_column_selection_'
                            indexForUniqueId={relatedCdIndex.toString()}
                            selectedColumnDefinition={relatedCd}
                            onClickFunction={selectRelatedColumn}
                            data-key-related-column-index={relatedCdIndex}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
}

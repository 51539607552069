import { FC, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { emptyImageWithMetadataObject } from "./constants";
import { RemarksColumnDefinition, RemarkDefinition, ConditionConfigType, ColumnDefinition, ImageWithMetadataObject } from "./custom_types";


interface RemarksColumnsTableDemoProps { 
    remarksColumnObject: RemarksColumnDefinition,
    enrichImageWithMetadataObjectArray: any,
    updatePropertyInExampleImageWithMetadataObject: any,
    exampleImageWithMetadataObject: ImageWithMetadataObject,
}

export const RemarksColumnsTableDemo: FC<RemarksColumnsTableDemoProps> = ({
    remarksColumnObject,
    enrichImageWithMetadataObjectArray,
    updatePropertyInExampleImageWithMetadataObject,
    exampleImageWithMetadataObject
}) => {    

    let relevantColumnDefinitionsMap = {};
    for (let i=0; i<remarksColumnObject.remarksArray.length; i++) {
        for (let j=0; j<remarksColumnObject.remarksArray[i].conditionConfigArray.length; j++) {
            let tmpCd : ColumnDefinition = remarksColumnObject.remarksArray[i].conditionConfigArray[j].columnDefinition;
            relevantColumnDefinitionsMap[tmpCd.columnDefinitionConstant] = tmpCd;
        }   
    }
    
    return (
        <Table>
            <tbody>
            <tr className='tr-demo-row'>
                <td colSpan={1} className='remarks-column-highlight-1'>
                    {/* @ts-ignore */}
                    {Object.values(relevantColumnDefinitionsMap).map((cd: ColumnDefinition, cdIndex: number) => 
                        <>
                            <label htmlFor="basic-url">
                                {`Inhalt der Spalte "${cd.nameInFrontend}":`}
                            </label>
                            <Form.Control
                                type="text"
                                value={exampleImageWithMetadataObject[cd.columnDefinitionConstant]}
                                onChange={(event) => updatePropertyInExampleImageWithMetadataObject(event.target.value, cd.columnDefinitionConstant)}
                            />
                        </> 
                    
                    )}
                </td>
                <td colSpan={1} className='remarks-column-highlight-1'>
                    {/* @ts-ignore */}
                    {Object.values(relevantColumnDefinitionsMap).map((cd: ColumnDefinition, cdIndex: number) => 
                        <div className='remarks_columns_table_arrow_lr'>-------&gt;</div>
                    
                    )}
                </td>
                <td colSpan={6} className='remarks-column-highlight-1'>
                        <>
                            <label htmlFor="basic-url">
                                {`Ausgabe in der Spalte "${remarksColumnObject.nameInFrontend}":`}
                            </label>
                            <Form.Control
                                disabled
                                type="text"
                                value={enrichImageWithMetadataObjectArray(
                                    [exampleImageWithMetadataObject],
                                    false // setLoadingVariables
                                )[0]['remarksColumns'][remarksColumnObject.columnDefinitionConstant].join(', ')}
                            />
                        </>
                </td>
            </tr>
            </tbody>
        </Table>
    )      
}

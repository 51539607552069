import { ColumnPresetFile, DataFilePresetFile } from "./custom_types";


export const userDefinedFormatFunctions: string[] = [
    'formatLoknummer',
];

export const userDefinedRenderFunctions: string[] = [
];

export const formatLoknummer = (s: string) => {
    if (s.indexOf('-') === -1) {
      let containsOnlyCharacters = s.match(/^[a-z]*$/i)
      if (!containsOnlyCharacters) {
        if (s.length === 6) {
          // TODO Add &nbsp; Non Breaking Space
          s = s.substring(0, 3) + ' ' + s.substring(3);
        } else if (s.length === 7) {
          s = s.substring(0, 4) + ' ' + s.substring(4);
        }
      }
      // Replace dashes with space
      return s.replace('-', ' ');
    } else {
      return s;
    }
  }
  
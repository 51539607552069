import { FC } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { ColumnDefinition, DB_Import, DB_System, ImageWithEnrichedMetadataObject, ImageWithMetadataObject, LoadingObject, Settings } from "../custom_types";
import { DataSourceSettings } from "../DataSourceSettings";
import { SettingsPage } from "../SettingsPage";
import { KeywordsTableSettings } from "../KeywordsTable/KeywordsTableSettings";
import { ImportToDatabase } from "../ImportToDatabase";
import { DATA_SOURCE_JSON } from "../constants";

interface DataSourceChangeModalProps {
    darkMode: boolean,
    settings: Settings,
    setSettings: any, // function
    connectedToDatabase: boolean,
    setConnectedToDatabase: any, // function
    connectedToNodeServer: boolean,
    setConnectedToNodeServer: any, // function
    testDatabaseConnection: any, // function
    testNodeServerConnection: any, // function
    databaseHost: string,
    setDatabaseHost: any, // function
    nodeServerHost: string,
    setNodeServerHost: any, // function
    setSettingsCollapseOpen: any, // function
    dataSource: number,
    setDataSource: any, // function
    loadingObject: LoadingObject,
    pathNameInputField: string,
    setPathNameInputField: any, // function
    keywordsLoadFromPathname: string,
    changePathNameInputField: any, // function
    ihkhsjsonHistoryItems: string[],
    loadDataFromJSONFromFilesystem: any, //function
    showDataSourceSelectModal: any,
    handleCloseDataSourceSelectModal: any, // function
    pathNameInputFieldExistsOnFilesystem: boolean,
    checkPathExistsOnFilesystem: any, //function
    appHasData: boolean,
    filePathReplace1String: string,
    filePathReplace2String: string,
    changeAndSetFilePathReplace1String: any, //function
    changeAndSetFilePathReplace2String: any, //function
    filePathReplace2StringExistsOnFileSystem: boolean,
    exampleImageWithEnrichedMetadataObject?: ImageWithEnrichedMetadataObject,
    replaceDirectoryInFilePath: any, //function
    loadDataFromFile: any,
    columnDefinitionArray: ColumnDefinition[],
    combinedColumnDefinitionArray: ColumnDefinition[],
    toggleCheckForKeywordCorrections: any, // function
    showPreviewImages: boolean,
    toggleShowPreviewImages: any, // function
    setShowPreviewImages: any, //function
    useImgSrcReplacement: boolean,
    useImgExtReplacement: boolean,
    setUseImgSrcReplacement: any, // function
    toggleUseImgSrcReplacement: any, // function
    toggleUseImgExtReplacement: any, // function
    checkForKeywordCorrections: boolean,
    setCheckForKeywordCorrections: any, // function
    doCalculateCheckNumberForLoknummer: boolean,
    setDoCalculateCheckNumberForLoknummer: any, //function
    toggleDoCalculateCheckNumberForLoknummer: any, //function
    fileExtReplaceString: string,
    setFileExtReplaceString: any,
    csrfToken: string,
    imageWithMetadataOriginalArray: ImageWithMetadataObject[],
    dataSourceOnlyFilesystemAndJSONFilename: string,
    importIsForbidden: boolean,
    systemsArray: DB_System[],
    importsArray: DB_Import[],
    updateImportsFromDatabase: any,
    updateSystemsFromDatabase: any,
}

export const DataSourceChangeModal: FC<DataSourceChangeModalProps> = ({
    darkMode,
    settings,
    setSettings,
    connectedToDatabase,
    setConnectedToDatabase,
    connectedToNodeServer,
    setConnectedToNodeServer,
    testDatabaseConnection,
    testNodeServerConnection,
    databaseHost,
    setDatabaseHost,
    nodeServerHost,
    setNodeServerHost,
    setSettingsCollapseOpen,
    dataSource,
    setDataSource,
    loadingObject,
    pathNameInputField,
    setPathNameInputField,
    keywordsLoadFromPathname,
    changePathNameInputField,
    ihkhsjsonHistoryItems,
    loadDataFromJSONFromFilesystem,
    showDataSourceSelectModal,
    handleCloseDataSourceSelectModal,
    pathNameInputFieldExistsOnFilesystem,
    checkPathExistsOnFilesystem,
    loadDataFromFile,
    appHasData,
    combinedColumnDefinitionArray,
    columnDefinitionArray,
    toggleCheckForKeywordCorrections,
    showPreviewImages,
    setShowPreviewImages,
    filePathReplace1String,
    filePathReplace2String,
    changeAndSetFilePathReplace1String,
    changeAndSetFilePathReplace2String,
    filePathReplace2StringExistsOnFileSystem,
    replaceDirectoryInFilePath,
    useImgExtReplacement,
    useImgSrcReplacement,
    setUseImgSrcReplacement,
    toggleUseImgSrcReplacement,
    toggleUseImgExtReplacement,
    checkForKeywordCorrections,
    setCheckForKeywordCorrections,
    doCalculateCheckNumberForLoknummer,
    setDoCalculateCheckNumberForLoknummer,
    toggleDoCalculateCheckNumberForLoknummer,
    exampleImageWithEnrichedMetadataObject,
    fileExtReplaceString,
    setFileExtReplaceString,
    csrfToken,
    imageWithMetadataOriginalArray,
    dataSourceOnlyFilesystemAndJSONFilename,
    importIsForbidden,
    systemsArray,
    importsArray,
    updateImportsFromDatabase,
    updateSystemsFromDatabase,
}) => {
    return (
        <Modal size='xl' className={darkMode ? 'darkmode data-source-change-modal' : 'data-source-change-modal'} show={showDataSourceSelectModal} onHide={handleCloseDataSourceSelectModal}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FontAwesomeIcon icon={faCog} /> Einstellungen
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                    <>
                    {!appHasData && (
                        <>
                            <h4>Datenquelle einstellen</h4>
                            <DataSourceSettings
                                settings={settings}
                                setSettings={setSettings}
                                connectedToDatabase={connectedToDatabase}
                                setConnectedToDatabase={setConnectedToDatabase}
                                connectedToNodeServer={connectedToNodeServer}
                                setConnectedToNodeServer={setConnectedToNodeServer}
                                testDatabaseConnection={testDatabaseConnection}
                                testNodeServerConnection={testNodeServerConnection}
                                databaseHost={databaseHost}
                                setDatabaseHost={setDatabaseHost}
                                nodeServerHost={nodeServerHost}
                                setNodeServerHost={setNodeServerHost}
                                setSettingsCollapseOpen={setSettingsCollapseOpen}
                                dataSource={dataSource}
                                setDataSource={setDataSource}
                                changePathNameInputField={changePathNameInputField}
                                keywordsLoadFromPathname={keywordsLoadFromPathname}
                                pathNameInputField={pathNameInputField}
                                setPathNameInputField={setPathNameInputField}
                                ihkhsjsonHistoryItems={ihkhsjsonHistoryItems}
                                loadDataFromJSONFromFilesystem={loadDataFromJSONFromFilesystem}
                                pathNameInputFieldExistsOnFilesystem={pathNameInputFieldExistsOnFilesystem}
                                checkPathExistsOnFilesystem={checkPathExistsOnFilesystem}
                                loadDataFromFile={loadDataFromFile}
                            />
                            </>
                    )}
                    <h4>Einstellungen</h4>
                    <SettingsPage
                        settings={settings}
                        setSettings={setSettings}
                        connectedToDatabase={connectedToDatabase}
                        connectedToNodeServer={connectedToNodeServer}
                        testDatabaseConnection={testDatabaseConnection}
                        testNodeServerConnection={testNodeServerConnection}
                        databaseHost={databaseHost}
                        setDatabaseHost={setDatabaseHost}
                        nodeServerHost={nodeServerHost}
                        setNodeServerHost={setNodeServerHost}
                    />
                    </>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <h4>Weitere Einstellungen</h4>
                        <KeywordsTableSettings
                            //columnDefinitionArray={columnDefinitionArray}
                            toggleCheckForKeywordCorrections={toggleCheckForKeywordCorrections}
                            showPreviewImages={showPreviewImages}
                            //toggleShowPreviewImages={toggleShowPreviewImages}
                            setShowPreviewImages={setShowPreviewImages}
                            connectedToNodeServer={connectedToNodeServer}
                            filePathReplace1String={filePathReplace1String}
                            filePathReplace2String={filePathReplace2String}
                            changeAndSetFilePathReplace1String={changeAndSetFilePathReplace1String}
                            changeAndSetFilePathReplace2String={changeAndSetFilePathReplace2String}
                            filePathReplace2StringExistsOnFileSystem={filePathReplace2StringExistsOnFileSystem}
                            exampleImageWithEnrichedMetadataObject={exampleImageWithEnrichedMetadataObject}
                            replaceDirectoryInFilePath={replaceDirectoryInFilePath}
                            useImgSrcReplacement={useImgSrcReplacement}
                            useImgExtReplacement={useImgExtReplacement}
                            //setUseImgSrcReplacement={setUseImgSrcReplacement}
                            toggleUseImgSrcReplacement={toggleUseImgSrcReplacement}
                            toggleUseImgExtReplacement={toggleUseImgExtReplacement}
                            checkForKeywordCorrections={checkForKeywordCorrections}
                            //setCheckForKeywordCorrections={setCheckForKeywordCorrections}
                            doCalculateCheckNumberForLoknummer={doCalculateCheckNumberForLoknummer}
                            //setDoCalculateCheckNumberForLoknummer={setDoCalculateCheckNumberForLoknummer}
                            //toggleDoCalculateCheckNumberForLoknummer={toggleDoCalculateCheckNumberForLoknummer}
                            fileExtReplaceString={fileExtReplaceString}
                            setFileExtReplaceString={setFileExtReplaceString}
                        />
                    </Col>
                    <Col md={6}>

                    </Col>
                </Row>
                {connectedToDatabase && dataSource===DATA_SOURCE_JSON && (
                    <Row>
                        <Col md={12}>
                            <h4>Import in die Datenbank</h4>
                            <ImportToDatabase
                                databaseHost={databaseHost}
                                csrfToken={csrfToken}
                                imageWithMetadataOriginalArray={imageWithMetadataOriginalArray}
                                dataSourceOnlyFilesystemAndJSONFilename={dataSourceOnlyFilesystemAndJSONFilename}
                                dataSource={dataSource}
                                importIsForbidden={importIsForbidden}
                                systemsArray={systemsArray}
                                importsArray={importsArray}
                                updateImportsFromDatabase={updateImportsFromDatabase}
                                updateSystemsFromDatabase={updateSystemsFromDatabase}                        />
                        </Col>
                    </Row>
                )}
            </Modal.Body>
        </Modal>
    );
}

import React, { FC, useState } from 'react';
import Form from "react-bootstrap/Form";
import { Settings } from "./custom_types";
import Button from "react-bootstrap/Button";
import { Badge, Col, Container, ListGroup, Row, Toast } from "react-bootstrap";
import {
    DATA_SOURCE_DATABASE,
    DATA_SOURCE_JSON,
} from "./constants";
import Tab from "react-bootstrap/Tab";
import { LoadingIndicator } from "./LoadingIndicator";
import { faArrowCircleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ScrollContainer } from "./ScrollContainer";
import { getUrlForPathExists } from './Helpers';
import { Dropzone } from './Dropzone';


interface DataSourceSettingsProps {
    settings: Settings,
    setSettings: any, // function
    connectedToDatabase: boolean,
    setConnectedToDatabase: any, // function
    connectedToNodeServer: boolean,
    setConnectedToNodeServer: any, // function
    testDatabaseConnection: any, // function
    testNodeServerConnection: any, // function
    databaseHost: string,
    setDatabaseHost: any, // function
    nodeServerHost: string,
    setNodeServerHost: any, // function
    setSettingsCollapseOpen: any, // function
    dataSource: number,
    setDataSource: any, // function
    pathNameInputField: string,
    setPathNameInputField: any, // function
    keywordsLoadFromPathname: string,
    changePathNameInputField: any, // function
    //pathNameHistoryItems: string[],
    ihkhsjsonHistoryItems: string[],
    loadDataFromJSONFromFilesystem: any, //function
    //setPathNameHistoryItems: any, // function
    pathNameInputFieldExistsOnFilesystem: boolean,
    checkPathExistsOnFilesystem: any, // function
    loadDataFromFile: any,
}

export const DataSourceSettings: FC<DataSourceSettingsProps> = ({
    settings,
    setSettings,
    connectedToDatabase,
    setConnectedToDatabase,
    connectedToNodeServer,
    setConnectedToNodeServer,
    testDatabaseConnection,
    testNodeServerConnection,
    databaseHost,
    setDatabaseHost,
    nodeServerHost,
    setNodeServerHost,
    setSettingsCollapseOpen,
    dataSource,
    setDataSource,
    pathNameInputField,
    setPathNameInputField,
    keywordsLoadFromPathname,
    changePathNameInputField,
    //pathNameHistoryItems,
    ihkhsjsonHistoryItems,
    loadDataFromJSONFromFilesystem,
    //setPathNameHistoryItems,
    pathNameInputFieldExistsOnFilesystem,
    checkPathExistsOnFilesystem,
    loadDataFromFile,
}) => {

    const [jsonPathNameInputField, setJsonPathNameInputField] = useState('');
    const [jsonPathNameExistsOnFileSystem, setJsonPathNameExistsOnFileSystem] = useState(false);

    const changeAndCheckJsonPathNameInputField = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log('changeAndCheckJsonPathNameInputField');
        const s = event.target.value;
        console.log('s: ' + s);

        setJsonPathNameInputField(s);
        fetch(getUrlForPathExists({ pathName: s }))
            .then(response => response.json())
            .then(response => setJsonPathNameExistsOnFileSystem(Boolean(response)))
    };
    //const [jsonSectionVisible, setJsonSectionVisible] = useState(false);

    // const saveSettings = () => {
    //     console.log('saveSettings');
    //     localStorage.setItem(LOCAL_STORAGE_KEY_SETTING_DATABASE_HOST, databaseHost);
    //     localStorage.setItem(LOCAL_STORAGE_KEY_SETTING_NODE_SERVER_HOST, nodeServerHost);

    //     setSettings({
    //         computerName: settings.computerName,
    //         databaseHost: databaseHost,
    //         nodeServerHost: nodeServerHost,
    //     } as Settings)

    //     setSettingsCollapseOpen(false);
    // }

    // const handleDrop = (files: any) => {
    //     console.log('handleDrop');
    //     console.log('files');
    //     console.log(files);

    //     // TODO: Pass files[0] to handleFileSelected  -
    //     // handleFileSelected needs to be refactored first
    // }

    const deletePathNameFromIhkhsjsonHistory = (idx: number) => {
        console.log('deletePathNameFromIhkhsjsonHistory');
        console.log('idx', idx);
        console.log('TODO Implement');

        // const tmpPathNameHistoryItems = [...pathNameHistoryItems];
        // tmpPathNameHistoryItems.splice(idx, 1);
        // setPathNameHistoryItems(tmpPathNameHistoryItems);
        // localStorage.setItem(LOCAL_STORAGE_KEY_PATH_NAME_HISTORY, JSON.stringify(tmpPathNameHistoryItems));
    }

    // const deletePathNameFromHistory = (idx: number) => {
    //     console.log('deletePathNameFromHistory');
    //     console.log('idx', idx);

    //     const tmpPathNameHistoryItems = [...pathNameHistoryItems];
    //     tmpPathNameHistoryItems.splice(idx, 1);
    //     setPathNameHistoryItems(tmpPathNameHistoryItems);
    //     localStorage.setItem(LOCAL_STORAGE_KEY_PATH_NAME_HISTORY, JSON.stringify(tmpPathNameHistoryItems));
    // }

    const addPathNameToHistory = (newPathName: string) => {
        console.log('addPathNameToHistory');
        console.log('newPathName', newPathName);
    }

    // const applySelectedPathName = (idx: number) => {
    //     setPathNameInputField(pathNameHistoryItems[idx]);
    //     checkPathExistsOnFilesystem(pathNameHistoryItems[idx]);
    // }

    const applySelectedIhkhsjsonPathName = (idx: number) => {
        console.log('applySelectedIhkhsjsonPathName');
        console.log('TODO implement');
        //setPathNameInputField(pathNameHistoryItems[idx]);
        //checkPathExistsOnFilesystem(pathNameHistoryItems[idx]);
    }

    return (
        <Tab.Container id="left-tabs-example" defaultActiveKey="data-source-filesystem">
            <Container fluid>
                <Row>
                    <Col md={6}>
                        <Toast className={'datasource-toast ' + (dataSource === DATA_SOURCE_DATABASE ? 'datasource-toast-selected' : '')}>
                            <Toast.Header
                                closeButton={false}
                                onClick={() => { setDataSource(DATA_SOURCE_DATABASE) }}
                            >
                                <Container>
                                    <Row>
                                        <Col>
                                            <strong className="me-auto">Datenbank</strong>
                                            <Badge className='data-source-status-badge' variant={connectedToDatabase ? 'success' : 'warning'}>{connectedToDatabase ? 'verbunden' : 'nicht verbunden'}</Badge>
                                        </Col>
                                    </Row>
                                </Container>
                            </Toast.Header>
                            <Toast.Body>
                                <p>TODO</p>
                            </Toast.Body>
                        </Toast>
                    </Col>
                    <Col md={6}>
                        <Toast className={'datasource-toast ' + (dataSource === DATA_SOURCE_JSON ? 'datasource-toast-selected' : '')}>
                            <Toast.Header
                                closeButton={false}
                                onClick={() => { setDataSource(DATA_SOURCE_JSON) }}
                            >
                                <Container>
                                    <Row>
                                        <Col>
                                            <strong className="me-auto">IHKHSJSON-Datei2</strong>
                                        </Col>
                                    </Row>
                                </Container>
                            </Toast.Header>
                            <Toast.Body>
                                <div>
                                    {connectedToNodeServer ? (
                                        <>
                                            <Form>
                                                {ihkhsjsonHistoryItems.length && (
                                                    <Form.Group controlId="exampleForm.SelectCustomHtmlSize">
                                                        <Form.Label>
                                                            Zuvor geladene Dateien
                                                        </Form.Label>
                                                        <ScrollContainer height='220px'>
                                                            <ListGroup>
                                                                {ihkhsjsonHistoryItems.map((ihkhsjsonFromHistory: string, i: number) =>
                                                                    <ListGroup.Item key={`data-source-settings-list-group-item${i}`}>
                                                                        <p>{ihkhsjsonFromHistory}</p>
                                                                        <Button
                                                                            size='sm'
                                                                            variant='secondary'
                                                                            disabled={!connectedToNodeServer}
                                                                            onClick={() => loadDataFromJSONFromFilesystem(ihkhsjsonFromHistory)}
                                                                        >
                                                                            <FontAwesomeIcon icon={faArrowCircleDown} /> Datei laden
                                                                        </Button>
                                                                    </ListGroup.Item>
                                                                )}
                                                            </ListGroup>
                                                        </ScrollContainer>
                                                    </Form.Group>
                                                )}
                                            </Form>
                                            <Form>
                                                <Form.Label>IHKHSJSON-Datei aus dem lokalen System einlesen</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={jsonPathNameInputField}
                                                    onChange={changeAndCheckJsonPathNameInputField}
                                                    placeholder='Pfad zur Datei hier eintragen'
                                                />
                                                <Button
                                                    disabled={!jsonPathNameExistsOnFileSystem || ((typeof jsonPathNameInputField !== 'undefined') && !(jsonPathNameInputField.match(/.ihkhsjson$/)))}
                                                    onClick={() => loadDataFromJSONFromFilesystem(jsonPathNameInputField)}
                                                >
                                                    Datei laden
                                                </Button>
                                            </Form>
                                        </>
                                    ) : (
                                        <Dropzone
                                            key='data-source-settings-dropzone'
                                            loadDataFromFile={loadDataFromFile}
                                            //handleFileSelected={handleFileSelected}
                                        />

                                        // <>
                                        //     <b>Daten aus IHKHSJSON-Datei laden</b>
                                        //     <br />
                                        //     {isLoading ? <LoadingIndicator /> : (
                                        //         <form>
                                        //             <input
                                        //                 onChange={handleFileSelected}
                                        //                 type="file"
                                        //                 accept=".ihkhsjson, .json"
                                        //             />
                                        //         </form>
                                        //     )}
                                        // </>
                                    )}
                                </div>
                            </Toast.Body>
                        </Toast>
                    </Col>
                </Row>
            </Container>
        </Tab.Container>
    )
};

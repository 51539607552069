import { FC } from 'react';
import { ColumnDefinition } from './custom_types';
import { OutputForRenderObject } from './KeywordsTable/OutputForRenderObject';

interface ValueWithLabelAndRenderfunctionProps {
    label?: string|null,
    // TODO: Ensure empty array everywhere to ensure string[] here
    values: string|string[],
    columnDefinition: ColumnDefinition,
    renderObjectsMap: any,
}

export const ValueWithLabelAndRenderfunction: FC<ValueWithLabelAndRenderfunctionProps> = ({
    label=null,
    values,
    columnDefinition,
    renderObjectsMap,
}) => {    
    return (
        (values==''||values.length==0) ? <></> :
        <div className={`value-with-label-and-renderfunction ${renderObjectsMap[columnDefinition.renderConfigsMapKey].constant}`} >
            {label ? <strong>{label}</strong>:null}
            <OutputForRenderObject
                contentArray={typeof values === 'string' ? [values] : values}
                renderConfigObject={renderObjectsMap[columnDefinition.renderConfigsMapKey]}
                // @ts-ignore
                trimStringStart={columnDefinition.trimResultStart}
                // @ts-ignore
                trimStringEnd={columnDefinition.trimResultEnd}
            />
        </div>
    );
}

import React, {FC, useEffect, useState} from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import { COLUMNS_ARRAY_MAP_FOR_IMAGE_WITH_ENRICHED_METADATA_OBJECT, COLUMNS_FOR_FOLDER_VIEW_KEY } from './constants';
import {ColumnDefinition, ColumnsForViewObject, FolderGridCell, FolderGridPage, ImageWithEnrichedMetadataObject, PaginationObject, Settings} from "./custom_types";
import {getUrlForGetFile} from "./Helpers";
import { ValueWithLabelAndRenderfunction } from './ValueWithLabelAndRenderfunction';


interface FolderGridProps {
    columnsForViewArray: ColumnsForViewObject,
    settings: Settings,
    connectedToNodeServer: null|Boolean,
    //replaceDirectoryInFilePath: any,
    filePathReplace2String: string,
    imgFilenamePrefix: string,
    setImgFilenamePrefix: any,
    imageWithEnrichedMetadataObjectsArray_All: ImageWithEnrichedMetadataObject[],
    combinedColumnDefinitionArray: ColumnDefinition[],
    mainPaginationObject: PaginationObject,
    renderObjectsMap: any,
}

export const FolderGrid: FC<FolderGridProps> = ({
    columnsForViewArray,
    settings,
    connectedToNodeServer,
    filePathReplace2String,
    imgFilenamePrefix,
    setImgFilenamePrefix,
    imageWithEnrichedMetadataObjectsArray_All,
    combinedColumnDefinitionArray,
    mainPaginationObject,
    renderObjectsMap,
}) => {

    const COLUMN_FOR_VIEW_KEY = COLUMNS_FOR_FOLDER_VIEW_KEY;

    const [selectedPage, setSelectedPage] = useState({} as FolderGridPage);
    const [rows, setRows] = useState(5 as number);
    const [cols, setCols] = useState(9 as number);
    const [page, setPage] = useState(1 as number);
    const [folderGridCellsArray, setFolderGridCellsArray] = useState([] as any);

    useEffect(() => {
        console.log('in useEffect for rows, cols, page');
        if (imageWithEnrichedMetadataObjectsArray_All) {
            console.log('in if');
            let tmpFolderGridCellsArray = [];
            for (let row=1; row<rows; row++) {
                if (typeof tmpFolderGridCellsArray[row]==='undefined') {
                    tmpFolderGridCellsArray[row] = [] as FolderGridCell[];
                }
                
                for (let col=1; col<cols; col++) {
                    //if (typeof tmpFolderGridCellsArray[row][col]==='undefined') {
                    //    tmpFolderGridCellsArray[row][col]
                    //}
    
                    let tmpFilename = `${imgFilenamePrefix}${(page<=10?'0'+page:page)}${row}${col}`;
                    let tmpRexexp = new RegExp(`^${tmpFilename}`);
                    //debugger;
                    tmpFolderGridCellsArray[row][col] = {
                        filename: tmpFilename,
                        imgSrc: getUrlForGetFile(connectedToNodeServer, { filePath: `${filePathReplace2String}${tmpFilename}.jpg` }),
                        imageWithEnrichedMetadataObjects: imageWithEnrichedMetadataObjectsArray_All.filter(e => e.baseColumns.file_name.match(tmpRexexp)),
                    } as FolderGridCell
                }   
            }
            setFolderGridCellsArray(tmpFolderGridCellsArray);
        }
      }, [rows, cols, page]);

    // const getStylesDivContent = () => {
    //     let stylesArray = [];
    //     const widthPercent = 100/selectedPage.cols;
    //     stylesArray.push(".images-table tr td { { width: " + widthPercent + "%; }");

    //     return stylesArray.join("");
    // }

    return (
        <>  
            {/* <style type="text/css">{getStylesDivContent()}</style> */}
            <Row>
                <Col md={3}>
                    <Form.Group controlId="inputFirstTwoDitisOfFolder">
                        <Form.Label>Dateinamen-Prefix (Ordnernummer)</Form.Label>
                        <Form.Control
                            type="number"
                            min={0}
                            max={99}
                            value={imgFilenamePrefix}
                            placeholder="Dateinamen-Prefix"
                            onChange={(e) => setImgFilenamePrefix(e.target.value.toString())}
                        />
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group controlId="inputPage">
                        <Form.Label>Seite</Form.Label>
                        <Form.Control
                            type="number"
                            value={page}
                            max={99}
                            min={1}
                            placeholder="Seite"
                            onChange={(e) => setPage(parseInt(e.target.value))}
                        />
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group controlId="inputRows">
                        <Form.Label>Anzahl Reihen</Form.Label>
                        <Form.Control
                            type="number"
                            value={rows}
                            max={9}
                            min={1}
                            placeholder="Anzahl Reihen"
                            onChange={(e) => setRows(parseInt(e.target.value))}
                        />
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group controlId="inputCols">
                        <Form.Label>Anzahl Spalten</Form.Label>
                        <Form.Control
                            type="number"
                            value={cols}
                            max={9}
                            min={1}
                            placeholder="Anzahl Spalten"
                            onChange={(e) => setCols(parseInt(e.target.value))}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Table className='folder-grid-table'>
                {folderGridCellsArray.map((folderGridCellsArrayRow: FolderGridCell[]) => 
                    <tr>
                        {folderGridCellsArrayRow.map((folderGridCellsArrayRowCol: FolderGridCell) => 
                            <td>
                                <Card className='card-grid-view' style={{ width: '18rem;', maxWidth: '100%;' }}>
                                    <Card.Img variant="top"
                                        src={folderGridCellsArrayRowCol.imgSrc}
                                    />
                                    <div className='card-grid-view-overlay'>
                                        {folderGridCellsArrayRowCol.filename}
                                    </div>
                                    <div>
                                        {folderGridCellsArrayRowCol.imageWithEnrichedMetadataObjects.length === 0 ?
                                            <></>
                                            : 
                                            columnsForViewArray[COLUMN_FOR_VIEW_KEY].map((cd: ColumnDefinition) => 
                                                <ValueWithLabelAndRenderfunction
                                                    values={folderGridCellsArrayRowCol.imageWithEnrichedMetadataObjects[0][COLUMNS_ARRAY_MAP_FOR_IMAGE_WITH_ENRICHED_METADATA_OBJECT[cd.columnDefinitionType]][cd.columnDefinitionConstant]}
                                                    columnDefinition={cd}
                                                    renderObjectsMap={renderObjectsMap}
                                                />
                                            )
                                        }
                                    </div>
                                </Card>
                            </td>
                        )}
                    </tr>
                )}
            </Table>
        </>
    )
};
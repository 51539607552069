import React, { FC, useState } from 'react';
import { Button, Col, Container, Dropdown, Row, Table, Toast } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import { COLUMNS_ARRAY_MAP_FOR_IMAGE_WITH_ENRICHED_METADATA_OBJECT, COLUMN_DEFINITION_CONST_DIRECTORY, COLUMN_DEFINITION_CONST_FILENAME, COLUMN_DEFINITION_CONST_KEYWORDS, COLUMN_DEFINITION_CONST_KEYWORDS_STRING, COLUMN_DEFINITION_CONST_KEYWORD_REST, COLUMN_DEFINITION_CONST_SOURCE_FILE, WARNING_CONDITIONS_ARRAY, WARNING_CONFIG_GROUP_CONNECT_TYPES } from '../constants';
import { ColumnDefinition, ImageWithEnrichedMetadataObject, ImageWithMetadataObject, WarningConfigGroupObject } from '../custom_types';
import { getUrlForGetFile } from '../Helpers';


interface KeywordsTableSettingsProps {
    showPreviewImages: boolean,
    setShowPreviewImages: any, // function
    toggleCheckForKeywordCorrections: any, // function
    connectedToNodeServer: boolean,
    filePathReplace1String: string,
    filePathReplace2String: string,
    changeAndSetFilePathReplace1String: any, //function
    changeAndSetFilePathReplace2String: any, //function
    filePathReplace2StringExistsOnFileSystem: boolean,
    exampleImageWithEnrichedMetadataObject?: ImageWithEnrichedMetadataObject,
    replaceDirectoryInFilePath: any, //function
    useImgSrcReplacement: boolean,
    useImgExtReplacement: boolean,
    toggleUseImgSrcReplacement: any, // function
    toggleUseImgExtReplacement: any, // function
    checkForKeywordCorrections: boolean,
    doCalculateCheckNumberForLoknummer: boolean,
    fileExtReplaceString: string,
    setFileExtReplaceString: any,
}

export const KeywordsTableSettings: FC<KeywordsTableSettingsProps> = ({
    toggleCheckForKeywordCorrections,
    doCalculateCheckNumberForLoknummer,
    showPreviewImages,
    setShowPreviewImages,
    connectedToNodeServer,
    filePathReplace1String,
    filePathReplace2String,
    changeAndSetFilePathReplace1String,
    changeAndSetFilePathReplace2String,
    filePathReplace2StringExistsOnFileSystem,
    exampleImageWithEnrichedMetadataObject,
    replaceDirectoryInFilePath,
    useImgSrcReplacement,
    useImgExtReplacement,
    toggleUseImgSrcReplacement,
    toggleUseImgExtReplacement,
    checkForKeywordCorrections,
    fileExtReplaceString,
    setFileExtReplaceString,
}) => {
    const [tmpShowPreviewImages, setTmpShowPreviewImages] = useState(showPreviewImages);

    setShowPreviewImages(tmpShowPreviewImages && (!connectedToNodeServer || (connectedToNodeServer && (!useImgSrcReplacement || filePathReplace2StringExistsOnFileSystem))));

    const toggleTmpShowPreviewImages = (event: React.ChangeEvent<HTMLInputElement>) => {
        //debugger;
        setTmpShowPreviewImages(event.target.checked);
    }
    const baseColumnsKey = COLUMNS_ARRAY_MAP_FOR_IMAGE_WITH_ENRICHED_METADATA_OBJECT[1];
    let exampleImageWithMetadataObject = null;
    if (exampleImageWithEnrichedMetadataObject) {
        exampleImageWithMetadataObject = {
            sourcefile: exampleImageWithEnrichedMetadataObject[baseColumnsKey][COLUMN_DEFINITION_CONST_SOURCE_FILE],
            file_name: exampleImageWithEnrichedMetadataObject[baseColumnsKey][COLUMN_DEFINITION_CONST_FILENAME],
            directory: exampleImageWithEnrichedMetadataObject[baseColumnsKey][COLUMN_DEFINITION_CONST_DIRECTORY],
            keywords_string: exampleImageWithEnrichedMetadataObject[baseColumnsKey][COLUMN_DEFINITION_CONST_KEYWORDS_STRING],
            keywords_array: exampleImageWithEnrichedMetadataObject[baseColumnsKey][COLUMN_DEFINITION_CONST_KEYWORDS],
            keywords_rest_array: exampleImageWithEnrichedMetadataObject[baseColumnsKey][COLUMN_DEFINITION_CONST_KEYWORD_REST],
            image_size: '4711', // TODO: Why the key in ImageWithEnrichedMetadataObject does not exist?
        } as ImageWithMetadataObject;
    }


/*     const allowedKeywordsFormInputs : JSX.Element[] = [];
    warningsAllowedKeywordsArray.map((warningsAllowedKeyword, warningsAllowedKeywordIndex) => {
        allowedKeywordsFormInputs.push(
            <Form.Control
                type="text"
                value={warningsAllowedKeyword}
                //onChange={(event) => setNodeServerHost(event.target.value)}
                placeholder="Einzelnes Keyword hier eintragen"
            />
        )
    }); */

    return (
        <>
            {/* <Col md={12}>
                <Toast className='warning-config-toast'>
                    <Toast.Header closeButton={true}>
                        <Container>
                            <Row>
                                <Col>
                                    <strong className="me-auto">Warnungen Konfigurieren</strong>
                                </Col>
                            </Row>
                        </Container>
                    </Toast.Header>
                    <Toast.Body>
                    <WarningsConfigurationTable
                        warningConfigGroupObjectsArray={warningConfigGroupObjectsArray}
                        setWarningConfigGroupObjectsArray={setWarningConfigGroupObjectsArray}
                        columnDefinitionArray={columnDefinitionArray}
                    />

                    </Toast.Body>

                    {/* <Toast.Header closeButton={true}>
                        <Container>
                            <Row>
                                <Col>
                                    <strong className="me-auto">Warnungen Konfigurieren</strong>
                                </Col>
                            </Row>
                        </Container>
                    </Toast.Header>
                    <Toast.Body>
                        <Form.Label>Erlaubte Keywords (hinzufügen +)</Form.Label>
                            {allowedKeywordsFormInputs}
                    </Toast.Body>
                </Toast>
            </Col>  */}
            {/* <Form.Check
                label='Prüfziffer für Loknummern berechnen?'
                checked={doCalculateCheckNumberForLoknummer}
                onChange={toggleDoCalculateCheckNumberForLoknummer}
            /> */}
            <Form.Check
                label='Keywordkorrekturen aus Datenbank laden?'
                checked={checkForKeywordCorrections}
                onChange={toggleCheckForKeywordCorrections}
            />
            <Form.Check
                label='Bilder anzeigen?'
                checked={tmpShowPreviewImages}
                onChange={toggleTmpShowPreviewImages}
            />
            {(tmpShowPreviewImages && exampleImageWithMetadataObject) && (
                <Col md={12}>
                    <Toast className='img-replacement-inputs-toast'>
                        <Toast.Header closeButton={false}>
                            <Container>
                                <Row>
                                    <Col>
                                        <strong className="me-auto">Pfadersetzung zum Laden von Bildern</strong>
                                    </Col>
                                </Row>
                            </Container>
                        </Toast.Header>
                        <Toast.Body>
                            <Form.Check
                                label='Dateiendung-Austausch anwenden?'
                                checked={useImgExtReplacement}
                                onChange={toggleUseImgExtReplacement}
                            />
                            <Form.Control
                                    type="text"
                                    value={fileExtReplaceString}
                                    onChange={(event) => setFileExtReplaceString(event.target.value)}
                                    placeholder="Ziel-Dateiendung"
                                />
                            <Form.Check
                                label='Pfadersetzung anwenden?'
                                checked={useImgSrcReplacement}
                                onChange={toggleUseImgSrcReplacement}
                            />
                            <ul>
                                <li>Für die Anpassung der Bild-Pfade</li>
                                <li>1. Feld: Der Teil des Pfads der in den Bildern ersetzt wird</li>
                                <li>2. Feld: Der Pfad auf dem Server</li>
                            </ul>
                            <Form.Group controlId="grid-view-file-path-replace">
                                <Form.Control
                                    type="text"
                                    value={filePathReplace1String}
                                    onChange={(event) => changeAndSetFilePathReplace1String(event.target.value)}
                                    placeholder="Ersetzung Von"
                                />
                                <Form.Control
                                    type="text"
                                    value={filePathReplace2String}
                                    onChange={(event) => changeAndSetFilePathReplace2String(event.target.value)}
                                    placeholder="Ersetzung Nach"
                                />
                                {filePathReplace2StringExistsOnFileSystem ? <div>gefunden</div> : <div>nicht gefunden</div>}
                            </Form.Group>
                            <ul>
                                <li><b>Beispiel anhand des Bilds "c:\pictures\a.jpg":</b></li>
                                <li>Im ersten Feld ist "c:\pictures" eingetragen.</li>
                                <li>Im zweiten Feld ist "d:\my-pictures" eingetragen.</li>
                                <li>Das Bild wird nicht mehr in "c:\pictures\a.jpg" gesucht sondern in "d:\my-pictures\a.jpg".</li>
                            </ul>
                        </Toast.Body>
                    </Toast>
                    <Toast className='img-example-toast'>
                        <Toast.Header closeButton={false}>
                            <Container>
                                <Row>
                                    <Col>
                                        <strong className="me-auto">Beispielbild</strong>
                                    </Col>
                                </Row>
                            </Container>
                        </Toast.Header>
                        <Toast.Body>
                            {exampleImageWithMetadataObject && (
                                <Container>
                                    <Row>
                                        <Col md={6}>
                                            {useImgSrcReplacement ? (
                                                <>
                                                    <p>
                                                        <b>Vor der Ersetzung:</b> {exampleImageWithMetadataObject.sourcefile}
                                                    </p>
                                                    <p>
                                                        <b>Danach:</b> {exampleImageWithMetadataObject.sourcefile.replace(filePathReplace1String, filePathReplace2String)}
                                                    </p>
                                                </>
                                            ) : (
                                                exampleImageWithMetadataObject.sourcefile
                                            )}
                                        </Col>
                                        <Col md={6}>
                                            <img alt="Beispielbild" className='settings-page-example-image' src={getUrlForGetFile(connectedToNodeServer, { filePath: replaceDirectoryInFilePath(exampleImageWithMetadataObject.sourcefile) })} />
                                        </Col>
                                    </Row>
                                </Container>
                            )}
                        </Toast.Body>
                    </Toast>
                </Col>
            )}
        </>
    )
}

import { FC } from 'react';
import { Card, Col, Container, Row } from "react-bootstrap";
import { COLUMNS_ARRAY_MAP_FOR_IMAGE_WITH_ENRICHED_METADATA_OBJECT, COLUMNS_FOR_GRID_VIEW_KEY } from './constants';
import { ColumnDefinition, ColumnsForViewObject, ImageWithEnrichedMetadataObject, PaginationObject } from './custom_types';
import { getUrlForGetFile } from './Helpers';
import { ValueWithLabelAndRenderfunction } from './ValueWithLabelAndRenderfunction';


interface GridViewProps {
    columnsForViewArray: ColumnsForViewObject,
    imageWithMetadataObjectsArray: ImageWithEnrichedMetadataObject[],
    checkPathExistsOnFilesystem: any, // function
    filePathReplace1String: string,
    filePathReplace2String: string,
    filePathReplace2StringExistsOnFileSystem: boolean,
    replaceDirectoryInFilePath: any, //function
    connectedToNodeServer: null|Boolean,
    mainPaginationObject: PaginationObject,
    setMainPaginationObject: any,
    renderObjectsMap: any,
}

export const GridView: FC<GridViewProps> = ({
    columnsForViewArray,
    imageWithMetadataObjectsArray,
    checkPathExistsOnFilesystem,
    filePathReplace1String,
    filePathReplace2String,
    filePathReplace2StringExistsOnFileSystem,
    replaceDirectoryInFilePath,
    connectedToNodeServer,
    mainPaginationObject,
    setMainPaginationObject,
    renderObjectsMap,
}) => {
    const COLUMN_FOR_VIEW_KEY = COLUMNS_FOR_GRID_VIEW_KEY;

    let rows = 1;
    if (mainPaginationObject.imagesPerRow && mainPaginationObject.itemsPerPage) {
        rows = Math.floor(mainPaginationObject.itemsPerPage/mainPaginationObject.imagesPerRow);
    }

    const layoutColsInRow = 12 / mainPaginationObject.imagesPerRow;
    return (
        <Container fluid className='container-grid-view'>
            <Row key='grid-view-row' className='grid-row'>
                {imageWithMetadataObjectsArray.map((imageWithMetadataObject: ImageWithEnrichedMetadataObject, k) => {
                    return (
                        <Col md={layoutColsInRow} key={`grid-view-col-tmp_${k}`}>
                            <div key={`{fragment_{i}_{k}}`}>
                                <Card className='card-grid-view' style={{ width: '18rem;', maxWidth: '100%' }}>
                                    <Card.Img variant="top"
                                        src={getUrlForGetFile(connectedToNodeServer, { filePath: replaceDirectoryInFilePath(imageWithMetadataObject.baseColumns.sourcefile) })}
                                    />
                                    <Card.Body>
                                        <Card.Title>
                                            {columnsForViewArray[COLUMN_FOR_VIEW_KEY].filter((e: ColumnDefinition)=>e.active).map((columnDefinition: ColumnDefinition) => 
                                                <ValueWithLabelAndRenderfunction
                                                    values={imageWithMetadataObject[COLUMNS_ARRAY_MAP_FOR_IMAGE_WITH_ENRICHED_METADATA_OBJECT[columnDefinition.columnDefinitionType]][columnDefinition.columnDefinitionConstant]}
                                                    columnDefinition={columnDefinition}
                                                    renderObjectsMap={renderObjectsMap}
                                                />
                                            )}
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    );
                })}
            </Row>
        </Container>
    );
}

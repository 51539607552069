import {FC, useState} from "react";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Button, ButtonGroup, Col, Container, Dropdown, Form, FormLabel, ListGroup, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {ColumnDefinition, ColumnsForViewObject} from "./custom_types";
import { updateSortPositionValuesBasedOnArrayIndex } from "./Helpers";
import { COLUMNS_FOR_VIEWS_MAP } from "./constants";
import { RenderObjectSelectWithPreview } from "./RenderObjectSelectWithPreview";

interface ColumnDefinitionCustomizerProps {
    columnsForViewArray: ColumnsForViewObject,
    columnsForViewKey: string,
    setColumnsForViewArray: any, // function
    renderObjectsMap: any,
}

export const ColumnDefinitionCustomizer: FC<ColumnDefinitionCustomizerProps> = ({
    columnsForViewArray,
    columnsForViewKey,
    setColumnsForViewArray,
    renderObjectsMap,
}) => {
    //const [selectedColumnForCreateCopy, setSelectedColumnForCreateCopy] = useState(Object.keys(columnsForViewArray)[0]);
    const [selectedColumnForCreateCopy, setSelectedColumnForCreateCopy] = useState('');

    // @ts-ignore
    const onClickFunction = (renderObjectKey: string, columnDefinitionIndex : number) => {
        const newColumnsForViewArray = Object.assign({}, columnsForViewArray);
        
        // TODO: Check is both assignments are required
        newColumnsForViewArray[columnsForViewKey][columnDefinitionIndex].renderConfigsMapKey = renderObjectKey;

        // TODO: Check is both assignments are required
        newColumnsForViewArray[columnsForViewKey][columnDefinitionIndex].renderConfigObject = Object.assign({}, renderObjectsMap[renderObjectKey]);
        setColumnsForViewArray(newColumnsForViewArray);
    }

    // Function to move columnDefinitionItem one position up
    const moveColumnDefinitionUp = (columnDefinitionIndex : number) => {
        if (columnDefinitionIndex >= 1) {
            const newColumnsForViewArray: ColumnsForViewObject = Object.assign({}, columnsForViewArray);
            let tmp = newColumnsForViewArray[columnsForViewKey][columnDefinitionIndex - 1];
            newColumnsForViewArray[columnsForViewKey][columnDefinitionIndex - 1] = newColumnsForViewArray[columnsForViewKey][columnDefinitionIndex];
            newColumnsForViewArray[columnsForViewKey][columnDefinitionIndex] = tmp;
            updateSortPositionValuesBasedOnArrayIndex(newColumnsForViewArray[columnsForViewKey]);
            setColumnsForViewArray(newColumnsForViewArray);
        }
    };

    const moveColumnDefinitionToTop = (columnDefinitionIndex : number) => {
        if (columnDefinitionIndex < columnsForViewArray[columnsForViewKey].length-1) {
            const newColumnsForViewArray: ColumnsForViewObject = Object.assign({}, columnsForViewArray);
            let tmp = newColumnsForViewArray[columnsForViewKey][columnDefinitionIndex];
            newColumnsForViewArray[columnsForViewKey].splice(columnDefinitionIndex, 1);
            newColumnsForViewArray[columnsForViewKey].unshift(tmp);
            updateSortPositionValuesBasedOnArrayIndex(newColumnsForViewArray[columnsForViewKey]);
            setColumnsForViewArray(newColumnsForViewArray);
        }
    };

    // Function to move columnDefinitionItem one position down
    const moveColumnDefinitionDown = (columnDefinitionIndex : number) => {
        if (columnDefinitionIndex < columnsForViewArray[columnsForViewKey].length-1) {
            const newColumnsForViewArray: ColumnsForViewObject = Object.assign({}, columnsForViewArray);
            let tmp = newColumnsForViewArray[columnsForViewKey][columnDefinitionIndex + 1];
            newColumnsForViewArray[columnsForViewKey][columnDefinitionIndex + 1] = newColumnsForViewArray[columnsForViewKey][columnDefinitionIndex];
            newColumnsForViewArray[columnsForViewKey][columnDefinitionIndex] = tmp;
            updateSortPositionValuesBasedOnArrayIndex(newColumnsForViewArray[columnsForViewKey]);
            setColumnsForViewArray(newColumnsForViewArray);
        }
    };

    const moveColumnDefinitionToBottom = (columnDefinitionIndex : number) => {
        if (columnDefinitionIndex < columnsForViewArray[columnsForViewKey].length-1) {
            const newColumnsForViewArray: ColumnsForViewObject = Object.assign({}, columnsForViewArray);
            let tmp = newColumnsForViewArray[columnsForViewKey][columnDefinitionIndex];
            newColumnsForViewArray[columnsForViewKey].splice(columnDefinitionIndex, 1);
            newColumnsForViewArray[columnsForViewKey].push(tmp);
            updateSortPositionValuesBasedOnArrayIndex(newColumnsForViewArray[columnsForViewKey]);
            setColumnsForViewArray(newColumnsForViewArray);
        }
    };

    // @ts-ignore
    const updateWidth = (event) => {
        const newColumnsForViewArray: ColumnsForViewObject = Object.assign({}, columnsForViewArray);
        let columnDefinitionIndex = event.target.getAttribute('data-key-column-definition-index');
        newColumnsForViewArray[columnsForViewKey][columnDefinitionIndex].width = parseInt(event.target.value);
        setColumnsForViewArray(newColumnsForViewArray);
    };

    const deactivateAll = () => {
        if (window.confirm('Sollen alle Spalten ausgeblendet werden?')) {
            const newColumnsForViewArray: ColumnsForViewObject = Object.assign({}, columnsForViewArray);
            columnsForViewArray[columnsForViewKey].forEach((columnDefinitionItem: ColumnDefinition) => {
                columnDefinitionItem.active=false;
            });
            setColumnsForViewArray(newColumnsForViewArray);
        }
    }

    const activateAll = () => {
        if (window.confirm('Sollen alle Spalten eingeblendet werden?')) {
            const newColumnsForViewArray: ColumnsForViewObject = Object.assign({}, columnsForViewArray);
            columnsForViewArray[columnsForViewKey].forEach((columnDefinitionItem: ColumnDefinition) => {
                columnDefinitionItem.active=true;
            });
            setColumnsForViewArray(newColumnsForViewArray);
        }
    }

    // Function to toggle "active"-state of columnDefinitionItem
    const toggleColumnDefinition = (columnDefinitionConstant : string) => {
        const newColumnsForViewArray: ColumnsForViewObject = Object.assign({}, columnsForViewArray);
        //const newColumnDefinitionArray : ColumnDefinition[] = Object.assign([], columnsForViewArray[columnsForViewKey]);
        //const newColumnDefinitionArray = Object.assign([], columnDefinitionArray);

        columnsForViewArray[columnsForViewKey].forEach((columnDefinitionItem: ColumnDefinition) => {
           if (columnDefinitionItem.columnDefinitionConstant === columnDefinitionConstant) {
               columnDefinitionItem.active = !columnDefinitionItem.active;
           }
        });

        //setCombinedColumnDefinitionArray(newColumnDefinitionArray);
        setColumnsForViewArray(newColumnsForViewArray);
    };

    const createCopyFromOtherColumnForView = () => {
        if (window.confirm(`Soll die Spaltenkonfiguration der ${COLUMNS_FOR_VIEWS_MAP[selectedColumnForCreateCopy]} für die ${COLUMNS_FOR_VIEWS_MAP[columnsForViewKey]} übernommen werden?`)) {
            const newColumnsForViewArray: ColumnsForViewObject = Object.assign({}, columnsForViewArray);
            let copiedColumnForViewArray = [];
            for (let i=0; i<columnsForViewArray[selectedColumnForCreateCopy].length; i++) {
                copiedColumnForViewArray.push(Object.assign({}, columnsForViewArray[selectedColumnForCreateCopy][i]));
            }
            newColumnsForViewArray[columnsForViewKey] = copiedColumnForViewArray;
            setColumnsForViewArray(newColumnsForViewArray);
            setSelectedColumnForCreateCopy('');
        }
    }

    const tooltipMoveToTop = <Tooltip id={'tooltip_move_to_top'}>An den Anfang</Tooltip>
    const tooltipMoveUp = <Tooltip id={'tooltip_move_up'}>Nach oben</Tooltip>
    const tooltipMoveDown = <Tooltip id={'tooltip_move_down'}>Nach unten</Tooltip>
    const tooltipMoveToBottom = <Tooltip id={'tooltip_move_to_bottom'}>An das Ende</Tooltip>

    return (
        <>
            <div className='column_definition_customizer_button_group_div'>
                <ButtonGroup>
                    <Button size='sm' variant='secondary' onClick={() => deactivateAll()}>
                        Alle ausblenden
                    </Button>
                    <Button size='sm' variant='secondary' onClick={() => activateAll()}>
                        Alle einblenden
                    </Button>
                </ButtonGroup>
                <ButtonGroup>
                    <Button size='sm' variant='secondary' onClick={() => createCopyFromOtherColumnForView()} disabled={selectedColumnForCreateCopy===''} >
                        Konfiguration von Ansicht übernehmen
                    </Button>
                    <Dropdown>
                        <Dropdown.Toggle split size='sm' variant='secondary' id={`dropdown_column_definition_customizer`}>
                        {selectedColumnForCreateCopy ? `${COLUMNS_FOR_VIEWS_MAP[selectedColumnForCreateCopy]}` : 'Ansicht auswählen'}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {Object.keys(columnsForViewArray).map((columnForViewKey: string, columnForViewKeyIndex: number) => 
                                <Dropdown.Item
                                    onClick={() => setSelectedColumnForCreateCopy(columnForViewKey)}
                                    key={`dropdown_column_definition_customizer_create_copy_dropdown_item${columnForViewKeyIndex}`}
                                >
                                    {`${COLUMNS_FOR_VIEWS_MAP[columnForViewKey]}`}
                                </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </ButtonGroup>
            </div>
            <ListGroup as="ul">
                {columnsForViewArray[columnsForViewKey].map((columnDefinition: ColumnDefinition, columnDefinitionIndex: number) => 
                    <ListGroup.Item
                        as="li"
                        key={columnDefinition.columnDefinitionConstant}
                        variant='secondary'
                    >
                        <Container>
                            <Row>
                                <Col sm={2}>
                                    <FormLabel className={`column-definition-type-${columnDefinition.columnDefinitionType}`}>
                                        <strong>{columnDefinition.nameInFrontend}</strong>
                                    </FormLabel>
                                </Col>
                                <Col sm={2}>
                                    <Form.Group controlId={"checkbox" + columnDefinition.columnDefinitionConstant}>
                                        <Form.Check
                                            type="checkbox"
                                            onChange={() => toggleColumnDefinition(columnDefinition.columnDefinitionConstant)}
                                            checked={columnDefinition.active}
                                            label="aktiv?"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={2}>
                                    <ButtonGroup>
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={tooltipMoveToTop}
                                        >
                                            <Button variant='outline-secondary' onClick={() => moveColumnDefinitionToTop(columnDefinitionIndex)}>
                                                <FontAwesomeIcon icon={faArrowUp} />
                                                <FontAwesomeIcon icon={faArrowUp} />
                                            </Button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={tooltipMoveUp}
                                        >
                                            <Button variant='outline-secondary' onClick={() => moveColumnDefinitionUp(columnDefinitionIndex)}>
                                                <FontAwesomeIcon icon={faArrowUp} />
                                            </Button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={tooltipMoveDown}
                                        >
                                            <Button variant='outline-secondary' onClick={() => moveColumnDefinitionDown(columnDefinitionIndex)}>
                                                <FontAwesomeIcon icon={faArrowDown} />
                                            </Button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={tooltipMoveToBottom}
                                        >
                                            <Button variant='outline-secondary' onClick={() => moveColumnDefinitionToBottom(columnDefinitionIndex)}>
                                                <FontAwesomeIcon icon={faArrowDown} />
                                                <FontAwesomeIcon icon={faArrowDown} />
                                            </Button>
                                        </OverlayTrigger>
                                    </ButtonGroup>
                                </Col>
                                <Col sm={2}>
                                    <Form.Label>Spaltenbreite (in px)</Form.Label>
                                    <Form.Control
                                        key={`column-customizer-width-textfield-${columnDefinitionIndex}--${columnsForViewKey}`}
                                        size="sm"
                                        type="number"
                                        min="0"
                                        max="2000"
                                        value={columnDefinition.width}
                                        data-key-column-definition-index={columnDefinitionIndex}
                                        onChange={(event) => updateWidth(event)}
                                    />
                                </Col>
                                <Col sm={4}>
                                <Form.Label>Zellenaussehen auswählen</Form.Label>
                                    {renderObjectsMap[columnDefinition.renderConfigsMapKey] ? (
                                    <RenderObjectSelectWithPreview
                                        renderConfigObject={renderObjectsMap[columnDefinition.renderConfigsMapKey]}
                                        dataKeyColumnDefinitionIndex={columnDefinitionIndex}
                                        onClickFunction={onClickFunction}
                                        renderObjectsMap={renderObjectsMap}
                                    /> ) : ( <strong>Fehler {columnDefinition.renderConfigsMapKey} fehlt in renderObjectsMap</strong>)
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </ListGroup.Item>
                )}
            </ListGroup>
        </>
    );
}

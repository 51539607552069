import { faImage, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useState } from 'react';
import { Button, ButtonGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import {
    COLUMNS_ARRAY_MAP_FOR_IMAGE_WITH_ENRICHED_METADATA_OBJECT,
    getKeywordCorrectionsFromDbUrl,
    TAB_KEY_IMAGE_DETAIL_VIEW,
    TAB_KEY_TABLE_VIEW,
} from './constants';
import { ColumnDefinition, ImageWithEnrichedMetadataObject, Settings } from "./custom_types";
import { KeywordsTableTd } from './KeywordsTable/KeywordsTableTd';

interface KeywordRowProps {
    imageWithEnrichedMetadataObject: ImageWithEnrichedMetadataObject,
    imageWithMetadataObjectIndex?: number,
    combinedColumnDefinitionArray: ColumnDefinition[],
    checkKeywordCorrections: any,
    editPossible?: boolean,
    i: number,
    settings: Settings,
    connectedToDatabase: boolean,
    selectedSourceFileValues: string[],
    setSelectedSourceFileValues: any, //function
    changeActiveViewTab?: any,
    activeViewTabKey?: string,
    renderObjectsMap: any,
}

export const KeywordRow: FC<KeywordRowProps> = ({
    imageWithEnrichedMetadataObject,
    combinedColumnDefinitionArray,
    checkKeywordCorrections = false,
    editPossible = false,
    i,
    settings,
    connectedToDatabase,
    selectedSourceFileValues,
    setSelectedSourceFileValues,
    changeActiveViewTab,
    activeViewTabKey,
    renderObjectsMap,
}) => {
    const [keywordCorrectionsString, setKeywordCorrectionsString] = useState(null as string | null);

    const keywordRowIsSelected = (selectedSourceFileValues.indexOf(imageWithEnrichedMetadataObject.baseColumns.sourcefile) !== -1);

    
    // const handleDoubleClick = () => {
    //     if (selectedSourceFileValues) {     
    //         const tmpSelectedSourceFile = Array.from(selectedSourceFileValues);
    //         let existingIndex = selectedSourceFileValues.indexOf(imageWithEnrichedMetadataObject.baseColumns.SourceFile);
    //         if (existingIndex === -1) {
    //             // add newIdx
    //             tmpSelectedSourceFile.push(imageWithEnrichedMetadataObject.baseColumns.SourceFile);
    //         } else {
    //             // remove newIdx
    //             tmpSelectedSourceFile.splice(existingIndex, 1);
    //         }
    //         setSelectedSourceFileValues(tmpSelectedSourceFile);
    //     } else {
    //         console.log('skipped handleDoubleClick');
    //     }
    // }

    const showInDetailView = () => {
        //console.log('showInDetailView show for ' + imageWithEnrichedMetadataObject.baseColumns.FileName);
        //if (imageWithEnrichedMetadataObject.baseColumns.Id) {
        //    console.log('showInDetailView new Index(Page for DetailView) ' + (parseInt(imageWithEnrichedMetadataObject.baseColumns.Id)+1));
        //}
        changeActiveViewTab(
            TAB_KEY_IMAGE_DETAIL_VIEW,
            imageWithEnrichedMetadataObject.baseColumns.id ? (parseInt(imageWithEnrichedMetadataObject.baseColumns.id)+1) : null
        );
    }

    const checkForKeywordCorrection = () => {
        if (connectedToDatabase && imageWithEnrichedMetadataObject && imageWithEnrichedMetadataObject.baseColumns.file_name && imageWithEnrichedMetadataObject.baseColumns.file_name !== '') {

            console.log('checkForKeywordCorrection');
            console.log(imageWithEnrichedMetadataObject.baseColumns.file_name);
            console.log('settings');
            console.log(settings);

            // TODO: Only fire request to url when connetedToDatabase === true
            const url =
                settings.databaseHost + getKeywordCorrectionsFromDbUrl + '?filename=' + imageWithEnrichedMetadataObject.baseColumns.file_name;
            console.log('fetching url', url);
            fetch(url)
                .then(response => response.json())
                .then(response => {
                    if (response.length == 0) {
                        imageWithEnrichedMetadataObject.baseColumns.keywords_correction = '';
                        setKeywordCorrectionsString('');
                    } else {
                        if (typeof response[0].keywords !== 'undefined') {
                            setKeywordCorrectionsString(response[0].keywords);
                            imageWithEnrichedMetadataObject.baseColumns.keywords_correction = response[0].keywords;
                        } else {
                            setKeywordCorrectionsString('Fehler');
                            imageWithEnrichedMetadataObject.baseColumns.keywords_correction = 'Fehler';
                        }
                    }
                    // if (response.length == 0) {
                    //     console.log('Keine Korrektur gefunden');
                    // } else {
                    //     console.log(response.length + ' Korrekturen gefunden!');
                    //     for(let i=0; i<response.length; i++) {
                    //         console.log(response[i]['keywords']);
                    //         if (typeof response[i].keywords !== 'undefined') {
                    //             console.log('Korrektur ' + i + ':' + response[i].keywords);
                    //         }
                    //     }
                    // }
                });
        }
    };

    if (connectedToDatabase && checkKeywordCorrections && !imageWithEnrichedMetadataObject.baseColumns.keywords_correction) {
        checkForKeywordCorrection();
    }

    const popover = (
        <Popover id='keyword-row-popover' className='keyword-row-popover'>
            <Popover.Title as="h3">{imageWithEnrichedMetadataObject.baseColumns.sourcefile}</Popover.Title>
            <Popover.Content>
            <ButtonGroup aria-label="Basic example">
                {/* <Button size='sm' variant="outline-secondary">
                    <FontAwesomeIcon icon={faStar} /> Als Favorit markieren
                </Button> */}
                {activeViewTabKey===TAB_KEY_TABLE_VIEW ?
                <Button size='sm' variant="outline-secondary" onClick={showInDetailView} disabled={!changeActiveViewTab}>
                    <FontAwesomeIcon icon={faImage} /> In Detailansicht anzeigen
                </Button>
                : <></> }
            </ButtonGroup>
            </Popover.Content>
        </Popover>
      );

    return (
        <OverlayTrigger trigger="click" placement="top" overlay={popover} rootClose>
            <tr key={`keyword-row-${i}-${imageWithEnrichedMetadataObject.baseColumns.id}`}
                className={keywordRowIsSelected ? 'keyword-row-is-selected' : ''}
            >
                {combinedColumnDefinitionArray.filter(e => e.active).map((columnDefinition: ColumnDefinition, columnDefinitionIndex) => 
                    <KeywordsTableTd
                        //TODO: Add unique key here
                        key={`keywords_row_keywords_table_td_${columnDefinitionIndex}`}
                        contentArray={imageWithEnrichedMetadataObject[COLUMNS_ARRAY_MAP_FOR_IMAGE_WITH_ENRICHED_METADATA_OBJECT[columnDefinition.columnDefinitionType]][columnDefinition.columnDefinitionConstant]}
                        renderConfigObject={renderObjectsMap[columnDefinition.renderConfigsMapKey]}
                        // @ts-ignore
                        trimStringStart={columnDefinition.trimResultStart}
                        // @ts-ignore
                        trimStringEnd={columnDefinition.trimResultEnd}
                        width={columnDefinition.width}
                    />
                )}
            </tr>
        </OverlayTrigger>
    );
};

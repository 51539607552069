import React, { FC } from 'react';
import { DEFAULT_RENDER_CONFIG_OBJECT } from '../constants';
import { RenderConfigObject } from '../custom_types';
import { OutputForRenderObject } from './OutputForRenderObject';

interface KeywordsTableTdProps {
    contentArray?: string[],
    renderConfigObject: RenderConfigObject,
    trimStringStart?: number,
    trimStringEnd?: number,
    width?: number,
}

export const KeywordsTableTd: FC<KeywordsTableTdProps> = ({
    contentArray,
    renderConfigObject,
    trimStringStart = 0,
    trimStringEnd = 0,
    width=50,
}) => {

    if (!renderConfigObject) {
        renderConfigObject = DEFAULT_RENDER_CONFIG_OBJECT;
    }

    let modifiedContentArray : string[] = [''];
    if (contentArray) {
        // TODO Improve this
        if (typeof contentArray === 'string') {
            modifiedContentArray = contentArray;
            //modifiedContentArray = ((contentArray as string).substring(trimStringStart, (contentArray as string).length-trimStringEnd) as string);

            //arrayFromSourceColumnString.substring(customColumnObject.trimResultStart, arrayFromSourceColumnString.length-customColumnObject.trimResultEnd)
        } else {
            if (typeof contentArray.map !== 'undefined') {
                 modifiedContentArray = contentArray.map((item: any) => {
                    //return item;
                    //if (typeof item !== 'string') {
                    //    debugger;
                    //}
                    //if (typeof item.substring === 'undefined') {
                    //    debugger;
                    //}

                    if (typeof item === 'string') {
                        return item.substring(trimStringStart, item.length-trimStringEnd);
                    } else {
                        return item;    
                    }

                    //return item.substring(trimStringStart, item.length-trimStringEnd);
                 });
            }
        }
    }

    return (
        <td style={{ "minWidth": width, "maxWidth": width } as React.CSSProperties} className={renderConfigObject.constant} >
                <OutputForRenderObject
                    contentArray={modifiedContentArray}
                renderConfigObject={renderConfigObject}
                />
        </td>
    );
}

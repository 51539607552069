import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { Pie } from 'react-chartjs-2';
import ReactWordcloud from 'react-wordcloud';
import { PIE_CHART_BACKGROUND_COLORS_ARRAY, PIE_CHART_HOVER_COLORS_ARRAY, WARNING_CONDITION_TYPE_DOES_MATCH_KEY } from './constants';
import { ColumnDefinition, DataAnalysisCountObject, DataFilterObject } from './custom_types';
import { numberWithSeparator } from './Helpers';

interface DataAnalysisSectionUniqueValuesTableProps {
    headline: string,
    totalImages: number,
    columnsMap: Object,
    uniqueValuesArray: DataAnalysisCountObject[],
    setDataFilterObjectArray: any,
    columnDefinition: ColumnDefinition,
    applyFilterForDataSourceJSON: any,
    setShowDataAnalysisModal: any,
}

export const DataAnalysisSectionUniqueValuesTable: FC<DataAnalysisSectionUniqueValuesTableProps> = ({
    headline,
    totalImages,
    columnsMap,
    uniqueValuesArray,
    setDataFilterObjectArray,
    columnDefinition,
    applyFilterForDataSourceJSON,
    setShowDataAnalysisModal,
}) => {
    let trsUniqueArray: JSX.Element[] = [];
    const addAsFilter = (valueString: string) => {
        const newDataFilterObject : DataFilterObject[] = [{
            column: columnDefinition,
            conditionType: WARNING_CONDITION_TYPE_DOES_MATCH_KEY,
            searchValue: valueString,
        }] as DataFilterObject[];

        setShowDataAnalysisModal(false);
        setDataFilterObjectArray(newDataFilterObject);

        // TODO: (when this code is activated) Solve problem that last set filter is applied - but not the most recently set filter
        //setTimeout(() => {applyFilterForDataSourceJSON()}, 1000);      
        //applyFilterForDataSourceJSON();

        setTimeout(() => {
            alert('Der Filter wurde automatisch gesetzt. Die automatische Filterung funktioniert derzeit noch nicht. Bitte klicken sie oben auf "Filtern" um die Suche zu starten.');
        }, 1000);      
    }

    uniqueValuesArray.forEach((dataAnalysisCountObject: DataAnalysisCountObject, dataAnalysisCountObjectIndex: number) => {
        //let s = uniqueValuesArray[dataAnalysisCountObjectKey] as DataAnalysisCountObject;
        trsUniqueArray.push(
            <tr key={`data_analysis_section_${dataAnalysisCountObject.text}`}>
                <td>
                    <Button
                        size='sm'
                        variant='secondary'
                        onClick={() => addAsFilter(dataAnalysisCountObject.text)}
                    >
                        <FontAwesomeIcon icon={faSearch} />
                    </Button> {dataAnalysisCountObject.text}
                </td>
                <td>{numberWithSeparator(dataAnalysisCountObject.value)}</td>
            </tr>
        );
    });

    return (
        <Row>
            <Col>
                <div className='container-data-analysis'>
                    <Table className='data-analysis'>
                        <thead>
                            <tr>
                                <th>Wert</th>
                                <th>Anzahl</th>
                            </tr>
                        </thead>
                        <tbody>
                            {trsUniqueArray}
                        </tbody>
                    </Table>
                </div>
            </Col>
        </Row>
    )
};

import { faBars, faPause } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useState } from 'react';
import { Button, Dropdown, OverlayTrigger, Pagination, Popover } from "react-bootstrap";
import { ELEMENTS_PER_PAGE_1, ELEMENTS_PER_PAGE_100, ELEMENTS_PER_PAGE_1000, ELEMENTS_PER_PAGE_10000, ELEMENTS_PER_PAGE_100000, ELEMENTS_PER_PAGE_15, ELEMENTS_PER_PAGE_30, ELEMENTS_PER_PAGE_50, TAB_KEY_GRID_VIEW, TAB_KEY_IMAGE_DETAIL_VIEW } from "./constants";
import { PaginationObject } from './custom_types';
import { numberWithSeparator } from './Helpers';

interface MyPaginationProps {
    setRequestPageWithLoading: any, //function
    hideItemsPerPage?: boolean,
    mainPaginationObject: PaginationObject,
    setMainPaginationObject: any,
    activeViewTabKey: string,
    slideshowIntervalVariable: any,
    setSlideshowIntervalVariable: null|any,

}

export const MyPagination: FC<MyPaginationProps> = ({
    setRequestPageWithLoading,
    hideItemsPerPage,
    mainPaginationObject,
    setMainPaginationObject,
    activeViewTabKey,
    slideshowIntervalVariable,
    setSlideshowIntervalVariable,
}) => {
    
    const [goToPageNumber, setgoToPageNumber] = useState(1 as number);

    // TODO: Check if this function works as expected
    // const setRandomRequestPage = () => {
    //     //console.log('setRandomRequestPage was called');
    //     let newRandomPage = Math.floor(Math.random() * lastPage);
    //     while ((newRandomPage = Math.floor(Math.random() * lastPage)) !== currentPage) {
    //         //console.log('setRequestPage:' + newRandomPage);
    //         setRequestPageWithLoading(newRandomPage);
    //     }
    // };

    const toggleSlideshow = (milliseconds?: number|null) => {
        if (slideshowIntervalVariable) {
            setSlideshowIntervalVariable(clearInterval(slideshowIntervalVariable));
            //clearInterval(intervalVariable);
            //setIntervalVariable(intervalVariable);
            //setIntervalVariable(intervalVariable);
        } else {
            console.log('toggleSlideshow');
            let tmpNewRequestPage = mainPaginationObject.requestPage;
            let tmpInterval = setInterval(() => {
                    tmpNewRequestPage++;
                    const newMainPagination = Object.assign({}, mainPaginationObject);
                    newMainPagination.requestPage=tmpNewRequestPage;
                    setMainPaginationObject(newMainPagination);
            }, (milliseconds ? milliseconds : 2000));
            setSlideshowIntervalVariable(tmpInterval);
        }
    }

    const changeGoToPageNumber = () => {
        setRequestPageWithLoading(goToPageNumber);
    }

    const changeItemsPerPage = (elementsPerPage: number, resetRequestPage : boolean = false) => {
        if (resetRequestPage) {
            setRequestPageWithLoading(1);
        }
        let newMainPagination = Object.assign({}, mainPaginationObject);
        newMainPagination.itemsPerPage = elementsPerPage;
        setMainPaginationObject(newMainPagination);
    };

    const changePageFunction = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        // @ts-ignore
        setRequestPageWithLoading(parseInt(event.target.getAttribute('data-target-page')));
      };

    const changeGridColumns = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newMainPagination = Object.assign({}, mainPaginationObject);
        newMainPagination.imagesPerRow = parseInt(event.target.value);
        setMainPaginationObject(newMainPagination);
      };

    let elementPerPageOptionsArray = [];

    switch (activeViewTabKey) {
        case TAB_KEY_GRID_VIEW:
            elementPerPageOptionsArray = [6,12,24,36];
            break;
        default:
            elementPerPageOptionsArray = [
                ELEMENTS_PER_PAGE_1,
                ELEMENTS_PER_PAGE_15,
                ELEMENTS_PER_PAGE_30,
                ELEMENTS_PER_PAGE_50,
                ELEMENTS_PER_PAGE_100,
                ELEMENTS_PER_PAGE_1000,
                ELEMENTS_PER_PAGE_10000,
                ELEMENTS_PER_PAGE_100000,
            ];
            break;
    }

    const imagesPerRowOptionsArray = [
        2,3,4,6
    ]

    let active = mainPaginationObject.requestPage;
    let items = [];

    for (let pageIdx = 1; pageIdx <= mainPaginationObject.maxPage; pageIdx++) {
        if (pageIdx === 1 ||
            pageIdx >= mainPaginationObject.requestPage - 2 && pageIdx <= mainPaginationObject.requestPage + 2 ||
            pageIdx == mainPaginationObject.maxPage

        ) {
            items.push(
                <Pagination.Item
                    key={pageIdx}
                    active={pageIdx === active}
                    data-target-page={pageIdx}
                    // @ts-ignore
                    onClick={changePageFunction}
                    href=''
                >
                    {pageIdx}
                </Pagination.Item>
            );
        }
    }

    const popover = (
        <Popover id="popover-basic">
            <Popover.Title as="h3">
                Seiten-Einstellungen    
            </Popover.Title>
            {activeViewTabKey===TAB_KEY_GRID_VIEW ?
            <Popover.Content>
                <span>Bilder pro Reihe: </span>
                <select
                    value={mainPaginationObject.imagesPerRow}
                    defaultValue={2}
                    onChange={(event) => changeGridColumns(event)}
                    >
                        {imagesPerRowOptionsArray.map((imagesPerRowOption: number) => 
                            <option>{imagesPerRowOption}</option>
                        )}
                </select>
            </Popover.Content>  : <></> }  
            {! hideItemsPerPage ? 
            <Popover.Content>   
                <Dropdown drop='up' id='my-pagination-elements-per-page-dropdown'>
                    <Dropdown.Toggle split size='sm' variant='secondary'>
                        {`${numberWithSeparator(mainPaginationObject.itemsPerPage)} Elemente pro Seite `}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {elementPerPageOptionsArray.map((elementsPerPageOption: number, elementsPerPageOptionIndex: number) =>                                             
                            <Dropdown.Item
                                key={`my-pagination-dropdown${elementsPerPageOption}`}
                                onClick={() => changeItemsPerPage(elementsPerPageOption)}
                            >
                                {numberWithSeparator(elementsPerPageOption)} pro Seite
                            </Dropdown.Item>
                            )
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </Popover.Content> : <></> }  
            <Popover.Content> 
                <span className='pagination-goto-page-span'>Gehe zu Seite </span>
                <input
                    style={{width:'60px'}}
                    className="text-muted"
                    type='number'
                    min={1}
                    max={mainPaginationObject.maxPage}
                    //onChange={(event) => setgoToPageNumber(parseInt(event.target.value))}
                    onChange={(event) => setgoToPageNumber(parseInt(event.target.value))}
                    value={goToPageNumber}
                />
                <Button variant='primary' size='sm' onClick={() => changeGoToPageNumber()}>{'-->'}</Button>
            </Popover.Content>
        </Popover>
    );

    return (
        <Pagination size='sm'>
            <Pagination.Item
                key='previous_page'
                data-target-page={mainPaginationObject.requestPage - 1}
                // @ts-ignore
                onClick={changePageFunction}
                disabled={mainPaginationObject.requestPage - 1 < 1}
                href=''
            >
                Vorherige
            </Pagination.Item>
            {items}
            <Pagination.Item
                key='next_page'
                data-target-page={mainPaginationObject.requestPage + 1}
                // @ts-ignore
                onClick={changePageFunction}
                disabled={mainPaginationObject.requestPage + 1 > mainPaginationObject.maxPage}
                href=''
            >
                Nächste
            </Pagination.Item>
            <Pagination.Item className='pagination-item-hamburger-menu-button'>
                <OverlayTrigger trigger="click" placement="top" overlay={popover} rootClose>
                    <Button size='sm' variant='secondary'>
                        <FontAwesomeIcon icon={faBars} />
                    </Button>
                </OverlayTrigger>
            </Pagination.Item>
            {activeViewTabKey===TAB_KEY_IMAGE_DETAIL_VIEW &&
                <>
                    {slideshowIntervalVariable ?
                        <Button size='sm' variant='secondary' onClick={() => toggleSlideshow()}>
                            <FontAwesomeIcon icon={faPause} /> Diashow stoppen
                        </Button>
                        :
                        <Dropdown drop='up' id='my-pagination-elements-per-page-dropdown'>
                            <Dropdown.Toggle split size='sm' variant='secondary'>Diashow </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {[3,5,10,20].map((durationInSeconds: number, durationInSecondsIndex: number) =>                                             
                                    <Dropdown.Item
                                        onClick={() => toggleSlideshow(durationInSeconds*1000)}
                                    >
                                        {durationInSeconds} Sek.
                                    </Dropdown.Item>
                                    )
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </>
            }
            
            
        </Pagination>
    )
};

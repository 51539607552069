import { FC } from 'react';
import { ProgressBar } from "react-bootstrap";
import { LoadingObject } from './custom_types';


interface LoadingProgressProps {
    loadingObject: LoadingObject,
}

export const LoadingProgress: FC<LoadingProgressProps> = ({
    loadingObject,
}) => {
   
    return (
        <>
         <ProgressBar animated now={loadingObject.progress} />
         <p>{loadingObject.text}</p>
        </>
    )
}

import { FC, useState } from "react";
import { FormatFunction } from "./custom_types";
import { Carousel, Dropdown } from "react-bootstrap";
import { FORMATFUNCTIONS_ARRAY} from "./constants";


interface FormatFunctionSelectWithPreviewProps { 
    selectedFormatFunctionConstant: string,
    data_key1_name: string,
    data_key1_value: number,
    data_key2_name?: string,
    data_key2_value?: number,
    onClickFunction: any,
    typeExperiment: string,
}

export const FormatFunctionSelectWithPreview: FC<FormatFunctionSelectWithPreviewProps> = ({
    selectedFormatFunctionConstant,
    data_key1_name,
    data_key1_value,
    data_key2_name,
    data_key2_value,
    onClickFunction,
    typeExperiment,
}) => {
    let dataKey2 = null;
    if (data_key2_name && data_key2_value) {
        dataKey2 = {[data_key2_name]: data_key2_value};
    }

    const [index, setIndex] = useState(0);
      
    // @ts-ignore
    const handleSelect = (selectedIndex: number, e) => {
        setIndex(selectedIndex);
    };

    let dropdownItemsArray: JSX.Element[] = [];
    for (let formatFunctionIndex=0; formatFunctionIndex<FORMATFUNCTIONS_ARRAY.length; formatFunctionIndex++) {
        dropdownItemsArray.push( 
            <Dropdown.Item
                key={`format-function-component-dropdown-item-${formatFunctionIndex}`}
                data-key-selected-format-function-constant={FORMATFUNCTIONS_ARRAY[formatFunctionIndex].constant}
                {...{[data_key1_name]: data_key1_value}}
                onClick={onClickFunction}
            >
                {FORMATFUNCTIONS_ARRAY[formatFunctionIndex].name}
            </Dropdown.Item>
        )
    }

    let carouselItemsArray: JSX.Element[] = [];
    for (let formatFunctionIndex=0; formatFunctionIndex<FORMATFUNCTIONS_ARRAY.length; formatFunctionIndex++) {
        carouselItemsArray.push(                                     
            <Carousel.Item>
                {`${FORMATFUNCTIONS_ARRAY[formatFunctionIndex].name} (Formatfunction Idx ${formatFunctionIndex})`}
                <Carousel.Caption>
                <h3>First slide label</h3>
                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </Carousel.Caption>
            </Carousel.Item>
        );
    }

    let formatFunctionObject = FORMATFUNCTIONS_ARRAY.find(element => element.constant === selectedFormatFunctionConstant) as FormatFunction;
    
    if (!formatFunctionObject) {
        formatFunctionObject = FORMATFUNCTIONS_ARRAY[0];
    }
    return (
        <>
            {(typeExperiment == 'dropdown') ? (
                <Dropdown>
                    <Dropdown.Toggle split size='sm' variant='secondary' id={`dropdown_custom_column_source_column_${index}`}>
                        {formatFunctionObject ? `${formatFunctionObject.name} ` : 'FEHLER '}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {dropdownItemsArray}
                    </Dropdown.Menu>
                </Dropdown>
            ) : (
                <div>
                    <Carousel
                        onSelect={handleSelect}
                        activeIndex={index}
                        slide={false}
                    >
                        {carouselItemsArray}
                    </Carousel>
                </div>
            )}
        </>
    );
}
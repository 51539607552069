import { FC, useEffect } from "react";
import { Badge, Col, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar, faCog, faGripHorizontal, faGripVertical, faImage } from "@fortawesome/free-solid-svg-icons";
import { ColumnDefinition, RemarksColumnDefinition, CustomColumnDefinition, ColumnPresetFile, DataFilePresetFile, AdditionalColumnsConfig, ColumnsForViewObject, Settings, ExchangeRemarksColumnDefinition, ExchangeCustomColumnDefinition, ImageWithEnrichedMetadataObject, DB_Import, DB_System, ImageWithMetadataObject } from "../custom_types";
import { COLUMNS_FOR_COMPARE_VIEW_KEY, COLUMNS_FOR_DETAIL_VIEW_KEY, COLUMNS_FOR_FOLDER_VIEW_KEY, COLUMNS_FOR_GRID_VIEW_KEY, COLUMNS_FOR_TABLE_VIEW_KEY, COLUMNS_FOR_VIEWS_MAP, CUSTOM_COLUMN_TAB_KEY_ADD, DATA_SOURCE_JSON, REMARKS_COLUMN_TAB_KEY_ADD, TAB_KEY_COLUMN_CUSTOMIZER_COMMENT_COLUMN, TAB_KEY_COLUMN_CUSTOMIZER_CUSTOM_COLUMN, TAB_KEY_COLUMN_CUSTOMIZER_IMPORT_EXPORT, TAB_KEY_COLUMN_CUSTOMIZER_LOAD_PRESETS, TAB_KEY_COLUMN_CUSTOMIZER_QR_CODE_AND_LINK_PAGE, TAB_KEY_COLUMN_CUSTOMIZER_RELATED_COLUMNS, TAB_KEY_COLUMN_CUSTOMIZER_RENDER_OBJECTS_CUSTOMIZER, TAB_KEY_COLUMN_CUSTOMIZER_REXEXP_ERROR_LOG, TAB_KEY_COLUMN_CUSTOMIZER_SETTINGS, TAB_KEY_COLUMN_CUSTOMIZER_SORT, TAB_KEY_COLUMN_CUSTOMIZER_SORT_FOR_COMPARE_VIEW, TAB_KEY_COLUMN_CUSTOMIZER_SORT_FOR_DETAIL_VIEW, TAB_KEY_COLUMN_CUSTOMIZER_SORT_FOR_FOLDER_VIEW, TAB_KEY_COLUMN_CUSTOMIZER_SORT_FOR_GRID_VIEW, TAB_KEY_COLUMN_CUSTOMIZER_SORT_FOR_SEARCH_VIEW, TAB_KEY_COLUMN_CUSTOMIZER_SORT_FOR_TABLE_VIEW, TAB_KEY_GRID_VIEW, TAB_KEY_IMAGE_COMPARE_VIEW, TAB_KEY_IMAGE_DETAIL_VIEW, TAB_KEY_SEARCH_VIEW, TAB_KEY_TABLE_VIEW } from "../constants";
import { CustomColumnsTable } from "../CustomColumnsTable";
import { RemarksColumnsTable } from "../RemarksColumnsTable";
import { RelatedColumnSelection } from "../RelatedColumnSelection";
import { RenderObjectsCustomizer } from "../RenderObjectsCustomizer";
import { ColumnDefinitionLoadPresets } from "../ColumnDefinitionLoadPresets";
import { QRCodeAndLinkPage } from "../QRCodeAndLinkPage";
import { ColumnDefinitionCustomizer } from "../ColumnDefinitionCustomizer";
import { DataSourceSettings } from "../DataSourceSettings";
import { SettingsPage } from "../SettingsPage";
import { KeywordsTableSettings } from "../KeywordsTable/KeywordsTableSettings";
import { ImportToDatabase } from "../ImportToDatabase";

interface ColumnDefinitionModalProps { // TODO: Move to constants
    dataSource: number,
    darkMode: boolean,
    columnDefinitionArray: ColumnDefinition[],
    columnsForViewArray: ColumnsForViewObject,
    showColumnDefinitionModal: any, // function
    handleCloseColumnDefinitionModal: any, // function

    customColumnsArray: CustomColumnDefinition[],
    setCustomColumnsArray: any, // function

    remarksColumnsArray: RemarksColumnDefinition[],
    setRemarksColumnsArray: any, // function

    setColumnsForViewArray: any,
    columnPresetFilesArray: ColumnPresetFile[],
    presetDataFilesArray: DataFilePresetFile[],
    loadPredefinedColumnsFile: any,

    additionalColumnsConfigFromLocalStorage: AdditionalColumnsConfig[],
    setAdditionalColumnsConfigFromLocalStorage: any,
    setStateVariablesForAdditionalColumnsConfig: any,
    relatedColumnsArray: ColumnDefinition[],
    setRelatedColumnsArray: any,
    setImagesWithMetadataNeedsNewEnrichment: any,
    getColumnDefinitionByCustomDefinitionConstant: any,
    activeViewTabKey: string,
    enrichImageWithMetadataObjectArray: any,
    settings: Settings,
    setSettings: any,
    activeRemarksColumnTabKey: string,
    setActiveRemarksColumnTabKey: any,
    activeCustomColumnTabKey: string,
    setActiveCustomColumnTabKey: any,
    activeTabKeyColumnDefinitionModal: string,
    setActiveTabKeyColumnDefinitionModal: any,
    activeTabKeyColumnDefinitionModal2: string,
    setActiveTabKeyColumnDefinitionModal2: any,
    renderObjectsMap: any,
    setRenderObjectsMap: any,
    additionalColumnsRepoRemarkColumnsArray: ExchangeRemarksColumnDefinition[],
    additionalColumnsRepoCustomColumnsArray: ExchangeCustomColumnDefinition[],
    connectedToDatabase: boolean,
    setConnectedToDatabase: any,
    connectedToNodeServer: boolean,
    setConnectedToNodeServer: any,
    testDatabaseConnection: any,
    appHasData: boolean,
    testNodeServerConnection: any,
    databaseHost: string,
    setDatabaseHost: any, // function
    nodeServerHost: string,
    setNodeServerHost: any, // function
    setSettingsCollapseOpen: any, // function
    setDataSource: any, // function
    changePathNameInputField: any, // function
    keywordsLoadFromPathname: string,
    pathNameInputField: string,
    setPathNameInputField: any, // function
    ihkhsjsonHistoryItems: string[],
    loadDataFromJSONFromFilesystem: any, //function
    pathNameInputFieldExistsOnFilesystem: boolean,
    checkPathExistsOnFilesystem: any, //function
    loadDataFromFile: any,

    showPreviewImages: boolean,
    setShowPreviewImages: any, // function
    toggleCheckForKeywordCorrections: any, // function
    filePathReplace1String: string,
    filePathReplace2String: string,
    changeAndSetFilePathReplace1String: any, //function
    changeAndSetFilePathReplace2String: any, //function
    filePathReplace2StringExistsOnFileSystem: boolean,
    exampleImageWithEnrichedMetadataObject?: ImageWithEnrichedMetadataObject,
    replaceDirectoryInFilePath: any, //function
    useImgSrcReplacement: boolean,
    setUseImgSrcReplacement: any, // function
    useImgExtReplacement: boolean,
    toggleUseImgSrcReplacement: any, // function
    toggleUseImgExtReplacement: any, // function
    checkForKeywordCorrections: boolean,
    setCheckForKeywordCorrections: any, // function
    doCalculateCheckNumberForLoknummer: boolean,
    toggleDoCalculateCheckNumberForLoknummer: any, //function
    fileExtReplaceString: string,
    setFileExtReplaceString: any,

    csrfToken: string,
    //imageWithMetadataOriginalArray: ImageWithMetadataObject[],
    //dataSourceOnlyFilesystemAndJSONFilename: string,
    //dataSource: number,
    importsArray: DB_Import[],
    systemsArray: DB_System[],
    imageWithMetadataOriginalArray: ImageWithMetadataObject[],
    dataSourceOnlyFilesystemAndJSONFilename: string,
    importIsForbidden: boolean,
    updateImportsFromDatabase: any,
    updateSystemsFromDatabase: any,
}

export const ColumnDefinitionModal: FC<ColumnDefinitionModalProps> = ({
    dataSource,
    darkMode,
    columnDefinitionArray,
    columnsForViewArray,
    showColumnDefinitionModal,
    handleCloseColumnDefinitionModal,
    customColumnsArray,
    setCustomColumnsArray,

    remarksColumnsArray,
    setRemarksColumnsArray,

    setColumnsForViewArray,

    columnPresetFilesArray,
    presetDataFilesArray,
    loadPredefinedColumnsFile,

    additionalColumnsConfigFromLocalStorage,
    setAdditionalColumnsConfigFromLocalStorage,

    setStateVariablesForAdditionalColumnsConfig,
    relatedColumnsArray,
    setRelatedColumnsArray,
    setImagesWithMetadataNeedsNewEnrichment,
    getColumnDefinitionByCustomDefinitionConstant,
    activeViewTabKey,
    enrichImageWithMetadataObjectArray,
    settings,
    setSettings,
    activeRemarksColumnTabKey,
    setActiveRemarksColumnTabKey,
    activeCustomColumnTabKey,
    setActiveCustomColumnTabKey,
    activeTabKeyColumnDefinitionModal,
    setActiveTabKeyColumnDefinitionModal,
    activeTabKeyColumnDefinitionModal2,
    setActiveTabKeyColumnDefinitionModal2,
    renderObjectsMap,
    setRenderObjectsMap,
    additionalColumnsRepoRemarkColumnsArray,
    additionalColumnsRepoCustomColumnsArray,
    connectedToDatabase,
    setConnectedToDatabase,
    connectedToNodeServer,
    setConnectedToNodeServer,
    testDatabaseConnection,
    appHasData,
    testNodeServerConnection,
    databaseHost,
    setDatabaseHost,
    nodeServerHost,
    setNodeServerHost,
    setSettingsCollapseOpen,
    setDataSource,
    changePathNameInputField,
    keywordsLoadFromPathname,
    pathNameInputField,
    setPathNameInputField,
    ihkhsjsonHistoryItems,
    loadDataFromJSONFromFilesystem,
    pathNameInputFieldExistsOnFilesystem,
    checkPathExistsOnFilesystem,
    loadDataFromFile,

    toggleCheckForKeywordCorrections,
    doCalculateCheckNumberForLoknummer,
    showPreviewImages,
    setShowPreviewImages,

    filePathReplace1String,
    filePathReplace2String,
    changeAndSetFilePathReplace1String,
    changeAndSetFilePathReplace2String,
    filePathReplace2StringExistsOnFileSystem,
    exampleImageWithEnrichedMetadataObject,
    replaceDirectoryInFilePath,
    useImgSrcReplacement,
    useImgExtReplacement,
    toggleUseImgSrcReplacement,
    toggleUseImgExtReplacement,
    checkForKeywordCorrections,
    toggleDoCalculateCheckNumberForLoknummer,
    fileExtReplaceString,
    setFileExtReplaceString,
    csrfToken,
    importsArray,
    systemsArray,
    imageWithMetadataOriginalArray,
    dataSourceOnlyFilesystemAndJSONFilename,
    importIsForbidden,
    updateImportsFromDatabase,
    updateSystemsFromDatabase,
}) => {
    useEffect(() => {
        switch(activeViewTabKey) {
            case TAB_KEY_TABLE_VIEW:
                setActiveTabKeyColumnDefinitionModal2(TAB_KEY_COLUMN_CUSTOMIZER_SORT_FOR_TABLE_VIEW);

            break;
            case TAB_KEY_SEARCH_VIEW:
                setActiveTabKeyColumnDefinitionModal2(TAB_KEY_COLUMN_CUSTOMIZER_SORT_FOR_SEARCH_VIEW);

            break;
            case TAB_KEY_IMAGE_COMPARE_VIEW:
                setActiveTabKeyColumnDefinitionModal2(TAB_KEY_COLUMN_CUSTOMIZER_SORT_FOR_COMPARE_VIEW);

            break;
            case TAB_KEY_IMAGE_DETAIL_VIEW:
                setActiveTabKeyColumnDefinitionModal2(TAB_KEY_COLUMN_CUSTOMIZER_SORT_FOR_DETAIL_VIEW);

            break;
            case TAB_KEY_GRID_VIEW:
                setActiveTabKeyColumnDefinitionModal2(TAB_KEY_COLUMN_CUSTOMIZER_SORT_FOR_GRID_VIEW);
            break;
            default: //TAB_KEY_IMAGE_FOLDER_VIEW
            setActiveTabKeyColumnDefinitionModal2(TAB_KEY_COLUMN_CUSTOMIZER_SORT_FOR_FOLDER_VIEW);
            break;
        }
    }, [activeViewTabKey]);

    const changeActiveTabKeyColumnDefinitionModal = (newTabKey: string | null): void => {
        if (newTabKey) {
            setActiveTabKeyColumnDefinitionModal(newTabKey);
        }
    }

    const changeActiveTabKeyColumnDefinitionModal2 = (newTabKey: string | null): void => {
        if (newTabKey) {
            setActiveTabKeyColumnDefinitionModal2(newTabKey);
        }
    }

    //const [activeTabKey, setActiveTabKey] = useState(TAB_KEY_COLUMN_CUSTOMIZER_SORT);

    return (
        <Modal size="xl" show={showColumnDefinitionModal} onHide={handleCloseColumnDefinitionModal} className={darkMode ? 'darkmode' : ''}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FontAwesomeIcon icon={faCog} /> Einstellungen
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs activeKey={activeTabKeyColumnDefinitionModal} defaultActiveKey={TAB_KEY_COLUMN_CUSTOMIZER_SORT} onSelect={(key) => changeActiveTabKeyColumnDefinitionModal(key)} className='additional-columns-first-layer-tabs'>
                    <Tab eventKey={TAB_KEY_COLUMN_CUSTOMIZER_SORT} title='Sortierung' tabClassName='tab-primary' className='tab-show-border-top'>
                        <h4>Sortierung der Spalten ändern</h4>
                        <p>Für die Ansichten gibt es jeweils eine seperate Spalten-Konfiguration</p>
                        {activeTabKeyColumnDefinitionModal === TAB_KEY_COLUMN_CUSTOMIZER_SORT && (
                            <Tabs activeKey={activeTabKeyColumnDefinitionModal2} defaultActiveKey={TAB_KEY_COLUMN_CUSTOMIZER_SORT_FOR_TABLE_VIEW} onSelect={(key) => changeActiveTabKeyColumnDefinitionModal2(key)} className='additional-columns-second-layer-tabs'>
                                <Tab eventKey={TAB_KEY_COLUMN_CUSTOMIZER_SORT_FOR_TABLE_VIEW} title={<><FontAwesomeIcon icon={faChartBar} /> {COLUMNS_FOR_VIEWS_MAP[COLUMNS_FOR_TABLE_VIEW_KEY]}</>} tabClassName='tab-primary'>
                                    
                                    {/* <ColumnDefinitionDropdown
                                        switchToColumnDefinitionPreset={switchToColumnDefinitionPreset}
                                        dropdownId={'column_definition_modal_column_definition_dropdown'}
                                    /> */}
                                    <ColumnDefinitionCustomizer
                                        columnsForViewArray={columnsForViewArray}
                                        columnsForViewKey={COLUMNS_FOR_TABLE_VIEW_KEY}
                                        setColumnsForViewArray={setColumnsForViewArray}
                                        renderObjectsMap={renderObjectsMap}
                                    />
                                </Tab>
                                <Tab eventKey={TAB_KEY_COLUMN_CUSTOMIZER_SORT_FOR_DETAIL_VIEW} title={<><FontAwesomeIcon icon={faImage} /> {COLUMNS_FOR_VIEWS_MAP[COLUMNS_FOR_DETAIL_VIEW_KEY]}</>} tabClassName='tab-primary'>
                                    <ColumnDefinitionCustomizer
                                        columnsForViewArray={columnsForViewArray}
                                        columnsForViewKey={COLUMNS_FOR_DETAIL_VIEW_KEY}
                                        setColumnsForViewArray={setColumnsForViewArray}
                                        renderObjectsMap={renderObjectsMap}
                                    />
                                </Tab>
                                <Tab eventKey={TAB_KEY_COLUMN_CUSTOMIZER_SORT_FOR_GRID_VIEW} title={<><FontAwesomeIcon icon={faGripHorizontal} /> {COLUMNS_FOR_VIEWS_MAP[COLUMNS_FOR_GRID_VIEW_KEY]}</>} tabClassName='tab-primary'>
                                    <ColumnDefinitionCustomizer
                                        columnsForViewArray={columnsForViewArray}
                                        columnsForViewKey={COLUMNS_FOR_GRID_VIEW_KEY}
                                        setColumnsForViewArray={setColumnsForViewArray}
                                        renderObjectsMap={renderObjectsMap}
                                    />
                                </Tab>
                                <Tab eventKey={TAB_KEY_COLUMN_CUSTOMIZER_SORT_FOR_FOLDER_VIEW} title={<><FontAwesomeIcon icon={faGripVertical} /> {COLUMNS_FOR_VIEWS_MAP[COLUMNS_FOR_FOLDER_VIEW_KEY]}</>} tabClassName='tab-primary'>
                                    <ColumnDefinitionCustomizer
                                        columnsForViewArray={columnsForViewArray}
                                        columnsForViewKey={COLUMNS_FOR_FOLDER_VIEW_KEY}
                                        setColumnsForViewArray={setColumnsForViewArray}
                                        renderObjectsMap={renderObjectsMap}
                                    />
                                </Tab>
                                <Tab eventKey={TAB_KEY_COLUMN_CUSTOMIZER_SORT_FOR_COMPARE_VIEW} title={<>{COLUMNS_FOR_VIEWS_MAP[COLUMNS_FOR_COMPARE_VIEW_KEY]}</>} tabClassName='tab-primary'>
                                    <ColumnDefinitionCustomizer
                                        columnsForViewArray={columnsForViewArray}
                                        columnsForViewKey={COLUMNS_FOR_COMPARE_VIEW_KEY}
                                        setColumnsForViewArray={setColumnsForViewArray}
                                        renderObjectsMap={renderObjectsMap}
                                    />
                                </Tab>
                            </Tabs>
                        )}
                    </Tab>
                    <Tab eventKey={TAB_KEY_COLUMN_CUSTOMIZER_COMMENT_COLUMN}
                        title={<span key='column-def-modal-tab-span-1'>Bemerkungsspalten <Badge variant="secondary">{remarksColumnsArray.length}</Badge></span>}
                        tabClassName='tab-primary'
                    >
                        {activeTabKeyColumnDefinitionModal === TAB_KEY_COLUMN_CUSTOMIZER_COMMENT_COLUMN && (
                            <RemarksColumnsTable
                                customColumsArray={customColumnsArray}
                                setCustomColumsArray={setCustomColumnsArray}
                                columnDefinitionArray={columnDefinitionArray}
                                remarksColumnsArray={remarksColumnsArray}
                                setRemarksColumnsArray={setRemarksColumnsArray}
                                setImagesWithMetadataNeedsNewEnrichment={setImagesWithMetadataNeedsNewEnrichment}
                                settings={settings}
                                dataSource={dataSource}
                                columnsForViewArray={columnsForViewArray}
                                setColumnsForViewArray={setColumnsForViewArray}
                                getColumnDefinitionByCustomDefinitionConstant={getColumnDefinitionByCustomDefinitionConstant}
                                enrichImageWithMetadataObjectArray={enrichImageWithMetadataObjectArray}
                                activeRemarksColumnTabKey={activeRemarksColumnTabKey}
                                setActiveRemarksColumnTabKey={setActiveRemarksColumnTabKey}
                                setActiveCustomColumnTabKey={setActiveCustomColumnTabKey}
                                setActiveTabKeyColumnDefinitionModal={setActiveTabKeyColumnDefinitionModal}
                                renderObjectsMap={renderObjectsMap}
                                additionalColumnsRepoCustomColumnsArray={additionalColumnsRepoCustomColumnsArray}                            
                                additionalColumnsRepoRemarkColumnsArray={additionalColumnsRepoRemarkColumnsArray}                            
                            />
                        )}
                    </Tab>
                    <Tab eventKey={TAB_KEY_COLUMN_CUSTOMIZER_CUSTOM_COLUMN}
                        title={<span key='column-def-modal-tab-span-2'>Zusatzspalten <Badge variant="secondary">{customColumnsArray.length}</Badge></span>}
                        tabClassName='tab-primary'
                        >
                        {activeTabKeyColumnDefinitionModal === TAB_KEY_COLUMN_CUSTOMIZER_CUSTOM_COLUMN && (
                            <CustomColumnsTable
                                dataSource={dataSource}
                                columnDefinitionArray={columnDefinitionArray}
                                customColumnsArray={customColumnsArray}
                                remarksColumnsArray={remarksColumnsArray}
                                setCustomColumnsArray={setCustomColumnsArray}
                                setImagesWithMetadataNeedsNewEnrichment={setImagesWithMetadataNeedsNewEnrichment}
                                columnsForViewArray={columnsForViewArray}
                                setColumnsForViewArray={setColumnsForViewArray}
                                getColumnDefinitionByCustomDefinitionConstant={getColumnDefinitionByCustomDefinitionConstant}
                                enrichImageWithMetadataObjectArray={enrichImageWithMetadataObjectArray}
                                settings={settings}
                                activeCustomColumnTabKey={activeCustomColumnTabKey}
                                setActiveCustomColumnTabKey={setActiveCustomColumnTabKey}
                                renderObjectsMap={renderObjectsMap}
                                additionalColumnsRepoRemarkColumnsArray={additionalColumnsRepoRemarkColumnsArray}
                                additionalColumnsRepoCustomColumnsArray={additionalColumnsRepoCustomColumnsArray}
                                setRemarksColumnsArray={setRemarksColumnsArray}
                                setActiveRemarksColumnTabKey={setActiveRemarksColumnTabKey}
                                setActiveTabKeyColumnDefinitionModal={setActiveTabKeyColumnDefinitionModal}           
                            />
                        )}
                    </Tab>
                    <Tab eventKey={TAB_KEY_COLUMN_CUSTOMIZER_RENDER_OBJECTS_CUSTOMIZER} title="Zellenaussehen" tabClassName='tab-primary'>
                        {activeTabKeyColumnDefinitionModal === TAB_KEY_COLUMN_CUSTOMIZER_RENDER_OBJECTS_CUSTOMIZER && (
                            <RenderObjectsCustomizer
                                renderObjectsMap={renderObjectsMap}
                                setRenderObjectsMap={setRenderObjectsMap}
                                columnsForViewArray={columnsForViewArray}
                                setColumnsForViewArray={setColumnsForViewArray}
                            />
                        )}
                    </Tab>
                    {/* <Tab eventKey={TAB_KEY_COLUMN_CUSTOMIZER_LOAD_PRESETS} title="Voreinstellungen" tabClassName='tab-primary' className='tab-show-border-top'>
                        {activeTabKeyColumnDefinitionModal === TAB_KEY_COLUMN_CUSTOMIZER_LOAD_PRESETS && (
                            <ColumnDefinitionLoadPresets                            
                                loadPredefinedColumnsFile={loadPredefinedColumnsFile}
                                columnPresetFilesArray={columnPresetFilesArray}
                                columnsForViewArray={columnsForViewArray}
                                additionalColumnsConfigFromLocalStorage={additionalColumnsConfigFromLocalStorage}
                                setAdditionalColumnsConfigFromLocalStorage={setAdditionalColumnsConfigFromLocalStorage}
                                customColumnsArray={customColumnsArray}
                                setCustomColumnsArray={setCustomColumnsArray}
                                remarksColumnsArray={remarksColumnsArray}
                                setRemarksColumnsArray={setRemarksColumnsArray}
                                setStateVariablesForAdditionalColumnsConfig={setStateVariablesForAdditionalColumnsConfig}
                                renderObjectsMap={renderObjectsMap}                            />
                        )}
                    </Tab> */}
                    <Tab eventKey={TAB_KEY_COLUMN_CUSTOMIZER_RELATED_COLUMNS} title="Spalten für verwandte Bilder" tabClassName='tab-primary' className='tab-show-border-top'>
                        {activeTabKeyColumnDefinitionModal === TAB_KEY_COLUMN_CUSTOMIZER_RELATED_COLUMNS && (
                            <RelatedColumnSelection                          
                                customColumnsArray={customColumnsArray}
                                remarksColumnsArray={remarksColumnsArray}
                                relatedColumnsArray={relatedColumnsArray}
                                setRelatedColumnsArray={setRelatedColumnsArray}
                                dataSource={dataSource}
                                getColumnDefinitionByCustomDefinitionConstant={getColumnDefinitionByCustomDefinitionConstant}
                            />
                        )}
                    </Tab>
                    {/* <Tab eventKey={TAB_KEY_COLUMN_CUSTOMIZER_REXEXP_ERROR_LOG} title={`RegExp Error Log (${regExpErrorArray.length} Einträge)`} tabClassName='tab-primary'>
                        <RegExpErrorLog
                            regExpErrorLog={regExpErrorArray}
                        />
                    </Tab> */}
                    <Tab eventKey={TAB_KEY_COLUMN_CUSTOMIZER_QR_CODE_AND_LINK_PAGE} title='QR' tabClassName='tab-primary' className='tab-show-border-top'>
                        {activeTabKeyColumnDefinitionModal === TAB_KEY_COLUMN_CUSTOMIZER_QR_CODE_AND_LINK_PAGE && (
                            <QRCodeAndLinkPage
                                columnPresetFilesArray={columnPresetFilesArray}
                                presetDataFilesArray={presetDataFilesArray}
                            />
                        )}
                    </Tab>
                    <Tab eventKey={TAB_KEY_COLUMN_CUSTOMIZER_SETTINGS} title="Allgemeine Einstellungen" tabClassName='tab-primary' className='tab-show-border-top'>
                        {activeTabKeyColumnDefinitionModal === TAB_KEY_COLUMN_CUSTOMIZER_SETTINGS && (
                           <>
                                <Row>
                                    <Col>
                                    <>
                                    {!appHasData && (
                                        <>
                                            <h4>Datenquelle einstellen</h4>
                                            <DataSourceSettings
                                                settings={settings}
                                                setSettings={setSettings}
                                                connectedToDatabase={connectedToDatabase}
                                                setConnectedToDatabase={setConnectedToDatabase}
                                                connectedToNodeServer={connectedToNodeServer}
                                                setConnectedToNodeServer={setConnectedToNodeServer}
                                                testDatabaseConnection={testDatabaseConnection}
                                                testNodeServerConnection={testNodeServerConnection}
                                                databaseHost={databaseHost}
                                                setDatabaseHost={setDatabaseHost}
                                                nodeServerHost={nodeServerHost}
                                                setNodeServerHost={setNodeServerHost}
                                                setSettingsCollapseOpen={setSettingsCollapseOpen}
                                                dataSource={dataSource}
                                                setDataSource={setDataSource}
                                                changePathNameInputField={changePathNameInputField}
                                                keywordsLoadFromPathname={keywordsLoadFromPathname}
                                                pathNameInputField={pathNameInputField}
                                                setPathNameInputField={setPathNameInputField}
                                                ihkhsjsonHistoryItems={ihkhsjsonHistoryItems}
                                                loadDataFromJSONFromFilesystem={loadDataFromJSONFromFilesystem}
                                                pathNameInputFieldExistsOnFilesystem={pathNameInputFieldExistsOnFilesystem}
                                                checkPathExistsOnFilesystem={checkPathExistsOnFilesystem}
                                                loadDataFromFile={loadDataFromFile}
                                            />
                                            </>
                                    )}
                                    <h4>Einstellungen</h4>
                                    <SettingsPage
                                        settings={settings}
                                        setSettings={setSettings}
                                        connectedToDatabase={connectedToDatabase}
                                        connectedToNodeServer={connectedToNodeServer}
                                        testDatabaseConnection={testDatabaseConnection}
                                        testNodeServerConnection={testNodeServerConnection}
                                        databaseHost={databaseHost}
                                        setDatabaseHost={setDatabaseHost}
                                        nodeServerHost={nodeServerHost}
                                        setNodeServerHost={setNodeServerHost}
                                    />
                                    </>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <h4>Weitere Einstellungen</h4>
                                        <KeywordsTableSettings
                                            toggleCheckForKeywordCorrections={toggleCheckForKeywordCorrections}
                                            showPreviewImages={showPreviewImages}
                                            setShowPreviewImages={setShowPreviewImages}
                                            connectedToNodeServer={connectedToNodeServer}
                                            filePathReplace1String={filePathReplace1String}
                                            filePathReplace2String={filePathReplace2String}
                                            changeAndSetFilePathReplace1String={changeAndSetFilePathReplace1String}
                                            changeAndSetFilePathReplace2String={changeAndSetFilePathReplace2String}
                                            filePathReplace2StringExistsOnFileSystem={filePathReplace2StringExistsOnFileSystem}
                                            exampleImageWithEnrichedMetadataObject={exampleImageWithEnrichedMetadataObject}
                                            replaceDirectoryInFilePath={replaceDirectoryInFilePath}
                                            useImgSrcReplacement={useImgSrcReplacement}
                                            useImgExtReplacement={useImgExtReplacement}
                                            toggleUseImgSrcReplacement={toggleUseImgSrcReplacement}
                                            toggleUseImgExtReplacement={toggleUseImgExtReplacement}
                                            checkForKeywordCorrections={checkForKeywordCorrections}
                                            doCalculateCheckNumberForLoknummer={doCalculateCheckNumberForLoknummer}
                                            fileExtReplaceString={fileExtReplaceString}
                                            setFileExtReplaceString={setFileExtReplaceString}
                                        />
                                    </Col>
                                    <Col md={6}>

                                    </Col>
                                </Row>
                                {connectedToDatabase && dataSource===DATA_SOURCE_JSON && (
                                    <Row>
                                        <Col md={12}>
                                            <h4>Import in die Datenbank</h4>
                                            <ImportToDatabase
                                                databaseHost={databaseHost}
                                                csrfToken={csrfToken}
                                                imageWithMetadataOriginalArray={imageWithMetadataOriginalArray}
                                                dataSourceOnlyFilesystemAndJSONFilename={dataSourceOnlyFilesystemAndJSONFilename}
                                                dataSource={dataSource}
                                                importIsForbidden={importIsForbidden}
                                                systemsArray={systemsArray}
                                                importsArray={importsArray}
                                                updateImportsFromDatabase={updateImportsFromDatabase}
                                                updateSystemsFromDatabase={updateSystemsFromDatabase}                        />
                                        </Col>
                                    </Row>
                                )}
                           </>
                        )}
                    </Tab>
                </Tabs>
            </Modal.Body>
        </Modal>
    );
}

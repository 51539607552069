import { AdditionalColumnsConfig, ColumnDefinition, ColumnDefinitionExchangeObject, ColumnDefinitionMappingObject, ColumnForViewImportExportDefinition, ColumnsForViewObject, ConditionConfigType, CustomColumnDefinition, CustomColumnRegex, CustomColumnSource, DataFilterObject, ImageWithEnrichedMetadataObject, ImageWithMetadataObject, RemarkDefinition, RemarksColumnDefinition, SearchReplaceDefinition } from "./custom_types";
import {
    COLUMN_DEFINITION_CONST_DATE_TIME_CREATED,
    COLUMN_DEFINITION_CONST_DIRECTORY,
    COLUMN_DEFINITION_CONST_FILENAME,
    COLUMN_DEFINITION_CONST_ID,
    COLUMN_DEFINITION_CONST_KEYWORDS,
    COLUMN_DEFINITION_CONST_KEYWORDS_SUMMARY,
    COLUMN_DEFINITION_CONST_KEYWORD_REST,
    COLUMN_DEFINITION_CONST_SOURCE_FILE,
    IHKHSJSON_FIELD_KEYWORDS,
    initialBaseColumnDefinitionArray,
    LOCAL_STORAGE_KEY_SETTING_NODE_SERVER_HOST,
    monthMapGerman,
    COLUMN_DEFINITION_TYPE_REMARKS_COLUMN_DEFINITION,
    COLUMN_DEFINITION_TYPE_CUSTOM_COLUMN_DEFINITION,
    COLUMNS_ARRAY_MAP_FOR_IMAGE_WITH_ENRICHED_METADATA_OBJECT,
    WARNING_CONDITION_TYPE_IS_EMPTY_KEY,
    WARNING_CONDITION_TYPE_IS_NOT_EMPTY_KEY,
    WARNING_CONDITION_TYPE_MORE_THAN_ONE_VALUE_KEY,
    WARNING_CONDITION_TYPE_DOES_MATCH_KEY,
    COLUMNS_FOR_VIEWS_ARRAY,
    FORMATFUNCTION_DEFAULT_KEY,
    FORMATFUNCTION_LOWERCASE_KEY,
    FORMATFUNCTION_UPPERCASE_KEY,
    FORMATFUNCTION_KHS_DATE_KEY,
    FORMATFUNCTION_KHS_LOKNR_KEY,
    COLUMN_DEFINITION_CONST_MD5,
    COLUMN_DEFINITION_CONST_KEYWORDS_STRING,
    WARNING_CONDITION_TYPE_DOES_MATCH_EXACT_KEY,
} from "./constants";
import { formatLoknummer } from "./___user_defined";
import { CustomColumnsTable } from "./CustomColumnsTable";


const formatAsDate = (s: string, dateIsExact: boolean) => {
    if (s.toLocaleLowerCase() === 'io') {
      return 'io';
    } else if (s.toLocaleLowerCase() === '0') {
      return '2007-2012';
    } else {
      let year1 = s.substring(0, 2);
      if (parseInt(year1) > 40) {
        year1 = '19' + year1;
      } else {
        year1 = '20' + year1;
      }

      const month1 = s.substring(2, 4);
      const day1 = s.substring(4, 6);

      // console.log('month1');
      // console.log(month1);
      // console.log('day1');
      // console.log(day1);

      if (dateIsExact || day1 !== '01') {
        return [day1 + '.', monthMapGerman[parseInt(month1)], year1].join(' ');
        // When date is 01.05.xxxx the month is correct, but the day is unknown (except keyword yio also exists)
      } else if (day1 === '01' && month1 !== '01') {
        return [monthMapGerman[parseInt(month1)], year1].join(' ');
        // When date is 01.01.xxxx the day is only a placeholder for the year
        // Only output the year in this case
      } else if (day1 === '01' && month1 === '01') {
        return year1;
      } else {
        return [monthMapGerman[month1], year1].join(' ')
      }
    }
  }

export function formatForFormatFunction(str: string, formatFunctionKey: string) {

    if (typeof str.toLocaleLowerCase === 'undefined') {
        console.error('str.toLocaleLowerCase is undefined');
        debugger;
        return '';
    }

    switch (formatFunctionKey) {
        case FORMATFUNCTION_DEFAULT_KEY:
            return str;
        case FORMATFUNCTION_LOWERCASE_KEY:
            return str.toLowerCase();
        case FORMATFUNCTION_UPPERCASE_KEY:
            return str.toUpperCase();
        case FORMATFUNCTION_KHS_DATE_KEY:
            // TODO: It would be great when date is exact parameter could be determined correctly
            // It is true when "yio" is given in the keywords
            return formatAsDate(str, false);
        case FORMATFUNCTION_KHS_LOKNR_KEY:
            return formatLoknummer(str);
        default:
            // Fallback for now when some presets don't yet have a format-function set
            console.error('unexpected formatFuntion: ' + formatFunctionKey);
            return str;
    }
}

export function loadColumnDefinitionExchangeObjectFromJSONString(importString: string, setStateVariablesForAdditionalColumnsConfig: any, callbackFnc?: any) {
    let obj = JSON.parse(importString);
    let importSuccessful = false;

    // is ColumnDefinitionExchangeObject ?
    if (obj['baseColumnsArray'] && obj['customColumnsArray'] && obj['remarksColumnsArray'] && obj['columnsForViewArray']) {

        let columnConfig = {
            configName: 'Konfiguration aus Import ',
            columnDefinitionExchangeObject: obj,
        } as AdditionalColumnsConfig;
        console.log('in loadColumnDefinitionExchangeObjectFromJSONString --- calling setStateVariablesForAdditionalColumnsConfig');
        setStateVariablesForAdditionalColumnsConfig(columnConfig, callbackFnc);
        
        importSuccessful = true;
    }

    if (!importSuccessful) {
        alert('Fehler beim Datenimport - Es wurde nichts importiert');
    }
}

export function getOnlyColumnDefinitions(customColumnsArray: ColumnDefinition[], remarksColumnsArray: ColumnDefinition[]) {
  return {
    customColumnsArray: customColumnsArray,
    remarksColumnsArray: remarksColumnsArray,
  };
}


export function getColumnDefinitionExchangeObject(renderObjectsMap: any, columnsForViewArray: ColumnsForViewObject, customColumnsArray: ColumnDefinition[], remarksColumnsArray: ColumnDefinition[]) {

    let tmpExportObj = {
        renderConfigObjectArray: Object.values(renderObjectsMap),
        baseColumnsArray: initialBaseColumnDefinitionArray,
        customColumnsArray: customColumnsArray,
        remarksColumnsArray: remarksColumnsArray,
        columnsForViewArray: {},
    } as ColumnDefinitionExchangeObject;

    COLUMNS_FOR_VIEWS_ARRAY.map((columnForViewKey: string) => {
        columnsForViewArray[columnForViewKey].map((cd: ColumnDefinition) => {
            if (!tmpExportObj.columnsForViewArray[columnForViewKey]) {
                tmpExportObj.columnsForViewArray[columnForViewKey]=[];
            }
            tmpExportObj.columnsForViewArray[columnForViewKey].push(
                {
                    active: cd.active,
                    columnDefinitionConstant: cd.columnDefinitionConstant,
                    width: cd.width,
                    renderConfigsMapKey: cd.renderConfigsMapKey,
                } as ColumnForViewImportExportDefinition);
        })
    })
    //debugger;
    return tmpExportObj;
    }

export function getCookie(name: string) : string|null  {
  var cookieValue = null;
  if (document.cookie && document.cookie !== '') {
      var cookies = document.cookie.split(';');
      for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i].trim();
          // Does this cookie string begin with the name we want?
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
              cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
              break;
          }
      }
  }
  return cookieValue;
}

// Function taken from https://stackoverflow.com/questions/11661187/form-serialize-javascript-no-framework/30153391#30153391
// @ts-ignore
export function serialize(form) {
  var field, s = [];
  if (typeof form == 'object' && form.nodeName == "FORM") {
      var len = form.elements.length;
      for (let i=0; i<len; i++) {
          field = form.elements[i];
          if (field.name && !field.disabled && field.type != 'file' && field.type != 'reset' && field.type != 'submit' && field.type != 'button') {
              if (field.type == 'select-multiple') {
                  for (let j=form.elements[i].options.length-1; j>=0; j--) {
                      if(field.options[j].selected)
                          s[s.length] = encodeURIComponent(field.name) + "=" + encodeURIComponent(field.options[j].value);
                  }
              } else if ((field.type != 'checkbox' && field.type != 'radio') || field.checked) {
                  s[s.length] = encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value);
              }
          }
      }
  }
  return s.join('&').replace(/%20/g, '+');
}

export function convertIhkhsjsonObjectIntoImageWithMetadataObject(baseColumnsMappingArray: ColumnDefinitionMappingObject[], objectFromIhkhsjson: any): ImageWithMetadataObject {

    let imageWithMetadataObject = {} as ImageWithMetadataObject;
    for (let i=0; i<baseColumnsMappingArray.length; i++) {
            imageWithMetadataObject[baseColumnsMappingArray[i].columnDefinitionConstant] = objectFromIhkhsjson[baseColumnsMappingArray[i].ihkhsjsonField] ? objectFromIhkhsjson[baseColumnsMappingArray[i].ihkhsjsonField].toString() : null;
    }

    if (imageWithMetadataObject[COLUMN_DEFINITION_CONST_MD5]) {
      imageWithMetadataObject[COLUMN_DEFINITION_CONST_MD5] = imageWithMetadataObject[COLUMN_DEFINITION_CONST_MD5].replace(/ /g, '').substring(0,32);
    }

    let keywordsArray = [] as string[];
    let keywordsString = '';

    if (objectFromIhkhsjson[IHKHSJSON_FIELD_KEYWORDS]) {
        switch (typeof objectFromIhkhsjson[IHKHSJSON_FIELD_KEYWORDS]) {
            case 'string':
                keywordsString = objectFromIhkhsjson[IHKHSJSON_FIELD_KEYWORDS];
                keywordsArray = objectFromIhkhsjson[IHKHSJSON_FIELD_KEYWORDS].replace(/ /g, '').split(',');
                break;
            case 'object':
                keywordsString = objectFromIhkhsjson[IHKHSJSON_FIELD_KEYWORDS].join(', ');
                keywordsArray = objectFromIhkhsjson[IHKHSJSON_FIELD_KEYWORDS];
                break;
            default: // maybe null or undefined
                break;
        }
    }
    imageWithMetadataObject[COLUMN_DEFINITION_CONST_KEYWORDS_STRING] = keywordsString;
    imageWithMetadataObject[COLUMN_DEFINITION_CONST_KEYWORDS] = keywordsArray;
    imageWithMetadataObject[COLUMN_DEFINITION_CONST_KEYWORD_REST] = Object.assign([], keywordsArray);

    return imageWithMetadataObject;


    // return {
    //     //Id: objectFromIhkhsjson['i_id'],
    //     SourceFile: objectFromIhkhsjson[IHKHSJSON_FIELD_SOURCEFILE],
    //     Directory: objectFromIhkhsjson[IHKHSJSON_FIELD_DIRECTORY],
    //     FileName: objectFromIhkhsjson[IHKHSJSON_FIELD_FILENAME],
    //     //KeywordsString: objectFromIhkhsjson[IHKHSJSON_FIELD_KEYWORDS] ? objectFromIhkhsjson[IHKHSJSON_FIELD_KEYWORDS] : '',
    //     // TODO: Why is this empty?
    //     //KeywordsArray: [],
    //     //KeywordsRestArray: [],
    //     KeywordsString: keywordsString,
    //     KeywordsArray: keywordsArray,
    //     KeywordsRestArray: Object.assign([], keywordsArray),
    //     ImageSize: objectFromIhkhsjson[IHKHSJSON_FIELD_IMAGESIZE],
    //     FileSize: objectFromIhkhsjson[IHKHSJSON_FIELD_FILESIZE],
    //     Flash: objectFromIhkhsjson[IHKHSJSON_FIELD_FLASH],
    //     Aperture: objectFromIhkhsjson[IHKHSJSON_FIELD_APERTURE],
    //     Lens: objectFromIhkhsjson[IHKHSJSON_FIELD_LENS],
    //     LensID: objectFromIhkhsjson[IHKHSJSON_FIELD_LENS_ID],
    //     GPSLatitude: objectFromIhkhsjson[IHKHSJSON_FIELD_GPS_LATITUDE],
    //     GPSLongitude: objectFromIhkhsjson[IHKHSJSON_FIELD_GPS_LONGITUDE],
    //     ISO: objectFromIhkhsjson[IHKHSJSON_FIELD_ISO],
    //     Make: objectFromIhkhsjson[IHKHSJSON_FIELD_CAMERA_MAKE],
    //     Model: objectFromIhkhsjson[IHKHSJSON_FIELD_CAMERA_MODEL],
    //     ShutterSpeed: objectFromIhkhsjson[IHKHSJSON_FIELD_SHUTER_SPEED],
    //     MimeType: objectFromIhkhsjson[IHKHSJSON_FIELD_MIME_TYPE],
    //     SubSecDateTimeOriginal: objectFromIhkhsjson[IHKHSJSON_FIELD_SUB_SEC_DATE_TIME_ORIGINAL],
    //     SubSecCreateDate: objectFromIhkhsjson[IHKHSJSON_FIELD_SUB_SEC_CREATE_DATE],
    //     SubSecModifyDate: objectFromIhkhsjson[IHKHSJSON_FIELD_SUB_SEC_MODIFY_DATE],
    //     MD5: objectFromIhkhsjson[IHKHSJSON_FIELD_MD5],
    //   } as ImageWithMetadataObject;
}

export function digitSum(n: number) :number { //quersumme
    let sum = 0;
    let rest = n;
    while (rest > 0) {
      let einer = rest % 10;
      sum = sum + einer;
      rest = (rest - einer)/10;
    }
    console.log('quersumme von ' + n + ' -> ' + sum);
    return sum;
    // const res = n < 10 ? n : n % 9;
    
    // return res;
}

export function convertDatabaseObjectIntoImageWithMetadataObject(objectFromDB: any): ImageWithMetadataObject {
    return {
        id: objectFromDB['i_id'],
        sourcefile: objectFromDB['i_sourceFile'],
        directory: objectFromDB['i_directory'], // TODO: Change from i_folderName to i_directory
        //Directory: 'TODO from db', // TODO: Change from i_folderName to i_directory
        file_name: objectFromDB['i_fileName'],
        keywords_string: objectFromDB['i_keywordsString'] ? objectFromDB['i_keywordsString'] : '',
        image_size: objectFromDB['i_imageSize'],
        file_size: objectFromDB['i_fileSizeBytes'],
        flash: objectFromDB['i_flash'],
        aperture: objectFromDB['i_aperture'],
        lens: objectFromDB['i_lens'],
        gps_latitude: objectFromDB['i_gpslatitude'],
        gps_longitude: objectFromDB['i_gpslongitude'],
        iso: objectFromDB['i_iso'],
        camera_make: objectFromDB['i_cameraMake'],
        camera_model: objectFromDB['i_cameraModel'],
        shutter_speed: objectFromDB['i_shutterSpeed'],
        //MimeType: objectFromIhkhsjson[IHKHSJSON_FIELD_MIME_TYPE],
        //SubSecDateTimeOriginal: objectFromIhkhsjson[IHKHSJSON_FIELD_SUB_SEC_DATE_TIME_ORIGINAL],
        //SubSecCreateDate: objectFromIhkhsjson[IHKHSJSON_FIELD_SUB_SEC_CREATE_DATE],
        //SubSecModifyDate: objectFromIhkhsjson[IHKHSJSON_FIELD_SUB_SEC_MODIFY_DATE],
        //MD5: objectFromIhkhsjson[IHKHSJSON_FIELD_MD5],
      } as ImageWithMetadataObject;
}

export function getResultsForDataFilterObjectArray(imageWithEnrichedMetadataObjectsArray_All: ImageWithEnrichedMetadataObject[], dataFilterObjectArray: DataFilterObject[]) : ImageWithEnrichedMetadataObject[] {
    let newData: ImageWithEnrichedMetadataObject[] = [];

    // Use imageWithMetadataObjectsArray_Filtered instead of imageWithMetadataObjectsArray_Page
    //imageWithEnrichedMetadataObjectsArray_All.forEach((tmpimageWithMetadataObject: ImageWithEnrichedMetadataObject, tmpIdx: number) => {
        for (let tmpimageWithMetadataObjectIndex=0; tmpimageWithMetadataObjectIndex<imageWithEnrichedMetadataObjectsArray_All.length; tmpimageWithMetadataObjectIndex++) {
            const tmpimageWithMetadataObject = imageWithEnrichedMetadataObjectsArray_All[tmpimageWithMetadataObjectIndex];
      
            let conditionsMatched = 0;
      
            loopDataFilterObjectArray: for (let dataFilterObjectIndex=0; dataFilterObjectIndex<dataFilterObjectArray.length; dataFilterObjectIndex++) {
            const dataFilterObject = dataFilterObjectArray[dataFilterObjectIndex];
            if (dataFilterObject.column) {
            const imageWithMetadata1stLevelKey = COLUMNS_ARRAY_MAP_FOR_IMAGE_WITH_ENRICHED_METADATA_OBJECT[dataFilterObject.column.columnDefinitionType];
            let columnValue = tmpimageWithMetadataObject[imageWithMetadata1stLevelKey][dataFilterObject.column.columnDefinitionConstant];
            if (typeof columnValue === 'string') {            
                columnValue = [columnValue];
            }
    
            if (typeof columnValue !== 'undefined') {
                switch (dataFilterObject.conditionType) {
                case WARNING_CONDITION_TYPE_IS_EMPTY_KEY:
                    if (columnValue.length===0) {
                        conditionsMatched++;
                        continue loopDataFilterObjectArray;
                    }
                    break;
                case WARNING_CONDITION_TYPE_IS_NOT_EMPTY_KEY:
                    if (columnValue.length>0) {
                        conditionsMatched++;
                        continue loopDataFilterObjectArray;
                    }
    
                    break;
                case WARNING_CONDITION_TYPE_MORE_THAN_ONE_VALUE_KEY:
                    if (columnValue.length>=2) {
                    conditionsMatched++;
                    continue loopDataFilterObjectArray;
                    }
                    
                    break;
                default: //WARNING_CONDITION_TYPE_DOES_MATCH_KEY or WARNING_CONDITION_TYPE_DOES_NOT_MATCH_KEY
                    for (let i=0; i<columnValue.length; i++) {   
                    if (dataFilterObject.conditionType === WARNING_CONDITION_TYPE_DOES_MATCH_KEY) {
                        if (columnValue[i].match(new RegExp(dataFilterObject.searchValue, 'i'))) {
                          conditionsMatched++;
                          continue loopDataFilterObjectArray;
                        }
                    } else if (dataFilterObject.conditionType === WARNING_CONDITION_TYPE_DOES_MATCH_EXACT_KEY) {
                      debugger;
                      if (columnValue[i].match(new RegExp('^'+dataFilterObject.searchValue+'$'))) {
                          conditionsMatched++;
                          continue loopDataFilterObjectArray;
                        }
                    } else {
                        if (!(columnValue[i].match(new RegExp(`^${dataFilterObject.searchValue}$`)))) {
                        conditionsMatched++;
                        continue loopDataFilterObjectArray;
                        }
                    }
                    }
                    break;
                }
                }
            }
        };
        if (conditionsMatched == dataFilterObjectArray.length) {
            newData.push(tmpimageWithMetadataObject);
        }
          // TODO: Implement combination with AND, OR
    }
    return newData;
}

export function loadMermaidGraph1(setMermaidContent: any) : void {
    const mermaidContentArray = [];
    mermaidContentArray.push('graph TB');
    mermaidContentArray.push('FE[Frontend]');
    mermaidContentArray.push('DB[Datenbank]');
    mermaidContentArray.push('Node[Node-Server]');
    mermaidContentArray.push('ExifTool');
    mermaidContentArray.push('JSON[JSON-Datei]');

    mermaidContentArray.push('FE--erzeugt-->JSON');
    mermaidContentArray.push('FE--lädt ein-->JSON');
    mermaidContentArray.push('JSON--später-->DB');
    mermaidContentArray.push('Node--steuert-->ExifTool');
    mermaidContentArray.push('ExifTool--erhält Daten-->Node');
    mermaidContentArray.push('FE--ruft Daten ab-->DB');
    mermaidContentArray.push('FE---Node');
    setMermaidContent(
        <div className='mermaid' id='mermaid1Graph'>
            {mermaidContentArray.join('\n')}
        </div>
    );
}

export function loadMermaidGraph2(connectedToDatabase: boolean, connectedToNodeServer: boolean, setMermaidContent: any, lastMermaidRef: any) : void {
    const mermaidContentArray = [];
    mermaidContentArray.push('graph TB');

    const colorDatabase = connectedToDatabase ? '#0f0' : '#f00';
    const colorNodeserver = connectedToNodeServer ? '#0f0' : '#f00';

    // mermaidContentArray.push(`style DB fill:${colorDatabase},stroke:#333,stroke-width:4px`);
    mermaidContentArray.push(`style DB fill:${colorDatabase}`);
    mermaidContentArray.push(`style Node fill:${colorNodeserver}`);
    mermaidContentArray.push('FE[Frontend]');
    mermaidContentArray.push('DB[Datenbank]');
    mermaidContentArray.push('Node[Node-Server]');
    mermaidContentArray.push('FE---DB');
    mermaidContentArray.push('FE---Node');
    setMermaidContent(
        <div className='mermaid' id='mermaid2Graph' ref={lastMermaidRef}>
            {mermaidContentArray.join('\n')}
        </div>
    );
}

export function capitalizeFirstLetter(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

export function getUrlForGetFile(connectedToNodeServer: null|Boolean, params: any) {
    //console.log("getUrlForGetFile");
    //console.log("connectedToNodeServer", connectedToNodeServer);
    if (connectedToNodeServer) {
        return generateNodeServerUrl(
            'get_file_by_file_path',
            params,
            ['filePath'],
        );
    } else {
        // console.log("getUrlForGetFile");
        // console.log("params");
        // console.log(params);
        // console.log("params[filePath]");
        // console.log(params['filePath']);
        return params['filePath'];
    }
}

export function getUrlForGetByPathName(params: any) {
    return generateNodeServerUrl(
        'get_by_path_name',
        params,
        ['pathName'],
    );
}

export function getUrlForGetPreparedMetadata(params: any) {
    return generateNodeServerUrl(
        'get_prepared_metadata',
        params,
        ['pathName'],
    );
}

// Legacy URL
export function getUrlForKeywordsTable(params: any) {
    return generateNodeServerUrl(
        'get_keywords_table',
        params,
        ['pathName'],
    );
}

export function getUrlForPathExists(params: any) {
    return generateNodeServerUrl(
        'path_exists',
        params,
        ['pathName'],
    );
}

export function getUrlForGetImagesWithMetadata(params: any) {
    return generateNodeServerUrl(
        'get_images_with_metadata',
        params,
        ['pathName'],
    );
}

export function getUrlForUpdateKeywordsForImage(params: any) {
    return generateNodeServerUrl(
        'update_keywords_for_image',
        params,
        ['filePath', 'keywordsString2'],
    );
}

export function getUrlForMoveByKeywords(params: any) {
    return generateNodeServerUrl(
        'move_by_keywords',
        params,
        ['pathName'],
    );
}

export function getGetParametersFromArray(parametersMap: any) {
    const parametersArray: string[] = [];

    // let returnString = '?';
    Object.keys(parametersMap).forEach(function (parameterName) {
        parametersArray.push(parameterName + '=' + parametersMap[parameterName]);
    });

    if (parametersArray.length) {
        return '?' + parametersArray.join('&');
    } else {
        return '';
    }
}

export function generateNodeServerUrl(route: string, params: any, requiredParams: any) {
    let backendServerUrl = localStorage.getItem(LOCAL_STORAGE_KEY_SETTING_NODE_SERVER_HOST);
    let url = backendServerUrl + '/' + route;

    if (requiredParams.length >= 1) {
        checkRequiredParameters(params, requiredParams);
        url += addPostParams(params);
    }

    //alert("generateUrl returned " + url);
    //console.log("generateUrl returned " + url);
    return url;
}

export function checkRequiredParameters(params: any[], requiredParametersArray: any[]) {
    for (let i = 0; i < requiredParametersArray.length; i++) {
        if (params[requiredParametersArray[i]] === 'undefined') {
            // @ts-ignore
            console.log("caller is " + this.caller);
            throw Error('Parameter ' + requiredParametersArray + ' is missing');
        }
    }
}

export function addPostParams(params: any) {
    let parts: any[] = [];
    Object.entries(params).forEach(entry => {
        parts.push(entry[0] + '=' + entry[1])
    });
    if (parts.length >= 1) {
        return '?' + parts.join('&');
    } else {
        return '';
    }
}

// Adds properties "regexStringForCHECKING" and "regexStringValid"
// The two properties are used in RemarksColumnTable
// This function should be called after initialization

export const checkRegexStringInRemarksArray = (remarksColumnsArray: RemarksColumnDefinition[]): void => {
    remarksColumnsArray.forEach((remarksColumnDefinition: RemarksColumnDefinition, remarksColumnDefinitionIndex: number) => {
        remarksColumnDefinition.remarksArray.forEach((remarksColumnDefinition: RemarkDefinition, remarksColumnDefinitionIndex: number) => {
            remarksColumnDefinition.conditionConfigArray.forEach((remarkDefinitionConditionConfig: ConditionConfigType, RemarkDefinitionConditionConfigIndex: number) => {
                if (!remarkDefinitionConditionConfig.regexStringForCHECKING) {
                    try {
                        const re = new RegExp(remarkDefinitionConditionConfig.regexString);
                        remarkDefinitionConditionConfig.regexStringValid = true;
                    } catch (error) {
                        remarkDefinitionConditionConfig.regexStringValid = false;
                    }
                    remarkDefinitionConditionConfig.regexStringForCHECKING = remarkDefinitionConditionConfig.regexString;
                }
            })
        });
    });
}

// Adds properties "regexStringForCHECKING" and "regexStringValid"
// The two properties are used in CustomColumnsTable
// This function should be called after initialization
export const checkRegexStringInCustomColumnsArray = (customColumnsArray: CustomColumnDefinition[]): void => {
    customColumnsArray.forEach((customColumnObject: CustomColumnDefinition, customColumnObjectIndex: number) => {
        customColumnObject.customColumnSourceArray.forEach((customColumnSource: CustomColumnSource, customColumnSourceIndex: number) => {
            customColumnSource.customColumnRegexArray.forEach((customColumnRegex: CustomColumnRegex, customColumnRegexIndex: number) => {
                if (!customColumnRegex.regexStringForCHECKING) {
                    try {
                        const re = new RegExp(customColumnRegex.regexString);
                        customColumnRegex.regexStringValid = true;
                    } catch (error) {
                        customColumnRegex.regexStringValid = false;
                    }
                    customColumnRegex.regexStringForCHECKING = customColumnRegex.regexString;
                }
            });
        });
    });
}

export const removeStringFromKeywordsRest = (stringToRemove: string, imageWithEnrichedMetadataObject: ImageWithEnrichedMetadataObject): void => {

    if (typeof imageWithEnrichedMetadataObject.baseColumns[COLUMN_DEFINITION_CONST_KEYWORD_REST] === 'undefined') {
      debugger;
    }
    const indexToRemove = imageWithEnrichedMetadataObject.baseColumns[COLUMN_DEFINITION_CONST_KEYWORD_REST].indexOf(stringToRemove);
    if (indexToRemove > -1) { // only splice array when item is found
    imageWithEnrichedMetadataObject.baseColumns[COLUMN_DEFINITION_CONST_KEYWORD_REST].splice(indexToRemove, 1); // 2nd parameter means remove one item only
    }
}

export const enrichImageWithMetadataObjectPartForRemarksColumns = (remarksColumnsArray: RemarksColumnDefinition[], imageWithEnrichedMetadataObject: ImageWithEnrichedMetadataObject) => {
    remarksColumnsArray.forEach((remarksColumnObject: RemarksColumnDefinition) => {
      if (remarksColumnObject.active) {
        imageWithEnrichedMetadataObject.remarksColumns[remarksColumnObject.columnDefinitionConstant]=[];
        
        remarksColumnObject.remarksArray.forEach((remarkObject: RemarkDefinition) => { 
         
          if (remarkObject.active) {
            // TODO: This needs to be changed!
            //let valueFromSourceColumn = imageWithEnrichedMetadataObject[COLUMN_DEFINITION_KEYWORDS];

            let conditionsMatched = 0;
            loopRemarkObjectConditionConfigArray: for (let remarkObjectConditionConfigIndex=0; remarkObjectConditionConfigIndex<remarkObject.conditionConfigArray.length; remarkObjectConditionConfigIndex++) {

              // TODO: Maybe const?
              let remarkObjectConditionConfigObject = remarkObject.conditionConfigArray[remarkObjectConditionConfigIndex];

              const columnArraySelector = COLUMNS_ARRAY_MAP_FOR_IMAGE_WITH_ENRICHED_METADATA_OBJECT[remarkObjectConditionConfigObject.columnDefinition.columnDefinitionType];
              let valueFromSourceColumn = imageWithEnrichedMetadataObject[columnArraySelector][remarkObjectConditionConfigObject.columnDefinition.columnDefinitionConstant];

              //if (typeof valueFromSourceColumn === 'undefined') {
                //alert(`RemarkColumn: ${remarksColumnObject.nameInFrontend} - valueFromSourceColumn is undefined (imageWithEnrichedMetadataObject[${columnArraySelector}][${remarkObjectConditionConfigObject.columnDefinition.columnDefinitionConstant}]) - Probably ${remarkObjectConditionConfigObject.columnDefinition.columnDefinitionConstant} is not defined -> The rule will be deactivated`);
                //console.error(`RemarkColumn: ${remarksColumnObject.nameInFrontend} - valueFromSourceColumn is undefined (imageWithEnrichedMetadataObject[${columnArraySelector}][${remarkObjectConditionConfigObject.columnDefinition.columnDefinitionConstant}]) - Probably ${remarkObjectConditionConfigObject.columnDefinition.columnDefinitionConstant} is not defined -> The rule will be deactivated`);
                //debugger;

                // const columnWithProblem = combinedColumnDefinitionArray.find(element=>element.columnDefinitionConstant===remarkObjectConditionConfigObject.columnDefinition.columnDefinitionConstant);
                // if (columnWithProblem) {
                //   columnWithProblem.active = false;
                // }
              //}

              //Index in WARNING_CONDITIONS_ARRAY 
              switch (remarkObjectConditionConfigObject.warningConditionType) {
                case WARNING_CONDITION_TYPE_IS_EMPTY_KEY:
                  // TODO: Verify if this works for all columns
                  if (valueFromSourceColumn.length===0) {
                    //remarksColumnContentArray.push(remarkObject.remarkString);
                    conditionsMatched++;
                    continue loopRemarkObjectConditionConfigArray;
                  }
                  break;
                case WARNING_CONDITION_TYPE_IS_NOT_EMPTY_KEY:
                  // TODO: Verify if this works for all columns
                  if (valueFromSourceColumn.length>=1) {
                    //remarksColumnContentArray.push(remarkObject.remarkString);
                    conditionsMatched++;
                    continue loopRemarkObjectConditionConfigArray;
                  }
                  break;
                case WARNING_CONDITION_TYPE_MORE_THAN_ONE_VALUE_KEY:
                  // TODO: Verify if this works for all columns
                  if (valueFromSourceColumn.length>=2) {
                    //remarksColumnContentArray.push(remarkObject.remarkString);
                    conditionsMatched++;
                    continue loopRemarkObjectConditionConfigArray;
                  }
                  break;
                default: // CASE WARNING_CONDITION_TYPE_DOES_MATCH_KEY and WARNING_CONDITION_TYPE_DOES_NOT_MATCH_KEY
                  try {
                    let re = new RegExp(
                      remarkObjectConditionConfigObject.regexString,
                      remarkObjectConditionConfigObject.regexStringIgnoreCase ? 'i' : ''
                    );
                    
                    if (typeof valueFromSourceColumn === 'undefined') {
                      console.error(`RemarkColumn: ${remarksColumnObject.nameInFrontend} - valueFromSourceColumn is undefined (imageWithEnrichedMetadataObject[${columnArraySelector}][${remarkObjectConditionConfigObject.columnDefinition.columnDefinitionConstant}]) - Probably ${remarkObjectConditionConfigObject.columnDefinition.columnDefinitionConstant} is not defined -> The rule will be deactivated`);
                      //debugger;
                    } else if (typeof valueFromSourceColumn === 'string') {
                      if (remarkObjectConditionConfigObject.warningConditionType === WARNING_CONDITION_TYPE_DOES_MATCH_KEY) {
                        if (valueFromSourceColumn.match(re)) {
                          conditionsMatched++;
                          continue loopRemarkObjectConditionConfigArray;
                        }
                      } else {
                        if (!valueFromSourceColumn.match(re)) {
                          conditionsMatched++;
                          continue loopRemarkObjectConditionConfigArray;
                        }
                      }
                    } else {
                      for (let arrayFromSourceColumnStringIndex=0; arrayFromSourceColumnStringIndex<valueFromSourceColumn.length; arrayFromSourceColumnStringIndex++) {
                        // TODO: maybe const
                        let arrayFromSourceColumnString = valueFromSourceColumn[arrayFromSourceColumnStringIndex];

                        if (remarkObjectConditionConfigObject.warningConditionType === WARNING_CONDITION_TYPE_DOES_MATCH_KEY) {
                          if (arrayFromSourceColumnString.match(re)) {
                            conditionsMatched++;
                            continue loopRemarkObjectConditionConfigArray;
                          }
                        } else {
                          if (!arrayFromSourceColumnString.match(re)) {
                            conditionsMatched++;
                            continue loopRemarkObjectConditionConfigArray;
                          }
                        }
                      };
                    }
                  
                  } catch (error) {
                    debugger;
                  }

                  break;
              } // END SWITCH         
            };

            // TODO: This works or CONNECT_TYPE_AND
            // This needs to be adapted for CONNECT_TYPE_OR
            if (conditionsMatched == remarkObject.conditionConfigArray.length) {
              //remarksColumnContentArray.push(remarkObject.remarkString);
              if (!imageWithEnrichedMetadataObject.remarksColumns[remarksColumnObject.columnDefinitionConstant]) {
                imageWithEnrichedMetadataObject.remarksColumns[remarksColumnObject.columnDefinitionConstant] = [];
              }
              imageWithEnrichedMetadataObject.remarksColumns[remarksColumnObject.columnDefinitionConstant].push(remarkObject.remarkString)
            }
          }
          });
          
        //imageWithEnrichedMetadataObject.remarksColumns[remarksColumnObject.columnDefinitionConstant] = remarksColumnContentArray;
      }
    });
    //return imageWithEnrichedMetadataObject;
  }

  export const enrichImageWithMetadataObjectPartForCustomColumns = (customColumnsArray: CustomColumnDefinition[], imageWithEnrichedMetadataObject: ImageWithEnrichedMetadataObject) => {
    customColumnsArray.forEach((customColumnObject: CustomColumnDefinition) => {
      if (customColumnObject.active) {

       // debugger;
        let customColumnContentArray = [] as string[];
        customColumnObject.customColumnSourceArray.forEach((customColumnSourceObject: CustomColumnSource) => {

          //let valueFromSourceColumn = imageWithEnrichedMetadataObject[][customColumnSourceObject.sourceColumn.columnDefinitionConstant];
          const columnArraySelectorForSourceColumn = COLUMNS_ARRAY_MAP_FOR_IMAGE_WITH_ENRICHED_METADATA_OBJECT[customColumnSourceObject.sourceColumn.columnDefinitionType];
          let valueFromSourceColumn = imageWithEnrichedMetadataObject[columnArraySelectorForSourceColumn][customColumnSourceObject.sourceColumn.columnDefinitionConstant];

          let arrayFromSourceColumn = (typeof valueFromSourceColumn === 'string') ? [valueFromSourceColumn] : Object.assign([], valueFromSourceColumn);
          let arrayFromSourceColumnStringsToSkip : string [] = [];

          // Add values from sourceColumn "as is" when no regex is given
          if (customColumnSourceObject.customColumnRegexArray.length === 0) {
            let tmpValueFromSourceColumn = imageWithEnrichedMetadataObject[columnArraySelectorForSourceColumn][customColumnSourceObject.sourceColumn.columnDefinitionConstant];
            if (typeof tmpValueFromSourceColumn === 'string') {
              tmpValueFromSourceColumn = [`${customColumnSourceObject.prefix}${tmpValueFromSourceColumn}${customColumnSourceObject.postfix}`];
            }
            if (typeof imageWithEnrichedMetadataObject.customColumns[customColumnObject.columnDefinitionConstant] === 'undefined') {
              imageWithEnrichedMetadataObject.customColumns[customColumnObject.columnDefinitionConstant] = [];
            }
            switch(typeof imageWithEnrichedMetadataObject.customColumns[customColumnObject.columnDefinitionConstant]) {
              case 'object':
                if (tmpValueFromSourceColumn) {
                  imageWithEnrichedMetadataObject.customColumns[customColumnObject.columnDefinitionConstant] = imageWithEnrichedMetadataObject.customColumns[customColumnObject.columnDefinitionConstant].concat(tmpValueFromSourceColumn);
                }
                for (let i=0; i<imageWithEnrichedMetadataObject.customColumns[customColumnObject.columnDefinitionConstant].length; i++) {
                  imageWithEnrichedMetadataObject.customColumns[customColumnObject.columnDefinitionConstant][i] = formatForFormatFunction(imageWithEnrichedMetadataObject.customColumns[customColumnObject.columnDefinitionConstant][i], customColumnObject.formatFunctionConstant);
                }
                break;
              default: //case 'string':
                imageWithEnrichedMetadataObject.customColumns[customColumnObject.columnDefinitionConstant].push(
                  `${customColumnSourceObject.prefix}${formatForFormatFunction(tmpValueFromSourceColumn, customColumnObject.formatFunctionConstant)}${customColumnSourceObject.postfix}`, 
                  );
              break;
            }
            // TODO: Handle cases where data is string
            //if (typeof imageWithEnrichedMetadataObject.customColumns[customColumnObject.columnDefinitionConstant] === 'object') {
            //  imageWithEnrichedMetadataObject.customColumns[customColumnObject.columnDefinitionConstant] = imageWithEnrichedMetadataObject.customColumns[customColumnObject.columnDefinitionConstant].concat(tmpValueFromSourceColumn);
            //} else {
            // imageWithEnrichedMetadataObject.customColumns[customColumnObject.columnDefinitionConstant] = tmpValueFromSourceColumn;
            //}
          } else {
            customColumnSourceObject.customColumnRegexArray.forEach((customColumnRegexObject: CustomColumnRegex) => {
            try {
              let re = new RegExp(
                customColumnRegexObject.regexString,
                customColumnRegexObject.regexStringIgnoreCase ? 'i' : ''
              );

                arrayFromSourceColumn.forEach((arrayFromSourceColumnString: string) => {
                  if (arrayFromSourceColumnString === '') {
                    console.error('arrayFromSourceColumnString is an empty string. Investigate this - Empty strings here should be avoided! (regex:' + customColumnRegexObject.regexString + ')');
                  }
                
                  if (arrayFromSourceColumnStringsToSkip.indexOf(arrayFromSourceColumnString) === -1) {
                    let arrayFromSourceColumnStringShouldTrim = true;

                    if (arrayFromSourceColumnString.match(re)) {
                      arrayFromSourceColumnStringsToSkip.push(arrayFromSourceColumnString);

                      if (customColumnObject.removeFromKeywordRest && (customColumnSourceObject.sourceColumn.columnDefinitionConstant == COLUMN_DEFINITION_CONST_KEYWORDS) ) {
                        removeStringFromKeywordsRest(arrayFromSourceColumnString, imageWithEnrichedMetadataObject);
                      }

                      customColumnRegexObject.searchReplaceArray.forEach((searchReplaceObject: SearchReplaceDefinition) => {       
                          const regExpSearchReplace = new RegExp(
                            searchReplaceObject.searchReplaceSearchString,
                            searchReplaceObject.searchReplaceIgnoreCase ? 'i' : ''
                          );

                          if (arrayFromSourceColumnString.match(regExpSearchReplace)) {
                            arrayFromSourceColumnString = arrayFromSourceColumnString.replace(regExpSearchReplace, searchReplaceObject.searchReplaceReplaceString);
                            removeStringFromKeywordsRest(arrayFromSourceColumnString, imageWithEnrichedMetadataObject);
                          }             
                      });

                      if (arrayFromSourceColumnStringShouldTrim && (customColumnRegexObject.trimResultStart !== 0) || (customColumnRegexObject.trimResultEnd !== 0)) {
                        const tmpFormattedOutput = formatForFormatFunction(
                          arrayFromSourceColumnString.substring(
                            (customColumnRegexObject.trimResultStart<0 ? arrayFromSourceColumnString.length+customColumnRegexObject.trimResultStart : customColumnRegexObject.trimResultStart),
                            arrayFromSourceColumnString.length-customColumnRegexObject.trimResultEnd
                          ), customColumnObject.formatFunctionConstant
                        );
                        customColumnContentArray.push(`${customColumnSourceObject.prefix}${tmpFormattedOutput}${customColumnSourceObject.postfix}`);
                      } else {
                        customColumnContentArray.push(
                          `${customColumnSourceObject.prefix}${formatForFormatFunction(arrayFromSourceColumnString, customColumnObject.formatFunctionConstant)}${customColumnSourceObject.postfix}`); 
                      }
                    }
                }
                });
            } catch (error) {
                debugger;
            }

            if (customColumnContentArray.length===0 && customColumnObject.defaultString.length) {
              imageWithEnrichedMetadataObject.customColumns[customColumnObject.columnDefinitionConstant] = [customColumnObject.defaultString];
            } else {
              imageWithEnrichedMetadataObject.customColumns[customColumnObject.columnDefinitionConstant] = customColumnContentArray;
            }
        });
      } // End else
        });
      }
    });
    //return imageWithEnrichedMetadataObject;
  }

export const numberWithSeparator = (x: string|number) => {
    // TODO: Extract . as constant
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const updateSortPositionValuesBasedOnArrayIndex = (columnDefinitionArray: ColumnDefinition[]) => {
    for (let i=0; i<columnDefinitionArray.length; i++) {
        columnDefinitionArray[i].sortPosition=i;
    }
}

export const getCombinedColumnDefinitionArray = (customColumnsArray: ColumnDefinition[], remarksColumnsArray: ColumnDefinition[]) => {
  let combinedArray = [];

  for (let i=0; i<initialBaseColumnDefinitionArray.length; i++) {
    combinedArray.push(initialBaseColumnDefinitionArray[i]);
  }

  for (let i=0; i<customColumnsArray.length; i++) {
    combinedArray.push(customColumnsArray[i]);
  }

  for (let i=0; i<remarksColumnsArray.length; i++) {
    combinedArray.push(remarksColumnsArray[i]);
  }

  return combinedArray;
}

// sets an unique value on ColumnDefinition
export const getUniqueValueForCustomColumnConstant = (nameInFrontend: string, columnDefinitionType: number, customColumsArray: ColumnDefinition[], remarksColumsArray: ColumnDefinition[]): string => {
    let prefix;
    let postfix = 0;
    switch (columnDefinitionType) {
        case COLUMN_DEFINITION_TYPE_CUSTOM_COLUMN_DEFINITION:
            prefix = 'custom_column';
            break;
        case COLUMN_DEFINITION_TYPE_REMARKS_COLUMN_DEFINITION:
            prefix = 'remark_column';
            break;
        default:
            prefix = 'base_column';
            break;
    }

    const nameInFrontendCleaned = nameInFrontend.toLowerCase().replace(/ /g, '').replace(/[^a-z]/gi, '');
    let returnValue = `${prefix}_${nameInFrontendCleaned}_${postfix}`;

    let combinedColumnDefinitionsArray = getCombinedColumnDefinitionArray(customColumsArray, remarksColumsArray);

    while (combinedColumnDefinitionsArray.find(element => element.columnDefinitionConstant === returnValue)) {
        returnValue = `${prefix}_${nameInFrontendCleaned}_${postfix}`;
        postfix++;
    }
    return returnValue;   
}

// sets an unique value on ColumnDefinition
export const getUniqueValueForRenderObjectsConstant = (name: string, renderObjectsMap: any): string => {
  const prefix = 'render-object-';
  let postfix = 0;

  const nameCleaned = (name+'-').toLowerCase().replace(/ /g, '').replace(/[^a-z]/gi, '');
  let returnValue = `${prefix}${nameCleaned}${postfix}`;

  // let combinedColumnDefinitionsArray = getCombinedColumnDefinitionArray(customColumsArray, remarksColumsArray);


  //element as RenderConfigObject
  // @ts-ignore
  while (Object.values(renderObjectsMap).find(element => element.constant === returnValue)) {
      returnValue = `${prefix}${nameCleaned}${postfix}`;
      postfix++;
  }
  console.log('getUniqueValueForRenderObjectsConstant:'+returnValue);
  return returnValue;   
}

export const getColumnDefinitionPresetHideKeywordDetails = (): ColumnDefinition[] => {
    const modifiedColumnDefinitionArray: ColumnDefinition[] = [];
    const columnsToDeactivate: string[] = [
        COLUMN_DEFINITION_CONST_FILENAME,
        COLUMN_DEFINITION_CONST_DIRECTORY,
        COLUMN_DEFINITION_CONST_KEYWORD_REST,
    ];

    initialBaseColumnDefinitionArray.forEach((columnDefinitionObject: ColumnDefinition) => {
        const tmpColumnDefinitionObject = { ...columnDefinitionObject };
        tmpColumnDefinitionObject.active = columnsToDeactivate.includes(columnDefinitionObject.columnDefinitionConstant);
        modifiedColumnDefinitionArray.push(tmpColumnDefinitionObject);
    });
    return modifiedColumnDefinitionArray;
}

// COLUMN_DEFINITION_PRESET_FOR_PUBLIC
export const getColumnDefinitionPresetForPublic = (): ColumnDefinition[] => {
    console.log('getColumnDefinitionPresetForPublic');
    const modifiedColumnDefinitionArray: ColumnDefinition[] = [];
    const columnsToDeactivate: string[] = [
        COLUMN_DEFINITION_CONST_ID,
        COLUMN_DEFINITION_CONST_DATE_TIME_CREATED,
    ];

    initialBaseColumnDefinitionArray.forEach((columnDefinitionObject: ColumnDefinition) => {
        const tmpColumnDefinitionObject = { ...columnDefinitionObject };
        tmpColumnDefinitionObject.active = columnsToDeactivate.includes(columnDefinitionObject.columnDefinitionConstant);
        modifiedColumnDefinitionArray.push(tmpColumnDefinitionObject);
    });
    return modifiedColumnDefinitionArray;
}

// COLUMN_DEFINITION_PRESET_ALL_COLUMNS
export const getColumnDefinitionAllColumns = (): ColumnDefinition[] => {
    const modifiedColumnDefinitionArray: ColumnDefinition[] = [];
    initialBaseColumnDefinitionArray.forEach((columnDefinitionObject: ColumnDefinition) => {
        const tmpColumnDefinitionObject = { ...columnDefinitionObject };
        tmpColumnDefinitionObject.active = true;
        modifiedColumnDefinitionArray.push(tmpColumnDefinitionObject);
    });
    return modifiedColumnDefinitionArray;
}

// COLUMN_DEFINITION_PRESET_NO_COLUMNS
export const getColumnDefinitionNoColumns = (): ColumnDefinition[] => {
    const modifiedColumnDefinitionArray: ColumnDefinition[] = [];
    initialBaseColumnDefinitionArray.forEach((columnDefinitionObject: ColumnDefinition) => {
        const tmpColumnDefinitionObject = { ...columnDefinitionObject };
        tmpColumnDefinitionObject.active = false;
        modifiedColumnDefinitionArray.push(tmpColumnDefinitionObject);
    });
    return modifiedColumnDefinitionArray;
}

// COLUMN_DEFINITION_PRESET_HIDE_KEYWORDS
export const getColumnDefinitionPresetHideKeywords = (): ColumnDefinition[] => {
    const columnsToDeactivate: string[] = [
        COLUMN_DEFINITION_CONST_FILENAME,
        COLUMN_DEFINITION_CONST_DIRECTORY,
        COLUMN_DEFINITION_CONST_KEYWORDS,
    ];

    const modifiedColumnDefinitionArray: ColumnDefinition[] = [];
    initialBaseColumnDefinitionArray.forEach((columnDefinitionObject: ColumnDefinition) => {
        const tmpColumnDefinitionObject = { ...columnDefinitionObject };
        tmpColumnDefinitionObject.active = columnsToDeactivate.includes(columnDefinitionObject.columnDefinitionConstant);
        modifiedColumnDefinitionArray.push(tmpColumnDefinitionObject);
    });
    return modifiedColumnDefinitionArray;
}

// COLUMN_DEFINITION_PRESET_WITH_SUMMARIES
export const getColumnDefinitionPresetWithSummaries = (): ColumnDefinition[] => {
    const columnsToActivate: string[] = [
        COLUMN_DEFINITION_CONST_ID,
        COLUMN_DEFINITION_CONST_SOURCE_FILE,
        //COLUMN_DEFINITION_FILENAME,
        //COLUMN_DEFINITION_DIRECTORY,
        // COLUMN_DEFINITION_CAMERA_EQUIPMENT,
        COLUMN_DEFINITION_CONST_KEYWORDS_SUMMARY,
        // COLUMN_DEFINITION_PHOTO_INFOS,
    ];

    const modifiedColumnDefinitionArray: ColumnDefinition[] = [];
    initialBaseColumnDefinitionArray.forEach((columnDefinitionObject: ColumnDefinition) => {
        const tmpColumnDefinitionObject = { ...columnDefinitionObject };
        tmpColumnDefinitionObject.active = columnsToActivate.includes(columnDefinitionObject.columnDefinitionConstant);
        modifiedColumnDefinitionArray.push(tmpColumnDefinitionObject);
    });
    return modifiedColumnDefinitionArray;
}

// COLUMN_DEFINITION_PRESET_WITH_SUMMARIES_AND_WARNINGS
export const getColumnDefinitionPresetWithSummariesAndWarnings = (): ColumnDefinition[] => {
    const columnsToActivate: string[] = [
        COLUMN_DEFINITION_CONST_ID,
        COLUMN_DEFINITION_CONST_SOURCE_FILE,
        COLUMN_DEFINITION_CONST_KEYWORDS_SUMMARY,
    ];

    const modifiedColumnDefinitionArray: ColumnDefinition[] = [];
    initialBaseColumnDefinitionArray.forEach((columnDefinitionObject: ColumnDefinition) => {
        const tmpColumnDefinitionObject = { ...columnDefinitionObject };
        tmpColumnDefinitionObject.active = columnsToActivate.includes(columnDefinitionObject.columnDefinitionConstant);
        modifiedColumnDefinitionArray.push(tmpColumnDefinitionObject);
    });
    return modifiedColumnDefinitionArray;
}

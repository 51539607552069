import { FC, useState } from 'react';
import { ColumnsForViewObject, ImageWithEnrichedMetadataObject, Settings } from "../custom_types";
import { COLUMNS_FOR_TABLE_VIEW_KEY } from '../constants';
import { BaseTabContentComponent } from '../BaseTabContentComponent';
import { KeywordsTable } from './KeywordsTable';

interface KeywordsTableContainerProps {
    columnsForViewArray: ColumnsForViewObject,
    settings: Settings,
    connectedToNodeServer: boolean,
    connectedToDatabase: boolean,
    imageWithEnrichedMetadataObjectsArray_Page: ImageWithEnrichedMetadataObject[],
    editPossible: boolean,
    checkForKeywordCorrections: boolean,
    selectedSourceFileValues: string[],
    setSelectedSourceFileValues: any, //function
    changeActiveViewTab: any,
    activeViewTabKey: string,
    theadValueTest: number,
    setActiveRemarksColumnTabKey?: any,
    setActiveCustomColumnTabKey?: any,
    setShowColumnDefinitionModal?: any,
    setActiveTabKeyColumnDefinitionModal?: any,
    renderObjectsMap: any,
}

export const KeywordsTableContainer: FC<KeywordsTableContainerProps> = ({
    columnsForViewArray,
    settings,
    connectedToNodeServer,
    connectedToDatabase,
    imageWithEnrichedMetadataObjectsArray_Page,
    editPossible,
    checkForKeywordCorrections,
    selectedSourceFileValues,
    setSelectedSourceFileValues,
    changeActiveViewTab,
    activeViewTabKey,
    theadValueTest,
    setActiveRemarksColumnTabKey,
    setActiveCustomColumnTabKey,
    setShowColumnDefinitionModal,
    setActiveTabKeyColumnDefinitionModal,
    renderObjectsMap,
}) => {
    const [errorsArray, setErrorsArray] = useState([]);

    return (
        <BaseTabContentComponent
            errorsArray={errorsArray}
            mainContent={
                <KeywordsTable
                    columnsArray={columnsForViewArray[COLUMNS_FOR_TABLE_VIEW_KEY]}
                    theadValueTest={theadValueTest}
                    idPrefix='keyword_table_container_keywords_table'
                    settings={settings}
                    connectedToNodeServer={connectedToNodeServer}
                    connectedToDatabase={connectedToDatabase}
                    editPossible={editPossible}
                    imageWithEnrichedMetadataObjectsArray={imageWithEnrichedMetadataObjectsArray_Page}
                    checkForKeywordCorrections={checkForKeywordCorrections}
                    selectedSourceFileValues={selectedSourceFileValues}
                    setSelectedSourceFileValues={setSelectedSourceFileValues}
                    changeActiveViewTab={changeActiveViewTab}
                    activeViewTabKey={activeViewTabKey}
                    setActiveRemarksColumnTabKey={setActiveRemarksColumnTabKey}
                    setActiveCustomColumnTabKey={setActiveCustomColumnTabKey}
                    setShowColumnDefinitionModal={setShowColumnDefinitionModal}
                    setActiveTabKeyColumnDefinitionModal={setActiveTabKeyColumnDefinitionModal}
                    renderObjectsMap={renderObjectsMap}
                />
            }
        />
    );
}

import { FC } from 'react';
import Badge from "react-bootstrap/Badge";
import { RENDER_CONTAINER_ARRAY_JOIN_KEY, RENDER_CONTAINER_BADGE_FULL_WIDTH_KEY } from '../constants';
import { RenderConfigObject } from '../custom_types';

interface OutputForRenderObjectProps {
    // TODO: Ensure string[] here
    contentArray: string[],
    renderConfigObject: RenderConfigObject,
}

export const OutputForRenderObject: FC<OutputForRenderObjectProps> = ({
    contentArray,
    renderConfigObject,
}) => {
    const modifiedContentArray = (typeof contentArray === 'string') ?  [contentArray] : contentArray;
    let tmpRenderFunction: any;

    {renderConfigObject.showInList ?
        tmpRenderFunction = (items: string[]) => 
            <ul>
            { items.map((item: any, index: number) => {
                return (
                    <li key={index}>
                        {renderConfigObject.renderContainer === RENDER_CONTAINER_ARRAY_JOIN_KEY ?
                        <>{item}</> :
                            <Badge className={renderConfigObject.renderContainer === RENDER_CONTAINER_BADGE_FULL_WIDTH_KEY ? 'badge-full-width' : ''}>
                                {item}
                            </Badge>
                        }
                    </li>
                )
            })}
            </ul>
    :
        tmpRenderFunction = (items: string[]) => {
            return renderConfigObject.renderContainer === RENDER_CONTAINER_ARRAY_JOIN_KEY ?
                items.join(', ') :
                items.map((item: any, index: number) => {
                    return (
                    <Badge className={renderConfigObject.renderContainer === RENDER_CONTAINER_BADGE_FULL_WIDTH_KEY ? 'badge-full-width' : ''}>
                        {item}
                    </Badge>)
                })
        }
    }

    return modifiedContentArray ? tmpRenderFunction(modifiedContentArray) : null;
}

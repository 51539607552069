import React, { FC, Fragment } from 'react';
import { Table } from "react-bootstrap";
import { ColumnDefinition, ImageWithEnrichedMetadataObject, Settings } from "../custom_types";
import { COLUMN_DEFINITION_TYPE_CUSTOM_COLUMN_DEFINITION, COLUMN_DEFINITION_TYPE_REMARKS_COLUMN_DEFINITION, TAB_KEY_COLUMN_CUSTOMIZER_COMMENT_COLUMN, TAB_KEY_COLUMN_CUSTOMIZER_CUSTOM_COLUMN } from '../constants';
import { KeywordRow } from '../KeywordRow';

interface KeywordsTableProps {
    theadValueTest?: any, // TODO: Rename this variable
    tbodyRef?: any,
    idPrefix: string,
    editPossible: boolean,
    imageWithEnrichedMetadataObjectsArray: ImageWithEnrichedMetadataObject[],
    settings: Settings,
    connectedToNodeServer: boolean,
    connectedToDatabase: boolean,
    checkForKeywordCorrections?: boolean,
    selectedSourceFileValues?: string[],
    setSelectedSourceFileValues?: any, //function
    changeActiveViewTab?: any,
    activeViewTabKey?: string,
    columnsArray: ColumnDefinition[],
    setActiveRemarksColumnTabKey?: any,
    setActiveCustomColumnTabKey?: any,
    setShowColumnDefinitionModal?: any,
    setActiveTabKeyColumnDefinitionModal?: any,
    renderObjectsMap: any,
}

export const KeywordsTable: FC<KeywordsTableProps> = ({
    theadValueTest,
    idPrefix,
    editPossible,
    imageWithEnrichedMetadataObjectsArray,
    settings,
    connectedToDatabase,
    checkForKeywordCorrections=false,
    selectedSourceFileValues,
    setSelectedSourceFileValues,
    changeActiveViewTab,
    activeViewTabKey,
    columnsArray,
    setActiveRemarksColumnTabKey,
    setActiveCustomColumnTabKey,
    setShowColumnDefinitionModal,
    setActiveTabKeyColumnDefinitionModal,
    renderObjectsMap,
}) => {

    const handleDoubleClick = (columnDefintionConstant: string, columnDefinitionType: number) => {
        if (setShowColumnDefinitionModal) {
            if (columnDefinitionType === COLUMN_DEFINITION_TYPE_REMARKS_COLUMN_DEFINITION) {
                setShowColumnDefinitionModal(true);

                setActiveTabKeyColumnDefinitionModal(TAB_KEY_COLUMN_CUSTOMIZER_COMMENT_COLUMN);

                setActiveRemarksColumnTabKey(columnDefintionConstant);
            } else if (columnDefinitionType === COLUMN_DEFINITION_TYPE_CUSTOM_COLUMN_DEFINITION) {
                setShowColumnDefinitionModal(true);

                setActiveTabKeyColumnDefinitionModal(TAB_KEY_COLUMN_CUSTOMIZER_CUSTOM_COLUMN);

                setActiveCustomColumnTabKey(columnDefintionConstant);
            }
        }
    }
    
    return (
        <Table className='keywords-table' id={idPrefix}>
            <thead style={{ "left": theadValueTest } as React.CSSProperties}>
                <tr>
                    {columnsArray.map((columnDefinition: ColumnDefinition, columnDefinitionIndex: number) => {    
                        return (columnDefinition?.active ? (
                            <th
                                onDoubleClick={() => handleDoubleClick(columnDefinition.columnDefinitionConstant, columnDefinition.columnDefinitionType)}
                                key={`keywords_table_column_${columnDefinitionIndex}`}
                                className={`column-definition-type-${columnDefinition.columnDefinitionType}`}
                                style={{ "minWidth": columnDefinition.width, "maxWidth": columnDefinition.width } as React.CSSProperties}
                                >
                                {columnDefinition.nameInFrontend}
                            </th>
                        ) : <Fragment key={`keywords_table_column_${columnDefinitionIndex}`}></Fragment>
                    )}
                    )}
                </tr>
            </thead>
            <tbody>
                {imageWithEnrichedMetadataObjectsArray.length > 0 ? 
                    Object.keys(imageWithEnrichedMetadataObjectsArray).map((k, i: number) => {
                        return (
                            <KeywordRow
                                settings={settings}
                                editPossible={editPossible}
                                combinedColumnDefinitionArray={columnsArray}
                                checkKeywordCorrections={checkForKeywordCorrections}
                                key={`keywords_table_row${i}`}
                                i={i}
                                imageWithEnrichedMetadataObject={imageWithEnrichedMetadataObjectsArray[k]}
                                connectedToDatabase={connectedToDatabase}
                                selectedSourceFileValues={selectedSourceFileValues ? selectedSourceFileValues : []}
                                setSelectedSourceFileValues={setSelectedSourceFileValues}
                                changeActiveViewTab={changeActiveViewTab}
                                activeViewTabKey={activeViewTabKey}
                                renderObjectsMap={renderObjectsMap}
                            />
                        )
                    })
                 : <tr><td>Keine Daten</td></tr>}
            </tbody>
        </Table>
    )
}

import React, { FC, useState } from 'react';
import Form from "react-bootstrap/Form";
import { ColumnDefinition, ImageWithMetadataObject, Settings } from "../custom_types";
import { getCombinedColumnDefinitionArray } from '../Helpers';
import { KeywordsTable } from '../KeywordsTable/KeywordsTable';

interface KeywordRowDemoProps {
    customColumnsArray: ColumnDefinition[],
    remarksColumnsArray: ColumnDefinition[],
    settings: Settings,
    enrichImageWithMetadataObjectArray: any, //function
    renderObjectsMap: any,
}

export const KeywordRowDemo: FC<KeywordRowDemoProps> = ({
    customColumnsArray,
    remarksColumnsArray,
    settings,
    enrichImageWithMetadataObjectArray,
    renderObjectsMap,
}) => {
    const [keywordsString, setKeywordsString] = useState('');
    const [filename, setFilename] = useState('');
    const [directory, setDirectory] = useState('');
    let tmpKeywords = '';
    let combinedColumnDefinitionArray = getCombinedColumnDefinitionArray(customColumnsArray, remarksColumnsArray);

    return (
        <>
            <h1>Tabellenverhalten testen</h1>
            <Form>
                <Form.Group controlId="inputFilename">
                    <Form.Label>Dateiname</Form.Label>
                    <Form.Control
                        type="text"
                        value={filename}
                        onInput={(event: React.ChangeEvent<HTMLInputElement>) => setFilename(event.target.value)}
                        placeholder='Dateiname hier eintragen'
                        onChange={() => null}
                    />
                </Form.Group>
                <Form.Group controlId="inputDirectory">
                    <Form.Label>Ordnername</Form.Label>
                    <Form.Control
                        type="text"
                        value={directory}
                        onInput={(event: React.ChangeEvent<HTMLInputElement>) => setDirectory(event.target.value)}
                        placeholder='Ordnername hier eintragen'
                    />
                </Form.Group>
                <Form.Group controlId="inputKeywords">
                    <Form.Label>Stichwörter</Form.Label>
                    <Form.Control
                        type="text"
                        value={keywordsString}
                        onInput={(event: React.ChangeEvent<HTMLInputElement>) => setKeywordsString(event.target.value)}
                        placeholder='Stichwörter kommagetrennt hier eintragen'
                    />
                </Form.Group>
            </Form>
            <KeywordsTable
                idPrefix='keyword_row_demo_keywords_table_0'
                renderObjectsMap={renderObjectsMap}
                settings={settings}
                editPossible={false}
                connectedToNodeServer={false}
                connectedToDatabase={false}
                columnsArray={combinedColumnDefinitionArray}
                imageWithEnrichedMetadataObjectsArray={
                    enrichImageWithMetadataObjectArray(
                        [{
                            sourcefile: '',
                            file_name: filename,
                            directory: directory,
                            keywords_string: keywordsString,
                            image_size: '',
                        } as ImageWithMetadataObject],
                        false // setLoadingVariables
                    )
                }
            />
            <hr />
            <hr />
            <h1>Beispiele</h1>
            <h3>Beispiel 1:</h3>
            <Form>
                <Form.Control type='text' value={tmpKeywords = '7DB, n038772, n24-009, xc, y860605, zK-Niehl, xFarbeRot, xxZweiXBemerkungen, xxDreiBemerkungen, xEineBemerkung'} />
            </Form>
            <ul>
                <li>Kann man bei der Suche nach "Infos aus Ordnername" davon ausgehen, dass es immer den Zwischenordner (hier 30a) gibt? Dann müsste man nur nach etwas in Slashes suchen?!</li>
                <li>Die Loknummer "24-009" sollte exakt so in der Liste der Loknummern auftauchen. Regel: Wenn in der Loknummer ein Bindestrich vorkommt wird die Nummer genauso übernommen.</li>
                <li>Es gibt kein Keyword im "Rest" angezeigt wird (das sonst nicht anders eingeordnet werden kann). Die Spalte "Rest" sollte dann auch nicht rot hervorgehoben werden.</li>
            </ul>
            <KeywordsTable
                idPrefix='keyword_row_demo_keywords_table_1'
                renderObjectsMap={renderObjectsMap}
                settings={settings}
                editPossible={false}
                connectedToNodeServer={false}
                connectedToDatabase={false}
                columnsArray={combinedColumnDefinitionArray}
                imageWithEnrichedMetadataObjectsArray={
                    enrichImageWithMetadataObjectArray(
                        [{
                            sourcefile: '',
                            file_name: 'Beispiel1__12121212__.jpg',
                            directory: 'E:/Ordner30-19Juli2021/30a/Chep__CZ-30a',
                            keywords_string: tmpKeywords,
                            image_size: '',
                        } as ImageWithMetadataObject],
                        false // setLoadingVariables
                    )
                }
            />

            <h3>Beispiel 2:</h3>
            <Form>
                <Form.Control type='text' value={tmpKeywords = '0SNCB, nunbekannt, nStrecke, nBf, nWerk, nBW, xFoto-Kempf, zK-Deutzerfeld-Werk, xFarbe'} />
            </Form>
            <ul>
                <li>Bei Loknummer sollten die Begriffe jeweils ohne Leerzeichen dazwischen angzeigt werden</li>
                <li>xFoto-Kempf sollte als Warnung ausgegeben werden - xFotoKempf immer ohne Zeichen dazwischen</li>
            </ul>
            <KeywordsTable
                idPrefix='keyword_row_demo_keywords_table_2'
                renderObjectsMap={renderObjectsMap}
                settings={settings}
                editPossible={false}
                connectedToNodeServer={false}
                connectedToDatabase={false}
                columnsArray={combinedColumnDefinitionArray}
                imageWithEnrichedMetadataObjectsArray={
                    enrichImageWithMetadataObjectArray(
                        [{
                            sourcefile: '',
                            file_name: 'Beispiel2.jpg',
                            directory: 'E:/Ordner30-19Juli2021/30r/München-30r',
                            keywords_string: tmpKeywords,
                            image_size: '',
                        } as ImageWithMetadataObject],
                        false // setLoadingVariables
                    )
                }
            />

            <h3>Beispiel 3:</h3>
            <Form>
                <Form.Control type='text' value={tmpKeywords = 'n110999, n390, neu'} />
            </Form>
            <ul>
                <li>Die Loknummer mit "999" am Ende ist nicht richtig. Die richtige Nummer ist nicht bekannt, da sie nicht lesbar ist. Wenn eine Prüfziffer angegeben ist konnte die Loknummer nicht richtig erkannt werden. Hier ist Nacharbeit nötig.</li>
            </ul>
            <KeywordsTable
                idPrefix='keyword_row_demo_keywords_table_3'
                renderObjectsMap={renderObjectsMap}
                settings={settings}
                editPossible={false}
                connectedToNodeServer={false}
                connectedToDatabase={false}
                columnsArray={combinedColumnDefinitionArray}
                imageWithEnrichedMetadataObjectsArray={
                    enrichImageWithMetadataObjectArray(
                        [{
                            sourcefile: '',
                            file_name: 'Beispiel3.jpg',
                            directory: 'E:/Ordner30-19Juli2021/30a/Linz__A-30a/',
                            keywords_string: tmpKeywords,
                            image_size: '',
                        } as ImageWithMetadataObject],
                        false // setLoadingVariables
                    )
                }
            />

            <h3>Beispiel 4:</h3>
            <Form>
                <Form.Control type='text' value={tmpKeywords = '0DR, n50-3558, y850101, zStendal-Werk, foobar, xFotoWenserski, xFotoKempf'} />
            </Form>
            <ul>
                <li>Es ist der 01.01 angegeben. Das bedeutet, dass das Datum ungenau ist und nur das Jahr ist sicher bekannt. Daher wird in der Spalte Datum nur "1980" ausgegben.</li>
                <li>Das Keyword "foobar" sollte im Rest angezeigt werden. Die Spalte Rest sollte dann rot hinterlegt sein.</li>
                <li>In der Spalte Bemerkung sollte kein "Foto X" stehen. Es sollte stattdessen eine Warnung ausgegeben werden, dass 2 Fotografen angegeben sind.</li>
            </ul>
            <KeywordsTable
                idPrefix='keyword_row_demo_keywords_table_4'
                renderObjectsMap={renderObjectsMap}
                settings={settings}
                editPossible={false}
                connectedToNodeServer={false}
                connectedToDatabase={false}
                columnsArray={combinedColumnDefinitionArray}
                imageWithEnrichedMetadataObjectsArray={
                    enrichImageWithMetadataObjectArray(
                        [{
                            sourcefile: '',
                            file_name: 'Beispiel4.jpg',
                            directory: 'E:/Ordner30-19Juli2021/30a/Basel__Ch-30a',
                            keywords_string: tmpKeywords,
                            image_size: '',
                        } as ImageWithMetadataObject],
                        false // setLoadingVariables
                    )
                }
            />

            <h3>Beispiel 5:</h3>
            <Form>
                <Form.Control type='text' value={tmpKeywords = '7DR, n38-1182, y820901, zNordhausen-Werk, xFotoKempf'} />
            </Form>
            <ul>
                <li>Im Datum steht der erste eines Monats. Das bedeutet, dass Monat bekannt ist - der Tag aber nicht. Daher wird nur der Monat ausgegeben, nicht aber der Tag. Daher wird in der Spalte Datum nur "September 1982" ausgegben.</li>
                <li>In der Spalte Fotograf muss "Kempf" und nicht mehr "KHS" stehen.</li>
            </ul>
            <KeywordsTable
                idPrefix='keyword_row_demo_keywords_table_5'
                renderObjectsMap={renderObjectsMap}
                settings={settings}
                editPossible={false}
                connectedToNodeServer={false}
                connectedToDatabase={false}
                columnsArray={combinedColumnDefinitionArray}
                imageWithEnrichedMetadataObjectsArray={
                    enrichImageWithMetadataObjectArray(
                        [{
                            sourcefile: '',
                            file_name: 'Beispiel5.jpg',
                            directory: 'E:/Ordner30-19Juli2021/30r/Vorchdorf__A30r',
                            keywords_string: tmpKeywords,
                            image_size: '',
                        } as ImageWithMetadataObject],
                        false // setLoadingVariables
                    )
                }
            />

            <h3>Beispiel 6:</h3>
            <Form>
                <Form.Control type='text' value={tmpKeywords = '7EK_M, 7ErfurterIndustriebahn, n24-009, n74-1192, xQ3, zBindweide, xb, xb2'} />
            </Form>
            <ul>
                <li>Bei "Bemerkung" sollte Museum stehen, da der Betrieb "_M" am Ende enthält.</li>
                <li>Der Tag "xb" alleine ist nicht gültig - es sollte als Fehler gemeldet werden</li>
                <li>Der Tag "xb2" besagt, dass die 2te Lok in der Keyword-Liste die größte der dargestellten Loks ist. In den Bemerkungen sollte "Begegnung stehen"</li>
            </ul>
            <KeywordsTable
                idPrefix='keyword_row_demo_keywords_table_6'
                renderObjectsMap={renderObjectsMap}
                settings={settings}
                editPossible={false}
                connectedToNodeServer={false}
                connectedToDatabase={false}
                columnsArray={combinedColumnDefinitionArray}
                imageWithEnrichedMetadataObjectsArray={
                    enrichImageWithMetadataObjectArray(
                        [{
                            sourcefile: '',
                            file_name: 'Beispiel6.jpg',
                            directory: 'E:/Ordner30-19Juli2021/30r/Vorchdorf__A30r',
                            keywords_string: tmpKeywords,
                            image_size: '',
                        } as ImageWithMetadataObject],
                        false // setLoadingVariables
                    )
                }
            />

            <h3>Beispiel 7:</h3>
            <Form>
                <Form.Control type='text' value={tmpKeywords = '5BLE-DGEG_M, 5ErfurterIndustriebahn_M, zunbekannt, n146, n74-1192, xQ4, xQneu'} />
            </Form>
            <ul>
                <li>xQneu ein kein valides Keyword. Generell sollte "Unbekannte Bemerkung "xyz"" nur bei Warnung auftauchen und nicht in der Spalte Bemerkungen</li>
                <li>Bei "Bemerkung" sollte Museum stehen</li>
                <li>Der Ort ist unbekannt - Es sollte "unbekannt" im Feld Ort stehen</li>
            </ul>
            <KeywordsTable
                idPrefix='keyword_row_demo_keywords_table_7'
                renderObjectsMap={renderObjectsMap}
                settings={settings}
                editPossible={false}
                connectedToNodeServer={false}
                connectedToDatabase={false}
                columnsArray={combinedColumnDefinitionArray}
                imageWithEnrichedMetadataObjectsArray={
                    enrichImageWithMetadataObjectArray(
                        [{
                            sourcefile: '',
                            file_name: 'Beispiel7.jpg',
                            directory: 'E:/Ordner30-19Juli2021/30r/Vorchdorf__A30r',
                            keywords_string: tmpKeywords,
                            image_size: '',
                        } as ImageWithMetadataObject],
                        false // setLoadingVariables
                    )
                }
            />

            <h3>Beispiel 8:</h3>
            <Form>
                <Form.Control type='text' value={tmpKeywords = '0NS, 5Utrecht, zUtrecht-'} />
            </Form>
            <ul>
                <li>Der Ort ist mit einem Bindestrich am Ende angegeben. Es sollte die Bemerkung "Ortsteil fehlt" angegeben sein.</li>
                <li>Es ist keine Loknummer angegeben. Es sollte die Warnung "Loknr fehlt" angezeigt werden.</li>
            </ul>
            <KeywordsTable
                idPrefix='keyword_row_demo_keywords_table_8'
                renderObjectsMap={renderObjectsMap}
                settings={settings}
                editPossible={false}
                connectedToNodeServer={false}
                connectedToDatabase={false}
                columnsArray={combinedColumnDefinitionArray}
                imageWithEnrichedMetadataObjectsArray={
                    enrichImageWithMetadataObjectArray(
                        [{
                            sourcefile: '',
                            file_name: 'Beispiel8.jpg',
                            directory: 'E:/Ordner30-19Juli2021/30a/Chep__CZ-30d',
                            keywords_string: tmpKeywords,
                            image_size: '',
                        } as ImageWithMetadataObject],
                        false // setLoadingVariables
                    )
                }
            />

            <h3>Beispiel 9:</h3>
            <Form>
                <Form.Control type='text' value={tmpKeywords = 'Drehen, n23, zNordhausen-Werk, zUnbekannt'} />
            </Form>
            <ul>
                <li>Es sind 2 Orte angegeben - Eine entsprechende Warnung sollte ausgegeben werden.</li>
             </ul>
            <KeywordsTable
                idPrefix='keyword_row_demo_keywords_table_9'
                renderObjectsMap={renderObjectsMap}
                settings={settings}
                editPossible={false}
                connectedToNodeServer={false}
                connectedToDatabase={false}
                columnsArray={combinedColumnDefinitionArray}
                imageWithEnrichedMetadataObjectsArray={
                    enrichImageWithMetadataObjectArray(
                        [{
                            sourcefile: '',
                            file_name: 'Beispiel9.jpg',
                            directory: 'E:/Ordner30-19Juli2021/30a/Chep__CZ-30d',
                            keywords_string: tmpKeywords,
                            image_size: '',
                        } as ImageWithMetadataObject],
                        false // setLoadingVariables
                    )
                }
            />

            <h3>Beispiel 10:</h3>
            <Form>
                <Form.Control type='text' value={tmpKeywords = 'xwp, xwg, xwS, xxSchnee'} />
            </Form>
            <ul>
                <li>xwp &gt; Personenw.</li>
                <li>xwg &gt; Güterw.</li>
                <li>xwS  &gt; Sonderw.</li>
                <li>Keyword beginnend mit xx wird in die Bemerkungen übernommen</li>
             </ul>
            <KeywordsTable
                idPrefix='keyword_row_demo_keywords_table_10'
                renderObjectsMap={renderObjectsMap}
                settings={settings}
                editPossible={false}
                connectedToNodeServer={false}
                connectedToDatabase={false}
                columnsArray={combinedColumnDefinitionArray}
                imageWithEnrichedMetadataObjectsArray={
                    enrichImageWithMetadataObjectArray(
                        [{
                            sourcefile: '',
                            file_name: 'Beispiel10.jpg',
                            directory: 'E:/Ordner30-19Juli2021/30a/Chep__CZ-30d',
                            keywords_string: tmpKeywords,
                            image_size: '',
                        } as ImageWithMetadataObject],
                        false // setLoadingVariables
                    )
                }
            />

            <h3>Beispiel 11:</h3>
            <Form>
                <Form.Control type='text' value={tmpKeywords = '9KVB, n146, n2000, n12345, xQ4, zK-Riehl-AmsterdamerStr, 8KVB'} />
            </Form>
            <ul>
                <li>Die Loknummer muss bei KVB 4-stellig sein</li>
                <li>Die nicht 4-stelligen Loknummern müssen in den Warnungen angezeigt werden.</li>
            </ul>
            <KeywordsTable
                idPrefix='keyword_row_demo_keywords_table_11'
                renderObjectsMap={renderObjectsMap}
                settings={settings}
                editPossible={false}
                connectedToNodeServer={false}
                connectedToDatabase={false}
                columnsArray={combinedColumnDefinitionArray}
                imageWithEnrichedMetadataObjectsArray={
                    enrichImageWithMetadataObjectArray(
                        [{
                            sourcefile: '',
                            file_name: 'Beispiel7.jpg',
                            directory: 'E:/Ordner30-19Juli2021/30r/Vorchdorf__A30r',
                            keywords_string: tmpKeywords,
                            image_size: '',
                        } as ImageWithMetadataObject],
                        false // setLoadingVariables
                    )
                }
            />            
        </>
        
    )
};

import { FC } from "react";
import { ColumnDefinition, DB_Import, DB_System } from "./custom_types";
import { Alert, Col, Dropdown, Form, ListGroup, Row } from "react-bootstrap";
import { DATA_SOURCE_DATABASE, initialBaseColumnDefinitionArray } from "./constants";


interface CombinedColumnSelectProps {
    customColumnsArray: ColumnDefinition[]|null,
    remarksColumnsArray: ColumnDefinition[]|null,
    dataSource: number,
    idPrefix: string,
    indexForUniqueId: string,
    selectedColumnDefinition?: ColumnDefinition,
    onClickFunction: any,
    importsArray?: DB_Import[]|null,
    systemsArray?: DB_System[]|null,
}

export const CombinedColumnSelect: FC<CombinedColumnSelectProps> = ({
    customColumnsArray=null,
    remarksColumnsArray=null,
    dataSource,
    idPrefix,
    indexForUniqueId,
    selectedColumnDefinition,
    onClickFunction,
    importsArray=null,
    systemsArray=null,
    ...props
}) => {

    const baseColumnsArray = initialBaseColumnDefinitionArray;

    //const baseColumns = combinedColumnDefinitionArray.length ? combinedColumnDefinitionArray.filter(e => e.columnDefinitionType===COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION) as ColumnDefinition[] : [] ;
    //const customColumns = combinedColumnDefinitionArray.length ? combinedColumnDefinitionArray.filter(e => e.columnDefinitionType===COLUMN_DEFINITION_TYPE_CUSTOM_COLUMN_DEFINITION) as ColumnDefinition[] : [];
    //const remarksColumns = combinedColumnDefinitionArray.length ? combinedColumnDefinitionArray.filter(e => e.columnDefinitionType===COLUMN_DEFINITION_TYPE_REMARKS_COLUMN_DEFINITION) as ColumnDefinition[] : [];

    return (
        <Dropdown>
            <Dropdown.Toggle split size='sm' variant='secondary' id={`${idPrefix}-combined-column-select-${indexForUniqueId}`}>
                {selectedColumnDefinition ? `${selectedColumnDefinition.nameInFrontend} ` : '-'}
            </Dropdown.Toggle>
            <Dropdown.Menu className='combined-column-select-dropdown-menu'>
                <Row>
                    <Col md={4}>
                        <Form.Label>Basis-Spalten</Form.Label>
                        <Dropdown.Item>
                            <ListGroup>
                                {baseColumnsArray.map((cd: ColumnDefinition) => 
                                        <ListGroup.Item
                                    onClick={onClickFunction}
                                            data-key-column-definition-constant={cd.columnDefinitionConstant}
                                            className={selectedColumnDefinition?.columnDefinitionConstant===cd.columnDefinitionConstant ? 'selected' : ''}
                                            {...props}
                                        >
                                            {cd.nameInFrontend}
                                        </ListGroup.Item>
                                )}
                            </ListGroup>
                        </Dropdown.Item>
                    </Col>
                    <Col md={4}>
                        {customColumnsArray &&
                            <>
                                <Form.Label>Zusatz-Spalten</Form.Label>
                                {dataSource===DATA_SOURCE_DATABASE ?
                                    <Alert variant='info'>
                                       Kann bei Verbindung mit der Datenbank nicht durchsucht werden
                                    </Alert>
                                :
                                    <Dropdown.Item>
                                        <ListGroup>
                                            {customColumnsArray.map((cd: ColumnDefinition) => 
                                                <ListGroup.Item
                                                className={selectedColumnDefinition?.columnDefinitionConstant===cd.columnDefinitionConstant ? 'dropdown_item_column_definition_type_2 selected' : 'dropdown_item_column_definition_type_2'}
                                                onClick={onClickFunction}
                                                    data-key-column-definition-constant={cd.columnDefinitionConstant}
                                                    {...props}
                                                >
                                                    {cd.nameInFrontend}
                                                </ListGroup.Item>
                                            )}
                                        </ListGroup>
                                    </Dropdown.Item>
                                }
                            </>
                        }
                    </Col>
                    <Col md={4}>
                        {remarksColumnsArray &&
                        <>
                            <Form.Label>Bemerkungs-Spalten</Form.Label>
                            {dataSource===DATA_SOURCE_DATABASE ?
                                    <Alert variant='info'>
                                       Kann bei Verbindung mit der Datenbank nicht durchsucht werden
                                    </Alert>
                                :
                                <Dropdown.Item>
                                    <ListGroup>
                                        {remarksColumnsArray.map((cd: ColumnDefinition) => 
                                            <ListGroup.Item
                                                className={selectedColumnDefinition?.columnDefinitionConstant===cd.columnDefinitionConstant ? 'dropdown_item_column_definition_type_3 selected' : 'dropdown_item_column_definition_type_3'}
                                            onClick={onClickFunction}
                                                data-key-column-definition-constant={cd.columnDefinitionConstant}
                                                {...props}
                                            >
                                                {cd.nameInFrontend}
                                            </ListGroup.Item>
                                        )}
                                    </ListGroup>
                                </Dropdown.Item>
                            }
                        </>
                        }
                    </Col>
                </Row>
                {/* {combinedColumnDefinitionArray.map((columnDefinition: ColumnDefinition, combinedColumnDefintionIndex: number) => 
                    <Dropdown.Item
                        data-key-data-filter-object-index={indexForUniqueId}
                        data-key-combined-column-definition-index={combinedColumnDefintionIndex}
                        onClick={onClickFunction}
                        className={`dropdown_item_column_definition_type_${columnDefinition.columnDefinitionType}`}
                    >
                        {columnDefinition.nameInFrontend}
                    </Dropdown.Item>
                )} */}
            </Dropdown.Menu>
        </Dropdown>
    );
}
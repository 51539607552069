import React, { FC, useEffect, useState } from 'react';
import './styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "./custom_types.d"
import { FolderGrid } from "./FolderGrid";
import {
  ColumnDefinition,
  RemarksColumnDefinition,
  CustomColumnDefinition,
  ImageWithEnrichedMetadataObject,
  ImageWithMetadataObject,
  Settings,
  DataFilePresetFile,
  AdditionalColumnsConfig,
  DataFilterObject,
  ColumnsForViewObject,
  ColumnForViewImportExportDefinition,
  LoadingObject,
  UrlParametersObject,
  DB_System,
  DB_Import,
  PaginationObject,
  DB_KeywordCorrection,
  RenderConfigObject,
  ExchangeCustomColumnDefinition,
  ExchangeRemarksColumnDefinition,
} from "./custom_types";
import {
  DATA_SOURCE_DATABASE,
  DATA_SOURCE_JSON,
  DATA_SOURCES,
  DEFAULT_DATABASE_HOST,
  DEFAULT_NODE_SERVER_HOST,
  defaultItemsPerPage,
  getDatabaseImagesFromDbBaseUrl,
  getDatabaseIsAliveUrl,
  getNodeIsAliveUrl,
  initialBaseColumnDefinitionArray,
  LOCAL_STORAGE_KEY_SETTING_COMPUTER_NAME,
  LOCAL_STORAGE_KEY_SETTING_DATABASE_HOST,
  LOCAL_STORAGE_KEY_SETTING_NODE_SERVER_HOST,
  COLUMN_DEFINITION_CONST_KEYWORDS,
  COLUMN_DEFINITION_CONST_ID,
  LOCAL_STORAGE_KEY_IHKHSJSON_HISTORY,
  NODE_SERVER_IS_ALIVE_EXPECETED_ANSWER,
  DATABASE_IS_ALIVE_EXPECETED_ANSWER,
  TAB_KEY_IMAGE_DETAIL_VIEW,
  TAB_KEY_GRID_VIEW,
  TAB_KEY_TABLE_VIEW,
  FILTER_COLUMN_MAP,
  COLUMN_DEFINITION_CONST_FILE_SIZE,
  COLUMN_DEFINITION_CONST_APERTURE,
  COLUMN_DEFINITION_CONST_ISO,
  COLUMN_DEFINITION_CONST_GPS_LATITUDE,
  COLUMN_DEFINITION_CONST_GPS_LONGITUDE,
  COLUMN_DEFINITION_TYPE_CUSTOM_COLUMN_DEFINITION,
  WARNING_CONDITION_TYPE_DOES_MATCH_KEY,
  COLUMN_DEFINITION_CONST_KEYWORDS_STRING,
  COLUMN_DEFINITION_TYPE_REMARKS_COLUMN_DEFINITION,
  FILENAME_FOR_EMPTY_COLUMNS_PRESET,
  COLUMN_DEFINITION_CONST_SOURCE_FILE,
  COLUMN_DEFINITION_CONST_IMAGE_PREVIEW,
  COLUMNS_FOR_TABLE_VIEW_KEY,
  COLUMNS_FOR_GRID_VIEW_KEY,
  COLUMNS_FOR_COMPARE_VIEW_KEY,
  COLUMNS_FOR_DETAIL_VIEW_KEY,
  COLUMNS_FOR_VIEWS_ARRAY,
  TAB_KEY_IMAGE_FOLDER_VIEW,
  baseColumnsMappingArray,
  COLUMNS_FOR_FOLDER_VIEW_KEY,
  defaultItemsPerPageGridView,
  DEFAULT_PRESETS_FOLDER,
  LOCAL_STORAGE_KEY_NAME_FOR_SAVING_COMMENTS,
  DATA_SOURCE_INITIAL,
  COLUMN_DEFINITION_CONST_KEYWORDS_CORRECTION,
  COLUMN_DEFINITION_CONST_FILENAME,
  COLUMNS_FOR_TABLE_VIEW_NAME_IN_FRONTEND,
  COLUMNS_FOR_DETAIL_VIEW_NAME_IN_FRONTEND,
  COLUMNS_FOR_FOLDER_VIEW_NAME_IN_FRONTEND,
  COLUMNS_FOR_GRID_VIEW_NAME_IN_FRONTEND,
  REMARKS_COLUMN_TAB_KEY_ADD,
  CUSTOM_COLUMN_TAB_KEY_ADD,
  TAB_KEY_COLUMN_CUSTOMIZER_SORT_FOR_TABLE_VIEW,
  TAB_KEY_COLUMN_CUSTOMIZER_SORT,
  DEFAULT_RENDER_CONFIG_OBJECT,
  RENDER_OBJECT_MAP_DEFAULT_KEY,
} from "./constants";
import { Footer } from "./Footer";
import { faChartBar, faCog, faGripHorizontal, faGripVertical, faImage, faInfo, faLightbulb, faQuestion, faTable, faThList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonGroup, ButtonToolbar, Col, Container, OverlayTrigger, Popover, Row, Tooltip } from "react-bootstrap";
import {
  convertDatabaseObjectIntoImageWithMetadataObject,
  getGetParametersFromArray,
  getUrlForGetFile,
  getUrlForPathExists,
  convertIhkhsjsonObjectIntoImageWithMetadataObject,
  numberWithSeparator,
  getResultsForDataFilterObjectArray,
  loadColumnDefinitionExchangeObjectFromJSONString,
  enrichImageWithMetadataObjectPartForCustomColumns,
  enrichImageWithMetadataObjectPartForRemarksColumns,
  getCookie,
  getCombinedColumnDefinitionArray,
} from "./Helpers";
import { ColumnDefinitionModal } from "./Modals/ColumnDefinitionModal";
import { DataAnalysisModal } from "./Modals/DataAnalysisModal";
import { MyPagination } from './MyPagination';
import { DataSourceChangeModal } from './Modals/DataSourceChangeModal';
import { DataFilterRow } from './DataFilterRow';
import { SplashScreenModal } from './Modals/SplashScreenModal';
import { HelpModal } from './Modals/HelpModal';
import { LoadingProgress } from './LoadingProgress';
import { ImageDetailView } from './ImageDetailView';
import { GridView } from './GridView';
import { KeywordsTableContainer } from './KeywordsTable/KeywordsTableContainer';

const App: FC = ({ }) => {
  //console.log('----------------------APP.tsx START---');

  // START load and init mermaid
  // const script = document.createElement("script");
  // script.src = '/mermaid/dist/mermaid.js';
  // script.async = true;
  // document.body.appendChild(script);
  // mermaid.initialize({startOnLoad:true});
  // END load and init mermaid


  const [additionalColumnsRepoCustomColumnsArray, setAdditionalColumnsRepoCustomColumnsArray] = useState([] as ExchangeCustomColumnDefinition[]);
  const [additionalColumnsRepoRemarkColumnsArray, setAdditionalColumnsRepoRemarkColumnsArray] = useState([] as ExchangeRemarksColumnDefinition[]);

  const [legalStringLinesArray, setLegalStringLinesArray] = useState([] as string[]);
  const [filesToSelectRequestWasSent, setFilesToSelectRequestWasSent] = useState(false);
  const [additionalColumnRepoRequestWasSent, setAdditionalColumnRepoRequestWasSent] = useState(false);

  // For usage with handleKeyDownFunction
  const requestPageNext = (currentPage: number) => {
    //setRequestPage(currentPage+1);
    let newMainPagination = Object.assign({}, mainPaginationObject);
    newMainPagination.requestPage = currentPage+1;
    setMainPaginationObject(newMainPagination);
  }

  // For usage with handleKeyDownFunction
  const requestPagePrev = (currentPage: number) => {
    //setRequestPage(currentPage-1);
    let newMainPagination = Object.assign({}, mainPaginationObject);
    newMainPagination.requestPage = currentPage-1;
    setMainPaginationObject(newMainPagination);
  }

  // @ts-ignore
  const handleKeyDownFunction = (e) => {
    if (e.key === 'ArrowRight') {
      if (mainPaginationObject.requestPage<mainPaginationObject.maxPage) {
        requestPageNext(mainPaginationObject.requestPage);
      }
    } else if (e.key === 'ArrowLeft') {
      if (mainPaginationObject.requestPage>1) {
        requestPagePrev(mainPaginationObject.requestPage);
      }
    }
  }

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyDownFunction);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyDownFunction);
    };
  }, [handleKeyDownFunction]);

  // TODO: Activate this again - or move to useEffect that is called on startup
  // let additionalColumnsConfigsFromLocalStorageInitialValue: AdditionalColumnsConfig[] = [];
  // if (localStorage.getItem(LOCAL_STORAGE_KEY_ADDITIONAL_COLUMNS_CONFIGS)) {
  //   // @ts-ignore
  //   additionalColumnsConfigsFromLocalStorageInitialValue = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_ADDITIONAL_COLUMNS_CONFIGS));
  // }

  const [selectedDBImport, setSelectedDBImport] = useState(null as null|DB_Import);
  const [selectedDBSystem, setSelectedDBSystem] = useState(null as null|DB_System);

  const [imagesWithMetadataNeedsNewEnrichment, setImagesWithMetadataNeedsNewEnrichment] = useState(false);

  const [folderGridViewImgFilenamePrefix, setFolderGridViewImgFilenamePrefix] = useState('' as string);

  const [additionalColumnsConfigFromLocalStorage, setAdditionalColumnsConfigFromLocalStorage] = useState([] as AdditionalColumnsConfig[]);
  //const [additionalColumnsConfigFromLocalStorage, setAdditionalColumnsConfigFromLocalStorage] = useState(additionalColumnsConfigsFromLocalStorageInitialValue as AdditionalColumnsConfig[]);

  const [setSettingComputerNameWarningWasShown, setSetSettingComputerNameWarningWasShown] = useState(false);

  const settingComputerName = localStorage.getItem(LOCAL_STORAGE_KEY_SETTING_COMPUTER_NAME);

  const settingNameForSavingCommentsInitialValue = localStorage.getItem(LOCAL_STORAGE_KEY_NAME_FOR_SAVING_COMMENTS);

  const [customStylesString, setCustomStylesString] = useState('');

  const [settingNameForSavingComments, setSettingNameForSavingComments] = useState(settingNameForSavingCommentsInitialValue as string);

  const [activeRemarksColumnTabKey, setActiveRemarksColumnTabKey] = useState(REMARKS_COLUMN_TAB_KEY_ADD);
  const [activeCustomColumnTabKey, setActiveCustomColumnTabKey] = useState(CUSTOM_COLUMN_TAB_KEY_ADD);
  
  // Disabled for now
//  if (!setSettingComputerNameWarningWasShown && !settingComputerName) {
//    alert('Bitte Computernamen in den Einstellungen setzen.');
//    setSetSettingComputerNameWarningWasShown(true);
//  }

  let settingDatabaseHost = localStorage.getItem(LOCAL_STORAGE_KEY_SETTING_DATABASE_HOST);
  if (!settingDatabaseHost) {
    settingDatabaseHost = DEFAULT_DATABASE_HOST;
  }

  let settingNodeServerHost = localStorage.getItem(LOCAL_STORAGE_KEY_SETTING_NODE_SERVER_HOST);
  if (!settingNodeServerHost) {
    settingNodeServerHost = DEFAULT_NODE_SERVER_HOST;
  }

  const [settings, setSettings] = useState({
    computerName: settingComputerName,
    databaseHost: settingDatabaseHost,
    nodeServerHost: settingNodeServerHost,
  } as Settings);

  const [renderObjectsMap, setRenderObjectsMap] = useState({[RENDER_OBJECT_MAP_DEFAULT_KEY]: DEFAULT_RENDER_CONFIG_OBJECT });

  const [slideshowIntervalVariable, setSlideshowIntervalVariable] = useState(null as null|any);

  const [relatedColumnsArray, setRelatedColumnsArray] = useState([] as ColumnDefinition[]);

  const [presetDataFilesArray, setPresetDataFilesArray] = useState([] as DataFilePresetFile[]);
  const [presetColumnFilesArray, setPresetColumnFilesArray] = useState([] as DataFilePresetFile[]);

  // For connection tests
  const [databaseTestTimestamp, setDatabaseTestTimestamp] = useState(0 as number);
  const [nodeServerTestTimestamp, setNodeServerTestTimestamp] = useState(0 as number);
  const [connectedToDatabase, setConnectedToDatabase] = useState(false);
  const [connectedToNodeServer, setConnectedToNodeServer] = useState(null as (boolean | null)); // true/false/null


  const [importsArray, setImportsArray] = useState([] as DB_Import[]);
  const [systemsArray, setSystemsArray] = useState([] as DB_System[]);
  const [importIsForbidden, setImportIsForbidden] = useState(true);
  
  const [isKeywordsTableFloatingHeadExtended, setIsKeywordsTableFloatingHeadExtended] = useState(true as boolean);

  const [csrfToken, setCsrfToken] = useState('');

  const [checkForKeywordCorrections, setCheckForKeywordCorrections] = useState(false);
  const [doCalculateCheckNumberForLoknummer, setDoCalculateCheckNumberForLoknummer] = useState(false);


  const [databaseHost, setDatabaseHost] = useState(settings.databaseHost);
  const [nodeServerHost, setNodeServerHost] = useState(settings.nodeServerHost);

  const [loadingObject, setLoadingObject] = useState({ isLoading: true, text: 'Anwendung startet', progress: 5 } as LoadingObject);
  //const [finishedLoadingDataFromWindowLocationSearch, setFinishedLoadingDataFromWindowLocationSearch] = useState(false);

  const [settingsCollapseOpen, setSettingsCollapseOpen] = useState(true);
  const [dataSource, setDataSource] = useState(DATA_SOURCE_INITIAL);
  const [dataSourceOnlyFilesystemAndJSONFilename, setDataSourceOnlyFilesystemAndJSONFilename] = useState('');

  const [selectedSourceFileValues, setSelectedSourceFileValues] = useState([] as string[]);

  const [needsIFrameForCSRFToken, setNeedsIFrameForCSRFToken] = useState(false);

  // MAIN-DATA-SOURCE

  const [imageWithMetadataOriginalArray, setImageWithMetadataOriginalArray] = useState([] as ImageWithMetadataObject[]);
  // Original json file is parsed and enriched and written to this array
  // This process should only be done once - after that the array is just read
  const [imageWithEnrichedMetadataObjectsArray_All, setImageWithEnrichedMetadataObjectsArray_All] = useState([] as ImageWithEnrichedMetadataObject[]);
  // State that is the base for the pagination - this list 
  const [imageWithEnrichedMetadataObjectsArray_Filtered, setImageWithEnrichedMetadataObjectsArray_Filtered] = useState([] as ImageWithEnrichedMetadataObject[]);
  // Current page of imageWithEnrichedMetadataObjectsArray_Filtered
  const [imageWithEnrichedMetadataObjectsArray_Page, setImageWithEnrichedMetadataObjectsArray_Page] = useState([] as ImageWithEnrichedMetadataObject[]);
  const [showDataAnalysisModal, setShowDataAnalysisModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [loadDataFromUrlParameters, setLoadDataFromUrlParameters] = useState(false);

  const [ignorePathForfilePathReplacement, setIgnorePathForfilePathReplacement] = useState(false);
  const [filePathReplace1String, setFilePathReplace1String] = useState('');
  const [filePathReplace1StringExistsOnFileSystem, setFilePathReplace1StringExistsOnFileSystem] = useState(false);
  const [filePathReplace2String, setFilePathReplace2String] = useState('');
  const [filePathReplace2StringExistsOnFileSystem, setFilePathReplace2StringExistsOnFileSystem] = useState(false);

  // DataOptions
  const [showPreviewImages, setShowPreviewImages] = useState(false);
  const [useImgSrcReplacement, setUseImgSrcReplacement] = useState(false);
  const [useImgExtReplacement, setUseImgExtReplacement] = useState(false);

  const [useFrontendPagination, setUseFrontendPagination] = useState(true);
  const [editPossible, setEditPossible] = useState(false);
  const [pathNameInputField, setPathNameInputField] = useState('');
  const [pathNameInputFieldExistsOnFilesystem, setPathNameInputFieldExistsOnFilesystem] = useState(false);
  const [mainPaginationObject, setMainPaginationObject] = useState({requestPage: 1, maxPage: 1, itemsPerPage: defaultItemsPerPage, imagesPerRow: 3, totalImages: 0} as PaginationObject)

  const [keywordsLoadFromPathname, setKeywordsLoadFromPathname] = useState('');
  const [filtersMap, setFiltersMap] = useState({});
  const handleCloseColumnDefinitionModal = () => setShowColumnDefinitionModal(false);
  const handleShowColumnDefinitionModal = () => setShowColumnDefinitionModal(true);
  const handleCloseDataSourceSelectModal = () => setShowDataSourceSelectModal(false);
  const handleCloseSplashScreenModal = () => setShowSplashScreenModal(false);
  const handleCloseGridViewModal = () => setShowGridViewModal(false);

  const [keywordCorrectionsMap, setKeywordCorrectionsMap] = useState({});
  const [keywordCorrectionsArray, setKeywordCorrectionsArray] = useState([] as DB_KeywordCorrection[]);


  const [activeViewTabKey, setActiveViewTabKey] = useState(TAB_KEY_TABLE_VIEW);
  const [showColumnDefinitionModal, setShowColumnDefinitionModal] = useState(false);
  const [showGridViewModal, setShowGridViewModal] = useState(false);

  const [showSplashScreenModal, setShowSplashScreenModal] = useState(false);
  const [showDataSourceSelectModal, setShowDataSourceSelectModal] = useState(false);
  const [showDataSourceSelectButton, setShowDataSourceSelectButton] = useState(false);
  const [showImageDetailViewModal, setShowImageDetailViewModal] = useState(false);
  const [showImageCompareViewModal, setShowImageCompareViewModal] = useState(false);

  const handleShowDataSourceSelectModal = () => setShowDataSourceSelectModal(true);

  const handleShowHelpModal = () => setShowHelpModal(true);
  const handleCloseHelpModal = () => setShowHelpModal(false);

  const handleShowDataAnalysisModal = () => setShowDataAnalysisModal(true);
  const handleCloseDataAnalysisModal = () => setShowDataAnalysisModal(false);

  // // TODO: Improve this logic to get the index of things in "columnDefinitionArray"
  // //let columnDefinitionIdxIHKHSHASH = -1;
  // let columnDefinitionIdx_KEYWORD_Y_DATE = -1;
  // let columnDefinitionIdx_FILE_EXT = -1;
  // let columnDefinitionIdx_KEYWORDS = -1;

  // // Basic Columns
  const [columnDefinitionArray, setColumnDefinitionArray] = useState(initialBaseColumnDefinitionArray);
  const [darkMode, setDarkMode] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches);
  const [fileExtReplaceString, setFileExtReplaceString] = useState('');

  let refForMainContainer = React.useRef();
  
  // Custom Columns
  const [customColumnsArray, setCustomColumnsArray] = useState([] as CustomColumnDefinition[]);

  const initialColumnsForViewArray = {
    [COLUMNS_FOR_TABLE_VIEW_KEY]: [] as ColumnDefinition[],
    [COLUMNS_FOR_GRID_VIEW_KEY]: [] as ColumnDefinition[],
    [COLUMNS_FOR_COMPARE_VIEW_KEY]: [] as ColumnDefinition[],
    [COLUMNS_FOR_DETAIL_VIEW_KEY]: [] as ColumnDefinition[],
    [COLUMNS_FOR_FOLDER_VIEW_KEY]: [] as ColumnDefinition[],
  } as ColumnsForViewObject;

  const [columnsForViewArray, setColumnsForViewArray] = useState(initialColumnsForViewArray);
  
  //const [activeTabKeyColumnDefinitionModal, setActiveTabKeyColumnDefinitionModal] = useState(COLUMNS_FOR_TABLE_VIEW_KEY);
  const [activeTabKeyColumnDefinitionModal, setActiveTabKeyColumnDefinitionModal] = useState(TAB_KEY_COLUMN_CUSTOMIZER_SORT);
  const [activeTabKeyColumnDefinitionModal2, setActiveTabKeyColumnDefinitionModal2] = useState(TAB_KEY_COLUMN_CUSTOMIZER_SORT_FOR_TABLE_VIEW);
  
  const setStateVariablesForAdditionalColumnsConfig = (config: AdditionalColumnsConfig, callbackFnc?: any) => {
    debugger;
    increasePercentageOfLoadingObject(5, 'Bereite Zusatzspalten vor');
    console.log('in setStateVariablesForAdditionalColumnsConfig1');

    if (
    //config.columnDefinitionExchangeObject['renderConfigObjectArray'] &&
    config.columnDefinitionExchangeObject['baseColumnsArray'] &&
    config.columnDefinitionExchangeObject['customColumnsArray'] &&
    config.columnDefinitionExchangeObject['remarksColumnsArray'] &&
    config.columnDefinitionExchangeObject['columnsForViewArray']) {
      console.log('in setStateVariablesForAdditionalColumnsConfig2');
     
      if (config.columnDefinitionExchangeObject['renderConfigObjectArray']) {
        const renderConfigObjectArray : RenderConfigObject[] = config.columnDefinitionExchangeObject['renderConfigObjectArray'];
        let tmpRenderObjectsMap = {};
        for (let i=0; i<renderConfigObjectArray.length; i++) {
          tmpRenderObjectsMap[renderConfigObjectArray[i].constant] = renderConfigObjectArray[i];
        }
        // @ts-ignore
        setRenderObjectsMap(tmpRenderObjectsMap);
      }

      const baseColumnsFromACC : ColumnDefinition[] = config.columnDefinitionExchangeObject['baseColumnsArray'];
      const customColumnsFromACC : CustomColumnDefinition[] = config.columnDefinitionExchangeObject['customColumnsArray'] as CustomColumnDefinition[];
      const remarksColumnsFromACC : RemarksColumnDefinition[] = config.columnDefinitionExchangeObject['remarksColumnsArray'] as RemarksColumnDefinition[];

      let tmpColumnsMap = {};
      let combinedColumnDefinitionArray = getCombinedColumnDefinitionArray(customColumnsFromACC, remarksColumnsFromACC);
      for (let i=0; i<combinedColumnDefinitionArray.length; i++) {
        const currentCd : ColumnDefinition = combinedColumnDefinitionArray[i];
        tmpColumnsMap[currentCd.columnDefinitionConstant] = currentCd;
      }

      setCustomColumnsArray(customColumnsFromACC);
      console.log(customColumnsFromACC.length + ' columns were set in setRemarksColumnsArray');

      setRemarksColumnsArray(remarksColumnsFromACC);
      console.log(remarksColumnsFromACC.length + ' columns were set in setRemarksColumnsArray');

      // Pass values for "active", "sortPosition" and "width" to existing columns
      for (let i=0; i<baseColumnsFromACC.length; i++) {
          let cd: ColumnDefinition = baseColumnsFromACC[i];
          let existingBaseColumn = initialBaseColumnDefinitionArray.find(e => e.columnDefinitionConstant === cd.columnDefinitionConstant);
          if (existingBaseColumn) {
              existingBaseColumn.active = cd.active;
              existingBaseColumn.sortPosition = cd.sortPosition;
              existingBaseColumn.width = cd.width;
          }
      }

      increasePercentageOfLoadingObject(5);
      setTimeout(() => {
        let newColumnsForViewArray = {
          [COLUMNS_FOR_TABLE_VIEW_KEY]: [] as ColumnDefinition[],
          [COLUMNS_FOR_GRID_VIEW_KEY]: [] as ColumnDefinition[],
          [COLUMNS_FOR_COMPARE_VIEW_KEY]: [] as ColumnDefinition[],
          [COLUMNS_FOR_DETAIL_VIEW_KEY]: [] as ColumnDefinition[],
          [COLUMNS_FOR_FOLDER_VIEW_KEY]: [] as ColumnDefinition[],
        } as ColumnsForViewObject;

        COLUMNS_FOR_VIEWS_ARRAY.map((columnForViewKey: string) => {
          if (typeof config.columnDefinitionExchangeObject['columnsForViewArray'][columnForViewKey] === 'undefined') {
            config.columnDefinitionExchangeObject['columnsForViewArray'][columnForViewKey] = Object.assign([], combinedColumnDefinitionArray);
          } else {
            config.columnDefinitionExchangeObject['columnsForViewArray'][columnForViewKey].map((cdImportExport: ColumnForViewImportExportDefinition, cdImportExportIndex: number) => {
              if (typeof newColumnsForViewArray === 'undefined') {
                debugger
              }

              if (typeof newColumnsForViewArray[columnForViewKey] === 'undefined') {
                debugger
              }

              let tmpColumn : ColumnDefinition = Object.assign({}, tmpColumnsMap[cdImportExport.columnDefinitionConstant]);
              tmpColumn.active = cdImportExport.active;
              tmpColumn.renderConfigsMapKey = cdImportExport.renderConfigsMapKey ? cdImportExport.renderConfigsMapKey : RENDER_OBJECT_MAP_DEFAULT_KEY;
              tmpColumn.width = cdImportExport.width;
              newColumnsForViewArray[columnForViewKey].push(tmpColumn);
            });
          }
        });
        increasePercentageOfLoadingObject(5);
        setColumnsForViewArray(newColumnsForViewArray);
        setImagesWithMetadataNeedsNewEnrichment(true);
        increasePercentageOfLoadingObject(10);
        setTimeout(() => {
          if (callbackFnc) {
            console.log('in setStateVariablesForAdditionalColumnsConfig --- calling callbackFnc');
            callbackFnc();
          }
        }, 2000);
      }, 2000);

      if (dataSource===DATA_SOURCE_DATABASE) {
        setLoadingCompleteForLoadingObject();
      }
    } else {
        alert('Spalten konnten nicht geladen werden - Konfiguration ungültig');
    }
  }

  const combineArraysToCombinedColumnDefinitionsArray = function(localCustomColumnsArray: CustomColumnDefinition[], localRemarksColumnsArray : RemarksColumnDefinition[]): ColumnDefinition[] {  
    
    debugger;

    console.log("in useEffect - columnDefitionsArray or customColumnsArray has changed");
    const newCombinedDefinitionsArray : ColumnDefinition[] = Object.assign([], columnDefinitionArray);

    localCustomColumnsArray.map((customColumnObject: CustomColumnDefinition) => {
      newCombinedDefinitionsArray.push(customColumnObject as ColumnDefinition);
    });
  
    localRemarksColumnsArray.map((remarksColumnObject: RemarksColumnDefinition) => {
      newCombinedDefinitionsArray.push(remarksColumnObject as ColumnDefinition);
    });
   
    return newCombinedDefinitionsArray;
  }

  // Basic Columns + Custom Columns + Custom Column by Keywords, sorted by the "sort"-value
  //const [combinedColumnDefinitionArray, setCombinedColumnDefinitionArray] = useState(combineArraysToCombinedColumnDefinitionsArray() as ColumnDefinition[]);
  const [combinedColumnDefinitionArray, setCombinedColumnDefinitionArray] = useState([] as ColumnDefinition[]);

  let initialDataFilterArray : DataFilterObject[] = [{
    column: columnDefinitionArray.find(cd => cd.columnDefinitionConstant === COLUMN_DEFINITION_CONST_KEYWORDS),
    conditionType: WARNING_CONDITION_TYPE_DOES_MATCH_KEY,
    searchValue: '',
}] as DataFilterObject[];

const updateKeywordCorrectionsFromDatabase = () => {
  fetch(`${databaseHost}/imagedb/keyword-correction/?format=json`)
  .then(response => {
      return response.json();
  })
  .then(response => {
    if (Boolean(response)) { // json filePath is absolute
      let tmpKeywordCorrectionsArray = response.results;

      setKeywordCorrectionsArray(tmpKeywordCorrectionsArray);

      let tmpKeywordCorrectionsMap = {};

      for (let i=0; i<tmpKeywordCorrectionsArray.length; i++) {
        tmpKeywordCorrectionsMap[tmpKeywordCorrectionsArray[i].filename.toString()] = tmpKeywordCorrectionsArray[i].keywords;
      }
      setKeywordCorrectionsMap(tmpKeywordCorrectionsMap);
      setImagesWithMetadataNeedsNewEnrichment(true);
    }
  })
}

const updateSystemsFromDatabase = () => {
  fetch(`${databaseHost}/imagedb/system/?format=json`)
  .then(response => {
      if (response.status !== 403) { // Forbidden
        setImportIsForbidden(false);
      } else if (importIsForbidden===false) {
        setImportIsForbidden(true);        
      }
      return response.json();
  })
  .then(response => {
    if (Boolean(response)) { // json filePath is absolute
      setSystemsArray(response.results);
    }
  })
}

const updateImportsFromDatabase = () => {
  fetch(`${databaseHost}/imagedb/import/?format=json`)
  .then(response => response.json())
  .then(response => {
    if (Boolean(response)) { // json filePath is absolute
      setImportsArray(response.results);
    }
  })
}

const [dataFilterObjectArray, setDataFilterObjectArray] = useState(initialDataFilterArray);

const [remarksColumnsArray, setRemarksColumnsArray] = useState([] as RemarksColumnDefinition[]);

// Re-Write combinedColumnDefinitionArray to trigger setting the content of combinedColumnDefinitionArray -> columnsForViewArray
//setCombinedColumnDefinitionArray(Object.assign([], combinedColumnDefinitionArray));

const [ihkhsjsonHistoryItems, setIhkhsjsonHistoryItems] = useState(
  // @ts-ignore
  localStorage.getItem(LOCAL_STORAGE_KEY_IHKHSJSON_HISTORY) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_IHKHSJSON_HISTORY)) : []
);

// Creates CSS-Styles for user defined RenderConfigObjects
useEffect(() => {
  let tmpCustomStylesStringArray : string[] = [];
  Object.keys(renderObjectsMap).map((renderObjectKey: string) => {
    const tmpRenderConfigObject: RenderConfigObject = renderObjectsMap[renderObjectKey];

    // TODO: Improve logic for grid view
    tmpCustomStylesStringArray.push(`.container-grid-view .${tmpRenderConfigObject.constant}{`);
    tmpCustomStylesStringArray.push(`font-size: 18pt;`);
    tmpCustomStylesStringArray.push(`}`);


    tmpCustomStylesStringArray.push(`.${tmpRenderConfigObject.constant}{`);
    tmpCustomStylesStringArray.push(`color:${tmpRenderConfigObject.colorText};`);
    tmpCustomStylesStringArray.push(`font-weight:${tmpRenderConfigObject.bold ? 'bold' : 'normal'};`);
    if (tmpRenderConfigObject.italic) {
      tmpCustomStylesStringArray.push('font-style:italic;');
    }
    if (tmpRenderConfigObject.colorBackground) {
      tmpCustomStylesStringArray.push(`background-color:${tmpRenderConfigObject.colorBackground};`);
    }
    tmpCustomStylesStringArray.push('}');
    if (tmpRenderConfigObject.colorElement) {
      tmpCustomStylesStringArray.push(`.${tmpRenderConfigObject.constant} .badge{`);
      tmpCustomStylesStringArray.push(`background-color:${tmpRenderConfigObject.colorElement};`);
      tmpCustomStylesStringArray.push(`font-weight:${tmpRenderConfigObject.bold ? 'bold' : 'normal'};`);
      tmpCustomStylesStringArray.push(`}`);
    }
  });
  setCustomStylesString(tmpCustomStylesStringArray.join(''));
}, [renderObjectsMap]);


  // UseEffect without condition - load only once on startup
  useEffect(() => {
    // Keep this in UseEffect - This should only be used on startup
    const fetchLoadjsonFunction = (loadjson: string, callbackFnc: any) => {
    //const fetchLoadjsonFunction = (loadjson: string, callbackFnc: any) => {
      if (Boolean(loadjson.match(/[\/\\]/)?.length)) {
        increasePercentageOfLoadingObject(5, 'Lade Datendatei');
        // jsonFileToLoad contains / or \
        // -> This means file path is expected to be an absolute path - example:
        // http://localhost:3000/?loadjson=/Users/michaelsteiner/Downloads/2021-11-07DESKTOP-43QBO2C.ihkhsjson
        if (connectedToNodeServer === false) {
          alert('Fehler! Kann Datei mit absolutem Pfad nicht laden. Der Node-Server ist nicht verfügbar.');
        } else {
          console.log('Trying to load "' + loadjson + '" via node-server');
          fetch(getUrlForPathExists({ pathName: loadjson }))
            .then(response => response.json())
            .then(response => {
              if (Boolean(response)) { // json filePath is absolute
                // @ts-ignore
                loadDataFromJSONFromFilesystem(loadjson);
              }
            })
        }
      } else {
        increasePercentageOfLoadingObject(5, 'Lade Datendatei');
        loadIHKHSJSONFromPublicFolderByFilename(loadjson, callbackFnc);
      }
    }

    const callFetchLoadFunctionWithCallback = (loadjson:string) => {
      // @ts-ignore
      fetchLoadjsonFunction(loadjson, () => {
        setLoadingCompleteForLoadingObject();
        setShowSplashScreenModal(false);
      });
    }

    // Test once
    testNodeServerConnection();

    const csfrCookie = getCookie('csrftoken');
    if (csfrCookie) {
      setCsrfToken(csfrCookie);
    } else {
        setNeedsIFrameForCSRFToken(true);
    }

    if (imageWithEnrichedMetadataObjectsArray_All.length===0) {
      increasePercentageOfLoadingObject(5);
      const windowLocationSearchAsArray = (window.location.search.length>=1) ? window.location.search.substring(1).split('&') : [];
      if (windowLocationSearchAsArray.length) {
        let urlParametersObject = {} as UrlParametersObject;

        setLoadDataFromUrlParameters(true);
        windowLocationSearchAsArray.forEach(element => {
          if (element.match(/^usedatabase=1/)) {
            urlParametersObject.usedatabase=1;
          }

          if (element.match(/^loadjson=/)) {
            urlParametersObject.loadjson=element.substring(9);
          }

          if (element.match(/^loadcustomcolumns=/)) {
            urlParametersObject.loadcustomcolumns=element.substring(18);
          }

          if (element.match(/^frp1=/)) {
            urlParametersObject.frp1=element.substring(5);
            if (filePathReplace1String == '') {
              setFilePathReplace1String(decodeURIComponent(urlParametersObject.frp1));
            }
          }

          if (element.match(/^frp2=/)) {
            urlParametersObject.frp2=element.substring(5);
            setFilePathReplace2String(element.substring(5));
            if (filePathReplace2String == '') {
              setFilePathReplace2String(decodeURIComponent(urlParametersObject.frp2));
            }
          }

          if (element.match(/^ignorePathForfilePathReplacement=1/)) {
            setIgnorePathForfilePathReplacement(true);
          }

          if (element.match(/^fileExtReplaceString=/)) {
            
            setUseImgExtReplacement(true);
            setFileExtReplaceString(element.substring(21));
          }
        });

        //console.log('calling setUrlParametersObject');
        //console.log(tmpUrlParametersObject);
        //setUrlParametersObject(tmpUrlParametersObject);

        if (((urlParametersObject.frp1 && urlParametersObject.frp1.length) || (urlParametersObject.frp2 && urlParametersObject.frp2.length)) && !useImgSrcReplacement) {
          setShowPreviewImages(true);
          setUseImgSrcReplacement(true);
        }

        const logicForDatabase = () => {
          getDataFromDatabase();
          handleCloseSplashScreenModal();
        }

        if (urlParametersObject.usedatabase === 1) {
          setDataSource(DATA_SOURCE_DATABASE);
          increasePercentageOfLoadingObject(20, 'Vorbeitungen für die Datenbank');
          
          if (urlParametersObject.loadcustomcolumns) {
            loadPredefinedColumnsFile(urlParametersObject.loadcustomcolumns, logicForDatabase);                
          } else {
            logicForDatabase();
          }
        } else if (urlParametersObject.loadjson) {
          setDataSource(DATA_SOURCE_JSON);
          if (urlParametersObject.loadcustomcolumns) {
            loadPredefinedColumnsFile(
              urlParametersObject.loadcustomcolumns, () => {
                  // @ts-ignore
                  //fetchLoadjsonFunction(urlParametersObject.loadjson);

                    callFetchLoadFunctionWithCallback(urlParametersObject.loadjson);
                    setImagesWithMetadataNeedsNewEnrichment(true);
                  //setLoadingCompleteForLoadingObject();
                  console.log('after callback in callback');
                });
              } else {
                callFetchLoadFunctionWithCallback(urlParametersObject.loadjson);
              }
        }
      } else {
        setShowSplashScreenModal(true);
        // App was started without urlParameters
        increasePercentageOfLoadingObject(30, 'Daten für die Dateiauswahl wird geladen');
      }

      // Load __filesToSelect.json
      if (!filesToSelectRequestWasSent) {
        increasePercentageOfLoadingObject(5);
        const filesToSelectFileName = '__filesToSelect_.json';
        console.log(`%c sending request for :${filesToSelectFileName}`, 'background: #000; color: #fff');
        console.log('-- sending request for ' + {filesToSelectFileName});
        setFilesToSelectRequestWasSent(true);
        fetch(`/${filesToSelectFileName}`).then(response => {
          setFilesToSelectRequestWasSent(true);
          return response.json();
        }).then(fileToSelect => {
          if (fileToSelect['userDefinedDataFilePresetFilesArray']) {
            setPresetDataFilesArray(fileToSelect['userDefinedDataFilePresetFilesArray']);
          }
          if (fileToSelect['userDefinedColumnPresetFilesArray']) {
            setPresetColumnFilesArray(fileToSelect['userDefinedColumnPresetFilesArray']);
          }
          debugger;
          if (fileToSelect['legalStringLinesArray']) {
            setLegalStringLinesArray(fileToSelect['legalStringLinesArray']);
          }
          // Hide loadingIndicator when app was started without UrlParameters
          if (!windowLocationSearchAsArray.length) {
            setLoadingCompleteForLoadingObject();
          }
        });
      }

      // Load __additionalColumnRepo.json
      if (!additionalColumnRepoRequestWasSent) {
        increasePercentageOfLoadingObject(5);
        const additionalColumnRepoFileName = '__additionalColumnRepo.json';
        console.log(`%c sending request for :${additionalColumnRepoFileName}`, 'background: #000; color: #fff');
        console.log('-- sending request for ' + {additionalColumnRepoFileName});
        setFilesToSelectRequestWasSent(true);
        fetch(`/${additionalColumnRepoFileName}`).then(response => {
          setAdditionalColumnRepoRequestWasSent(true);
          return response.json();
        }).then(additonalColumnsArray => {
          if (additonalColumnsArray['customColumnsArray'] && additonalColumnsArray['remarksColumnsArray']) {
            setAdditionalColumnsRepoCustomColumnsArray(additonalColumnsArray['customColumnsArray']);
            setAdditionalColumnsRepoRemarkColumnsArray(additonalColumnsArray['remarksColumnsArray']);
          }
        });
      }
    }
    
  }, []); //End of UseEffect

  useEffect(() => {
    console.log('in UseEffect for [columnDefinitionArray, customColumnsArray, remarksColumnsArray]');
    if (showColumnDefinitionModal) {
      debugger;
      setCombinedColumnDefinitionArray(
        combineArraysToCombinedColumnDefinitionsArray(customColumnsArray, remarksColumnsArray)
      );
      setImagesWithMetadataNeedsNewEnrichment(true);
    }
    
  }, [columnDefinitionArray, customColumnsArray, remarksColumnsArray]);

  useEffect(() => {
    console.log('in useEffect for [imagesWithMetadataNeedsNewEnrichment, imageWithMetadataOriginalArray]);');
    if (dataSource===DATA_SOURCE_DATABASE || (imagesWithMetadataNeedsNewEnrichment===true && imageWithMetadataOriginalArray.length)) {
      console.log('[in if] in useEffect for [imagesWithMetadataNeedsNewEnrichment, imageWithMetadataOriginalArray]);');
      const tmpEnriched = enrichImageWithMetadataObjectArray(imageWithMetadataOriginalArray);
      setImageWithEnrichedMetadataObjectsArray_All(tmpEnriched);
      setImageWithEnrichedMetadataObjectsArray_Filtered(tmpEnriched);
      setImagesWithMetadataNeedsNewEnrichment(false);
    }
  }, [imagesWithMetadataNeedsNewEnrichment, imageWithMetadataOriginalArray]);

  useEffect(() => {
    if (additionalColumnsConfigFromLocalStorage['combinedColumnDefinitionArray']) {
      additionalColumnsConfigFromLocalStorage['customColumnDefinitionArray'] = 
        additionalColumnsConfigFromLocalStorage['combinedColumnDefinitionArray'].filter((e: ColumnDefinition) => e.columnDefinitionType === COLUMN_DEFINITION_TYPE_CUSTOM_COLUMN_DEFINITION)

      additionalColumnsConfigFromLocalStorage['remarksColumnDefinitionArray'] =
        additionalColumnsConfigFromLocalStorage['combinedColumnDefinitionArray'].filter((e: ColumnDefinition) => e.columnDefinitionType === COLUMN_DEFINITION_TYPE_REMARKS_COLUMN_DEFINITION)
    }
  // TODO Find baseColumns and copy sort and width values
  }, [additionalColumnsConfigFromLocalStorage])
  
  useEffect(() => {
    testDatabaseConnection();
  }, [databaseHost]);

  useEffect(() => {
    if (settingNameForSavingComments) {
      localStorage.setItem(LOCAL_STORAGE_KEY_NAME_FOR_SAVING_COMMENTS, settingNameForSavingComments);
    }
  }, [settingNameForSavingComments]);

  useEffect(() => {
    if (connectedToDatabase &&
      (dataSource === DATA_SOURCE_DATABASE) &&
      (imageWithEnrichedMetadataObjectsArray_Page.length === 0)
    ) {
      getDataFromDatabase();
    }
  }, [dataSource]);

  useEffect(() => {
    if (connectedToDatabase) {
      updateImportsFromDatabase();
      updateSystemsFromDatabase();
      updateKeywordCorrectionsFromDatabase();

      for (let i=0; i<combinedColumnDefinitionArray.length; i++) {
        if (typeof combinedColumnDefinitionArray[i] === 'undefined') {
          debugger;
        }

        if (typeof combinedColumnDefinitionArray[i].columnDefinitionConstant === 'undefined') {
          debugger;
        }

        if (combinedColumnDefinitionArray[i].columnDefinitionConstant === COLUMN_DEFINITION_CONST_KEYWORDS_CORRECTION) {
          combinedColumnDefinitionArray[i].active = true;
        }
      }
    }
  }, [connectedToDatabase])

  useEffect(() => {
    if (dataSource === DATA_SOURCE_DATABASE) {
      setLoadingCompleteForLoadingObject();
    } else if (dataSource === DATA_SOURCE_JSON) {
      const newMainPagination = Object.assign({}, mainPaginationObject);
      newMainPagination.requestPage = 1;
      newMainPagination.totalImages = imageWithEnrichedMetadataObjectsArray_Filtered.length;
      debugger;
      setMainPaginationObject(newMainPagination);
      loadNextPageForFrontendPagination();
    } 
  }, [imageWithEnrichedMetadataObjectsArray_Filtered]);

  useEffect(() => {
    console.log('in useEffect for nodeServerHost');
    console.log('new value: ', nodeServerHost);
    testNodeServerConnection();
  }, [nodeServerHost]);

  useEffect(() => {
    if (slideshowIntervalVariable && (mainPaginationObject.maxPage<=mainPaginationObject.requestPage)) {
      setSlideshowIntervalVariable(clearInterval(slideshowIntervalVariable));
    } 

    if (dataSource === DATA_SOURCE_DATABASE) {
      getDataFromDatabase();
    } else {
      loadNextPageForFrontendPagination();
    }

    //let a = mainPaginationObject.totalImages;
    //console.log("checking mainPaginationObject");

    const newMaxPage = Math.ceil(mainPaginationObject.totalImages / mainPaginationObject.itemsPerPage);
    //console.log("newMaxPage: " + newMaxPage);
    
    //let a = mainPaginationObject.totalImages;
    //let b = mainPaginationObject.itemsPerPage;
    //let c1 = mainPaginationObject.maxPage;
    //let c2 = newMaxPage;
    //debugger;
 
    if (newMaxPage !== mainPaginationObject.maxPage) {
      let newMainPaginationObject = Object.assign({}, mainPaginationObject);
      newMainPaginationObject.maxPage = newMaxPage;
      debugger;
      setMainPaginationObject(newMainPaginationObject);
    }

          //   console.log("checking mainPaginationObject");
          //   if (mainPaginationObject.maxPage !== newMaxPage) {

          //       console.log("calling setMainPaginationObject");
          //       console.log("newMaxPage: " + newMaxPage);
          //       console.log("mainPaginationObject.maxPage: " + mainPaginationObject.maxPage);
              
          //     // Activating this causes and endless loop causing images-url is called endlessly
          //     let newMainPagination = Object.assign({}, mainPaginationObject);
          //     newMainPagination.requestPage = mainPaginationObject.requestPage;
          //     newMainPagination.itemsPerPage = mainPaginationObject.itemsPerPage;
          //     newMainPagination.maxPage = Math.ceil(totalImages / mainPaginationObject.itemsPerPage);;
          //     // {requestPage: 1, maxPage: 1, itemsPerPage: defaultItemsPerPage, imagesPerRow: 3}
          //     setMainPaginationObject(newMainPagination);
          //   }

    switch (activeViewTabKey) {
      case TAB_KEY_TABLE_VIEW:        
         if (refForMainContainer.current) {
           // @ts-ignore
           refForMainContainer.current.scrollTo({
             top: 0,
             behavior: 'smooth' // auto
           });
        }
        break;
      case TAB_KEY_GRID_VIEW:
        if (refForMainContainer) {
          // @ts-ignore
          refForMainContainer.current.scrollTo({
            top: 0,
            behavior: 'smooth' // auto
          });
        }
        break;
    }
  }, [mainPaginationObject]);

  const setRequestPageWithLoading = (requestPage: number, skipShowLoading=false) => {
    //setIsLoading(true);
    if (!skipShowLoading) {
      increasePercentageOfLoadingObject(30, `Lädt Daten für Seite ${requestPage}`);
    }
    setTimeout(() => {
      const newMainPagination = Object.assign({}, mainPaginationObject);
      debugger;
      newMainPagination.requestPage = requestPage;
      debugger;
      setMainPaginationObject(newMainPagination);
    }, 50);
  }

  // const changeGridColumns = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   const newMainPagination = Object.assign({}, mainPaginationObject);
  //   newMainPagination.imagesPerRow = parseInt(event.target.value);
  //   setMainPaginationObject(newMainPagination);
  // };

  const loadIHKHSJSONFromPublicFolderByFilename = (jsonFileToLoad: string, callbackFnc: any) => {
    if (!jsonFileToLoad || jsonFileToLoad==='') {
      debugger;
    }
    // jsonFileToLoad does not contain / or \
    // -> This means that file is expected to be accesible by the browser itself (file is in public folder)

    console.log('loadIHKHSJSONFromPublicFolderByFilename');
    console.log('loadIHKHSJSONFromPublicFolderByFilename - will load ' + jsonFileToLoad);

    fetch(`${DEFAULT_PRESETS_FOLDER}/${jsonFileToLoad}`).then(response => {
      console.log('loadIHKHSJSONFromPublicFolderByFilename - in response');
      //parseJSONData(request.responseText, jsonFileToLoad, false);
      // parseJSONData(response.text(), jsonFileToLoad, false);
      // setAppLoadedViaLoadJsonParameter(true);
      // setShowDataSourceSelectModal(false);
      // setShowSplashScreenModal(false);

      //if (type && type.indexOf("text") !== 1) {
      return response.text();

    }).then(json => {
      console.log('loadIHKHSJSONFromPublicFolderByFilename - in json');
      parseJSONData(json, jsonFileToLoad, false);
      if (callbackFnc) {
        callbackFnc();
      }
    }).catch(error=> { debugger; console.log('will show alert'); alert(error.message);})

    // var request = new XMLHttpRequest();
    // request.open('GET', jsonFileToLoad, true);
    // request.send(null);
    // request.onreadystatechange = function () {
    //   if (!isLoading && request.readyState === 4 && request.status === 200) {
    //     var type = request.getResponseHeader('Content-Type'); 
    //     if (type && type.indexOf("text") !== 1) {
    //       if (request.responseText.substring(0, ('<!DOCTYPE html').length)==='<!DOCTYPE html') {
    //         alert(`Die Datei "${jsonFileToLoad}" konnte nicht geladen werden. Existiert die Datei?`);  
    //       } else {
    //         //setIsLoading(true);
    //         parseJSONData(request.responseText, jsonFileToLoad, false);
    //         setAppLoadedViaLoadJsonParameter(true);
    //         setShowDataSourceSelectModal(false);
    //         setShowSplashScreenModal(false);
    //       }
    //     }
    //   }
    // }
  }

  const loadPredefinedColumnsFile = (predefinedFilenameToLoad: string, callbackFnc: any) => {
    if (predefinedFilenameToLoad === FILENAME_FOR_EMPTY_COLUMNS_PRESET) {
      setCustomColumnsArray([]);
      setRemarksColumnsArray([]);
    } else {
      fetch(`${DEFAULT_PRESETS_FOLDER}/${predefinedFilenameToLoad}`).then(response => {
          return response.text();
        }).then(json => {
            //setTimeout(() => {
              console.log('in loadPredefinedColumnsFile --- calling loadColumnDefinitionExchangeObjectFromJSONString');
              loadColumnDefinitionExchangeObjectFromJSONString(json, setStateVariablesForAdditionalColumnsConfig, callbackFnc);
            //}, 2000);
            
        }).catch(error=> {debugger; console.log('will show alert'); alert(error.message);})
    }
  }

  const enrichImageWithMetadataObjectArray = (imageWithMetadataObjectArray: ImageWithMetadataObject[], setLoadingVariables=true): ImageWithEnrichedMetadataObject[] => {

    //if (keywordCorrectionsArray.length>=1) {
    //  debugger;
    //}
    
    if (imageWithMetadataObjectArray.length===0) {
      debugger;
    }
    if (setLoadingVariables) {
      increasePercentageOfLoadingObject(5, 'Bereite Daten vor');
    }
    
    console.log('---');
    console.log('in enrichImageWithMetadataObjectArray');
    console.log(imageWithMetadataObjectArray.length);
    console.log('---');
    console.log(`%c enrichImageWithMetadataObjectArray length:${imageWithMetadataObjectArray.length}`, 'background: #000; color: #fff');

    let imageWithEnrichedMetadataObjectArray: ImageWithEnrichedMetadataObject[] = [];
    imageWithMetadataObjectArray.forEach((imageWithMetadataObject: ImageWithMetadataObject, imageWithMetadataIndex: number) => {
      imageWithEnrichedMetadataObjectArray.push(enrichImageWithMetadataObject(imageWithMetadataObject, imageWithMetadataIndex));
    });

    if (setLoadingVariables) {
      if (imageWithEnrichedMetadataObjectArray.length) {
        setLoadingCompleteForLoadingObject();
      } else {
        increasePercentageOfLoadingObject(10);
      }
    }
    
    //setImagesWithMetadataNeedsNewEnrichment(false);
    return imageWithEnrichedMetadataObjectArray;
  }

  // TODO: Rename this (this replaces Directory and/or File-Extension)
  const replaceDirectoryInFilePath = (filePathOriginal: string): string => {
    
    if (ignorePathForfilePathReplacement) {
      let filenamePartOnly = filePathOriginal.substring(filePathOriginal.lastIndexOf('/'));

      if (useImgExtReplacement) {
        filenamePartOnly = `${filenamePartOnly.substring(0, filenamePartOnly.lastIndexOf('.'))}.${fileExtReplaceString}`;
      }

      if (useImgExtReplacement && filePathReplace1String && (!connectedToNodeServer || (connectedToNodeServer && fileExtReplaceString))) {
        filePathOriginal = `${filePathOriginal.substring(0, filePathOriginal.lastIndexOf('.'))}.${fileExtReplaceString}`;
      }

      if (useImgSrcReplacement && filePathReplace1String && (!connectedToNodeServer || (connectedToNodeServer && filePathReplace2StringExistsOnFileSystem))) {
        filePathOriginal = `${filePathReplace2String}${filenamePartOnly}`;
      }
    } else {
      if (useImgExtReplacement && filePathReplace1String && (!connectedToNodeServer || (connectedToNodeServer && fileExtReplaceString))) {
        filePathOriginal = `${filePathOriginal.substring(0, filePathOriginal.lastIndexOf('.'))}.${fileExtReplaceString}`;
      }

      if (useImgSrcReplacement && filePathReplace1String && (!connectedToNodeServer || (connectedToNodeServer && filePathReplace2StringExistsOnFileSystem))) {
        filePathOriginal = filePathOriginal.replace(filePathReplace1String, filePathReplace2String);
      }
    }
    return filePathOriginal;
  }

  const resetDataFilterObjectArray = () => {
    setLoadingObject({ isLoading: true, text: 'Setze Filter zurück', progress: 50 } as LoadingObject);
    setDataFilterObjectArray(initialDataFilterArray);
    setImageWithEnrichedMetadataObjectsArray_Filtered(imageWithEnrichedMetadataObjectsArray_All);
    setLoadingCompleteForLoadingObject();
    setSelectedDBImport(null);
    setSelectedDBSystem(null);
  }

  const popoverLoadedDataInfo = (
    <Popover id="popover-basic">
        <Popover.Title as="h3">
            Informationen zu geladenen Daten
        </Popover.Title>
        <Popover.Content>
            <p title={`${numberWithSeparator(mainPaginationObject.totalImages)} Bilder geladen (gefiltert: ${numberWithSeparator(imageWithEnrichedMetadataObjectsArray_Filtered.length)})`} >
                Daten für {numberWithSeparator(mainPaginationObject.totalImages)} Bilder geladen
                {(mainPaginationObject.totalImages !== imageWithEnrichedMetadataObjectsArray_Filtered.length) && (
                  <> (gefiltert: {numberWithSeparator(imageWithEnrichedMetadataObjectsArray_Filtered.length)})</>
                )}
              </p>
              <p>{`Datenquelle: ${DATA_SOURCES[dataSource]} `}</p>
              {/* <p>{dataSource === DATA_SOURCE_FILESYSTEM && dataSourceOnlyFilesystemAndJSONFilename !== '' && ' Pfad: ' + dataSourceOnlyFilesystemAndJSONFilename}</p> */}
            <p title={dataSourceOnlyFilesystemAndJSONFilename}>{dataSource === DATA_SOURCE_JSON && dataSourceOnlyFilesystemAndJSONFilename !== '' && 'Datei: ' + dataSourceOnlyFilesystemAndJSONFilename}</p>
        </Popover.Content>
    </Popover>
  );

  const changeActiveViewTab = (newTabKey: string | null, newRequestPage?: number | null): void => {
    if (newTabKey) {

      if (slideshowIntervalVariable) {
        setSlideshowIntervalVariable(clearInterval(slideshowIntervalVariable));
      }

      setActiveViewTabKey(newTabKey);
      let newMainPagination = Object.assign({}, mainPaginationObject);
      switch (newTabKey) {
        case TAB_KEY_IMAGE_DETAIL_VIEW:
          if (mainPaginationObject.itemsPerPage !== 1) {
            newMainPagination.itemsPerPage = 1;
            if (newRequestPage) {
              newMainPagination.requestPage=newRequestPage;
            }
            setMainPaginationObject(newMainPagination);
          }
          break;
        
        default:
          if (mainPaginationObject.itemsPerPage===1) {
            newMainPagination.itemsPerPage=newTabKey===TAB_KEY_GRID_VIEW ? defaultItemsPerPageGridView : defaultItemsPerPage;
          }
          if (newRequestPage) {
            newMainPagination.requestPage=newRequestPage;
          }
          debugger;
          setMainPaginationObject(newMainPagination);
          break;
      }
    }
  }

  const loadNextPageForFrontendPagination = (): void => {
    debugger;
    if (dataSource!==DATA_SOURCE_JSON) {
      //debugger; // Should not be called for other DataSource than JSON
      console.error('getDataFromDatabase was called with wrong DataSource (' + dataSource + ')');
    }
    let newMainPagination = Object.assign({}, mainPaginationObject);
    let newMainPaginationWasChanged=false;
    if (imageWithEnrichedMetadataObjectsArray_All.length) {
      console.log('loadNextPageForFrontendPagination');
      // console.log('loadNextPageForFrontendPagination for requestPage ' + requestPage);
      // setTotalResults(imageWithMetadataObjectsArray_Filtered.length);

      //setTotalImages(imageWithMetadataObjectsArray_Filtered.length);
      if (imageWithEnrichedMetadataObjectsArray_All.length===imageWithEnrichedMetadataObjectsArray_Filtered.length) {
        if (mainPaginationObject.totalImages!==imageWithEnrichedMetadataObjectsArray_All.length) {
          newMainPagination.totalImages = imageWithEnrichedMetadataObjectsArray_All.length;
          newMainPaginationWasChanged=true;
        }
      }
      
      // Create copy of _All-array and remove the parts that should not be shown
      // const tmp_page = Object.assign([], imageWithMetadataObjectsArray_Filtered);
      //const newMaxPage = Math.ceil(imageWithEnrichedMetadataObjectsArray_Filtered.length / mainPaginationObject.itemsPerPage);

      //let newMainPagination = Object.assign({}, mainPaginationObject);
      

      // TODO Move this logic to useEffect [mainPaginationObject] ??
      //if (newMaxPage!==mainPaginationObject.maxPage) {
        //newMainPagination.maxPage=newMaxPage;
        //newMainPaginationWasChanged=true;
        //console.log("in loadNextPageForFrontendPagination triggering setMainPaginationObject");
        //console.log("because maxPage was set to " + newMaxPage);
      //} else if (mainPaginationObject.requestPage>newMaxPage) {
        //newMainPagination.requestPage=1;
        //newMainPaginationWasChanged=true;
        //console.log("in loadNextPageForFrontendPagination triggering setMainPaginationObject");
        //console.log("because requestPage was set to " + 1);
        // The function is then called again in useEffect for [mainPaginationObject]
      //}

      if (newMainPaginationWasChanged) {
        debugger;
        setMainPaginationObject(newMainPagination);

      }
      if (!newMainPaginationWasChanged) {  
        if (imageWithEnrichedMetadataObjectsArray_Filtered) {
          const tmp_page: ImageWithEnrichedMetadataObject[] = [];
          const startIndex = mainPaginationObject.itemsPerPage * (mainPaginationObject.requestPage - 1);
          const maxIndex = Math.min(startIndex + mainPaginationObject.itemsPerPage, imageWithEnrichedMetadataObjectsArray_Filtered.length);
          for (let i = startIndex; i < maxIndex; i++) {
            tmp_page.push(imageWithEnrichedMetadataObjectsArray_Filtered[i]);
          }
          setImageWithEnrichedMetadataObjectsArray_Page(tmp_page);
        }
      }
      setLoadingCompleteForLoadingObject();
    }
  };

  const changeAndSetFilePathReplace1String = (s: string) => {
    //console.log('changeAndSetFilePathReplace1String');
    //console.log(s);
    setFilePathReplace1String(s);
    fetch(getUrlForPathExists({ pathName: s }))
      .then(response => response.json())
      .then(response => setFilePathReplace1StringExistsOnFileSystem(Boolean(response)))
  };

  const changeAndSetFilePathReplace2String = (s: string) => {
    //console.log('changeAndSetFilePathReplace2String');
    //console.log(s);
    setFilePathReplace2String(s);
    fetch(getUrlForPathExists({ pathName: s }))
      .then(response => response.json())
      .then(response => setFilePathReplace2StringExistsOnFileSystem(Boolean(response)))
  };

  // : ColumnDefinition|null
  const getColumnDefinitionByCustomDefinitionConstant = (cdConstant: string) => {
    for (let i=0; i<initialBaseColumnDefinitionArray.length; i++) {
      if (initialBaseColumnDefinitionArray[i].columnDefinitionConstant === cdConstant) {
        return initialBaseColumnDefinitionArray[i];
      }
    }
  
    for (let i=0; i<customColumnsArray.length; i++) {
      if (customColumnsArray[i].columnDefinitionConstant === cdConstant) {
        return customColumnsArray[i];
      }
    }
  
    for (let i=0; i<remarksColumnsArray.length; i++) {
      if (remarksColumnsArray[i].columnDefinitionConstant === cdConstant) {
        return remarksColumnsArray[i];
      }
    }

    console.error(`getColumnDefinitionByCustomDefinitionConstant did not return anything for "${cdConstant}"`);
    return null;
  }

  // The "normal" ImageWithMetadataObject only conatins data taken from the exiftool output
  // The "enriched" version contains output of the custom logic
  const enrichImageWithMetadataObject = (imageWithMetadataObject: ImageWithMetadataObject, imageWithMetadataObjectIndex: number): ImageWithEnrichedMetadataObject => {

    if (imageWithMetadataObject.keywords_string.length >= 1 && typeof imageWithMetadataObject.keywords_array === 'undefined') {
      imageWithMetadataObject.keywords_array = imageWithMetadataObject.keywords_string.replace(/ /g, '').split(',');
    } else {
      imageWithMetadataObject.keywords_string = imageWithMetadataObject.keywords_array ? imageWithMetadataObject.keywords_array.join(', ') : '';
    }

    const imageWithEnrichedMetadataObject = {
      baseColumns: {...imageWithMetadataObject },
      customColumns: {},
      remarksColumns: {},
  } as ImageWithEnrichedMetadataObject;

  const filenameWithoutExtension = imageWithEnrichedMetadataObject.baseColumns[COLUMN_DEFINITION_CONST_FILENAME].split('.')[0];

  if (connectedToDatabase && Object.keys(keywordCorrectionsMap).length>=1) {
    debugger;
  }
  
  let keywordCorrectionString = keywordCorrectionsMap[filenameWithoutExtension];

  if (connectedToDatabase) {
    imageWithEnrichedMetadataObject.baseColumns[COLUMN_DEFINITION_CONST_KEYWORDS_CORRECTION] = keywordCorrectionString ? 'Ja' : 'Nein';
  } else {
    imageWithEnrichedMetadataObject.baseColumns[COLUMN_DEFINITION_CONST_KEYWORDS_CORRECTION] = 'Nicht geprüft';

  }

  if (connectedToDatabase && keywordCorrectionString) {
    imageWithEnrichedMetadataObject.baseColumns[COLUMN_DEFINITION_CONST_KEYWORDS_STRING] = keywordCorrectionString;
    imageWithEnrichedMetadataObject.baseColumns.keywords_array = keywordCorrectionString.replace(/ /g, '').split(',');
    imageWithEnrichedMetadataObject.baseColumns.keywords_rest_array = keywordCorrectionString.replace(/ /g, '').split(',');
  } else {
      if (imageWithEnrichedMetadataObject.baseColumns[COLUMN_DEFINITION_CONST_KEYWORDS_STRING]) {
        switch (typeof imageWithEnrichedMetadataObject.baseColumns[COLUMN_DEFINITION_CONST_KEYWORDS_STRING]) {
          case 'string':
            imageWithEnrichedMetadataObject.baseColumns.keywords_array = imageWithEnrichedMetadataObject.baseColumns[COLUMN_DEFINITION_CONST_KEYWORDS_STRING].replace(/ /g, '').split(',');
            imageWithEnrichedMetadataObject.baseColumns.keywords_rest_array = Object.assign([], imageWithEnrichedMetadataObject.baseColumns.keywords_array);
            break;
          default:
            imageWithEnrichedMetadataObject.baseColumns.keywords_array = imageWithEnrichedMetadataObject.baseColumns[COLUMN_DEFINITION_CONST_KEYWORDS];
            imageWithEnrichedMetadataObject.baseColumns.keywords_rest_array = Object.assign([], imageWithEnrichedMetadataObject.baseColumns.keywords_array);
            break;
        }
      } else {
        imageWithEnrichedMetadataObject.baseColumns.keywords_array = [];
        imageWithEnrichedMetadataObject.baseColumns.keywords_rest_array = [];
      }
    }    
    enrichImageWithMetadataObjectPartForCustomColumns(customColumnsArray, imageWithEnrichedMetadataObject);
    enrichImageWithMetadataObjectPartForRemarksColumns(remarksColumnsArray, imageWithEnrichedMetadataObject);

    //TODO: Improve this - Maybe with regex /privat/i
    //const hasKeywordPrivate = (imageWithEnrichedMetadataObject.baseColumns.KeywordsArray.indexOf('privat') !== -1 || (imageWithEnrichedMetadataObject.baseColumns.KeywordsArray.indexOf('Privat') !== -1));

    // Loop over all keywords to group them
    // imageWithEnrichedMetadataObject.baseColumns.keywords_array.forEach(function (currentKeyword: string) {
    //   currentKeyword = currentKeyword.toString();
    //   const firstTwoCharacters = currentKeyword.substring(0, 2).toLowerCase();
    //   const firstCharacter = firstTwoCharacters[0];
    // });

    columnDefinitionArray.forEach((columnDefinition: ColumnDefinition) => {
      if (columnDefinition.active) {
        switch (columnDefinition.columnDefinitionConstant) {
          case COLUMN_DEFINITION_CONST_ID:
            imageWithEnrichedMetadataObject.baseColumns.id = imageWithMetadataObject.id?.toString() ? imageWithMetadataObject.id.toString() : imageWithMetadataObjectIndex.toString();
            // if (dataSource === DATA_SOURCE_DATABASE) {
            //   imageWithEnrichedMetadataObject.Id =  imageWithMetadataObject.Id?.toString() ? imageWithMetadataObject.Id.toString() : '4711';
            // } else {
            //   imageWithEnrichedMetadataObject.Id = imageWithMetadataObjectIndex.toString();
            // }

            break;

          case COLUMN_DEFINITION_CONST_FILE_SIZE:
            imageWithEnrichedMetadataObject.baseColumns.file_size = imageWithMetadataObject.file_size?.toString();
            break;
           case COLUMN_DEFINITION_CONST_ISO:
             imageWithEnrichedMetadataObject.baseColumns.iso = imageWithMetadataObject.iso?.toString();
             break;
           case COLUMN_DEFINITION_CONST_APERTURE:
             imageWithEnrichedMetadataObject.baseColumns.aperture = imageWithMetadataObject.aperture?.toString();
             break;
          case COLUMN_DEFINITION_CONST_GPS_LATITUDE:
            imageWithEnrichedMetadataObject.baseColumns.gps_latitude = imageWithMetadataObject.gps_latitude?.toString()
            break;
          case COLUMN_DEFINITION_CONST_GPS_LONGITUDE:
            imageWithEnrichedMetadataObject.baseColumns.gps_longitude = imageWithMetadataObject.gps_longitude?.toString();
            break;
           case COLUMN_DEFINITION_CONST_IMAGE_PREVIEW:
            imageWithEnrichedMetadataObject.baseColumns[COLUMN_DEFINITION_CONST_IMAGE_PREVIEW] = [<img src={ getUrlForGetFile(connectedToNodeServer, { filePath: replaceDirectoryInFilePath(imageWithMetadataObject[COLUMN_DEFINITION_CONST_SOURCE_FILE])})}/>];
           break;
           default:
            break;
        }
      }
    });

    return imageWithEnrichedMetadataObject as ImageWithEnrichedMetadataObject;
  }

  // const setDataFunction = (dataSource: number, imagesWithMetadataArrayLocal: ImageWithMetadataObject[]|null, imagesWithEnrichedMetadataArrayLocal: ImageWithEnrichedMetadataObject[]|null, editPossible: boolean) => {
  //   //setIsLoading(true);
  //   if (imagesWithMetadataArrayLocal) {
  //     if (imageWithMetadataOriginalArray.length === 0) {      
  //       // Data from orginial source
  //       setImageWithMetadataOriginalArray(imagesWithMetadataArrayLocal);
  //     }
  //     imagesWithEnrichedMetadataArrayLocal = enrichImageWithMetadataObjectArray(imagesWithMetadataArrayLocal);
  //   }  else {
  //     imagesWithEnrichedMetadataArrayLocal = enrichImageWithMetadataObjectArray(imageWithMetadataOriginalArray);
  //   }

  //   console.log('in setDataFunction');
  //   if (imagesWithMetadataArrayLocal) {
  //     console.log('imagesWithMetadataArrayLocal.length: ' + imagesWithMetadataArrayLocal.length);      
  //   }
  //   console.log('imageWithMetadataOriginalArray.length: ' + imageWithMetadataOriginalArray.length);

  //   if (imagesWithEnrichedMetadataArrayLocal) {
  //     console.log('imagesWithEnrichedMetadataArrayLocal.length: ' + imagesWithEnrichedMetadataArrayLocal.length);
  //   }
  //   console.log('imageWithEnrichedMetadataObjectsArray_All.length: ' + imageWithEnrichedMetadataObjectsArray_All.length);
    
  //   //imagesWithEnrichedMetadataArrayLocal = enrichImageWithMetadataObjectArray(imageWithMetadataOriginalArray);
  //   setImageWithEnrichedMetadataObjectsArray_All(imagesWithEnrichedMetadataArrayLocal);
  //   setImageWithEnrichedMetadataObjectsArray_Filtered(imagesWithEnrichedMetadataArrayLocal);
  //   //setLoadingCompleteForLoadingObject();
  // }

  // TODO: Improve the naming "parse and use"
  const parseJSONData = (jsonString: string, jsonFilename: string, addToHistoryInLocalStorage: boolean) => {

    if (imageWithEnrichedMetadataObjectsArray_All.length > 0) {
      console.log(`%c parseJSONData was skipped because imageWithEnrichedMetadataObjectsArray_All was already populated`, 'background: rgb(255, 193, 7); color: #000');
      console.log('parseJSONData was skipped because imageWithEnrichedMetadataObjectsArray_All was already populated');
      console.log('imageWithEnrichedMetadataObjectsArray_All');
      console.log(imageWithEnrichedMetadataObjectsArray_All.length);
      console.log(imageWithEnrichedMetadataObjectsArray_All);
    } else if (imageWithEnrichedMetadataObjectsArray_All.length === 0) {
      //let data = JSON.parse(jsonString); as ImageWithMetadataObject[];

      //debugger;
      let data = JSON.parse(jsonString);

      // TODO Extract constants for desciption, startDir etc.
      const todoDescription = data['description'];
      const todoStartDirectory = data['startDirectory'];
      const todoStorageMedium = data['storageMedium'];
      const todoComputerName = data['computerName'];

      console.log({
        todoDescription: todoDescription,
        todoStartDirectory: todoStartDirectory,
        todoStorageMedium: todoStorageMedium,
        todoComputerName: todoComputerName,
      });

      // TODO: Use Constants here
      data = data['ihkhsjson'];

      let dataConverted: ImageWithMetadataObject[] = [];
      if (data && data[0]) {
        data.forEach((element: any) => {
          dataConverted.push(convertIhkhsjsonObjectIntoImageWithMetadataObject(baseColumnsMappingArray, element));
        });
        setDataSource(DATA_SOURCE_JSON);
        setImageWithMetadataOriginalArray(dataConverted);
        //const tmpEnriched = enrichImageWithMetadataObjectArray(dataConverted);
        //setImageWithEnrichedMetadataObjectsArray_All(tmpEnriched);
        //setImageWithEnrichedMetadataObjectsArray_Filtered(tmpEnriched);
        //setImagesWithMetadataNeedsNewEnrichment(true);
      } else {
        alert('foooa342342'); // TODO: Check and remove this!
        setImageWithMetadataOriginalArray(data);
        //const tmpEnriched = enrichImageWithMetadataObjectArray(data);
        //setImageWithEnrichedMetadataObjectsArray_All(tmpEnriched);
        //setImageWithEnrichedMetadataObjectsArray_Filtered(tmpEnriched);
        //setImagesWithMetadataNeedsNewEnrichment(true);
      }
      setImagesWithMetadataNeedsNewEnrichment(true);

      // TODO: Refactor
      // Add path to pathNameHistoryItems
      if (addToHistoryInLocalStorage) {
        let tmpIhkhsjsonHistoryItems = [...ihkhsjsonHistoryItems];

        if (tmpIhkhsjsonHistoryItems.indexOf(jsonFilename) === -1) {
          tmpIhkhsjsonHistoryItems.push(jsonFilename);
          // TODO: Refactor (Do this in setPathNameHistoryItems?)
          localStorage.setItem(LOCAL_STORAGE_KEY_IHKHSJSON_HISTORY, JSON.stringify(tmpIhkhsjsonHistoryItems));
          //setPathNameHistoryItems(tmpIhkhsjsonHistoryItems);
        }
      }

      setSettingsCollapseOpen(false);
      setDataSourceOnlyFilesystemAndJSONFilename(jsonFilename);
    }
  }

  const loadDataFromJSONFromFilesystem = (jsonFilename: string) => {
    console.log('loadDataFromJSONFromFilesystem');

    //setIsLoading(true);
    fetch(getUrlForGetFile(connectedToNodeServer, { filePath: jsonFilename }))
      // TODO: This needs to be improved
      // First json() is called - then JSON.stringify() is called - This should be simplified!
      .then(response => response.json())
      .then(response => {
        //console.log('loaded json from absolute path');
        //console.log(response);
        parseJSONData(JSON.stringify(response), jsonFilename, true);
        //setAppLoadedViaLoadJsonParameter(true);
        setShowDataSourceSelectModal(false);
      })
  }

  // // TODO: Connect handleDrop in DataSourceSettings
  // const handleFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {

  //   console.log('in handleFileSelected');
  //   //setIsLoading(true);

  //   // @ts-ignore
  //   const files = Array.from(event.target.files)

  //   console.log('handleFileSelected');
  //   console.log("files:", files);
  //   console.log(files[0]);
  //   const f = files[0];

  //   loadDataFromFile(f);
  // }

  const loadDataFromFile = (file: File) => {
    const reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = ((theFile) => {
      return (event: any) => {
        console.log('in reader.onload');
        parseJSONData(event.target.result, file.name, true);
        setShowDataSourceSelectModal(false);
        setShowSplashScreenModal(false);
      };
    })(file);

    // Read in the image file as a data URL.
    reader.readAsText(file);
  }

  const resetDataForDataSourceJSON = () => {
    console.log('resetDataForDataSourceJSON');
    //setDataFunction(DATA_SOURCE_JSON, null, imageWithEnrichedMetadataObjectsArray_All, false);
    //setImageWithEnrichedMetadataObjectsArray_All(tmpEnriched);
    setImageWithEnrichedMetadataObjectsArray_Filtered(imageWithEnrichedMetadataObjectsArray_All);

    // TODO: Reset Filter-Inputfields in JsonFilters
  };

  const increasePercentageOfLoadingObject = (delta: number, newText?: string) => {
    loadingObject.isLoading=true;
    loadingObject.progress=loadingObject.progress+Math.floor(delta);
    if (newText) {
      loadingObject.text=newText;
    }
  }

  const setLoadingCompleteForLoadingObject = () => {
    setLoadingObject({ isLoading: false, text: '', progress: 0 } as LoadingObject);
  }

  const applyFilterForDataSourceJSON = () => {
    const newData : ImageWithEnrichedMetadataObject[] = getResultsForDataFilterObjectArray(imageWithEnrichedMetadataObjectsArray_All, dataFilterObjectArray);

    //const newMainPagination = Object.assign({}, mainPaginationObject);
    //newMainPagination.totalImages = newData.length;
    //newMainPagination.requestPage = 1;
    //setMainPaginationObject(newMainPagination);


    setImageWithEnrichedMetadataObjectsArray_Filtered(newData);
  }

  const toggleShowPreviewImages = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowPreviewImages(event.target.checked);
  }

  const toggleUseImgSrcReplacement = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUseImgSrcReplacement(event.target.checked);
  }

  const toggleUseImgExtReplacement = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUseImgExtReplacement(event.target.checked);
  }

  const toggleCheckForKeywordCorrections = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckForKeywordCorrections(event.target.checked);
  }

  const toggleDoCalculateCheckNumberForLoknummer = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDoCalculateCheckNumberForLoknummer(event.target.checked);
  }

  const changePathNameInputField = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("changePathNameInputField", event.target.value);
    checkPathExistsOnFilesystem(event.target.value);
    setPathNameInputField(event.target.value);
  };

  const checkPathExistsOnFilesystem = (pathName: string) => {
    const pathExistsUrl = getUrlForPathExists({ pathName: pathName });
    fetch(pathExistsUrl)
      .then(response => response.json())
      .then(response => setPathNameInputFieldExistsOnFilesystem(Boolean(response)))
  };

  // const testNodeServerConnection = (nodeServerHostToTest: string) => {
  const testNodeServerConnection = () => {
    //alert('checking' + nodeServerHost + getNodeIsAliveUrl);
    fetch(nodeServerHost + '' + getNodeIsAliveUrl)
      .then(response => response.json())
      .then(response => {
        setConnectedToNodeServer(response === NODE_SERVER_IS_ALIVE_EXPECETED_ANSWER);
      }).catch(function () {
        //console.log('testNodeServerConnection in catch (' + nodeServerHost + '' + getNodeIsAliveUrl + ')');
        //alert('testNodeServerConnection in catch (' + nodeServerHost + '' + getNodeIsAliveUrl + ')');
        setConnectedToNodeServer(false);
      });
  };

  //TODO: Improve definition of callback
  const getDataFromDatabase = () => {

    if (dataSource!==DATA_SOURCE_DATABASE) {
      //debugger; // Should not be called for other DataSource than JSON
      console.error('getDataFromDatabase was called with wrong DataSource (' + dataSource + ')');
    }
    //debugger;

    // const metadata = {
    //   page: mainPaginationObject.requestPage,
    //   itemsPerPage: mainPaginationObject.itemsPerPage,
    // }

    increasePercentageOfLoadingObject(10, 'Lädt Daten aus der Datenbank');

    console.log('universalGetKeywords');
    //console.log('metadata', metadata);
    //if (source === DATA_SOURCE_DATABASE && connectedToDatabase) {
    //setUseFrontendPagination(false);
    setDataSourceOnlyFilesystemAndJSONFilename('');

    let hasActiveFilters = false;
    let filterParameters = {};
    dataFilterObjectArray.forEach((dataFilterObject: DataFilterObject) => {
      if (dataFilterObject.column && dataFilterObject.searchValue) {
        filterParameters[dataFilterObject.column.columnDefinitionConstant] = dataFilterObject.searchValue;
        hasActiveFilters = true;
      }
    });

    if (selectedDBImport) {
      filterParameters['import_fk__id'] = selectedDBImport.id;
    }
    
    let url = settings.databaseHost + getDatabaseImagesFromDbBaseUrl + getGetParametersFromArray({
      format: 'json',
      page: hasActiveFilters ? 1 : mainPaginationObject.requestPage,
      itemsPerPage: mainPaginationObject.itemsPerPage,
      ...filterParameters,
    });

    if (hasActiveFilters) {
      setRequestPageWithLoading(1);
    }
    
    // FOR DATA_SOURCE_JSON see -> handleFileSelected
    // FOR DATA_SOURCE_DATABASE AND DATA_SOURCE_FILESYSTEM
    if (typeof url !== "undefined") {
      console.log('fetch url', url);
      fetch(url)
        .then(response => response.json())
        .then(response => {
          setShowSplashScreenModal(false);

          let editPossible = false;
          let converted = false;
          let newResponseData: ImageWithMetadataObject[] = [];
          console.log(response.pagination);

          if (typeof response['count'] !== 'undefined') {
            let totalImagesFromCount = parseInt(response['count']);
            
            if (mainPaginationObject.totalImages!==totalImagesFromCount) {
              let newMainPagination = Object.assign({}, mainPaginationObject);
              newMainPagination.totalImages = totalImagesFromCount;
              debugger;
              setMainPaginationObject(newMainPagination);

            }

            //const newMaxPage = Math.ceil(totalImages / mainPaginationObject.itemsPerPage);

          //   console.log("checking mainPaginationObject");
          //   if (mainPaginationObject.maxPage !== newMaxPage) {

          //       console.log("calling setMainPaginationObject");
          //       console.log("newMaxPage: " + newMaxPage);
          //       console.log("mainPaginationObject.maxPage: " + mainPaginationObject.maxPage);
              
          //     // Activating this causes and endless loop causing images-url is called endlessly
          //     let newMainPagination = Object.assign({}, mainPaginationObject);
          //     newMainPagination.requestPage = mainPaginationObject.requestPage;
          //     newMainPagination.itemsPerPage = mainPaginationObject.itemsPerPage;
          //     newMainPagination.maxPage = Math.ceil(totalImages / mainPaginationObject.itemsPerPage);;
          //     // {requestPage: 1, maxPage: 1, itemsPerPage: defaultItemsPerPage, imagesPerRow: 3}
          //     setMainPaginationObject(newMainPagination);
          //   }
          }

          // TODO: Extract as function
          response.results.forEach((responseItem: any) => {
            if ('i_id' in responseItem) {
              newResponseData.push(convertDatabaseObjectIntoImageWithMetadataObject(responseItem));
              converted = true;
            }
          });
          if (newResponseData.length) {
            response.results = newResponseData;
          }

          const enrichedTmp = enrichImageWithMetadataObjectArray(response.results);
          //if (hasActiveFilters) {
          //  setImageWithEnrichedMetadataObjectsArray_Filtered(enrichedTmp);
          //}
          // TODO: Improve this
          setImageWithEnrichedMetadataObjectsArray_Filtered(enrichedTmp);
          setImageWithEnrichedMetadataObjectsArray_Page(enrichedTmp);
          
          setEditPossible(editPossible);
          //setLoadingCompleteForLoadingObject();
        });
    }
  };

  const testDatabaseConnection = () => {
    fetch(databaseHost + getDatabaseIsAliveUrl)
      .then(response => response.json())
      .then(response => {
        setConnectedToDatabase(response === DATABASE_IS_ALIVE_EXPECETED_ANSWER);
      }).catch(function () {
        console.log('testDatabaseConnection in catch');
        setConnectedToDatabase(false);
      });
  };

  const filterMapSummaryArray: any[] = [];

  Object.keys(filtersMap).forEach((filterKey) => {
    if (filtersMap[filterKey] !== '') {
      filterMapSummaryArray.push(`${FILTER_COLUMN_MAP[filterKey]}:${filtersMap[filterKey]}`);
    }
  });

  const marginForTHead = 15;
  const [theadValueTest, setTheadValueTest] = useState(marginForTHead as number);

  const onScrollHandler = () => {
    // @ts-ignore
    setTheadValueTest(marginForTHead-refForMainContainer.current.scrollLeft);
  }

  // TODO: Create function to check conditions - then mark the loading as complete
  if (!imagesWithMetadataNeedsNewEnrichment && !showColumnDefinitionModal) {
    //if (combinedColumnDefinitionArray.length>=1 && combinedColumnDefinitionArray.length!==columnsForViewArray[COLUMNS_FOR_VIEWS_ARRAY[0]].length) {
    //  console.log('---in A');
    //  updateColumnsForViewArrayFromCombinedColumnDefinitionArray();
    //  setImagesWithMetadataNeedsNewEnrichment(true);
    //}

    if (imageWithEnrichedMetadataObjectsArray_All.length && imageWithEnrichedMetadataObjectsArray_All[0]) {
      let tmpEnrichedDataHasWrongNumberOfCustomColumns = false;
      let tmpEnrichedDataHasWrongNumberOfRemarksColumns = false;
      if (remarksColumnsArray.length) {
        tmpEnrichedDataHasWrongNumberOfRemarksColumns = (Object.keys(imageWithEnrichedMetadataObjectsArray_All[0].remarksColumns).length!==remarksColumnsArray.length)
      }
      if (customColumnsArray.length) {
        tmpEnrichedDataHasWrongNumberOfCustomColumns = (Object.keys(imageWithEnrichedMetadataObjectsArray_All[0].customColumns).length!==customColumnsArray.length)
      }
      if (tmpEnrichedDataHasWrongNumberOfRemarksColumns || tmpEnrichedDataHasWrongNumberOfCustomColumns) {
        setImagesWithMetadataNeedsNewEnrichment(true);
      }
    }
  }

  // if (imagesWithMetadataNeedsNewEnrichment && imageWithMetadataOriginalArray.length>=1) {
  //   console.log('---in B');
  //   const tmpEnriched = enrichImageWithMetadataObjectArray(imageWithMetadataOriginalArray);
  //   setImageWithEnrichedMetadataObjectsArray_All(tmpEnriched);
  //   setImageWithEnrichedMetadataObjectsArray_Filtered(tmpEnriched);
  //   setImagesWithMetadataNeedsNewEnrichment(false);
  // }

  // if (imagesWithMetadataNeedsNewEnrichment===true) {
  //   console.log('imagesWithMetadataNeedsNewEnrichment triggered enrichment...');
  //   //const enrichImageWithMetadataObjectArray 
  //   //debugger;

  //   if (imageWithMetadataOriginalArray.length>=1) {
  //     const tmpEnriched = enrichImageWithMetadataObjectArray(imageWithMetadataOriginalArray);
  //     setImageWithEnrichedMetadataObjectsArray_All(tmpEnriched);
  //     setImageWithEnrichedMetadataObjectsArray_Filtered(tmpEnriched);
  //     setImagesWithMetadataNeedsNewEnrichment(false);
  //   } else {
  //     //debugger;
  //   }
  //}
  console.log('----------------------APP.tsx BEFORE RENDER---');
  return (
    <div className={darkMode ? 'darkmode' : ''}>
      <div id='main-view'>
        <Row>
          <Col md={12}>
            <DataFilterRow
              importsArray={importsArray}
              systemsArray={systemsArray}
              dataSource={dataSource}
              loadingObject={loadingObject}
              applyFilterFunction={dataSource===DATA_SOURCE_JSON ?  applyFilterForDataSourceJSON : getDataFromDatabase}
              remarksColumsArray={remarksColumnsArray}
              customColumsArray={customColumnsArray}
              dataFilterObjectArray={dataFilterObjectArray}
              setDataFilterObjectArray={setDataFilterObjectArray}
              initialDataFilterArray={initialDataFilterArray}
              resetDataFilterObjectArray={resetDataFilterObjectArray}
              dataCountFiltered={imageWithEnrichedMetadataObjectsArray_Filtered.length}
              dataCountUnfiltered={dataSource===DATA_SOURCE_JSON ? imageWithEnrichedMetadataObjectsArray_All.length : mainPaginationObject.totalImages }
              selectedDBImport={selectedDBImport}
              setSelectedDBImport={setSelectedDBImport}
              selectedDBSystem={selectedDBSystem}
              setSelectedDBSystem={setSelectedDBSystem}
              connectedToDatabase={connectedToDatabase}
              getColumnDefinitionByCustomDefinitionConstant={getColumnDefinitionByCustomDefinitionConstant}
            />
            <div
                onScroll={onScrollHandler}
                id='keyword_table_container_div'
                className='keywords-table-container-fixed'
                // @ts-ignore
                ref={refForMainContainer}
            >
              <Tabs activeKey={activeViewTabKey} defaultActiveKey={TAB_KEY_TABLE_VIEW} onSelect={(key) => changeActiveViewTab(key)}>
                <Tab eventKey={TAB_KEY_TABLE_VIEW} title="Tabellenansicht" tabClassName='tab-primary' className='tab-with-margin'>
                  {activeViewTabKey === TAB_KEY_TABLE_VIEW && (
                    <KeywordsTableContainer
                      columnsForViewArray={columnsForViewArray}
                      settings={settings}
                      connectedToNodeServer={connectedToNodeServer === true}
                      connectedToDatabase={connectedToDatabase}
                      imageWithEnrichedMetadataObjectsArray_Page={imageWithEnrichedMetadataObjectsArray_Page}
                      editPossible={editPossible}
                      checkForKeywordCorrections={checkForKeywordCorrections}
                      selectedSourceFileValues={selectedSourceFileValues}
                      setSelectedSourceFileValues={setSelectedSourceFileValues}
                      changeActiveViewTab={changeActiveViewTab}
                      activeViewTabKey={activeViewTabKey}
                      theadValueTest={theadValueTest}
                      setActiveRemarksColumnTabKey={setActiveRemarksColumnTabKey}
                      setActiveCustomColumnTabKey={setActiveCustomColumnTabKey}
                      setShowColumnDefinitionModal={setShowColumnDefinitionModal}
                      setActiveTabKeyColumnDefinitionModal={setActiveTabKeyColumnDefinitionModal}
                      renderObjectsMap={renderObjectsMap}
                    />
                  )}
                </Tab>
                {showPreviewImages && (
                  <Tab eventKey={TAB_KEY_GRID_VIEW} title="Grid-Ansicht" tabClassName='tab-primary' className='tab-with-margin'>
                    {activeViewTabKey === TAB_KEY_GRID_VIEW && (
                      <GridView
                        columnsForViewArray={columnsForViewArray}
                        imageWithMetadataObjectsArray={imageWithEnrichedMetadataObjectsArray_Page}
                        checkPathExistsOnFilesystem={checkPathExistsOnFilesystem}
                        filePathReplace1String={filePathReplace1String}
                        filePathReplace2String={filePathReplace2String}
                        filePathReplace2StringExistsOnFileSystem={filePathReplace2StringExistsOnFileSystem}
                        replaceDirectoryInFilePath={replaceDirectoryInFilePath}
                        connectedToNodeServer={connectedToNodeServer}
                        mainPaginationObject={mainPaginationObject}
                        setMainPaginationObject={setMainPaginationObject}
                        renderObjectsMap={renderObjectsMap}
                      />
                    )}
                  </Tab>
                )}
                <Tab eventKey={TAB_KEY_IMAGE_DETAIL_VIEW} title="Einzelansicht" tabClassName='tab-primary' className='tab-with-margin'>
                  {activeViewTabKey === TAB_KEY_IMAGE_DETAIL_VIEW && (
                    <ImageDetailView
                      imageWithEnrichedMetadataObjectsArray_All={imageWithEnrichedMetadataObjectsArray_All}
                      settings={settings}
                      connectedToDatabase={connectedToDatabase}
                      connectedToNodeServer={connectedToNodeServer === true}
                      imageWithEnrichedMetadataObject={imageWithEnrichedMetadataObjectsArray_Page[0]}
                      columnsForViewArray={columnsForViewArray}
                      replaceDirectoryInFilePath={replaceDirectoryInFilePath}
                      selectedSourceFileValues={selectedSourceFileValues}
                      setSelectedSourceFileValues={setSelectedSourceFileValues}
                      mainPaginationObject={mainPaginationObject}
                      setMainPaginationObject={setMainPaginationObject}
                      changeActiveViewTab={changeActiveViewTab}
                      showPreviewImages={showPreviewImages}
                      relatedColumnsArray={relatedColumnsArray}
                      databaseHost={databaseHost}
                      csrfToken={csrfToken}
                      settingNameForSavingComments={settingNameForSavingComments}
                      setSettingNameForSavingComments={setSettingNameForSavingComments}
                      dataSourceOnlyFilesystemAndJSONFilename={dataSourceOnlyFilesystemAndJSONFilename}
                      keywordCorrectionsArray={keywordCorrectionsArray}
                      slideshowIntervalVariable={slideshowIntervalVariable}
                      renderObjectsMap={renderObjectsMap}
                    />
                  )}
                </Tab>
                <Tab eventKey={TAB_KEY_IMAGE_FOLDER_VIEW} title="Ordnerdarstellung" tabClassName='tab-secondary'>
                  {activeViewTabKey === TAB_KEY_IMAGE_FOLDER_VIEW && (
                    <FolderGrid
                      columnsForViewArray={columnsForViewArray}
                      filePathReplace2String={filePathReplace2String}
                      connectedToNodeServer={connectedToNodeServer}
                      settings={settings}
                      imgFilenamePrefix={folderGridViewImgFilenamePrefix}
                      setImgFilenamePrefix={setFolderGridViewImgFilenamePrefix}
                      imageWithEnrichedMetadataObjectsArray_All={imageWithEnrichedMetadataObjectsArray_All}
                      combinedColumnDefinitionArray={combinedColumnDefinitionArray}
                      mainPaginationObject={mainPaginationObject}
                      renderObjectsMap={renderObjectsMap}
                    />
                  )}
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
      </div>
      <Footer>
        <Container fluid>
          {loadingObject.isLoading ?
            <Row>
              <Col md={3}>
              </Col>
              <Col md={6}>
                <LoadingProgress loadingObject={loadingObject} />
              </Col>
              <Col md={3}>
              </Col>
            </Row>
            :
            (
            <Row>
              <Col sm={3}>
                <>
                <ButtonToolbar>
                  <ButtonGroup className='open-modals-button-group'>
                    <OverlayTrigger
                      placement='top'
                      overlay={
                          <Tooltip id='tooltip-bottom'>
                          Darkmode
                          </Tooltip>
                      }
                    >
                      <Button
                            size='sm'
                            className='open-modals-button-darkmode-button'
                            variant='outline-secondary'
                            onClick={() => setDarkMode(!darkMode)}
                            title='Darkmode'
                          >
                            <FontAwesomeIcon icon={faLightbulb} />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement='top'
                      overlay={
                          <Tooltip id='tooltip-bottom'>
                          Hilfe-Seite
                          </Tooltip>
                      }
                    >
                      <Button
                            size='sm'
                            className='open-modals-button-help-button'
                            variant='secondary'
                            onClick={handleShowHelpModal}
                            title='Hilfe'
                          >
                            <FontAwesomeIcon icon={faQuestion} />
                      </Button>
                    </OverlayTrigger>
                    {(imageWithEnrichedMetadataObjectsArray_All.length > 0) && (
                        <OverlayTrigger
                          placement='top'
                          overlay={
                              <Tooltip id='tooltip-bottom'>
                              Datenanalyse{dataSource === DATA_SOURCE_DATABASE && '(von aktueller Seite)'}
                              </Tooltip>
                          }
                        >
                          <Button
                            size='sm'
                            variant='secondary'
                            onClick={handleShowDataAnalysisModal}
                          >
                            <FontAwesomeIcon icon={faChartBar} />
                          </Button>
                        </OverlayTrigger>
                    )}
                    <OverlayTrigger
                      placement='top'
                      overlay={
                          <Tooltip id='tooltip-settings-button'>
                          Einstellungen
                          </Tooltip>
                      }
                    >
                      <Button
                        size='sm'
                        variant='secondary'
                        onClick={() => handleShowColumnDefinitionModal()}
                      >
                        <FontAwesomeIcon icon={faCog} />
                      </Button>
                    </OverlayTrigger>
                  </ButtonGroup>
                </ButtonToolbar>
                </>
              </Col>
              <Col sm={6}>
                <MyPagination
                  mainPaginationObject={mainPaginationObject}
                  setMainPaginationObject={setMainPaginationObject}
                  setRequestPageWithLoading={setRequestPageWithLoading}
                  hideItemsPerPage={false}
                  activeViewTabKey={activeViewTabKey}
                  slideshowIntervalVariable={slideshowIntervalVariable}
                  setSlideshowIntervalVariable={setSlideshowIntervalVariable}
                />
              </Col>
              <Col sm={2}>
              <div className={darkMode ? 'darkmode' : ''}>
                <ButtonGroup>
                    <OverlayTrigger
                        placement='top'
                        overlay={
                            <Tooltip id='tooltip-table-view'>{COLUMNS_FOR_TABLE_VIEW_NAME_IN_FRONTEND}</Tooltip>
                        }
                    >
                      <Button size='sm'
                        onClick={() => changeActiveViewTab(TAB_KEY_TABLE_VIEW)}
                        variant={`${((activeViewTabKey !== TAB_KEY_TABLE_VIEW) ? 'outline-' : '')}secondary`}
                      >
                        T <FontAwesomeIcon icon={faThList} />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement='top'
                        overlay={
                            <Tooltip id='tooltip-detail-view'>{COLUMNS_FOR_DETAIL_VIEW_NAME_IN_FRONTEND}</Tooltip>
                        }
                    >
                      <Button size='sm'
                        onClick={() => changeActiveViewTab(TAB_KEY_IMAGE_DETAIL_VIEW)}
                        variant={`${((activeViewTabKey !== TAB_KEY_IMAGE_DETAIL_VIEW) ? 'outline-' : '')}secondary`}
                      >
                        D <FontAwesomeIcon icon={faImage} />
                      </Button>
                    </OverlayTrigger>
                    {showPreviewImages && (
                    <OverlayTrigger
                            placement='top'
                            overlay={
                                <Tooltip id='tooltip-grid-view'>{COLUMNS_FOR_GRID_VIEW_NAME_IN_FRONTEND}</Tooltip>
                            }
                        >
                          <Button size='sm'
                            onClick={() => changeActiveViewTab(TAB_KEY_GRID_VIEW)}
                            variant={`${((activeViewTabKey !== TAB_KEY_GRID_VIEW) ? 'outline-' : '')}secondary`}
                          >
                            G <FontAwesomeIcon icon={faGripHorizontal} />
                          </Button>
                    </OverlayTrigger>
                    )}
                    {showPreviewImages && folderGridViewImgFilenamePrefix!=='' && (
                      <OverlayTrigger
                          placement='top'
                          overlay={
                              <Tooltip id='tooltip-folder-view'>{COLUMNS_FOR_FOLDER_VIEW_NAME_IN_FRONTEND}</Tooltip>
                          }
                      >
                        <Button size='sm'
                          onClick={() => changeActiveViewTab(TAB_KEY_IMAGE_FOLDER_VIEW)}
                          variant={`${((activeViewTabKey !== TAB_KEY_IMAGE_FOLDER_VIEW) ? 'outline-' : '')}secondary`}
                        >
                          O <FontAwesomeIcon icon={faGripVertical} />
                        </Button>
                      </OverlayTrigger>
                    )}
                  </ButtonGroup>
              </div>
              </Col>
              <Col sm={1}>
                <div className='button-group-in-footer'>
                  <OverlayTrigger trigger="click" placement="top" overlay={popoverLoadedDataInfo} rootClose>
                    <Button size='sm' variant='secondary'>
                        <FontAwesomeIcon icon={faInfo} /> 
                    </Button>
                  </OverlayTrigger>
                  <Button
                    size='sm'
                    variant='secondary'
                    className='open-splash-screen-modal-button'
                    onClick={() => { setShowSplashScreenModal(true); testDatabaseConnection(); }}
                  >
                    Impressum / Startseite
                  </Button>
                </div>
              </Col>
            </Row>
            )}
        </Container>
      </Footer>
      <HelpModal
        darkMode={darkMode}
        showHelpModal={showHelpModal}
        handleCloseHelpModal={handleCloseHelpModal}
        settings={settings}
        customColumnsArray={customColumnsArray}
        remarksColumnsArray={remarksColumnsArray}
        enrichImageWithMetadataObjectArray={enrichImageWithMetadataObjectArray}
        imageWithEnrichedMetadataObjectsArray_Filtered={imageWithEnrichedMetadataObjectsArray_Filtered}
        columnsForViewArray={columnsForViewArray}
        renderObjectsMap={renderObjectsMap}
      />
      <SplashScreenModal
        darkMode={darkMode}
        showSplashScreenModal={showSplashScreenModal}
        handleCloseSplashScreenModal={handleCloseSplashScreenModal}
        appHasData={(imageWithEnrichedMetadataObjectsArray_All.length > 0)}
        replaceDirectoryInFilePath={replaceDirectoryInFilePath}
        loadDataFromFile={loadDataFromFile}
        presetColumnFilesArray={presetColumnFilesArray}
        presetDataFilesArray={presetDataFilesArray}
        connectedToDatabase={connectedToDatabase}
        legalStringLinesArray={legalStringLinesArray}
      />
      <DataSourceChangeModal
        darkMode={darkMode}
        settings={settings}
        setSettings={setSettings}
        connectedToDatabase={connectedToDatabase}
        setConnectedToDatabase={setConnectedToDatabase}
        connectedToNodeServer={connectedToNodeServer === true}
        setConnectedToNodeServer={setConnectedToNodeServer}
        testDatabaseConnection={testDatabaseConnection}
        testNodeServerConnection={testNodeServerConnection}
        loadDataFromJSONFromFilesystem={loadDataFromJSONFromFilesystem}
        databaseHost={databaseHost}
        setDatabaseHost={setDatabaseHost}
        nodeServerHost={nodeServerHost}
        setNodeServerHost={setNodeServerHost}
        setSettingsCollapseOpen={setSettingsCollapseOpen}
        dataSource={dataSource}
        setDataSource={setDataSource}
        loadingObject={loadingObject}
        
        changePathNameInputField={changePathNameInputField}
        keywordsLoadFromPathname={keywordsLoadFromPathname}
        pathNameInputField={pathNameInputField}
        setPathNameInputField={setPathNameInputField}
        //pathNameHistoryItems={pathNameHistoryItems}
        ihkhsjsonHistoryItems={ihkhsjsonHistoryItems}
        //setPathNameHistoryItems={setPathNameHistoryItems}
        showDataSourceSelectModal={showDataSourceSelectModal}
        handleCloseDataSourceSelectModal={handleCloseDataSourceSelectModal}
        pathNameInputFieldExistsOnFilesystem={pathNameInputFieldExistsOnFilesystem}
        checkPathExistsOnFilesystem={checkPathExistsOnFilesystem}
        appHasData={(imageWithEnrichedMetadataObjectsArray_All.length > 0)}
        filePathReplace1String={filePathReplace1String}
        filePathReplace2String={filePathReplace2String}
        changeAndSetFilePathReplace1String={changeAndSetFilePathReplace1String}
        changeAndSetFilePathReplace2String={changeAndSetFilePathReplace2String}
        filePathReplace2StringExistsOnFileSystem={filePathReplace2StringExistsOnFileSystem}
        exampleImageWithEnrichedMetadataObject={(imageWithEnrichedMetadataObjectsArray_All.length >= 1) ? imageWithEnrichedMetadataObjectsArray_All[0] : undefined}
        replaceDirectoryInFilePath={replaceDirectoryInFilePath}
        loadDataFromFile={loadDataFromFile}
        columnDefinitionArray={columnDefinitionArray}
        combinedColumnDefinitionArray={combinedColumnDefinitionArray}
        showPreviewImages={showPreviewImages}
        useImgSrcReplacement={useImgSrcReplacement}
        setUseImgSrcReplacement={setUseImgSrcReplacement}
        useImgExtReplacement={useImgExtReplacement}
        toggleUseImgSrcReplacement={toggleUseImgSrcReplacement}
        toggleUseImgExtReplacement={toggleUseImgExtReplacement}
        toggleShowPreviewImages={toggleShowPreviewImages}
        setShowPreviewImages={setShowPreviewImages}
        setCheckForKeywordCorrections={setCheckForKeywordCorrections}
        checkForKeywordCorrections={checkForKeywordCorrections}
        toggleCheckForKeywordCorrections={toggleCheckForKeywordCorrections}
        doCalculateCheckNumberForLoknummer={doCalculateCheckNumberForLoknummer}
        setDoCalculateCheckNumberForLoknummer={setDoCalculateCheckNumberForLoknummer}
        toggleDoCalculateCheckNumberForLoknummer={toggleDoCalculateCheckNumberForLoknummer}
        fileExtReplaceString={fileExtReplaceString}
        setFileExtReplaceString={setFileExtReplaceString}
        csrfToken={csrfToken}
        imageWithMetadataOriginalArray={imageWithMetadataOriginalArray}
        dataSourceOnlyFilesystemAndJSONFilename={dataSourceOnlyFilesystemAndJSONFilename}
        updateImportsFromDatabase={updateImportsFromDatabase}
        updateSystemsFromDatabase={updateSystemsFromDatabase}
        importIsForbidden={importIsForbidden}
        importsArray={importsArray}
        systemsArray={systemsArray}
      />
      <DataAnalysisModal
        darkMode={darkMode}
        customColumnsArray={customColumnsArray}
        remarksColumnsArray={remarksColumnsArray}
        imageWithEnrichedMetadataObjectsArray={imageWithEnrichedMetadataObjectsArray_All}
        showDataAnalysisModal={showDataAnalysisModal}
        setShowDataAnalysisModal={setShowDataAnalysisModal}
        handleCloseDataAnalysisModal={handleCloseDataAnalysisModal}
        mainPaginationObject={mainPaginationObject}
        dataSource={dataSource}
        setDataFilterObjectArray={setDataFilterObjectArray}
        loadingObject={loadingObject}
        increasePercentageOfLoadingObject={increasePercentageOfLoadingObject}
        setLoadingCompleteForLoadingObject={setLoadingCompleteForLoadingObject}
        applyFilterForDataSourceJSON={applyFilterForDataSourceJSON}
        
      />
      <ColumnDefinitionModal
        darkMode={darkMode}
        dataSource={dataSource}
        remarksColumnsArray={remarksColumnsArray}
        setRemarksColumnsArray={setRemarksColumnsArray}
        customColumnsArray={customColumnsArray}
        columnsForViewArray={columnsForViewArray}
        setCustomColumnsArray={setCustomColumnsArray}
        setColumnsForViewArray={setColumnsForViewArray}
        columnDefinitionArray={columnDefinitionArray}
        handleCloseColumnDefinitionModal={handleCloseColumnDefinitionModal}
        showColumnDefinitionModal={showColumnDefinitionModal}
        columnPresetFilesArray={presetColumnFilesArray}
        presetDataFilesArray={presetDataFilesArray}
        loadPredefinedColumnsFile={loadPredefinedColumnsFile}
        additionalColumnsConfigFromLocalStorage={additionalColumnsConfigFromLocalStorage}
        setAdditionalColumnsConfigFromLocalStorage={setAdditionalColumnsConfigFromLocalStorage}
        setStateVariablesForAdditionalColumnsConfig={setStateVariablesForAdditionalColumnsConfig}
        relatedColumnsArray={relatedColumnsArray}
        setRelatedColumnsArray={setRelatedColumnsArray}
        setImagesWithMetadataNeedsNewEnrichment={setImagesWithMetadataNeedsNewEnrichment}
        getColumnDefinitionByCustomDefinitionConstant={getColumnDefinitionByCustomDefinitionConstant}
        activeViewTabKey={activeViewTabKey}
        enrichImageWithMetadataObjectArray={enrichImageWithMetadataObjectArray}
        settings={settings}
        activeRemarksColumnTabKey={activeRemarksColumnTabKey}
        setActiveRemarksColumnTabKey={setActiveRemarksColumnTabKey}
        activeCustomColumnTabKey={activeCustomColumnTabKey}
        setActiveCustomColumnTabKey={setActiveCustomColumnTabKey}
        activeTabKeyColumnDefinitionModal={activeTabKeyColumnDefinitionModal}
        setActiveTabKeyColumnDefinitionModal={setActiveTabKeyColumnDefinitionModal}
        activeTabKeyColumnDefinitionModal2={activeTabKeyColumnDefinitionModal2}
        setActiveTabKeyColumnDefinitionModal2={setActiveTabKeyColumnDefinitionModal2}
        renderObjectsMap={renderObjectsMap}
        setRenderObjectsMap={setRenderObjectsMap}
        additionalColumnsRepoCustomColumnsArray={additionalColumnsRepoCustomColumnsArray} // TODO: Change this
        additionalColumnsRepoRemarkColumnsArray={additionalColumnsRepoRemarkColumnsArray} // TODO: Change this
        setSettings={setSettings}
        connectedToDatabase={connectedToDatabase}
        setConnectedToDatabase={setConnectedToDatabase}
        connectedToNodeServer={connectedToNodeServer === true}
        setConnectedToNodeServer={setConnectedToNodeServer}
        testDatabaseConnection={testDatabaseConnection}
        appHasData={(imageWithEnrichedMetadataObjectsArray_All.length > 0)}
        testNodeServerConnection={testNodeServerConnection}
        databaseHost={databaseHost}
        setDatabaseHost={setDatabaseHost}
        nodeServerHost={nodeServerHost}
        setNodeServerHost={setNodeServerHost}
        setSettingsCollapseOpen={setSettingsCollapseOpen}
        setDataSource={setDataSource}
        changePathNameInputField={changePathNameInputField}
        keywordsLoadFromPathname={keywordsLoadFromPathname}
        pathNameInputField={pathNameInputField}
        setPathNameInputField={setPathNameInputField}
        ihkhsjsonHistoryItems={ihkhsjsonHistoryItems}
        loadDataFromJSONFromFilesystem={loadDataFromJSONFromFilesystem}
        pathNameInputFieldExistsOnFilesystem={pathNameInputFieldExistsOnFilesystem}
        checkPathExistsOnFilesystem={checkPathExistsOnFilesystem}
        loadDataFromFile={loadDataFromFile}
        showPreviewImages={showPreviewImages}
        setShowPreviewImages={setShowPreviewImages}
        toggleCheckForKeywordCorrections={toggleCheckForKeywordCorrections}
        filePathReplace1String={filePathReplace1String}
        filePathReplace2String={filePathReplace2String}
        changeAndSetFilePathReplace1String={changeAndSetFilePathReplace1String}
        changeAndSetFilePathReplace2String={changeAndSetFilePathReplace2String}
        filePathReplace2StringExistsOnFileSystem={filePathReplace2StringExistsOnFileSystem}
        replaceDirectoryInFilePath={replaceDirectoryInFilePath}
        useImgSrcReplacement={useImgSrcReplacement}
        setUseImgSrcReplacement={setUseImgSrcReplacement}
        useImgExtReplacement={useImgExtReplacement}
        toggleUseImgSrcReplacement={toggleUseImgSrcReplacement}
        toggleUseImgExtReplacement={toggleUseImgExtReplacement}
        checkForKeywordCorrections={checkForKeywordCorrections}
        setCheckForKeywordCorrections={setCheckForKeywordCorrections}
        doCalculateCheckNumberForLoknummer={doCalculateCheckNumberForLoknummer}
        toggleDoCalculateCheckNumberForLoknummer={toggleDoCalculateCheckNumberForLoknummer}
        fileExtReplaceString={fileExtReplaceString}
        setFileExtReplaceString={setFileExtReplaceString}
        csrfToken={csrfToken}
        importsArray={importsArray}
        systemsArray={systemsArray}
        imageWithMetadataOriginalArray={imageWithMetadataOriginalArray}
        dataSourceOnlyFilesystemAndJSONFilename={dataSourceOnlyFilesystemAndJSONFilename}
        importIsForbidden={importIsForbidden}
        updateImportsFromDatabase={updateImportsFromDatabase}
        updateSystemsFromDatabase={updateSystemsFromDatabase}      
      />

      {needsIFrameForCSRFToken && 
        <iframe id='admin-login-iframe' src={`${databaseHost}/admin/login`} sandbox='' />
      }
      <style type='text/css'>
        {customStylesString}
      </style>
    </div>
    

    
  );
}

export default App;

import React, {CSSProperties, FC} from 'react';
import Navbar from 'react-bootstrap/esm/Navbar';

interface FooterProps {
    children: JSX.Element,
}

export const Footer: FC<FooterProps> = ({
    children,
}) => {

    return (
        <div className="footer fixed-bottom">
            <Navbar color="dark">
                { children }
            </Navbar>
        </div>
    )
};
import { faCheck, faCircle, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useState } from 'react';
import { Accordion, Alert, Button, Col, Form, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { baseColumnsMappingArray, COLUMN_DEFINITION_CONST_KEYWORDS, COLUMN_DEFINITION_CONST_KEYWORDS_STRING, DATA_SOURCE_JSON, TAB_KEY_IMPORT_STEP_1, TAB_KEY_IMPORT_STEP_2, TAB_KEY_IMPORT_STEP_3 } from './constants';
import { DB_Import, DB_System, ImageWithMetadataObject, LoadingObject} from './custom_types';
import { numberWithSeparator, serialize } from './Helpers';
import { LoadingProgress } from './LoadingProgress';

interface ImportToDatabaseProps {
    databaseHost: string,
    csrfToken: string,
    imageWithMetadataOriginalArray: ImageWithMetadataObject[],
    dataSourceOnlyFilesystemAndJSONFilename: string,
    dataSource: number,
    importsArray: DB_Import[],
    systemsArray: DB_System[],
    updateImportsFromDatabase: any,
    updateSystemsFromDatabase: any,
    importIsForbidden: boolean,
}

export const ImportToDatabase: FC<ImportToDatabaseProps> = ({
    databaseHost,
    csrfToken,
    imageWithMetadataOriginalArray,
    dataSourceOnlyFilesystemAndJSONFilename,
    dataSource,
    importsArray,
    systemsArray,
    updateImportsFromDatabase,
    updateSystemsFromDatabase,
    importIsForbidden,
}) => {
    const predictedSystemNameFromJSONFilenamePartsArray = dataSourceOnlyFilesystemAndJSONFilename.split('.');
    const predictedSystemNameFromJSONFilename = predictedSystemNameFromJSONFilenamePartsArray[0].substring('01-01-1970_'.length);

    const [activeImportTabKey, setActiveImportTabKey] = useState(TAB_KEY_IMPORT_STEP_1 as string);

    const [existingImportWithSameJSONFile, setExistingImportWithSameJSONFile] = useState(null as DB_Import|null);
    const [selectedSystem, setSelectedSystem] = useState(null as DB_System|null);
    const [selectedImport, setSelectedImport] = useState(null as DB_Import|null);
    const [systemName, setSystemName] = useState(predictedSystemNameFromJSONFilename);
    const [importName, setImportName] = useState(`Import vom ${predictedSystemNameFromJSONFilenamePartsArray[0].substring(0, '01-01-1970'.length)}`);
    const [loadingObject, setLoadingObject] = useState({ isLoading: false, text: 'Import gestartet', progress: 0 } as LoadingObject);
    const [importSuccessful, setImportSuccessful] = useState(false);

    useEffect(() => {
        for (let i=0; i<importsArray.length; i++) {
            if (importsArray[i].importedfile_filename === dataSourceOnlyFilesystemAndJSONFilename) {
                if (importsArray[i].successful) {
                    setExistingImportWithSameJSONFile(importsArray[i]);
                }
            }
        }
    }, [importsArray]);

    useEffect(() => {
        if (importSuccessful === true) {
            setLoadingObject({ isLoading: false, text: 'Import beendet', progress: 0 } as LoadingObject);
        }
    }, [importSuccessful]);

    if (systemsArray.length === 0) {
        updateSystemsFromDatabase();
    }
    if (importsArray.length === 0) {
        updateImportsFromDatabase();
    }

    // @ts-ignore
    const createImportInDatabase = (event) => {
        let formParametersArray = [];
        formParametersArray.push(`csrfmiddlewaretoken=${csrfToken}`);
        formParametersArray.push(`name=${importName}`);
        formParametersArray.push(`importedfile_filename=${dataSourceOnlyFilesystemAndJSONFilename}`);
        formParametersArray.push(`basepath=todo`);
        formParametersArray.push(`successful=false`);
        formParametersArray.push(`importedfile_timestamp=${predictedSystemNameFromJSONFilenamePartsArray[0].substring(0, '01-01-1970'.length)}`);
        formParametersArray.push(`system=${databaseHost}/imagedb/system/${selectedSystem?.id}/`);
        formParametersArray.push(`import_type=${databaseHost}/imagedb/import_type/1/`);

        const formPostUrl = `${databaseHost}/imagedb/import/`;
        fetch(formPostUrl, {
            method: 'POST',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: formParametersArray.join('&')
        })
        .then(response => response.json())
        .then(response => {
                if (response['id']) {
                    setSelectedImport(response);
                }
                updateImportsFromDatabase();
        });
    }

    const createSystemInDatabase = () => {
        let formParametersArray = [];
        formParametersArray.push(`csrfmiddlewaretoken=${csrfToken}`);
        formParametersArray.push(`name=${systemName}`);

        const formPostUrl = `${databaseHost}/imagedb/system/`;
        fetch(formPostUrl, {
            method: 'POST',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: formParametersArray.join('&')
        })
        .then(response => response.json())
         .then(response => {
                if (response['id']) {
                    setSelectedSystem(response);
                }
                updateSystemsFromDatabase();
        });
    }

    // @ts-ignore
    const importToDatabase = (event) => {
        const chunkSize = 5;
        let imageWithMetadataOriginalArrayChunked : ImageWithMetadataObject[][] = [];
        for (let i = 0; i < imageWithMetadataOriginalArray.length; i += chunkSize) {
            imageWithMetadataOriginalArrayChunked.push(imageWithMetadataOriginalArray.slice(i, i + chunkSize));
        }

        const formPostUrl = `${databaseHost}/imagedb/image_post_only/`;
        
        let reponsesReiceivedSuccess = 0;
        let reponsesReiceivedFailed = 0;

        let imageIndexToImport = 0;
        
        const importNextImage = () => {
            console.log('imageIndexToImport - imageIndexToImport:' +imageIndexToImport);
            let formParametersArray = [];
            formParametersArray.push(`csrfmiddlewaretoken=${csrfToken}`);
            formParametersArray.push(`import_fk=${databaseHost}/imagedb/import/${selectedImport?.id}/`);

            for (let k=0; k<baseColumnsMappingArray.length; k++) {
                if (baseColumnsMappingArray[k].columnDefinitionConstant === COLUMN_DEFINITION_CONST_KEYWORDS) {
                    formParametersArray.push(
                        `${COLUMN_DEFINITION_CONST_KEYWORDS_STRING}=${encodeURIComponent(imageWithMetadataOriginalArray[imageIndexToImport][COLUMN_DEFINITION_CONST_KEYWORDS_STRING])}`
                    );
                } else {
                    if (imageWithMetadataOriginalArray[imageIndexToImport][baseColumnsMappingArray[k].columnDefinitionConstant]
                        && imageWithMetadataOriginalArray[imageIndexToImport][baseColumnsMappingArray[k].columnDefinitionConstant].length >= 1
                    ) {
                        formParametersArray.push(
                            `${baseColumnsMappingArray[k].columnDefinitionConstant}=${encodeURIComponent(imageWithMetadataOriginalArray[imageIndexToImport][baseColumnsMappingArray[k].columnDefinitionConstant])}`
                        );
                    }
                }
            }

            fetch(formPostUrl, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: formParametersArray.join('&')
            })
            .then(response => {
                if (response.ok) {                        
                    setImportSuccessful(true);
                    reponsesReiceivedSuccess++;
                } else {
                    reponsesReiceivedFailed++;
                    alert('Speichern fehlgeschlagen');
                    console.error('Speichern fehlgeschlagen');
                    console.error(response);
                    console.error(response.url);
                }
                
                if (reponsesReiceivedSuccess+reponsesReiceivedFailed === imageWithMetadataOriginalArray.length) {
                    // Same as in useEffect - useEffect would be better if it works reliable
                    setLoadingObject({ isLoading: false, text: 'Import beendet', progress: 0 } as LoadingObject);
                } else {
                    let newLoadingObject = Object.assign({}, loadingObject);
                    newLoadingObject.progress = Math.ceil(100*(reponsesReiceivedSuccess/imageWithMetadataOriginalArray.length));
                    newLoadingObject.isLoading = true;
                    newLoadingObject.text = `Fortschritt ${reponsesReiceivedSuccess} von ${imageWithMetadataOriginalArray.length} (${reponsesReiceivedFailed} Fehler)`;
                    setLoadingObject(newLoadingObject);
                }
                imageIndexToImport++;
                importNextImage();
            });   
            
        }

        if (imageIndexToImport<imageWithMetadataOriginalArray.length) {
            importNextImage();
        }

        //for (let i=0; i<1; i++) { 
        // for (let chunkIdx=0; chunkIdx<imageWithMetadataOriginalArrayChunked.length; chunkIdx++) {
        //     //let imageWithMetadataOriginalArrayChunk = imageWithMetadataOriginalArrayChunked[0];
        //     importNextChunk(imageWithMetadataOriginalArrayChunked[chunkIdx]);
        //     setTimeout(() => {let a = 0;}, 10000);
        // }
        //let progressCounter = 0;
        //let progressCounterIdx = 0;

        // while (progressCounter<=imageWithMetadataOriginalArray.length) {
        //     importNextChunk(imageWithMetadataOriginalArrayChunked[progressCounterIdx]);
        //     progressCounter=progressCounter+(imageWithMetadataOriginalArrayChunked[progressCounterIdx].length);
        //     progressCounterIdx++;
        //     //console.log('progressCounter:' + progressCounter + '  progressCounterIdx:' + progressCounterIdx);
        // }
    }
    const changeActiveViewTab = (newTabKey: string | null, newRequestPage?: number | null): void => {
        if (newTabKey) {
            setActiveImportTabKey(newTabKey);
        }
    };

    return (
        dataSource===DATA_SOURCE_JSON ? (
        !importIsForbidden ? (
        <div className='import-to-database'>
                <Row>
                <Col md={12}>
                    Dateiname: {dataSourceOnlyFilesystemAndJSONFilename}
                </Col>
            </Row>
            {existingImportWithSameJSONFile ? <strong>Die IHKHSJSON-Datei wurde bereits importiert.</strong> : 
            loadingObject.isLoading ? <LoadingProgress loadingObject={loadingObject} /> : (
            <>
                <Tabs className='import-tabs' activeKey={activeImportTabKey} defaultActiveKey={TAB_KEY_IMPORT_STEP_1} onSelect={(key) => changeActiveViewTab(key)}>
                    <Tab eventKey={TAB_KEY_IMPORT_STEP_1} title="Schritt 1: System-Datensatz" tabClassName='tab-primary' className='tab-with-margin'>
                        <Row>
                            <Col sm={6}>
                                <Table>
                                    <thead>
                                        <th>Name</th>
                                        <th></th>
                                    </thead>
                                    <tbody>
                                        {(typeof systemsArray !== 'undefined') && systemsArray.map((system: DB_System) => 
                                        <tr className={selectedSystem === system ? 'selected' : ''}>
                                            <td>{system.name}</td>
                                            <td>{system.description}</td>
                                            <td>
                                                <Button
                                                    size='sm'
                                                    variant='secondary'
                                                    type='button'
                                                    onClick={() => setSelectedSystem(system)}
                                                >
                                                    auswählen
                                                </Button>
                                            </td>
                                        </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                            <Col sm={6}>
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    value={systemName}
                                    onChange={(e) => setSystemName(e.target.value)}
                                />
                                <Button
                                    size='sm'
                                    disabled={systemName.length === 0}
                                    variant='secondary'
                                    type='button'
                                    onClick={createSystemInDatabase}
                                >
                                    System-Datensatz speichern
                                </Button>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey={TAB_KEY_IMPORT_STEP_2} title="Schritt 2: Import-Datensatz" tabClassName='tab-primary' className='tab-with-margin' disabled={selectedSystem===null}>
                        <Row>
                        <Col sm={6}>
                            <Table>
                                <thead>
                                    <th>Erfolg?</th>
                                    <th>Name</th>
                                    <th>Anzahl Bilder</th>
                                    <th></th>
                                </thead>
                                <tbody>
                                    {(typeof importsArray !== 'undefined') &&
                                    importsArray.map((importObject: DB_Import) => 
                                        <tr className={selectedImport === importObject ? 'selected' : ''}>
                                            <td>{importObject.successful && <FontAwesomeIcon icon={faCheck} />}</td>
                                            <td>{importObject.name}</td>
                                            <td>{numberWithSeparator(importObject.images_count)}</td>
                                            <td>
                                                <Button
                                                    size='sm'
                                                    variant='secondary'
                                                    type='button'
                                                    onClick={() => setSelectedImport(importObject)}
                                                >
                                                    auswählen
                                                </Button>
                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                            </Table>
                        </Col>
                        <Col sm={6}>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                value={importName}
                                onChange={(e) => setImportName(e.target.value)}
                            />
                            <Button
                                size='sm'
                                variant='secondary'
                                type='button'
                                onClick={createImportInDatabase}
                            >
                                Import-Datensatz speichern
                            </Button>
                        </Col>
                    </Row>
                    </Tab>
                    <Tab eventKey={TAB_KEY_IMPORT_STEP_3} title="Schritt 3: Bilder importieren" tabClassName='tab-primary' className='tab-with-margin' disabled={selectedImport===null}>
                        <Row>
                            <Col md={6}>
                                {importSuccessful &&
                                    <Alert variant='success' dismissible onClose={() => setImportSuccessful(false)}>
                                        Die Einträge wurden erfolgreich importiert.
                                    </Alert>
                                }
                                <Button
                                    size='sm'
                                    disabled={imageWithMetadataOriginalArray.length === 0}
                                    variant='secondary'
                                    type='button'
                                    onClick={importToDatabase}
                                >
                                    {numberWithSeparator(imageWithMetadataOriginalArray.length)} Einträge importieren
                                </Button>
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
                
                    
                    {/* <Accordion defaultActiveKey="0">
                    <Row>
                        <Col md={12}>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                <strong>Schritt 1: ({selectedSystemId}) System-Datensatz auswählen oder erstellen</strong>
                            </Accordion.Toggle>
                            
                        </Col>
                        <Accordion.Collapse eventKey="0">
                            <>
                                <Col md={6}>
                                    <Table>
                                        <thead>
                                            <th>Name</th>
                                            <th>Beschreibung</th>
                                            <th></th>
                                        </thead>
                                        <tbody>
                                            {systemsArray.map((system: DB_System) => 
                                            <tr>
                                                <td>{system.name}</td>
                                                <td>{system.description}</td>
                                                <td>
                                                    <Button
                                                        size='sm'
                                                        variant='secondary'
                                                        type='button'
                                                        onClick={() => setSelectedSystemId(system.id)}
                                                    >
                                                        auswählen
                                                    </Button>
                                                </td>
                                            </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        value={systemName}
                                        onChange={(e) => setSystemName(e.target.value)}
                                    />
                                    <Form.Label>Beschreibung</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        value={systemDescription}
                                        onChange={(e) => setSystemDescription(e.target.value)}
                                    />
                                    <Button
                                        size='sm'
                                        //disabled={imageWithMetadataOriginalArray.length === 0}
                                        variant='secondary'
                                        type='button'
                                        onClick={createSystemInDatabase}
                                    >
                                        System-Datensatz speichern
                                    </Button>
                                </Col>
                                <Col md={6}>

                                </Col>
                            </>
                        </Accordion.Collapse>
                    </Row>
                </Accordion>
                
                <Accordion defaultActiveKey="0">
                    <Row>
                    <Col md={12}>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                <strong>Schritt 2: ({selectedImportId}) Import-Datensatz auswählen oder erstellen</strong>
                            </Accordion.Toggle>                            
                        </Col>
                        <Accordion.Collapse eventKey="0">
                            <>                                                    
                                <Col md={6}>
                                    <Table>
                                        <thead>
                                            <th>Name</th>
                                        </thead>
                                        <tbody>
                                            {importsArray.map((importObject: DB_Import) => 
                                            <tr>
                                                <td>{importObject.name}</td>
                                                <td>
                                                    <Button
                                                        size='sm'
                                                        variant='secondary'
                                                        type='button'
                                                        onClick={() => setSelectedImportId(importObject.id)}
                                                    >
                                                        auswählen
                                                    </Button>
                                                </td>
                                            </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        value={importName}
                                        onChange={(e) => setImportName(e.target.value)}
                                    />
                                    <Form.Label>Beschreibung</Form.Label>
                                    <Button
                                        size='sm'
                                        variant='secondary'
                                        type='button'
                                        onClick={createImportInDatabase}
                                    >
                                        Import-Datensatz speichern
                                    </Button>
                                </Col>
                                <Col md={6}>
                                    
                                </Col>
                            </>
                        </Accordion.Collapse>      
                    </Row>
                </Accordion> */}

                {/* <Row>
                    <Col md={12}>
                        <strong>Schritt 3: Bilder importieren</strong>
                    </Col>
                    <Col md={12}>
                        {importSuccessful &&
                            <Alert variant='success' dismissible onClose={() => setImportSuccessful(false)}>
                                Die Einträge wurden erfolgreich importiert.
                            </Alert>
                        }
                        <Button
                            size='sm'
                            disabled={imageWithMetadataOriginalArray.length === 0}
                            variant='secondary'
                            type='button'
                            onClick={importToDatabase}
                        >
                            {imageWithMetadataOriginalArray.length} Einträge importieren
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        {importSuccessful &&
                            <Alert variant='success' dismissible onClose={() => setImportSuccessful(false)}>
                                Die Einträge wurden erfolgreich importiert.
                            </Alert>
                        }
                        <Button
                            size='sm'
                            disabled={imageWithMetadataOriginalArray.length === 0}
                            variant='secondary'
                            type='button'
                            onClick={importToDatabase}
                        >
                            {imageWithMetadataOriginalArray.length} Einträge importieren
                        </Button>
                    </Col>
                    <Col md={6}>
                    </Col>
                </Row> */}
            </>
            )}
        </div>
        ) : <Alert variant='info'>
                Login im Backend notwendig um Daten importieren zu können.
                <Button
                    size='sm'
                    disabled={imageWithMetadataOriginalArray.length === 0}
                    variant='secondary'
                    type='button'
                    onClick={() => updateImportsFromDatabase()}
                >
                    <FontAwesomeIcon icon={faSync}/> erneut probieren
                </Button>
            </Alert>
    ) : <></>)
}

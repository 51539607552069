import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useCallback, useState } from 'react';
import {useDropzone} from 'react-dropzone'

interface DropzoneProps {
    // settings: Settings,
    // setSettings: any, // function
    //handleFileSelected: any,
    loadDataFromFile: any

}

export const Dropzone: FC<DropzoneProps> = ({
    // settings,
    // setSettings,
    //handleFileSelected,
    loadDataFromFile,
}) => {

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        

        console.log("acceptedFiles");
        console.log(acceptedFiles);
        //handleFileSelected();
        loadDataFromFile(acceptedFiles[0]);
      }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    return (
    <div className='dropzone-container' {...getRootProps()}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Datei hier ablegen ...</p> :
          <>
          <FontAwesomeIcon icon={faFile} />
          <p>Hier klicken oder Datei per Drag und Drop hier ablegen</p>
          </>
      }
    </div>
    )
};

import { FC, Fragment } from "react";
import { Badge, Button, ButtonGroup, Col, Dropdown, Form, ListGroup, Row } from "react-bootstrap";
import { CombinedColumnSelect } from "./CombinedColumnSelect";
import { DATA_SOURCE_DATABASE, DATA_SOURCE_JSON, WARNING_CONDITIONS_ARRAY, WARNING_CONDITION_TYPE_DOES_MATCH_EXACT_NAME, WARNING_CONDITION_TYPE_DOES_MATCH_NAME, WARNING_CONDITION_TYPE_DOES_NOT_MATCH_NAME } from "./constants";
import { ColumnDefinition, DataFilterObject, DB_Import, DB_System, LoadingObject } from "./custom_types";
import { numberWithSeparator } from "./Helpers";


interface DataFilterRowProps {
    customColumsArray: ColumnDefinition[],
    remarksColumsArray: ColumnDefinition[],
    dataSource: number,
    loadingObject: LoadingObject,
    applyFilterFunction: any, // function
    dataFilterObjectArray: DataFilterObject[],
    setDataFilterObjectArray: any,
    initialDataFilterArray: DataFilterObject[],
    resetDataFilterObjectArray: any,
    dataCountFiltered: number,
    dataCountUnfiltered: number,
    importsArray?: DB_Import[],
    systemsArray?: DB_System[],
    selectedDBImport?: DB_Import|null,
    setSelectedDBImport: any,
    selectedDBSystem?: DB_System|null,
    setSelectedDBSystem: any,
    connectedToDatabase: boolean,
    getColumnDefinitionByCustomDefinitionConstant: any,
}

export const DataFilterRow: FC<DataFilterRowProps> = ({
    customColumsArray,
    remarksColumsArray,
    dataSource,
    loadingObject,
    applyFilterFunction,
    dataFilterObjectArray,
    setDataFilterObjectArray,
    resetDataFilterObjectArray,
    initialDataFilterArray,
    dataCountFiltered,
    dataCountUnfiltered,
    importsArray=null,
    systemsArray=null,
    selectedDBImport,
    setSelectedDBImport,
    selectedDBSystem,
    setSelectedDBSystem,
    connectedToDatabase,
    getColumnDefinitionByCustomDefinitionConstant,
}) => {

    // @ts-ignore
    const addNewEntryToDataFilterArray = () => {
        const newDataFilterObjectArray : DataFilterObject[] = Object.assign([], dataFilterObjectArray);
        newDataFilterObjectArray.push(initialDataFilterArray[0]);
        setDataFilterObjectArray(newDataFilterObjectArray);
    }

    // @ts-ignore
    const setColumnForDataFilterObject = (event) => {

        console.log('setColumnForDataFilterObject');
        //const combinedColumnDefinitionIndex = event.target.getAttribute('data-key-combined-column-definition-index');
        const dataFilterObjectIndex = event.target.getAttribute('data-key-data-filter-object-index');
        const dataKeyColumnDefinitionConstant = event.target.getAttribute('data-key-column-definition-constant');
        //debugger;
        //let combinedColumnDefinitionIndex = -1;
            
        //for (let i=0; i<combinedColumnDefinitionArray.length; i++) {
        //    if (combinedColumnDefinitionArray[i].columnDefinitionConstant === dataKeyColumnDefinitionConstant) {
        //        combinedColumnDefinitionIndex = i;
        //    }
        //}
        
        var selectedColumnDefinition = getColumnDefinitionByCustomDefinitionConstant(dataKeyColumnDefinitionConstant);
        if (selectedColumnDefinition) {
        const newDataFilterObjectArray : DataFilterObject[] = Object.assign([], dataFilterObjectArray);
            //debugger;
            newDataFilterObjectArray[dataFilterObjectIndex].column = selectedColumnDefinition;
            setDataFilterObjectArray(newDataFilterObjectArray);
        }
    }

    // @ts-ignore
    const updateSearchValueInDataFilterObject = (event) => {
        const dataFilterObjectIndex = event.target.getAttribute('data-key-data-filter-object-index');
        const newDataFilterObjectArray : DataFilterObject[] = Object.assign([], dataFilterObjectArray);
        newDataFilterObjectArray[dataFilterObjectIndex].searchValue = event.target.value;
        setDataFilterObjectArray(newDataFilterObjectArray);
    }

    // @ts-ignore
    const selectWarningConditionType = (event) => {
        const dataFilterObjectIndex = event.target.getAttribute('data-key-data-filter-object-index');
        const selectedWarningConditionType = event.target.getAttribute('data-key-selected-warning-condition-type');
        const newDataFilterObjectArray : DataFilterObject[] = Object.assign([], dataFilterObjectArray);
        newDataFilterObjectArray[dataFilterObjectIndex].conditionType = selectedWarningConditionType;
        setDataFilterObjectArray(newDataFilterObjectArray);
    }

    return (
        <div id='data-filter-row'>
            {(dataSource === DATA_SOURCE_JSON || dataSource === DATA_SOURCE_DATABASE) && (
                <Form onSubmit={(e) => {e.preventDefault(); applyFilterFunction()}}>
                    <Form.Row>
                        <Row>
                            {dataFilterObjectArray.map((dataFilterObject: DataFilterObject, dataFilterObjectIndex: number) => 
                                <Fragment key={`data-filter-row-fragment-${dataFilterObjectIndex}`}>
                                    <Col md="3">
                                        {/* <Dropdown>
                                            <Dropdown.Toggle split size='sm' variant='secondary' id={`data_filter_row${dataFilterObjectIndex}`}>
                                                {dataFilterObject.column ? `${dataFilterObject.column.nameInFrontend} ` : '-'}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {combinedColumnDefinitionArray.map((columnDefinition: ColumnDefinition, combinedColumnDefintionIndex: number) => 
                                                    <Dropdown.Item
                                                        data-key-data-filter-object-index={dataFilterObjectIndex}
                                                        data-key-combined-column-definition-index={combinedColumnDefintionIndex}
                                                        onClick={setColumnForDataFilterObject}
                                                        className={`dropdown_item_column_definition_type_${columnDefinition.columnDefinitionType}`}
                                                    >
                                                        {columnDefinition.nameInFrontend}
                                                    </Dropdown.Item>
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown> */}
                                        <CombinedColumnSelect
                                            dataSource={dataSource}
                                            importsArray={importsArray}
                                            systemsArray={systemsArray}
                                            remarksColumnsArray={remarksColumsArray}
                                            customColumnsArray={customColumsArray}
                                            idPrefix='data_filter_row'
                                            indexForUniqueId={dataFilterObjectIndex.toString()}
                                            selectedColumnDefinition={dataFilterObject.column ? dataFilterObject.column : undefined}
                                            // @ts-ignore
                                            onClickFunction={(e) => setColumnForDataFilterObject(e)}
                                            data-key-data-filter-object-index={dataFilterObjectIndex}
                                            />
                                        <Dropdown>
                                            <Dropdown.Toggle split size='sm' variant='secondary' id={`data_filter_row_${dataFilterObjectIndex}`}>
                                                {`${WARNING_CONDITIONS_ARRAY[dataFilterObject.conditionType]} `}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {Object.keys(WARNING_CONDITIONS_ARRAY).map((warningConditionKey: string, warningConditionIndex: number) =>
                                                    <Dropdown.Item
                                                        key={`data-filter-row-warningsconditions-array-${warningConditionIndex}`}
                                                        eventKey={warningConditionKey}
                                                        data-key-data-filter-object-index={dataFilterObjectIndex}
                                                        data-key-selected-warning-condition-type={warningConditionKey}                                                        
                                                        onClick={(e) => selectWarningConditionType(e)}
                                                    >
                                                        {WARNING_CONDITIONS_ARRAY[warningConditionKey]}
                                                    </Dropdown.Item>
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {[WARNING_CONDITION_TYPE_DOES_MATCH_NAME,WARNING_CONDITION_TYPE_DOES_MATCH_EXACT_NAME,WARNING_CONDITION_TYPE_DOES_NOT_MATCH_NAME].indexOf(WARNING_CONDITIONS_ARRAY[dataFilterObject.conditionType]) > -1 && (
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={dataFilterObject.searchValue}
                                                data-key-data-filter-object-index={dataFilterObjectIndex}
                                                onChange={(e) => updateSearchValueInDataFilterObject(e)}
                                            />
                                        )}
                                    </Col>
                                </Fragment>
                            )}
                            { dataFilterObjectArray.length<3 ? (
                                <Col md={9-(dataFilterObjectArray.length*3)}>
                                    {/* <OverlayTrigger
                                        placement='bottom'
                                        overlay={
                                            <Tooltip id='tooltip-bottom'>
                                            Filter hinzufügen
                                            </Tooltip>
                                        }
                                    > */}
                                        <Button
                                            size='sm'
                                            variant='outline-secondary'
                                            disabled={loadingObject.isLoading}
                                            type='button'
                                            className='data-filter-row-add-button'
                                            onClick={() => addNewEntryToDataFilterArray()}
                                        >
                                            +
                                        </Button>
                                    {/* </OverlayTrigger> */}
                                    </Col>
                            ) : <></> }
                                
                                <Col md={3}>
                                    <Row>
                                        <Col md={6}>
                                            {(dataSource === DATA_SOURCE_DATABASE && importsArray) && (
                                                <Dropdown>
                                                <Dropdown.Toggle split size='sm' variant='secondary'>
                                                    {selectedDBImport ? selectedDBImport.name : 'Import auswählen'}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className='combined-column-select-dropdown-menu'>
                                                    {importsArray.map((importObject: DB_Import) => 
                                                        <ListGroup.Item
                                                            className={selectedDBImport?.id===importObject.id ? 'selected' : ''}
                                                            onClick={() => setSelectedDBImport(importObject)}
                                                    >
                                                            <strong>{importObject.name}</strong>
                                                            <Badge variant={importObject.successful ? 'success' : 'danger'}>
                                                                {importObject.successful ? 'Import ok' : 'Import fehlgeschlagen'}
                                                            </Badge>

                                                            <Badge variant='primary'>
                                                                {numberWithSeparator(importObject.images_count)} Bilder
                                                            </Badge>
                                                            
                                                            <Badge variant='secondary'>
                                                                System: {importObject.system_name}
                                                            </Badge>

                                                            <p>{importObject.importedfile_filename}</p>
                                                        </ListGroup.Item>
                                                        
                                                    )}
                                                </Dropdown.Menu>
                                                </Dropdown>
                                            )}
                                        </Col>
                                        <Col md={6}>
                                            {(dataSource === DATA_SOURCE_DATABASE && systemsArray) && (
                                                <Dropdown>
                                                <Dropdown.Toggle split size='sm' variant='secondary'>
                                                    {selectedDBSystem ? selectedDBSystem.name : 'System auswählen'}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className='combined-column-select-dropdown-menu'>
                                                    {systemsArray.map((systemObject: DB_System) => 
                                                        <ListGroup.Item
                                                            //className={selectedColumnDefinition?.columnDefinitionConstant===cd.columnDefinitionConstant ? 'dropdown_item_column_definition_type_2 selected' : 'dropdown_item_column_definition_type_2'}
                                                            //onClick={onClickFunction}
                                                            onClick={() => setSelectedDBSystem(systemObject)}
                                                            className={selectedDBSystem?.id===systemObject.id ? 'selected' : ''}
                                                            //data-key-column-definition-constant={cd.columnDefinitionConstant}
                                                    >
                                                        {systemObject.name}
                                                        </ListGroup.Item>
                                                    )}
                                                </Dropdown.Menu>
                                                </Dropdown>
                                            )}
                                        </Col>
                                    </Row>
                                    <ButtonGroup>
                                        <Button
                                            size='lg'
                                            variant='outline-secondary'
                                            disabled={loadingObject.isLoading}
                                            type='button'
                                            onClick={(e) => {e.preventDefault(); applyFilterFunction()}}
                                        >
                                            Filtern
                                        </Button>
                                    </ButtonGroup>
                                    <ButtonGroup>
                                        <Button
                                            size='sm'
                                            variant='outline-secondary'
                                            disabled={!loadingObject.isLoading && dataFilterObjectArray[0].searchValue.length===0 && dataFilterObjectArray.length===1}
                                            type='button'
                                            onClick={() => resetDataFilterObjectArray()}
                                        >
                                            Zurücksetzen
                                        </Button>
                                    </ButtonGroup>
                                    <div>
                                        {`Zeige ${numberWithSeparator(dataCountFiltered)}/${numberWithSeparator(dataCountUnfiltered)}`}
                                    </div>
                                </Col>
                            </Row>      
                    </Form.Row>
                </Form>
            )}     
        </div>      
    );
}

import { FC, useState } from 'react';
import { Accordion, Button, Col, Container, ProgressBar, Row, Table } from "react-bootstrap";
import { ColumnDefinition, DataAnalysisColumnDefinitionHelper, DataAnalysisCountObject, ImageWithEnrichedMetadataObject, LoadingObject, PaginationObject } from "./custom_types";
import { COLUMNS_ARRAY_MAP_FOR_IMAGE_WITH_ENRICHED_METADATA_OBJECT, COLUMN_DEFINITION_CONST_DATE_TIME_CREATED, COLUMN_DEFINITION_CONST_DATE_TIME_ORIGINAL, DATA_SOURCE_DATABASE, initialBaseColumnDefinitionArray } from "./constants";
import { DataAnalysisSectionUniqueValuesTable } from './DataAnalysisSectionUniqueValuesTable';
import { DataAnalysisSectionPieCharts } from './DataAnalysisSectionPieChart';
import { LoadingProgress } from './LoadingProgress';
import ReactWordcloud from 'react-wordcloud';
import { Bar } from 'react-chartjs-2';

interface DataAnalysisProps {
    imageWithEnrichedMetadataObjectsArray: ImageWithEnrichedMetadataObject[],
    customColumnsArray: ColumnDefinition[],
    remarksColumnsArray: ColumnDefinition[],
    dataSource: number,
    mainPaginationObject: PaginationObject,
    setDataFilterObjectArray: any,
    loadingObject: LoadingObject,
    increasePercentageOfLoadingObject: any,
    setLoadingCompleteForLoadingObject: any,
    setShowDataAnalysisModal: any,
    applyFilterForDataSourceJSON: any,
}

export const DataAnalysis: FC<DataAnalysisProps> = ({
    imageWithEnrichedMetadataObjectsArray,
    customColumnsArray,
    remarksColumnsArray,
    dataSource,
    mainPaginationObject,
    setDataFilterObjectArray,
    loadingObject,
    increasePercentageOfLoadingObject,
    setLoadingCompleteForLoadingObject,
    setShowDataAnalysisModal,
    applyFilterForDataSourceJSON,
}) => {
    const [trsArray, setTrsArray] = useState([] as JSX.Element[]);
    const [columnsMap, setColumnsMap] = useState({} as any);

    const WITHOUT_DATA_STRING = '-ohne-';
    
    const mappedColumnDefinitions = [...customColumnsArray, ...remarksColumnsArray];
    ///const mappedColumnDefinitions = [...customColumnsArray, ...remarksColumnsArray, ...initialBaseColumnDefinitionArray];
    //const mappedColumnDefinitions = [...combinedColumnDefinitionArray]
    //    .filter(element => element.selectable && element.active);

    if (Object.keys(columnsMap).length === 0) {
        mappedColumnDefinitions.forEach((columnDefinition) => {
            columnsMap[columnDefinition.nameInFrontend] = { // TODO: Extract as custom_type
                amount: 0,
                columnDefinition: columnDefinition,
                countObjects: { },
            } as DataAnalysisColumnDefinitionHelper; 
        });
        setColumnsMap(columnsMap);
    }
    const populateDataAnalysisCountObject = (keywordSectionArray: string[], uniqueValuesArray: DataAnalysisCountObject) => {
        //if (keywordSectionArray) {
        //    keywordSectionArray = [];
        //}

        if (typeof keywordSectionArray.forEach === 'undefined') {
            debugger;
        }

        keywordSectionArray.forEach((s: string) => {
            if (typeof uniqueValuesArray[s] === 'undefined') {
                uniqueValuesArray[s] = { text: s, value: 1 } as DataAnalysisCountObject;
            } else {
                uniqueValuesArray[s] = { text: s, value: uniqueValuesArray[s].value + 1 } as DataAnalysisCountObject;
            }
        });
    }

    console.log('%c Performing analysis for DataAnalysis table', 'background: #f00; color: #fff');
    console.log('imageWithEnrichedMetadataObjectArray');

    imageWithEnrichedMetadataObjectsArray.forEach((imageWithEnrichedMetadata: ImageWithEnrichedMetadataObject) => {
        mappedColumnDefinitions.forEach((columnDefinition: ColumnDefinition) => {
            const imageWithMetadata1stLevelKey = COLUMNS_ARRAY_MAP_FOR_IMAGE_WITH_ENRICHED_METADATA_OBJECT[columnDefinition.columnDefinitionType];
            if (typeof imageWithEnrichedMetadata[imageWithMetadata1stLevelKey][columnDefinition.columnDefinitionConstant] !== 'undefined') {
                columnsMap[columnDefinition.nameInFrontend].amount += 1;
            }
        });
    });

    //increasePercentageOfLoadingObject(20, 'Datenanalyse vorbereiten');
    //setLoadingCompleteForLoadingObject();
    if (trsArray.length === 0) {
        Object.keys(columnsMap).map((columnsMapKey: string, columnDefinitionIdx:number ) => {
            let dataAnalysisColumnDefinitionHelperObject = columnsMap[columnsMapKey];
            trsArray.push(
                <tr key={columnDefinitionIdx}>
                    <td className={`column_definition_type_${dataAnalysisColumnDefinitionHelperObject.columnDefinition.columnDefinitionType}`}>
                        {dataAnalysisColumnDefinitionHelperObject.columnDefinition.nameInFrontend}
                    </td>
                    <td>
                        {dataAnalysisColumnDefinitionHelperObject.amount.toLocaleString('de-DE', { maximumFractionDigits: 0 })}
                    </td>
                    <td>
                        <ProgressBar now={(dataAnalysisColumnDefinitionHelperObject.amount / mainPaginationObject.totalImages) * 100} />
                    </td>
                </tr>
            );
        });
        setTrsArray(trsArray);
    }

    //increasePercentageOfLoadingObject(20);

    const START_YEAR = 1900;
    const END_YEAR = new Date().getFullYear();
    const COLUMN_DEFINTION_CONSTANT_DATUM = 'custom_column_datum_0';
    let yearsMap = {};
    yearsMap['unbekannt'] = {text: 'unbekannt', value: 0};
    for (let y=START_YEAR; y<END_YEAR; y++) {
        yearsMap[y] = {text: `${y}`, value: 0};
    }
    const yearRegex = /(19|20)\d{2}/;
    imageWithEnrichedMetadataObjectsArray.forEach((imageWithMetadata: ImageWithEnrichedMetadataObject) => {
        var tmpOnlyNumbers = 'unbekannt';
        
        if (typeof imageWithMetadata['customColumns'][COLUMN_DEFINTION_CONSTANT_DATUM] !== 'undefined' && imageWithMetadata['customColumns'][COLUMN_DEFINTION_CONSTANT_DATUM].length) {
            if (typeof imageWithMetadata['customColumns'][COLUMN_DEFINTION_CONSTANT_DATUM] === 'string') {
                var yearMatches = imageWithMetadata['customColumns'][COLUMN_DEFINTION_CONSTANT_DATUM].match(yearRegex);
                if (yearMatches) {
                    tmpOnlyNumbers = yearMatches[0];
                }
            } else { 
                var yearMatches = imageWithMetadata['customColumns'][COLUMN_DEFINTION_CONSTANT_DATUM][0].match(yearRegex);
                if (yearMatches) {
                    tmpOnlyNumbers = yearMatches[0];
                }
            }
        // @ts-ignore
        //} else if (typeof imageWithMetadata['baseColumns'][COLUMN_DEFINITION_CONST_DATE_TIME_ORIGINAL] !== 'undefined' && imageWithMetadata['baseColumns'][COLUMN_DEFINITION_CONST_DATE_TIME_ORIGINAL].length) {
        //} else if (typeof imageWithMetadata['baseColumns'][COLUMN_DEFINITION_CONST_DATE_TIME_ORIGINAL] === 'object') {
        } else if (typeof imageWithMetadata['baseColumns'][COLUMN_DEFINITION_CONST_DATE_TIME_ORIGINAL] !== 'undefined' && imageWithMetadata['baseColumns'][COLUMN_DEFINITION_CONST_DATE_TIME_ORIGINAL]) {            
            if (typeof imageWithMetadata['baseColumns'][COLUMN_DEFINITION_CONST_DATE_TIME_ORIGINAL] === 'string') {
                // @ts-ignore
                var yearMatches = imageWithMetadata['baseColumns'][COLUMN_DEFINITION_CONST_DATE_TIME_ORIGINAL].match(yearRegex);
                if (yearMatches) {
                    tmpOnlyNumbers = yearMatches[0];
                }
            } else {
                //var b = imageWithMetadata['baseColumns'][COLUMN_DEFINITION_CONST_DATE_TIME_ORIGINAL];
                debugger;
            }
        } else if (typeof imageWithMetadata['baseColumns'][COLUMN_DEFINITION_CONST_DATE_TIME_CREATED] !== 'undefined' && imageWithMetadata['baseColumns'][COLUMN_DEFINITION_CONST_DATE_TIME_CREATED]) {            
            if (typeof imageWithMetadata['baseColumns'][COLUMN_DEFINITION_CONST_DATE_TIME_CREATED] === 'string') {
                // @ts-ignore
                var yearMatches = imageWithMetadata['baseColumns'][COLUMN_DEFINITION_CONST_DATE_TIME_CREATED].match(yearRegex);
                if (yearMatches) {
                    tmpOnlyNumbers = yearMatches[0];
                }
            } else {
                //var b = imageWithMetadata['baseColumns'][COLUMN_DEFINITION_CONST_DATE_TIME_ORIGINAL];
                debugger;
            }
        }

        if (tmpOnlyNumbers.length==4) {
            if (typeof yearsMap[tmpOnlyNumbers] === 'undefined') {
                debugger
            } else {
                yearsMap[tmpOnlyNumbers] = {text: tmpOnlyNumbers, value: yearsMap[tmpOnlyNumbers].value+1};
            }
        }
        else {
            yearsMap['unbekannt'] = {text: 'unbekannt', value: yearsMap['unbekannt'].value+1};    
        }

        Object.keys(columnsMap).map((columnsMapKey: string, columnDefinitionIdx:number ) => {
            let dataAnalysisColumnDefinitionHelperObject = columnsMap[columnsMapKey];

            const imageWithMetadata1stLevelKey = COLUMNS_ARRAY_MAP_FOR_IMAGE_WITH_ENRICHED_METADATA_OBJECT[dataAnalysisColumnDefinitionHelperObject.columnDefinition.columnDefinitionType];
            let tmpValue = imageWithMetadata[imageWithMetadata1stLevelKey][dataAnalysisColumnDefinitionHelperObject.columnDefinition.columnDefinitionConstant];
            if (typeof tmpValue === 'string') {
                populateDataAnalysisCountObject([tmpValue], dataAnalysisColumnDefinitionHelperObject.countObjects);
            } else if (typeof tmpValue === 'object') {
                populateDataAnalysisCountObject(tmpValue ? tmpValue : [WITHOUT_DATA_STRING], dataAnalysisColumnDefinitionHelperObject.countObjects);
            }
        });
    });

    function compareDataAnalysisCountObject(a: DataAnalysisCountObject, b: DataAnalysisCountObject) {
        return (a.value > b.value) ? -1 : 1;
    }

    // Remove years from the beginning where value is 0
    for (let y=START_YEAR; y<END_YEAR; y++) {
        if (yearsMap[y].value===0) {
            delete yearsMap[y];
        } else {
            break;
        }
    }

    let wordCloudWordsArray = [] as DataAnalysisCountObject[];

    ['Betrieb', 'Ort'].map((columnDefitinionConstant: string) => {
        if (typeof columnsMap[columnDefitinionConstant] !== 'undefined') {
            wordCloudWordsArray = wordCloudWordsArray.concat(Object.values(columnsMap[columnDefitinionConstant].countObjects));
        }
    });
    wordCloudWordsArray = wordCloudWordsArray.sort(compareDataAnalysisCountObject).splice(0,40);

    return (
        <>
            {loadingObject.isLoading ? <LoadingProgress loadingObject={loadingObject} /> : (
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <h4>Bilder nach Jahr</h4>
                        <Bar
                            //options={options}
                            data={{
                                labels: Object.keys(yearsMap),
                                datasets: [
                                    {
                                      label: 'Dataset 1',
                                      // @ts-ignore
                                      data: Object.values(yearsMap).map((dataAnalysisCountObject: DataAnalysisCountObject) => dataAnalysisCountObject.value),
                                      backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                    },
                                ]
                            }}
                        />
                    </Col>
{/*                     <Col md={12}>
                        <ReactWordcloud
                            options={{
                                rotations:0,
                                fontSizes: [15, 45],
                                enableTooltip: false,
                                transitionDuration: 0
                                // colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
                                // enableTooltip: true,
                                // deterministic: false,
                                // fontFamily: "impact",
                                // fontSizes: [5, 60],
                                // fontStyle: "normal",
                                // fontWeight: "normal",
                                // padding: 1,
                                // rotations: 3,
                                // rotationAngles: [0, 90],
                                // scale: "sqrt",
                                // spiral: "archimedean",
                                // transitionDuration: 1000
                            }}
                            words={wordCloudWordsArray}
                        />
                    </Col> */}
                </Row>
                <Row>
                    <Col md={3}>
                    </Col>
                    <Col md={6}>
                        <p>Daten für {mainPaginationObject.totalImages.toLocaleString('de-DE', { maximumFractionDigits: 0 })} Bilder geladen</p>
                        {dataSource === DATA_SOURCE_DATABASE && (
                            <b>Achtung: Ergebnisse nur von aktueller Seite, nicht aus der gesamten Datenbank!</b>
                        )}
                    </Col>
                    <Col md={3}>
                    </Col>
                </Row>
                <Row className='justify-content-md-center'>
                    <Col md={6}>
                        <h4>Wieviele Bilder enthalten welche Metadaten?</h4>
                        <div className='container-data-analysis'>
                            <Table className='data-analysis'>
                                <thead>
                                    <tr>
                                        <th>Spalte</th>
                                        <th colSpan={2}>Bilder mit Daten</th>
                                    </tr>
                                </thead>
                                <tbody>  
                                    {trsArray}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {Object.keys(columnsMap).map((columnsMapKey: string, columnDefinitionIdx: number ) => 
                        <Col md={6} key={'data_analysis_data_analysis_section_col_' + columnDefinitionIdx}>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Toggle as={Button} variant="link" eventKey={`accordion_${columnDefinitionIdx}`}>
                                    {columnsMap[columnsMapKey].columnDefinition.nameInFrontend}
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={`accordion_${columnDefinitionIdx}`}>
                                    <>
                            {/*             <Row>
                                            <Col>
                                            <ReactWordcloud
                                                words={wordCloudWordsArray}
                                                options={{rotations:0, enableTooltip: false}}
                                            />
                                            </Col>
                                        </Row> */}
                                        <Row>
                                            <Col>
                                                <h2>{columnsMapKey}</h2>
                                            </Col>
                                        </Row>
                                        <DataAnalysisSectionPieCharts
                                            headline={columnsMapKey}
                                            totalImages={mainPaginationObject.totalImages}
                                            setDataFilterObjectArray={setDataFilterObjectArray}
                                            columnDefinition={columnsMap[columnsMapKey].columnDefinition}
                                            columnsMap={columnsMap}
                                            // @ts-ignore
                                            uniqueValuesArray={Object.values(columnsMap[columnsMapKey].countObjects).sort(compareDataAnalysisCountObject)}
                                        />
                                        {/* <ReactWordcloud words={columnsMap[columnsMapKey]} /> */}
                                        <DataAnalysisSectionUniqueValuesTable
                                            headline={columnsMapKey}
                                            totalImages={mainPaginationObject.totalImages}
                                            setDataFilterObjectArray={setDataFilterObjectArray}
                                            columnDefinition={columnsMap[columnsMapKey].columnDefinition}
                                            columnsMap={columnsMap}
                                            // @ts-ignore
                                            uniqueValuesArray={Object.values(columnsMap[columnsMapKey].countObjects).sort(compareDataAnalysisCountObject)}
                                            applyFilterForDataSourceJSON={applyFilterForDataSourceJSON}
                                            setShowDataAnalysisModal={setShowDataAnalysisModal}
                                        />
                                    </>
                                </Accordion.Collapse>
                            </Accordion>
                        </Col>
                    )}
                </Row>
            </Container>
        )}
        </>
    )
};

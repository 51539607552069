import { CSSProperties, FC, useState } from 'react';
import { ColumnDefinition, ColumnsForViewObject, DB_KeywordCorrection, ImageWithEnrichedMetadataObject, PaginationObject, Settings } from "./custom_types";
import { COLUMNS_ARRAY_MAP_FOR_IMAGE_WITH_ENRICHED_METADATA_OBJECT, COLUMNS_FOR_DETAIL_VIEW_KEY, COLUMN_DEFINITION_CONST_SOURCE_FILE } from "./constants";
import { getUrlForGetFile } from './Helpers';
import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { TabsWithSearches } from './TabsWithSearches';
import { CommentsOverview } from './CommentsOverview';
import { KeywordsTable } from './KeywordsTable/KeywordsTable';

interface ImageDetailViewProps {
    imageWithEnrichedMetadataObjectsArray_All: ImageWithEnrichedMetadataObject[],
    imageWithEnrichedMetadataObject: ImageWithEnrichedMetadataObject,
    columnsForViewArray: ColumnsForViewObject,
    settings: Settings,
    connectedToDatabase: boolean,
    connectedToNodeServer: boolean,
    selectRandomImageIndex?: any, //function
    replaceDirectoryInFilePath: any, //function,
    selectedSourceFileValues: string[],
    setSelectedSourceFileValues: any, //function
    mainPaginationObject: PaginationObject,
    setMainPaginationObject: any,
    changeActiveViewTab: any,
    showPreviewImages: boolean,
    relatedColumnsArray: ColumnDefinition[],
    databaseHost: string,
    csrfToken: string,
    settingNameForSavingComments: string,
    setSettingNameForSavingComments: any,
    dataSourceOnlyFilesystemAndJSONFilename: string,
    keywordCorrectionsArray: DB_KeywordCorrection[],
    slideshowIntervalVariable: any, 
    renderObjectsMap: any, 
}

export const ImageDetailView: FC<ImageDetailViewProps> = ({
    imageWithEnrichedMetadataObjectsArray_All,
    imageWithEnrichedMetadataObject,
    columnsForViewArray,
    settings,
    connectedToDatabase,
    connectedToNodeServer,
    replaceDirectoryInFilePath,
    selectedSourceFileValues,
    setSelectedSourceFileValues,
    mainPaginationObject,
    setMainPaginationObject,
    changeActiveViewTab,
    showPreviewImages,
    relatedColumnsArray,
    databaseHost,
    csrfToken,
    settingNameForSavingComments,
    setSettingNameForSavingComments,
    dataSourceOnlyFilesystemAndJSONFilename,
    keywordCorrectionsArray,
    slideshowIntervalVariable,
    renderObjectsMap,
}) => {
    const [showTabsWithSearches, setShowTabsWithSearches] = useState(false);
    const [showTabsWithKeywordCorrections, setShowTabsWithKeywordCorrections] = useState(true);

    const COLUMN_FOR_VIEW_KEY = COLUMNS_FOR_DETAIL_VIEW_KEY;

    const style: CSSProperties = {
        top: '63%',
        bottom: 'inherit',
        overflowY: 'auto',
    };

    // @ts-ignore
    const loadNextRequestPage = (event) => {
        const currentPage = parseInt(event.target.getAttribute('data-key-request-page'));
        if (currentPage<mainPaginationObject.maxPage) {
            const newMainPagination = Object.assign({}, mainPaginationObject);
            newMainPagination.requestPage=currentPage+1;
            setMainPaginationObject(newMainPagination);
        }
    }

    // @ts-ignore
    const loadPrevRequestPage = (event) => {
        const currentPage = parseInt(event.target.getAttribute('data-key-request-page'));
        if (currentPage>1) {
            const newMainPagination = Object.assign({}, mainPaginationObject);
            newMainPagination.requestPage=currentPage-1;
            setMainPaginationObject(newMainPagination);
        }
    }

    return (
        <>
            <div className='keywords-table-container' style={style}>
                <KeywordsTable
                    idPrefix='image_detail_view_keywords_table'
                    settings={settings}
                    editPossible={false}
                    connectedToDatabase={connectedToDatabase}
                    connectedToNodeServer={connectedToNodeServer}
                    imageWithEnrichedMetadataObjectsArray={imageWithEnrichedMetadataObject ? [imageWithEnrichedMetadataObject] : []}
                    columnsArray={columnsForViewArray[COLUMN_FOR_VIEW_KEY]}
                    selectedSourceFileValues={selectedSourceFileValues}
                    setSelectedSourceFileValues={setSelectedSourceFileValues}
                    changeActiveViewTab={changeActiveViewTab}
                    renderObjectsMap={renderObjectsMap}
                />
            </div>
            {imageWithEnrichedMetadataObject && (
                <>  
                {showPreviewImages && (
                    <>
                        <Row>
                            <Col xs={3}>
                                {!slideshowIntervalVariable &&
                                    <div className='image-detail-view-change-page-button' data-key-request-page={mainPaginationObject.requestPage} onClick={(e) => loadPrevRequestPage(e)}>
                                        <FontAwesomeIcon icon={faArrowLeft} />
                                    </div>
                                }
                            </Col>
                            <Col xs={6}>
                                <Card.Img
                                    variant="top"
                                    src={getUrlForGetFile(connectedToNodeServer, { filePath: replaceDirectoryInFilePath(imageWithEnrichedMetadataObject[COLUMNS_ARRAY_MAP_FOR_IMAGE_WITH_ENRICHED_METADATA_OBJECT[1]][COLUMN_DEFINITION_CONST_SOURCE_FILE]) })} />
                            </Col>     
                            <Col xs={3}>
                                {!slideshowIntervalVariable &&
                                    <div className='image-detail-view-change-page-button' data-key-request-page={mainPaginationObject.requestPage} onClick={(e) => loadNextRequestPage(e)}>
                                        <FontAwesomeIcon icon={faArrowRight} />    
                                    </div>
                                }
                            </Col>         
                        </Row>
                    </>
                )}
                {connectedToDatabase &&
                    <CommentsOverview
                        databaseHost={databaseHost}
                        csrfToken={csrfToken}
                        formValueSourcefile={imageWithEnrichedMetadataObject.baseColumns.sourcefile}
                        settingNameForSavingComments={settingNameForSavingComments}
                        setSettingNameForSavingComments={setSettingNameForSavingComments}
                        dataSourceOnlyFilesystemAndJSONFilename={dataSourceOnlyFilesystemAndJSONFilename}
                    />
                }
                {!slideshowIntervalVariable &&
                    <Accordion
                        defaultActiveKey={showTabsWithSearches?'0':''}
                    >
                        <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setShowTabsWithSearches(!showTabsWithSearches)}>
                            {showTabsWithSearches ? 'Verwandte Bilder verstecken' : 'Verwandte Bilder anzeigen'}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            {showTabsWithSearches ?
                            <TabsWithSearches
                                imageWithEnrichedMetadataObjectsArray_All={imageWithEnrichedMetadataObjectsArray_All}
                                settings={settings}
                                selectedImageWithEnrichedMetadataObject={imageWithEnrichedMetadataObject}
                                columnsForViewArray={columnsForViewArray}
                                columnForViewKey={COLUMN_FOR_VIEW_KEY}
                                relatedColumnsArray={relatedColumnsArray}
                                renderObjectsMap={renderObjectsMap}
                            />
                            : <></> }
                        </Accordion.Collapse>
                    </Accordion>
                }
                </>
            )}
        </>
    )
}

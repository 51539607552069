import React, {FC} from 'react';

interface ScrollContainerProps {
    height: string,
    children: JSX.Element
}

export const ScrollContainer: FC<ScrollContainerProps> = ({
    height,
    children
}) => {

    return (
        <div style={{height: height, overflowX: "scroll"}}>
            {children}
        </div>
    )
};

import { faCalendar, faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { QRCodeSVG } from "qrcode.react";
import React, {FC, useEffect, useState} from "react";
import {Button, Card, Col, Dropdown, Form, Row} from "react-bootstrap";
import {ColumnPresetFile, DataFilePresetFile} from "./custom_types";
import { numberWithSeparator } from "./Helpers";

interface QRCodeAndLinkPageProps {
    columnPresetFilesArray: ColumnPresetFile[],
    presetDataFilesArray: DataFilePresetFile[],
}

export const QRCodeAndLinkPage: FC<QRCodeAndLinkPageProps> = ({
    columnPresetFilesArray,
    presetDataFilesArray,
}) => {

    //const baseURLforQRCodeInitialValue = 'http://localhost:3000/';
    //const baseURLforQRCodeInitialValue = 'http://192.168.178.149:3000/';
    const baseURLforQRCodeInitialValue = 'http://www.khsbilder.de';

    const [baseURLforQRCode, setBaseURLforQRCode] = useState(baseURLforQRCodeInitialValue);

    const [selectedDataFilePresetFilename, setSelectedDataFilePresetFilename] = useState('');
    const [selectedColumnPresetFilename, setSelectedColumnPresetFilename] = useState('');
    const [filePathReplace1String, setFilePathReplace1String] = useState('');
    const [filePathReplace2String, setFilePathReplace2String] = useState('');
    const [selectedDataFilePreset, setSelectedDataFilePreset] = useState(undefined as DataFilePresetFile|undefined);
    const [selectedColumnPreset, setSelectedColumnPreset] = useState(undefined as ColumnPresetFile|undefined);
    const [urlForQRCode, setUrlForQRCode] = useState('');


    
    const getUrlForQrCode = (baseURLforQRCode: string, selectedDataFilePresetFilename: string, selectedColumnPresetString: string, frp1String: string, frp2String: string) => {
        let tmpUrl = '';
        if (baseURLforQRCode) {
            const tmpBaseUrl = baseURLforQRCode[baseURLforQRCode.length-1]==='/' ? baseURLforQRCode.substring(0, baseURLforQRCode.length-1) : baseURLforQRCode;
            tmpUrl = tmpBaseUrl;
            if (selectedDataFilePreset && baseURLforQRCode) {
                
                tmpUrl = `${tmpBaseUrl}/?loadjson=${selectedDataFilePresetFilename}`;
                if (selectedColumnPreset) {
                    tmpUrl = `${tmpUrl}&loadcustomcolumns=${selectedColumnPresetString}`;
                }
                if (frp1String || frp2String) {
                    tmpUrl = `${tmpUrl}&frp1=${frp1String}&frp2=${frp2String}`;

                }
            }
        }
        return tmpUrl;
    }

    useEffect(() => {
        let tmpUrl = getUrlForQrCode(
                baseURLforQRCode,
                (selectedDataFilePreset ? selectedDataFilePreset.filename : ''),
                (selectedColumnPreset ? selectedColumnPreset?.filename : ''),
                filePathReplace1String,
                filePathReplace2String
            );
        setUrlForQRCode(tmpUrl);
    }, [selectedColumnPreset, selectedDataFilePreset, baseURLforQRCode]);

    useEffect(() => {
        let tmpUrl = getUrlForQrCode(
                baseURLforQRCode,
                //selectedDataFilePresetFilename,
                (selectedDataFilePresetFilename ? selectedDataFilePresetFilename : (selectedDataFilePreset ? selectedDataFilePreset.filename : '')),
                (selectedColumnPresetFilename ? selectedColumnPresetFilename : (selectedColumnPreset ? selectedColumnPreset.filename : '')),
                //(selectedColumnPreset ? selectedColumnPreset?.filename : ''),
                filePathReplace1String,
                filePathReplace2String
            );
        setUrlForQRCode(tmpUrl);
    }, [selectedDataFilePresetFilename, selectedColumnPresetFilename]);

    // useEffect(() => {
    //     let tmpUrl = getUrlForQrCode(
    //             baseURLforQRCode,
    //             (selectedDataFilePresetFilename ? selectedDataFilePresetFilename : (selectedDataFilePreset ? selectedDataFilePreset.filename : '')),
    //             selectedColumnPresetFilename,
    //             filePathReplace1String,
    //             filePathReplace2String
    //         );
    //     setUrlForQRCode(tmpUrl);
    // }, [selectedColumnPresetFilename]);

    

    // @ts-ignore
    const changeAndSetFilePathReplace1String = (newValue: string) => {
        setFilePathReplace1String(newValue);
    }

    // @ts-ignore
    const changeAndSetFilePathReplace2String = (newValue: string) => {
        setFilePathReplace2String(newValue);
    }


    // @ts-ignore
    const selectDataFilePresetFile = (event) => {
        const dataFilePresetFileIndexDataKey = event.currentTarget.getAttribute('data-data-file-preset-file-index');
        let tmp = presetDataFilesArray[dataFilePresetFileIndexDataKey];
        setSelectedDataFilePresetFilename('');
        setSelectedDataFilePreset(tmp);
    }

    // @ts-ignore
    const selectColumnPresetFile = (event) => {
        const columPresetFileIndexDataKey = event.target.getAttribute('data-column-preset-file-index');
        let tmp = columnPresetFilesArray[columPresetFileIndexDataKey];
        setSelectedColumnPresetFilename('');
        setSelectedColumnPreset(tmp);
    }

    // @ts-ignore
    const updateDataFileString = (event) => {
        //const columPresetFileIndexDataKey = event.target.getAttribute('data-column-preset-file-index');
        //let tmp = columnPresetFilesArray[columPresetFileIndexDataKey];
        //setSelectedColumnPreset(tmp);
    }

    return (
        <>
            <h2>QR-Code generieren</h2>
            <Row>
                <Col md={8}>
                    <div className='qr_code_and_link_page_section'>
                        <p><strong>1. Basis-URL eingeben</strong></p>
                        <Form.Group controlId="settings-group">
                            <Form.Control
                                type="text"
                                size="sm"
                                value={baseURLforQRCode}
                                onChange={(event) => setBaseURLforQRCode(event.target.value)}
                                placeholder="http://..."
                            />
                        </Form.Group>
                    </div>
                    <div className='qr_code_and_link_page_section'>
                        <p><strong>2. Datendatei auswählen</strong></p>
                        <Dropdown>
                            <Dropdown.Toggle split size='sm' variant='secondary' id='qr-code-and-link-page-data-file-select-dropdown'>
                                {selectedDataFilePreset ? selectedDataFilePreset.filename : '-nichts ausgewählt-'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {presetDataFilesArray.map((dataFilePresetFile: DataFilePresetFile, dataFilePresetFileIndex: number) => 
                                    <Dropdown.Item
                                        key={`qr-code-and-link-page-dropdown-1-item-${dataFilePresetFileIndex}`}
                                        data-data-file-preset-file-index={dataFilePresetFileIndex}
                                        onClick={selectDataFilePresetFile}
                                    >
                                        <p><strong>{dataFilePresetFile.filename}</strong></p>
                                        <p><FontAwesomeIcon icon={faCalendar} /> {dataFilePresetFile.dateString}</p>
                                        <p><FontAwesomeIcon icon={faLayerGroup} /> {numberWithSeparator(dataFilePresetFile.amountOfFiles)} Einträge </p>
                                    </Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                        <p>oder Datendatei hier eintragen</p>
                        <Form.Control
                                type="text"
                                size="sm"
                                value={selectedDataFilePresetFilename}
                                onChange={(event) => setSelectedDataFilePresetFilename(event.target.value)}
                                placeholder=".ihkhsjson"
                            />
                    </div>
                    <div className='qr_code_and_link_page_section'>
                        <p><strong>3. (optional) Spalten-Voreinstellung auswählen</strong></p>
                        <Dropdown>
                            <Dropdown.Toggle split size='sm' variant='secondary' id='qr-code-and-link-page-column-select-dropdown'>
                                {selectedColumnPreset ? selectedColumnPreset.filename : '-nichts ausgewählt-'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {columnPresetFilesArray.map((columnPresetFile: ColumnPresetFile, columnPresetFileIndex: number) => 
                                    <Dropdown.Item
                                        key={`qr-code-and-link-page-dropdown-2-item-${columnPresetFileIndex}`}
                                        data-column-preset-file-index={columnPresetFileIndex}
                                        onClick={selectColumnPresetFile}
                                    >
                                        {columnPresetFile.filename}
                                    </Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                        <p>oder Spalten-Voreinstellunsdatei hier eintragen</p>
                        <Form.Control
                            type="text"
                            size="sm"
                            value={selectedColumnPresetFilename}
                            onChange={(event) => setSelectedColumnPresetFilename(event.target.value)}
                            placeholder=".ihkhscustomcolumns"
                        />
                        <p><strong>4. Pfad-Ersetzung für Bilder</strong></p>
                        <Form.Group controlId="grid-view-file-path-replace">
                            <Form.Control
                                type="text"
                                value={filePathReplace1String}
                                onChange={(event) => changeAndSetFilePathReplace1String(event.target.value)}
                                placeholder="Ersetzung Von"
                            />
                            <Form.Control
                                type="text"
                                value={filePathReplace2String}
                                onChange={(event) => changeAndSetFilePathReplace2String(event.target.value)}
                                placeholder="Ersetzung Nach"
                            />
                            {/* {filePathReplace2StringExistsOnFileSystem ? <div>gefunden</div> : <div>nicht gefunden</div>} */}
                        </Form.Group>

                    </div>
                </Col>
                <Col md={4}>
                    <Card style={{ width: '18rem' }}>
                        <p>{urlForQRCode}</p>
                    { urlForQRCode &&
                        <Card.Body>
                            <QRCodeSVG value={urlForQRCode} />
                        </Card.Body>
                    }
                    </Card>
                </Col>
            </Row>
        </>
    );
}

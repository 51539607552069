import { FC, useState } from "react";
import { ColumnDefinition, ColumnsForViewObject, ConditionConfigType, CustomColumnDefinition, CustomColumnRegex, CustomColumnSource, ExchangeCustomColumnDefinition, ExchangeRemarksColumnDefinition, ImageWithMetadataObject, RemarkDefinition, RemarksColumnDefinition, SearchReplaceDefinition, Settings } from "./custom_types";
import { Accordion, Alert, Button, Card, Form, FormLabel, InputGroup, Tab, Table, Tabs } from "react-bootstrap";
import { COLUMNS_FOR_COMPARE_VIEW_KEY, COLUMNS_FOR_DETAIL_VIEW_KEY, COLUMNS_FOR_FOLDER_VIEW_KEY, COLUMNS_FOR_GRID_VIEW_KEY, COLUMNS_FOR_TABLE_VIEW_KEY, COLUMNS_FOR_VIEWS_ARRAY, COLUMN_DEFINITION_CONST_KEYWORDS, COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, COLUMN_DEFINITION_TYPE_CUSTOM_COLUMN_DEFINITION, CUSTOM_COLUMN_TAB_KEY_ADD, DEFAULT_RENDER_CONFIG_OBJECT, DEFAULT_WITH_FOR_NEW_COLUMNS, emptyImageWithMetadataObject, FORMATFUNCTION_DEFAULT_KEY, RENDER_OBJECT_MAP_DEFAULT_KEY, requiredPropertiesOfImageWithMetadataObjectArray } from "./constants";
import { getUniqueValueForCustomColumnConstant, getUniqueValueForRenderObjectsConstant } from "./Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlasses, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FormatFunctionSelectWithPreview } from "./FormatFunctionSelectWithPreview";
import { CombinedColumnSelect } from "./CombinedColumnSelect";
import { AdditionalColumnsRepository } from "./AdditionalColumnsRepository";

interface CustomColumnsTableProps { 
    dataSource: number,
    customColumnsArray: CustomColumnDefinition[],
    remarksColumnsArray: RemarksColumnDefinition[],
    setCustomColumnsArray: any, // function
    columnDefinitionArray: ColumnDefinition[],
    setImagesWithMetadataNeedsNewEnrichment: any,
    columnsForViewArray: ColumnsForViewObject,
    setColumnsForViewArray: any,
    getColumnDefinitionByCustomDefinitionConstant: any,
    enrichImageWithMetadataObjectArray: any,
    settings: Settings,
    activeCustomColumnTabKey: string,
    setActiveCustomColumnTabKey: any,
    renderObjectsMap: any,
    //additionalColumnsRepoArray: CustomColumnDefinition[],
    additionalColumnsRepoRemarkColumnsArray: ExchangeRemarksColumnDefinition[],
    additionalColumnsRepoCustomColumnsArray: ExchangeCustomColumnDefinition[],
    setRemarksColumnsArray: any,
    setActiveRemarksColumnTabKey: any,
    setActiveTabKeyColumnDefinitionModal: any,
}

export const CustomColumnsTable: FC<CustomColumnsTableProps> = ({
    dataSource,
    columnDefinitionArray,
    remarksColumnsArray,
    customColumnsArray,
    setCustomColumnsArray,
    setImagesWithMetadataNeedsNewEnrichment,
    columnsForViewArray,
    setColumnsForViewArray,
    getColumnDefinitionByCustomDefinitionConstant,
    enrichImageWithMetadataObjectArray,
    activeCustomColumnTabKey,
    setActiveCustomColumnTabKey,
    settings,
    renderObjectsMap,
    //additionalColumnsRepoArray,
    additionalColumnsRepoRemarkColumnsArray,
    additionalColumnsRepoCustomColumnsArray,
    setRemarksColumnsArray,
    setActiveRemarksColumnTabKey,
    setActiveTabKeyColumnDefinitionModal,
}) => {
    const [exampleImageWithMetadataObject, setExampleImageWithMetadataObject] = useState({...emptyImageWithMetadataObject})

     // @ts-ignore
    const selectSourceColumn = (event) => {
        const customColumnIndexDataKey = event.target.getAttribute('data-key-custom-column-index');
        const customColumnSourceIndexDataKey = event.target.getAttribute('data-key-custom-column-source-index');
        const dataKeyColumnDefinitionConstant = event.target.getAttribute('data-key-column-definition-constant');

        var selectedColumnDefinition = getColumnDefinitionByCustomDefinitionConstant(dataKeyColumnDefinitionConstant);
        if (selectedColumnDefinition) {
            const newCustomColumnsArray : CustomColumnDefinition[] = Object.assign([], customColumnsArray);
            newCustomColumnsArray[customColumnIndexDataKey].customColumnSourceArray[customColumnSourceIndexDataKey].sourceColumn = selectedColumnDefinition;
            setCustomColumnsArray(newCustomColumnsArray);
            setImagesWithMetadataNeedsNewEnrichment(true);
        }
    }
    
    // @ts-ignore
    const updateNameInFrontend = (event) => {
        const customColumnIndexDataKey = event.target.getAttribute('data-key-custom-column-index');
        const newCustomColumnsArray : CustomColumnDefinition[] = Object.assign([], customColumnsArray);
        const newNameInFrontend = event.target.value;
        var newColumnDefinitionConstant = getUniqueValueForCustomColumnConstant(
            newNameInFrontend,
            newCustomColumnsArray[customColumnIndexDataKey].columnDefinitionType,
            customColumnsArray,
            remarksColumnsArray
        );

        // Update entries in columnsForViewArray
        for (let i=0; i<COLUMNS_FOR_VIEWS_ARRAY.length; i++) {
            for (let j=0; j<columnsForViewArray[COLUMNS_FOR_VIEWS_ARRAY[i]].length; j++) {
                if (columnsForViewArray[COLUMNS_FOR_VIEWS_ARRAY[i]][j].columnDefinitionConstant === newCustomColumnsArray[customColumnIndexDataKey].columnDefinitionConstant) {
                    columnsForViewArray[COLUMNS_FOR_VIEWS_ARRAY[i]][j].columnDefinitionConstant = newColumnDefinitionConstant;
                    columnsForViewArray[COLUMNS_FOR_VIEWS_ARRAY[i]][j].nameInFrontend = newNameInFrontend;
                } 
            }
        }

        newCustomColumnsArray.map((customColumnObject: CustomColumnDefinition) => {
            customColumnObject.customColumnSourceArray.map((customColumnSourceObject: CustomColumnSource) => {
                if (customColumnSourceObject.sourceColumn.columnDefinitionConstant === newCustomColumnsArray[customColumnIndexDataKey].columnDefinitionConstant) {
                    customColumnSourceObject.sourceColumn.columnDefinitionConstant = newColumnDefinitionConstant;
                    customColumnSourceObject.sourceColumn.nameInFrontend = newNameInFrontend;
                }
            })
        });

        remarksColumnsArray.map((remarksColumnObject: RemarksColumnDefinition) => {
            remarksColumnObject.remarksArray.map((remarkObject: RemarkDefinition) => {
                remarkObject.conditionConfigArray.map((conditionConfigObject: ConditionConfigType) => {
                    if (conditionConfigObject.columnDefinition.columnDefinitionConstant === newCustomColumnsArray[customColumnIndexDataKey].columnDefinitionConstant) {
                        conditionConfigObject.columnDefinition.columnDefinitionConstant = newColumnDefinitionConstant;
                        conditionConfigObject.columnDefinition.nameInFrontend = newNameInFrontend;
                    }
                });
            });
        });

        newCustomColumnsArray[customColumnIndexDataKey].columnDefinitionConstant = newColumnDefinitionConstant
        newCustomColumnsArray[customColumnIndexDataKey].nameInFrontend = newNameInFrontend;
        setCustomColumnsArray(newCustomColumnsArray);
        setActiveCustomColumnTabKey(newColumnDefinitionConstant);
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

    // @ts-ignore
    const updateDefaultValue = (event) => {
        const customColumnIndexDataKey = event.target.getAttribute('data-key-custom-column-index');
        const newCustomColumnsArray : CustomColumnDefinition[] = Object.assign([], customColumnsArray);
        newCustomColumnsArray[customColumnIndexDataKey].defaultString = event.target.value;
        setCustomColumnsArray(newCustomColumnsArray);
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

    // @ts-ignore
    const updatePrefix = (event) => {
        const customColumnIndexDataKey = event.target.getAttribute('data-key-custom-column-index');
        const customColumnSourceIndexDataKey = event.target.getAttribute('data-key-custom-column-source-index');
        const newCustomColumnsArray : CustomColumnDefinition[] = Object.assign([], customColumnsArray);
        newCustomColumnsArray[customColumnIndexDataKey].customColumnSourceArray[customColumnSourceIndexDataKey].prefix = event.target.value;
        setCustomColumnsArray(newCustomColumnsArray);
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

    // @ts-ignore
    const updatePostfix = (event) => {
        const customColumnIndexDataKey = event.target.getAttribute('data-key-custom-column-index');
        const customColumnSourceIndexDataKey = event.target.getAttribute('data-key-custom-column-source-index');
        const newCustomColumnsArray : CustomColumnDefinition[] = Object.assign([], customColumnsArray);
        //newCustomColumnsArray[customColumnIndexDataKey].postfix=event.target.value;
        newCustomColumnsArray[customColumnIndexDataKey].customColumnSourceArray[customColumnSourceIndexDataKey].postfix = event.target.value;
        setCustomColumnsArray(newCustomColumnsArray);
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

    // @ts-ignore
    const selectFormatFunction = (event) => {
        const customColumnIndexDataKey = event.target.getAttribute('data-key-custom-column-index');
        const dataKeySelectedFormatFunctionConstant = event.currentTarget.getAttribute('data-key-selected-format-function-constant');
        const newCustomColumnsArray : CustomColumnDefinition[] = Object.assign([], customColumnsArray);
        newCustomColumnsArray[customColumnIndexDataKey].formatFunctionConstant=dataKeySelectedFormatFunctionConstant;
        setCustomColumnsArray(newCustomColumnsArray);
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

    // @ts-ignore
    const updateTrimResultStart = (event) => {
        const customColumnIndexDataKey = event.target.getAttribute('data-key-custom-column-index');
        const customColumnRegexIndexDataKey = event.target.getAttribute('data-key-custom-column-regex-index');
        const customColumnSourceIndexDataKey = event.target.getAttribute('data-key-custom-column-source-index');

        //console.log(`${customColumnIndexDataKey}---${customColumnRegexIndexDataKey}---${customColumnSourceIndexDataKey}`);
        const newCustomColumnsArray : CustomColumnDefinition[] = Object.assign([], customColumnsArray);
        newCustomColumnsArray[customColumnIndexDataKey].customColumnSourceArray[customColumnSourceIndexDataKey].customColumnRegexArray[customColumnRegexIndexDataKey].trimResultStart=parseInt(event.target.value);
        setCustomColumnsArray(newCustomColumnsArray);
    }

    // @ts-ignore
    const updateTrimResultEnd = (event) => {
        const customColumnIndexDataKey = event.target.getAttribute('data-key-custom-column-index');
        const customColumnRegexIndexDataKey = event.target.getAttribute('data-key-custom-column-regex-index');
        const customColumnSourceIndexDataKey = event.target.getAttribute('data-key-custom-column-source-index');
        const newCustomColumnsArray : CustomColumnDefinition[] = Object.assign([], customColumnsArray);
        newCustomColumnsArray[customColumnIndexDataKey].customColumnSourceArray[customColumnSourceIndexDataKey].customColumnRegexArray[customColumnRegexIndexDataKey].trimResultEnd=parseInt(event.target.value);
        setCustomColumnsArray(newCustomColumnsArray);
    }

    // @ts-ignore
    const updateSearchReplaceSearchString = (event) => {
        const customColumnIndexDataKey = event.target.getAttribute('data-key-custom-column-index');
        const searchReplaceIndexDataKey = event.target.getAttribute('data-key-search-replace-index');
        const customColumnRegexIndexDataKey = event.target.getAttribute('data-key-custom-column-regex-index');
        const customColumnSourceIndexDataKey = event.target.getAttribute('data-key-custom-column-source-index');
        const newCustomColumnsArray : CustomColumnDefinition[] = Object.assign([], customColumnsArray);
        newCustomColumnsArray[customColumnIndexDataKey].customColumnSourceArray[customColumnSourceIndexDataKey].customColumnRegexArray[customColumnRegexIndexDataKey].searchReplaceArray[searchReplaceIndexDataKey].searchReplaceSearchString=event.target.value;
        setCustomColumnsArray(newCustomColumnsArray);
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

    // @ts-ignore
    const updateSearchReplaceReplaceString = (event) => {
        const customColumnIndexDataKey = event.target.getAttribute('data-key-custom-column-index');
        const searchReplaceIndexDataKey = event.target.getAttribute('data-key-search-replace-index');
        const customColumnRegexIndexDataKey = event.target.getAttribute('data-key-custom-column-regex-index');
        const customColumnSourceIndexDataKey = event.target.getAttribute('data-key-custom-column-source-index');
        const newCustomColumnsArray : CustomColumnDefinition[] = Object.assign([], customColumnsArray);
        newCustomColumnsArray[customColumnIndexDataKey].customColumnSourceArray[customColumnSourceIndexDataKey].customColumnRegexArray[customColumnRegexIndexDataKey].searchReplaceArray[searchReplaceIndexDataKey].searchReplaceReplaceString=event.target.value;
        setCustomColumnsArray(newCustomColumnsArray);
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

    // @ts-ignore
    const updateRegexString = (event) => {
        const customColumnIndexDataKey = event.target.getAttribute('data-key-custom-column-index');
        const customColumnRegexIndexDataKey = event.target.getAttribute('data-key-custom-column-regex-index');
        const customColumnSourceIndexDataKey = event.target.getAttribute('data-key-custom-column-source-index');

        const newCustomColumnsArray : CustomColumnDefinition[] = Object.assign([], customColumnsArray);
        newCustomColumnsArray[customColumnIndexDataKey].customColumnSourceArray[customColumnSourceIndexDataKey].customColumnRegexArray[customColumnRegexIndexDataKey].regexStringForCHECKING=event.target.value;
        newCustomColumnsArray[customColumnIndexDataKey].customColumnSourceArray[customColumnSourceIndexDataKey].customColumnRegexArray[customColumnRegexIndexDataKey].regexStringValid=false;
        try {
            new RegExp(event.target.value);
            newCustomColumnsArray[customColumnIndexDataKey].customColumnSourceArray[customColumnSourceIndexDataKey].customColumnRegexArray[customColumnRegexIndexDataKey].regexStringValid=true;
            newCustomColumnsArray[customColumnIndexDataKey].customColumnSourceArray[customColumnSourceIndexDataKey].customColumnRegexArray[customColumnRegexIndexDataKey].regexString=event.target.value;
            setCustomColumnsArray(newCustomColumnsArray);
        } catch (error) {
            setCustomColumnsArray(newCustomColumnsArray);
        }
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

    const toggleSplitKeywords = (columnDefinitionConstant : string) => {
        const newCustomColumnsArray : CustomColumnDefinition[] = Object.assign([], customColumnsArray);
        newCustomColumnsArray.forEach((customColumnDefinitionItem) => {
            if (customColumnDefinitionItem.columnDefinitionConstant === columnDefinitionConstant) {
                customColumnDefinitionItem.splitKeywords = !customColumnDefinitionItem.splitKeywords;
            }
        });
        setCustomColumnsArray(newCustomColumnsArray);
        setImagesWithMetadataNeedsNewEnrichment(true);
    };

    const toggleRemoveFromKeywordRest = (columnDefinitionConstant : string) => {
        const newCustomColumnsArray : CustomColumnDefinition[] = Object.assign([], customColumnsArray);
        newCustomColumnsArray.forEach((customColumnDefinitionItem) => {
            if (customColumnDefinitionItem.columnDefinitionConstant === columnDefinitionConstant) {
                customColumnDefinitionItem.removeFromKeywordRest = !customColumnDefinitionItem.removeFromKeywordRest;
            }
        });
        setCustomColumnsArray(newCustomColumnsArray);
        setImagesWithMetadataNeedsNewEnrichment(true);
    };

    const toggleSearchReplaceIgnoreCaseForSearchReplace = (columnDefinitionConstant : string, searchReplaceIndexDataKey: number, customColumnSourceIndex: number, customColumnRegexIndex: number) => {
        const newCustomColumnsArray : CustomColumnDefinition[] = Object.assign([], customColumnsArray);
        newCustomColumnsArray.forEach((customColumnDefinitionItem: CustomColumnDefinition) => {
            
            if (customColumnDefinitionItem.columnDefinitionConstant === columnDefinitionConstant) {
                customColumnDefinitionItem.customColumnSourceArray[customColumnSourceIndex].customColumnRegexArray[customColumnRegexIndex].searchReplaceArray[searchReplaceIndexDataKey].searchReplaceIgnoreCase = !customColumnDefinitionItem.customColumnSourceArray[customColumnSourceIndex].customColumnRegexArray[customColumnRegexIndex].searchReplaceArray[searchReplaceIndexDataKey].searchReplaceIgnoreCase;
            }
        });
        setCustomColumnsArray(newCustomColumnsArray);
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

    const toggleSearchReplaceIgnoreCaseForRegex = (columnDefinitionConstant : string, customColumnSourceIndex: number, customColumnRegexIndex: number) => {
        const newCustomColumnsArray : CustomColumnDefinition[] = Object.assign([], customColumnsArray);
        newCustomColumnsArray.forEach((customColumnDefinitionItem: CustomColumnDefinition) => {
            
            if (customColumnDefinitionItem.columnDefinitionConstant === columnDefinitionConstant) {
                customColumnDefinitionItem.customColumnSourceArray[customColumnSourceIndex].customColumnRegexArray[customColumnRegexIndex].regexStringIgnoreCase = !customColumnDefinitionItem.customColumnSourceArray[customColumnSourceIndex].customColumnRegexArray[customColumnRegexIndex].regexStringIgnoreCase;
            }
        });
        setCustomColumnsArray(newCustomColumnsArray);
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

     // @ts-ignore
     const addCustomColumn = (event) => {

        const nameInFrontend = 'PLATZHALTER';
        const newCustomColumnContant = getUniqueValueForCustomColumnConstant(nameInFrontend, COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, customColumnsArray, remarksColumnsArray);



        const newCustomColumnsArray : CustomColumnDefinition[] = Object.assign([], customColumnsArray);
        var newCustomColumn = {
            active: true,
            selectable: true,
            columnDefinitionType: COLUMN_DEFINITION_TYPE_CUSTOM_COLUMN_DEFINITION,
            splitKeywords: true,
            defaultString: '',
            removeFromKeywordRest: true,
            sortPosition: 1,
            width: DEFAULT_WITH_FOR_NEW_COLUMNS,
            nameInFrontend: nameInFrontend,
            columnDefinitionConstant: newCustomColumnContant,
            renderConfigsMapKey: Object.keys(renderObjectsMap)[0],
            formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY,
            customColumnSourceArray: [{
                sourceColumn: columnDefinitionArray[2],
                prefix: '',
                postfix: '',
                customColumnRegexArray: [
                    {
                        regexString: '',
                        regexStringForCHECKING: '',
                        regexStringValid: true,
                        regexStringIgnoreCase: true,
                        trimResultStart: 0,
                        trimResultEnd: 0,
                        searchReplaceArray: [],
                    }
                ],
            }
            ],
        }
        
        // Adds new column to the beginning of columnsForViewArray
        for (let i=0; i<COLUMNS_FOR_VIEWS_ARRAY.length; i++) {
            columnsForViewArray[COLUMNS_FOR_VIEWS_ARRAY[i]].unshift(Object.assign({}, newCustomColumn as ColumnDefinition));
        }
        newCustomColumnsArray.push(newCustomColumn);
        setCustomColumnsArray(newCustomColumnsArray);
        setActiveCustomColumnTabKey(newCustomColumnContant);
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

    // @ts-ignore
    const removeCustomColumn = (event) => {
        // TODO: Check before remove
        // if customColumn is used by remarkColumn
        // otherwise the logic will break

        const newCustomColumnsArray : CustomColumnDefinition[] = Object.assign([], customColumnsArray);
        const customColumnObjectIndexDataKey = event.currentTarget.getAttribute('data-key-custom-column-object-index');
        if (window.confirm(`Soll die Zusatzspalte "${customColumnsArray[customColumnObjectIndexDataKey].nameInFrontend}" wirklich gelöscht werden?`)) {

            var removedColumnDefinition = newCustomColumnsArray.splice(customColumnObjectIndexDataKey, 1)[0];

            if (removedColumnDefinition) {
                let newColumnsForViewObject = {
                    [COLUMNS_FOR_TABLE_VIEW_KEY]: [],
                    [COLUMNS_FOR_GRID_VIEW_KEY]: [],
                    [COLUMNS_FOR_COMPARE_VIEW_KEY]: [],
                    [COLUMNS_FOR_DETAIL_VIEW_KEY]: [],
                    [COLUMNS_FOR_FOLDER_VIEW_KEY]: [],
                };

                for (let i=0; i<COLUMNS_FOR_VIEWS_ARRAY.length; i++) {
                    for (let j=0; j<columnsForViewArray[COLUMNS_FOR_VIEWS_ARRAY[i]].length; j++) {
                        if (columnsForViewArray[COLUMNS_FOR_VIEWS_ARRAY[i]][j].columnDefinitionConstant !== removedColumnDefinition.columnDefinitionConstant) {
                            newColumnsForViewObject[COLUMNS_FOR_VIEWS_ARRAY[i]].push(columnsForViewArray[COLUMNS_FOR_VIEWS_ARRAY[i]][j]);
                        } 
                    }
                }
                setColumnsForViewArray(newColumnsForViewObject);
            }

            setCustomColumnsArray(newCustomColumnsArray);
            setImagesWithMetadataNeedsNewEnrichment(true);
            setActiveCustomColumnTabKey(CUSTOM_COLUMN_TAB_KEY_ADD);
        }
    }

    // @ts-ignore
    const addSearchReplaceObject = (event) => {
        const newCustomColumnsArray : CustomColumnDefinition[] = Object.assign([], customColumnsArray);
        const customColumnIndexDataKey = event.currentTarget.getAttribute('data-key-custom-column-index');
        const customColumnRegexIndexDataKey = event.currentTarget.getAttribute('data-key-custom-column-regex-index');
        const customColumnSourceIndexDataKey = event.currentTarget.getAttribute('data-key-custom-column-source-index');

        newCustomColumnsArray[customColumnIndexDataKey].customColumnSourceArray[customColumnSourceIndexDataKey].customColumnRegexArray[customColumnRegexIndexDataKey].searchReplaceArray.push(
            {
                searchReplaceSearchString: '',
                searchReplaceReplaceString: '',
                searchReplaceIgnoreCase: true,
            } as SearchReplaceDefinition,
        );
        setCustomColumnsArray(newCustomColumnsArray);
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

    // @ts-ignore
    const removeSearchReplaceObject = (event) => {
        const newCustomColumnsArray : CustomColumnDefinition[] = Object.assign([], customColumnsArray);
        const customColumnIndex = event.currentTarget.getAttribute('data-key-custom-column-index');
        const customColumnSourceObjectIndex = event.currentTarget.getAttribute('data-key-custom-column-source-index');
        const customColumnRegexObjectIndex = event.currentTarget.getAttribute('data-key-custom-column-regex-index');
        const searchReplaceObjectIndex = event.currentTarget.getAttribute('data-key-search-replace-object-index');
        newCustomColumnsArray[customColumnIndex].customColumnSourceArray[customColumnSourceObjectIndex].customColumnRegexArray[customColumnRegexObjectIndex].searchReplaceArray.splice(searchReplaceObjectIndex, 1);
        setCustomColumnsArray(newCustomColumnsArray);
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

    // @ts-ignore
    const addColumnRegexObject = (event) => {
        const newCustomColumnsArray : CustomColumnDefinition[] = Object.assign([], customColumnsArray);
        const customColumnIndexDataKey = event.currentTarget.getAttribute('data-key-custom-column-index');
        const customColumnSourceIndexDataKey = event.currentTarget.getAttribute('data-key-custom-column-source-index');

        newCustomColumnsArray[customColumnIndexDataKey].customColumnSourceArray[customColumnSourceIndexDataKey].customColumnRegexArray.push(
            {       
                regexString: '',
                regexStringIgnoreCase: true,
                trimResultStart: 0,
                trimResultEnd: 0,
                searchReplaceArray: [] as SearchReplaceDefinition[],
            }
        );
        setCustomColumnsArray(newCustomColumnsArray);
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

    // @ts-ignore
    const removeColumnRegexObject = (event) => {
        const newCustomColumnsArray : CustomColumnDefinition[] = Object.assign([], customColumnsArray);
        const customColumnIndex = event.currentTarget.getAttribute('data-key-custom-column-index');
        const customColumnSourceObjectIndex = event.currentTarget.getAttribute('data-key-custom-column-source-index');
        const customColumnRegexObjectIndex = event.currentTarget.getAttribute('data-key-custom-column-regex-object-index');
        debugger;
        newCustomColumnsArray[customColumnIndex].customColumnSourceArray[customColumnSourceObjectIndex].customColumnRegexArray.splice(customColumnRegexObjectIndex, 1);
        setCustomColumnsArray(newCustomColumnsArray);
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

    // @ts-ignore
    const updatePropertyInExampleImageWithMetadataObject = (newValue: string, columnDefinitionConstant: string) => {
        debugger;
        var newExampleImageWithMetadataObject: ImageWithMetadataObject = Object.assign({}, exampleImageWithMetadataObject);

        // Remove property when column is not required in ImageWithMetadataObject
        if (newValue === '' && requiredPropertiesOfImageWithMetadataObjectArray.indexOf(columnDefinitionConstant) === -1) {
            delete newExampleImageWithMetadataObject[columnDefinitionConstant];
        } else {
            if (columnDefinitionConstant === COLUMN_DEFINITION_CONST_KEYWORDS) {
                // @ts-ignore
                newExampleImageWithMetadataObject[columnDefinitionConstant] = newValue.replace(/ /g, '').split(',');
            } else {
                newExampleImageWithMetadataObject[columnDefinitionConstant] = newValue;
            }
        }
        setExampleImageWithMetadataObject(newExampleImageWithMetadataObject);
    }

    // @ts-ignore
    const addColumnSourceObject = (event) => {
        const newCustomColumnsArray : CustomColumnDefinition[] = Object.assign([], customColumnsArray);
        const customColumnIndexDataKey = event.currentTarget.getAttribute('data-key-custom-column-index');

        newCustomColumnsArray[customColumnIndexDataKey].customColumnSourceArray.push(
            {       
                sourceColumn: columnDefinitionArray[2],
                prefix: '',
                postfix: '',
                    customColumnRegexArray: [
                        {
                            regexString: '',
                            regexStringIgnoreCase: true,
                            trimResultStart: 0,
                            trimResultEnd: 0,
                            searchReplaceArray: [],
                        }
                    ],
            } as CustomColumnSource
        );
        setCustomColumnsArray(newCustomColumnsArray);
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

    // @ts-ignore
    const removeColumnSourceObject = (event) => {
        const newCustomColumnsArray : CustomColumnDefinition[] = Object.assign([], customColumnsArray);
        const customColumnIndex = event.currentTarget.getAttribute('data-key-custom-column-index');
        const customColumnSourceObjectIndex = event.currentTarget.getAttribute('data-key-custom-column-source-object-index');
        newCustomColumnsArray[customColumnIndex].customColumnSourceArray.splice(customColumnSourceObjectIndex, 1);
        setCustomColumnsArray(newCustomColumnsArray);
        setImagesWithMetadataNeedsNewEnrichment(true);
    }

    const changeActiveTab = (newTabKey: string | null): void => {
        if (newTabKey) {
            setActiveCustomColumnTabKey(newTabKey);
        }  
    }

    return (
        <>
            <Tabs activeKey={activeCustomColumnTabKey} defaultActiveKey={CUSTOM_COLUMN_TAB_KEY_ADD} onSelect={(key) => changeActiveTab(key)} className='additional-columns-second-layer-tabs'>
                <Tab eventKey={CUSTOM_COLUMN_TAB_KEY_ADD} title="+" tabClassName='tab-primary' className='tab-with-margin additional-columns-second-layer-tab'>
                    <Alert variant='info'>
                        In dieser Ansicht können Zusatzspalten definiert werden, deren Werte aus den Basis-Spalten extrahiert werden.
                    </Alert>
                    <Button
                        size='sm'
                        variant='secondary'
                        //data-key-custom-column-index={customColumnObjectIndex}
                        onClick={(event) => addCustomColumn(event)}
                    >
                        Zusatzspalte hinzufügen
                    </Button>
                    <AdditionalColumnsRepository
                        additionalColumnsRepoRemarkColumnsArray={additionalColumnsRepoRemarkColumnsArray}
                        additionalColumnsRepoCustomColumnsArray={additionalColumnsRepoCustomColumnsArray}
                        customColumnsArray={customColumnsArray}
                        setCustomColumnsArray={setCustomColumnsArray}
                        remarksColumnsArray={remarksColumnsArray}
                        setRemarksColumnsArray={setRemarksColumnsArray}
                        setActiveCustomColumnTabKey={setActiveCustomColumnTabKey}
                        setActiveRemarksColumnTabKey={setActiveRemarksColumnTabKey}
                        setActiveTabKeyColumnDefinitionModal={setActiveTabKeyColumnDefinitionModal}
                    />
                </Tab>
                {customColumnsArray.map((customColumnObject: CustomColumnDefinition, customColumnObjectIndex: number) => 
                    <Tab
                        eventKey={customColumnObject.columnDefinitionConstant}
                        className='additional-columns-second-layer-tab'
                        title={
                            <>{customColumnObject.nameInFrontend}
                            
                                {/* <Button
                                    className="float-right"
                                    size='sm'
                                    variant='danger'
                                    data-key-remarks-column-object-index={customColumnObjectIndex}
                                    onClick={removeCustomColumn}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button> */}
                            </>
                        }
                    >
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                        Demo
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <Table>
                                            <tr className='tr-demo-row'>
                                                <td className='custom-column-highlight-1'>
                                                    {customColumnObject.customColumnSourceArray.map((customColumnSourceObject: CustomColumnSource) => 
                                                        <>
                                                            <label>
                                                                {`Inhalt der Spalte "${customColumnSourceObject.sourceColumn.nameInFrontend}":`}
                                                            </label>
                                                            <Form.Control
                                                                type="text"
                                                                value={exampleImageWithMetadataObject[customColumnSourceObject.sourceColumn.columnDefinitionConstant]}
                                                                onChange={(event) => updatePropertyInExampleImageWithMetadataObject(event.target.value, customColumnSourceObject.sourceColumn.columnDefinitionConstant)}
                                                            />
                                                        </>
                                                    )}
                                                </td>
                                                <td className='custom-column-highlight-1'>
                                                    {customColumnObject.customColumnSourceArray.map((customColumnSourceObject: CustomColumnSource, customColumnSourceObjectIndex :number) => 
                                                        <div className='custom_columns_table_arrow_lr'>-------&gt;</div>
                                                    )}
                                                </td>
                                                <td className='custom-column-highlight-1'>
                                                    <>
                                                        <label htmlFor="basic-url">
                                                            {`Ausgabe in der Spalte "${customColumnObject.nameInFrontend}":`}
                                                        </label>
                                                        <Form.Control
                                                            disabled
                                                            type="text"
                                                            value={enrichImageWithMetadataObjectArray(
                                                                [exampleImageWithMetadataObject],
                                                                false // setLoadingVariables
                                                            )[0]['customColumns'][customColumnObject.columnDefinitionConstant].join(', ')}
                                                        />
                                                    </>
                                                </td>
                                            </tr>
                                        </Table>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                            <Table key={`custom-columns-table-table-1-${customColumnObjectIndex}`}>
                                <tbody>
                                <tr className='additional_column_definition_headline_tr'>
                                    <td colSpan={3}>
                                        <h4>Allgemeine Einstellungen</h4>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3} className='custom-column-highlight-1'>
                                        <Form.Label>Spaltenname</Form.Label>
                                        <Form.Control
                                            type="text"
                                            size="sm"
                                            value={customColumnObject.nameInFrontend}
                                            data-key-custom-column-index={customColumnObjectIndex}
                                            onChange={(event) => updateNameInFrontend(event)}
                                        />
                                    </td>
                                    <td colSpan={1} className='custom-column-highlight-1'>
                                        <Form.Label>Formatierung</Form.Label>
                                        <FormatFunctionSelectWithPreview
                                            data_key1_name='data-key-custom-column-index'
                                            data_key1_value={customColumnObjectIndex}
                                            selectedFormatFunctionConstant={customColumnObject.formatFunctionConstant}
                                            data-key-column-definition-index={customColumnObjectIndex}
                                            onClickFunction={selectFormatFunction}
                                            typeExperiment='dropdown'
                                        />
                                    </td>
                                    <td colSpan={2} className='custom-column-highlight-1'>
                                        <Button
                                            size='sm'
                                            className='float-right'
                                            variant='danger'
                                            data-key-custom-column-object-index={customColumnObjectIndex}
                                            onClick={removeCustomColumn}
                                        >
                                            <FontAwesomeIcon icon={faTrash} /> Zusatzspalte löschen
                                        </Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='custom-column-highlight-1' colSpan={5}>
                                        <>
                                            <Form.Label>Fallback-Wert</Form.Label>
                                            <Form.Control
                                                type="text"
                                                size="sm"
                                                value={customColumnObject.defaultString}
                                                data-key-custom-column-index={customColumnObjectIndex}
                                                onChange={(event) => updateDefaultValue(event)}
                                            />
                                        </>
                                    </td>
                                    <td>
                                        <Alert variant='info'>
                                            Wenn kein Wert für diese Spalte vorhanden ist, wird der Fallback-Wert angezeigt.
                                        </Alert>
                                    </td>
                                </tr>
                                    {customColumnObject.customColumnSourceArray.map((customColumnSourceObject: CustomColumnSource, customColumnSourceObjectIndex: number) => 
                                        <>
                                        <tr className='additional_column_definition_headline_tr'>
                                            <td className='additional_column_definition_indent_1_td'></td>
                                            <td className='additional_column_definition_indent_1_td'></td>
                                            <td colSpan={1} className='custom-column-highlight-2'>
                                                <h4>Aus welchen Spalten kommen die Daten?</h4>
                                            </td>
                                            <td className='custom-column-highlight-2'>
                                                <FormLabel>Quellspalte</FormLabel>
                                                <CombinedColumnSelect
                                                    remarksColumnsArray={null}
                                                    customColumnsArray={null}
                                                    dataSource={dataSource}
                                                    idPrefix='data_filter_row'
                                                    indexForUniqueId={customColumnSourceObjectIndex.toString()}
                                                    selectedColumnDefinition={customColumnSourceObject.sourceColumn}
                                                    onClickFunction={selectSourceColumn}
                                                    data-key-custom-column-index={customColumnObjectIndex}
                                                    data-key-custom-column-source-index={customColumnSourceObjectIndex}
                                                />
                                                {customColumnSourceObject.sourceColumn.columnDefinitionConstant === COLUMN_DEFINITION_CONST_KEYWORDS && (
                                                    <Form.Group controlId={"checkbox_7" + customColumnObject.columnDefinitionConstant}>
                                                        {/* <FormLabel>Keywords aufteilen?</FormLabel> */}
                                                        <Form.Check
                                                            type="checkbox"
                                                            onChange={() => toggleSplitKeywords(customColumnObject.columnDefinitionConstant)}
                                                            checked={customColumnObject.splitKeywords}
                                                            label='Keywords aufteilen?'
                                                            className='split-keywords-checkbox'
                                                        />
                                                    </Form.Group>    
                                                )}
                                                {customColumnSourceObject.sourceColumn.columnDefinitionConstant === COLUMN_DEFINITION_CONST_KEYWORDS && (
                                                    <Form.Group controlId={"checkbox_8" + customColumnObject.columnDefinitionConstant}>
                                                        <Form.Check
                                                            type="checkbox"
                                                            onChange={() => toggleRemoveFromKeywordRest(customColumnObject.columnDefinitionConstant)}
                                                            checked={customColumnObject.removeFromKeywordRest}
                                                            label='Aus Keyword-Rest entfernen?'
                                                            className='remove-from-keyword-rest-checkbox'
                                                        />
                                                    </Form.Group>    
                                                )}
                                            </td>
                                            <td className='custom-column-highlight-2'>
                                            </td>
                                            <td className='custom-column-highlight-2'>
                                                <Button
                                                    size='sm'
                                                    variant='primary'
                                                    className='float-right'
                                                    data-key-custom-column-index={customColumnObjectIndex}
                                                    onClick={addColumnSourceObject}
                                                >
                                                    <FontAwesomeIcon icon={faPlus} /> Quellspalte
                                                </Button>
                                                {customColumnObject.customColumnSourceArray.length>=2 &&
                                                    <Button
                                                        size='sm'
                                                        variant='danger'
                                                        className='float-right'
                                                        data-key-custom-column-index={customColumnObjectIndex}
                                                        data-key-custom-column-source-object-index={customColumnSourceObjectIndex}
                                                        onClick={removeColumnSourceObject}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} /> Quellspalte
                                                    </Button>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='additional_column_definition_indent_1_td'></td>
                                            <td className='additional_column_definition_indent_1_td'></td>
                                            <td colSpan={3} className='custom-column-highlight-2'>
                                                <FormLabel>Prefix</FormLabel>
                                                <Form.Control
                                                    type="text"
                                                    size="sm"
                                                    value={customColumnSourceObject.prefix ? customColumnSourceObject.prefix : ''}
                                                    data-key-custom-column-index={customColumnObjectIndex}
                                                    data-key-custom-column-source-index={customColumnSourceObjectIndex}
                                                    onChange={(event) => updatePrefix(event)}
                                                />
                                                <FormLabel>Postfix</FormLabel>
                                                <Form.Control
                                                    type="text"
                                                    size="sm"
                                                    value={customColumnSourceObject.postfix ? customColumnSourceObject.postfix : ''}
                                                    data-key-custom-column-index={customColumnObjectIndex}
                                                    data-key-custom-column-source-index={customColumnSourceObjectIndex}
                                                    onChange={(event) => updatePostfix(event)}
                                                />
                                            </td>
                                            <td>
                                                <Alert variant='info'>
                                                    {`Die gefundenen Werte aus der Quellspalte "${customColumnSourceObject.sourceColumn.nameInFrontend}" können durch den Prefix und den Postfix erweitert werden. Der Prefix wird am Anfang angehängt, der Postfix am Ende.`}
                                                </Alert>
                                            </td>
                                        </tr>
                                        {customColumnSourceObject.customColumnRegexArray.map((customColumnRegexObject: CustomColumnRegex, customColumnRegexObjectIndex: number) => 
                                            <>
                                                <tr className='additional_column_definition_headline_tr'>
                                                    <td className='additional_column_definition_indent_1_td'></td>
                                                    <td className='additional_column_definition_indent_1_td'></td>
                                                    <td className='additional_column_definition_indent_2_td'></td>
                                                    <td colSpan={2} className='custom-column-highlight-3'>
                                                        <h4>Welche Regulären Ausdrücke werden geprüft?</h4>
                                                    </td>
                                                    <td className='custom-column-highlight-3'>
                                                        <Button
                                                            size='sm'
                                                            className='float-right'
                                                            data-key-custom-column-index={customColumnObjectIndex}
                                                            data-key-custom-column-source-index={customColumnSourceObjectIndex}
                                                            onClick={addColumnRegexObject}
                                                        >
                                                            <FontAwesomeIcon icon={faPlus} /> Regex-Prüfung
                                                        </Button>
                                                        {customColumnSourceObject.customColumnRegexArray.length>=2 &&
                                                            <Button
                                                                size='sm'
                                                                variant='danger'
                                                                className='float-right'
                                                                data-key-custom-column-index={customColumnObjectIndex}
                                                                data-key-custom-column-source-index={customColumnSourceObjectIndex}
                                                                data-key-custom-column-regex-object-index={customColumnRegexObjectIndex}
                                                                onClick={removeColumnRegexObject}
                                                            >
                                                                <FontAwesomeIcon icon={faTrash} /> Regex-Prüfung
                                                            </Button>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='additional_column_definition_indent_1_td'></td>
                                                    <td className='additional_column_definition_indent_1_td'></td>
                                                    <td className='additional_column_definition_indent_2_td'></td>
                                                    <td colSpan={2} className='custom-column-highlight-3'>
                                                        
                                                        <Form.Label>Regex</Form.Label>
                                                        <Form.Control
                                                                type="text"
                                                                size="sm"
                                                                className={!customColumnRegexObject.regexStringValid ? 'regex-input-invalid' : ''}
                                                                value={customColumnRegexObject.regexStringForCHECKING}
                                                                data-key-custom-column-index={customColumnObjectIndex}
                                                                data-key-custom-column-regex-index={customColumnRegexObjectIndex}
                                                                data-key-custom-column-source-index={customColumnSourceObjectIndex}
                                                                onChange={(event) => updateRegexString(event)}
                                                            />
                                                            <Form.Check 
                                                                type='checkbox'
                                                                checked={customColumnRegexObject.regexStringIgnoreCase}
                                                                onChange={() => toggleSearchReplaceIgnoreCaseForRegex(customColumnObject.columnDefinitionConstant, customColumnSourceObjectIndex, customColumnRegexObjectIndex)}
                                                                className='ignore-case-checkbox'
                                                                id={`default-checkbox`}
                                                                label='Groß- und Kleinschreibung ignorieren'
                                                            />
                                                    </td>
                                                    <td>
                                                        <Alert variant='info'>
                                                            {`Die Werte aus der Quellspalte "${customColumnSourceObject.sourceColumn.nameInFrontend}" werden gegen diesen regulären Ausdruck geprüft. Entspricht der Wert dem regulären Ausdruck taucht er als Wert in der hier definierten Zusatzspalte "${customColumnObject.nameInFrontend}" auf.`}
                                                        </Alert>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='additional_column_definition_indent_1_td'></td>
                                                    <td className='additional_column_definition_indent_1_td'></td>
                                                    <td className='additional_column_definition_indent_2_td'></td>
                                                    <td colSpan={2} className='custom-column-highlight-3'>
                                                        <Form.Label>Zeichen abschneiden</Form.Label>
                                                        <InputGroup className="input-group-trim-values">
                                                            <Form.Control
                                                                type="number"
                                                                size="sm"
                                                                min={0}
                                                                max={255}
                                                                value={customColumnRegexObject.trimResultStart}
                                                                data-key-custom-column-index={customColumnObjectIndex}
                                                                data-key-custom-column-source-index={customColumnSourceObjectIndex}
                                                                data-key-custom-column-regex-index={customColumnRegexObjectIndex}
                                                                onChange={(event) => updateTrimResultStart(event)}
                                                            />
                                                            <Form.Control
                                                                type="number"
                                                                size="sm"
                                                                min={0}
                                                                max={255}
                                                                value={customColumnRegexObject.trimResultEnd}
                                                                data-key-custom-column-index={customColumnObjectIndex}
                                                                data-key-custom-column-source-index={customColumnSourceObjectIndex}
                                                                data-key-custom-column-regex-index={customColumnRegexObjectIndex}
                                                                onChange={(event) => updateTrimResultEnd(event)}
                                                            />
                                                        </InputGroup>
                                                    </td>
                                                    <td>
                                                        <Alert variant='info'>
                                                            Die gefundenen Einträge können hier gekürzt werden. Der erste Wert bestimmt wieviele Buchstaben am Anfang abgeschnitten werden. Der zweite Wert bestimmt wieviele Buchstaben am Ende abgeschnitten werden. 
                                                        </Alert>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='additional_column_definition_indent_1_td'></td>
                                                    <td className='additional_column_definition_indent_1_td'></td>
                                                    <td className='additional_column_definition_indent_2_td'></td>
                                                    <td colSpan={2} className='custom-column-highlight-3'>
                                                        {customColumnRegexObject.searchReplaceArray.map((searchReplaceObject: SearchReplaceDefinition, searchReplaceObjectIndex: number) => 
                                                            <>
                                                                <InputGroup>
                                                                    <Form.Control
                                                                        type="text"
                                                                        size="sm"
                                                                        value={searchReplaceObject.searchReplaceSearchString}
                                                                        data-key-custom-column-index={customColumnObjectIndex}
                                                                        data-key-search-replace-index={searchReplaceObjectIndex}
                                                                        data-key-custom-column-source-index={customColumnSourceObjectIndex}
                                                                        data-key-custom-column-regex-index={customColumnRegexObjectIndex}
                                                                        onChange={(event) => updateSearchReplaceSearchString(event)}
                                                                    />                         
                                                                    <Form.Label>{'-->'}</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        size="sm"
                                                                        value={searchReplaceObject.searchReplaceReplaceString}
                                                                        data-key-custom-column-index={customColumnObjectIndex}
                                                                        data-key-search-replace-index={searchReplaceObjectIndex}
                                                                        data-key-custom-column-source-index={customColumnSourceObjectIndex}
                                                                        data-key-custom-column-regex-index={customColumnRegexObjectIndex}
                                                                        aria-describedby="basic-addon2"
                                                                        onChange={(event) => updateSearchReplaceReplaceString(event)}
                                                                    />
                                                                    <Button
                                                                        size='sm'
                                                                        className='custom-column-highlight-4'
                                                                        data-key-custom-column-index={customColumnObjectIndex}
                                                                        data-key-custom-column-source-index={customColumnSourceObjectIndex}
                                                                        data-key-custom-column-regex-index={customColumnRegexObjectIndex}
                                                                        data-key-search-replace-object-index={searchReplaceObjectIndex}
                                                                        onClick={removeSearchReplaceObject}
                                                                    >
                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                    </Button>
                                                                </InputGroup>
                                                                <Form.Check 
                                                                    type='checkbox'
                                                                    checked={searchReplaceObject.searchReplaceIgnoreCase}
                                                                    onChange={() => toggleSearchReplaceIgnoreCaseForSearchReplace(customColumnObject.columnDefinitionConstant, searchReplaceObjectIndex, customColumnSourceObjectIndex, customColumnRegexObjectIndex)}
                                                                    className='ignore-case-checkbox'
                                                                    id={`default-checkbox`}
                                                                    label='Groß- und Kleinschreibung ignorieren'
                                                                />
                                                            </>
                                                        )}
                                                        <Button
                                                            size='sm'
                                                            variant='primary'
                                                            data-key-custom-column-index={customColumnObjectIndex}
                                                            data-key-custom-column-source-index={customColumnSourceObjectIndex}
                                                            data-key-custom-column-regex-index={customColumnRegexObjectIndex}
                                                            onClick={addSearchReplaceObject}
                                                        >
                                                            <FontAwesomeIcon icon={faPlus} /> Textersetzung
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <Alert variant='info'>
                                                            Die Einträge, die dem Regex entsprechen können hier umgewandelt werden. Das kann z.B. für die Ersetzung von Umlauten verwendet werden.
                                                        </Alert>
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                        </>
                                    )}   
                                </tbody>
                        </Table>
                    </Tab>
                )}
            </Tabs>
        </>
    );
}

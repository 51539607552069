import {ColumnDefinition, ColumnDefinitionMappingObject, ColumnDefinitionPreset, ImageWithMetadataObject, RenderConfigObject, RenderContainer, WarningConfigGroupConnectType} from "./custom_types";
import { getUniqueValueForRenderObjectsConstant } from "./Helpers";

export const defaultItemsPerPage = 30;
export const defaultItemsPerPageGridView = 24;

export const DATA_SOURCE_INITIAL = 0;
export const DATA_SOURCE_DATABASE = 2;
export const DATA_SOURCE_FILESYSTEM = 3;
export const DATA_SOURCE_JSON = 4;

export const DATA_SOURCES = {
    2: 'Datenbank',
    3: 'Dateisystem',
    4: 'IHKHSJSON-Datei',
};

export const NODE_SERVER_IS_ALIVE_EXPECETED_ANSWER = 'everything is fine';
export const DATABASE_IS_ALIVE_EXPECETED_ANSWER = 'alive and kicking';

export const DEFAULT_NODE_SERVER_HOST = 'http://localhost:3001';
export const getNodeIsAliveUrl = '/is_alive';
export const DEFAULT_DATABASE_HOST = 'http://localhost:8000';
//export const getDatabaseIsAliveUrl = '/api/is_alive'; // symfony
export const getDatabaseIsAliveUrl = '/comment/isAlive/?format=json'; // django
//export const getDatabaseImagesFromDbBaseUrl = '/api/get_images';
export const getDatabaseImagesFromDbBaseUrl = '/imagedb/image/';
export const getKeywordCorrectionsFromDbUrl = '/api/get_keyword_corrections';
export const getImportDetailsFromDbUrl = '/api/get_import_details';
export const LOCAL_STORAGE_KEY_NAME_FOR_SAVING_COMMENTS = 'setting_name_for_saving_comments';
export const LOCAL_STORAGE_KEY_SETTING_COMPUTER_NAME = 'setting_computer_name';
export const LOCAL_STORAGE_KEY_SETTING_DATABASE_HOST = 'setting_database_host';
export const LOCAL_STORAGE_KEY_ADDITIONAL_COLUMNS_CONFIGS = 'additional_columns_configs';
export const LOCAL_STORAGE_KEY_SETTING_NODE_SERVER_HOST = 'setting_node_server_host';
//export const LOCAL_STORAGE_KEY_PATH_NAME_HISTORY = 'path_name_history';
export const LOCAL_STORAGE_KEY_IHKHSJSON_HISTORY = 'ihkhsjson_history';

export const DEFAULT_PHOTOGRAPHER_STRING = 'KHS';

export const DEFAULT_PRESETS_FOLDER = '/presets';

export const REMARKS_COLUMN_TAB_KEY_ADD = 'remarks_column_tab_key_add';
export const CUSTOM_COLUMN_TAB_KEY_ADD = 'custom_column_tab_key_add';
export const RENDER_OBJECT_CUSTOMIZER_TAB_KEY_ADD = 'render_object_customizer_tab_key_add';

export const DEFAULT_LOADING_TEXT = 'Lädt...'
// export const emptyImageWithMetadataObject = {
//     sourcefile: 'sourcefile',
//     file_name: 'file_name',
//     directory: 'directory',
//     keywords_string: 'a, b, c',
//     keywords_array: ['a', 'b', 'c'],
//     keywords_rest_array: [],
//     image_size: '4711',
// } as ImageWithMetadataObject;

export const monthMapGerman = {
    1: 'Januar',
    2: 'Februar',
    3: 'März',
    4: 'April',
    5: 'Mai',
    6: 'Juni',
    7: 'Juli',
    8: 'August',
    9: 'September',
    10: 'Oktober',
    11: 'November',
    12: 'Dezember',
};

export const TAB_KEY_IMAGE_COMPARE_VIEW = 'image-compare-view';
export const TAB_KEY_IMAGE_DETAIL_VIEW = 'image-detail-view';
export const TAB_KEY_IMAGE_FOLDER_VIEW = 'image-folder-view';
export const TAB_KEY_GRID_VIEW = 'grid-view';
export const TAB_KEY_TABLE_VIEW = 'table-view';
export const TAB_KEY_SEARCH_VIEW = 'search-view';

export const TAB_KEY_IMPORT_STEP_1 = 'import-step-1';
export const TAB_KEY_IMPORT_STEP_2 = 'import-step-2';
export const TAB_KEY_IMPORT_STEP_3 = 'import-step-3';


export const TAB_KEY_HELP_MODAL_DEMO = 'help-modal-demo';
export const TAB_KEY_HELP_MODAL_MANUALPAGE = 'help-modal-manual-page';
export const TAB_KEY_HELP_MODAL_EXCHANGE = 'help-modal-exchange-page';

export const TAB_KEY_COLUMN_CUSTOMIZER_SORT = 'column-customizer-sort';
export const TAB_KEY_COLUMN_CUSTOMIZER_CUSTOM_COLUMN = 'column-customizer-custom-column';
export const TAB_KEY_COLUMN_CUSTOMIZER_COMMENT_COLUMN = 'column-customizer-comment-column';
export const TAB_KEY_COLUMN_CUSTOMIZER_IMPORT_EXPORT = 'column-customizer-import_export';
export const TAB_KEY_COLUMN_CUSTOMIZER_LOAD_PRESETS = 'column-customizer-load-presets';
export const TAB_KEY_COLUMN_CUSTOMIZER_REXEXP_ERROR_LOG = 'column-customizer-regexp-error-log';
export const TAB_KEY_COLUMN_CUSTOMIZER_QR_CODE_AND_LINK_PAGE = 'column-customizer-qr-code-and-link-page';
export const TAB_KEY_COLUMN_CUSTOMIZER_RENDER_OBJECTS_CUSTOMIZER = 'column-customizer-render-function-customizer';
export const TAB_KEY_COLUMN_CUSTOMIZER_SETTINGS = 'column-customizer-settings';
export const TAB_KEY_COLUMN_CUSTOMIZER_RELATED_COLUMNS = 'column-customizer-related-columns';

export const TAB_KEY_COLUMN_CUSTOMIZER_SORT_FOR_TABLE_VIEW = 'column-customizer-sort-for-table-view';
export const TAB_KEY_COLUMN_CUSTOMIZER_SORT_FOR_GRID_VIEW = 'column-customizer-sort-for-grid-view';
export const TAB_KEY_COLUMN_CUSTOMIZER_SORT_FOR_COMPARE_VIEW = 'column-customizer-sort-for-compare-view';
export const TAB_KEY_COLUMN_CUSTOMIZER_SORT_FOR_DETAIL_VIEW = 'column-customizer-sort-for-detail-view';
export const TAB_KEY_COLUMN_CUSTOMIZER_SORT_FOR_SEARCH_VIEW = 'column-customizer-sort-for-search-view';
export const TAB_KEY_COLUMN_CUSTOMIZER_SORT_FOR_FOLDER_VIEW = 'column-customizer-sort-for-folder-view';

//FA Icons
//Tabelle  faThList
//Vergleich ?
//Detail faImage
//Grid faGripHorizontal
//Folder faGripVertical

export const COLUMNS_FOR_TABLE_VIEW_KEY = 'columns-for-table-view-key';
export const COLUMNS_FOR_TABLE_VIEW_NAME_IN_FRONTEND = 'Tabellen-Ansicht';
export const COLUMNS_FOR_GRID_VIEW_KEY = 'columns-for-grid-view-key';
export const COLUMNS_FOR_GRID_VIEW_NAME_IN_FRONTEND = 'Grid-Ansicht';
export const COLUMNS_FOR_COMPARE_VIEW_KEY = 'columns-for-compare-view-key';
export const COLUMNS_FOR_COMPARE_VIEW_NAME_IN_FRONTEND = 'Vergleich-Ansicht';
export const COLUMNS_FOR_DETAIL_VIEW_KEY = 'columns-for-detail-view-key';
export const COLUMNS_FOR_DETAIL_VIEW_NAME_IN_FRONTEND = 'Detail-Ansicht';
export const COLUMNS_FOR_FOLDER_VIEW_KEY = 'columns-for-folder-view-key';
export const COLUMNS_FOR_FOLDER_VIEW_NAME_IN_FRONTEND = 'Ordner-Ansicht';

export const COLUMNS_FOR_VIEWS_MAP = {
    [COLUMNS_FOR_TABLE_VIEW_KEY]: COLUMNS_FOR_TABLE_VIEW_NAME_IN_FRONTEND,
    [COLUMNS_FOR_GRID_VIEW_KEY]: COLUMNS_FOR_GRID_VIEW_NAME_IN_FRONTEND,
    [COLUMNS_FOR_COMPARE_VIEW_KEY]: COLUMNS_FOR_COMPARE_VIEW_NAME_IN_FRONTEND,
    [COLUMNS_FOR_DETAIL_VIEW_KEY]: COLUMNS_FOR_DETAIL_VIEW_NAME_IN_FRONTEND,
    [COLUMNS_FOR_FOLDER_VIEW_KEY]: COLUMNS_FOR_FOLDER_VIEW_NAME_IN_FRONTEND,
};

export const COLUMNS_FOR_VIEWS_ARRAY = [
    COLUMNS_FOR_TABLE_VIEW_KEY,
    COLUMNS_FOR_GRID_VIEW_KEY,
    COLUMNS_FOR_COMPARE_VIEW_KEY,
    COLUMNS_FOR_DETAIL_VIEW_KEY,
    COLUMNS_FOR_FOLDER_VIEW_KEY,
]


export const FILTER_FIELD_DEFINITION_DB_IMPORT = 'db_import';
export const FILTER_FIELD_DEFINITION_DB_SYSTEM = 'db_system';

export const IHKHSJSON_FIELD_SOURCEFILE = 'SourceFile';
export const IHKHSJSON_FIELD_MIME_TYPE = 'File:MIMEType';
export const IHKHSJSON_FIELD_FILENAME = 'File:FileName';
export const IHKHSJSON_FIELD_DIRECTORY = 'File:Directory';
export const IHKHSJSON_FIELD_KEYWORDS = 'IPTC:Keywords';
export const IHKHSJSON_FIELD_FILESIZE = 'File:FileSize';
export const IHKHSJSON_FIELD_IMAGESIZE = 'Composite:ImageSize';
export const IHKHSJSON_FIELD_MODIFYDATE = 'EXIF:ModifyDate';
export const IHKHSJSON_FIELD_FILE_MODIFY_DATE = 'File:FileModifyDate';
export const IHKHSJSON_FIELD_CREATE_DATE = 'EXIF:CreateDate';
export const IHKHSJSON_FIELD_DATE_TIME_ORIGINAL = 'EXIF:DateTimeOriginal';
export const IHKHSJSON_FIELD_EXPOSURE_TIME = 'EXIF:ExposureTime';
export const IHKHSJSON_FIELD_FLASH = 'EXIF:Flash';
export const IHKHSJSON_FIELD_CAMERA_MAKE = 'EXIF:Make';
export const IHKHSJSON_FIELD_CAMERA_MODEL = 'EXIF:Model';
export const IHKHSJSON_FIELD_ORIGINAL_RAW_FILE_NAME = 'EXIF:OriginalRawFileName';
export const IHKHSJSON_FIELD_ISO = 'Composite:ISO';
export const IHKHSJSON_FIELD_APERTURE = 'Composite:Aperture';
export const IHKHSJSON_FIELD_SHUTER_SPEED = 'Composite:ShutterSpeed';
export const IHKHSJSON_FIELD_DATE_TIME_CREATED = 'Composite:DateTimeCreated';
export const IHKHSJSON_FIELD_LENS = 'Composite:Lens';
export const IHKHSJSON_FIELD_LENS_ID = 'Composite:LensID';
export const IHKHSJSON_FIELD_SUB_SEC_CREATE_DATE = 'Composite:SubSecCreateDate';
export const IHKHSJSON_FIELD_SUB_SEC_MODIFY_DATE = 'SourceFile'; // TODO
export const IHKHSJSON_FIELD_SUB_SEC_DATE_TIME_ORIGINAL = 'Composite:SubSecDateTimeOriginal';
export const IHKHSJSON_FIELD_IMAGE_NUMBER = 'SourceFile'; // TODO
export const IHKHSJSON_FIELD_GPS_LATITUDE = 'Composite:GPSLatitude';
export const IHKHSJSON_FIELD_GPS_LONGITUDE = 'Composite:GPSLongitude';
export const IHKHSJSON_FIELD_MD5 = 'Composite:MD5';


export const COLUMN_DEFINITION_CONST_ID = 'id';
export const COLUMN_DEFINITION_CONST_ID_NAME_IN_FRONTEND = 'Index';
export const COLUMN_DEFINITION_CONST_FILENAME = 'file_name';
export const COLUMN_DEFINITION_CONST_FILENAME_NAME_IN_FRONTEND = 'Dateiname';
export const COLUMN_DEFINITION_CONST_DIRECTORY = 'directory';
export const COLUMN_DEFINITION_CONST_DIRECTORY_NAME_IN_FRONTEND = 'Ordnername';
//export const COLUMN_DEFINITION_CONST_FILE_EXT = 'FileExt';
//export const COLUMN_DEFINITION_CONST_FILE_EXT_NAME_IN_FRONTEND = 'FileExt';
export const COLUMN_DEFINITION_CONST_MIME_TYPE = 'mime_type';
export const COLUMN_DEFINITION_CONST_MIME_TYPE_NAME_IN_FRONTEND = 'MIMEType';
export const COLUMN_DEFINITION_CONST_KEYWORDS_CORRECTION = 'keyword_correction';
export const COLUMN_DEFINITION_CONST_KEYWORDS_CORRECTION_NAME_IN_FRONTEND = 'Keyword-Korrektur';
export const COLUMN_DEFINITION_CONST_KEYWORDS_SUMMARY = 'keyword_summary';
export const COLUMN_DEFINITION_CONST_KEYWORDS_SUMMARY_NAME_IN_FRONTEND = 'keyword_summary';
export const COLUMN_DEFINITION_CONST_KEYWORDS = 'keywords_array';
export const COLUMN_DEFINITION_CONST_KEYWORDS_NAME_IN_FRONTEND = 'Keywords';
export const COLUMN_DEFINITION_CONST_KEYWORDS_STRING = 'keywords_string';
export const COLUMN_DEFINITION_CONST_KEYWORDS_STRING_NAME_IN_FRONTEND = 'Keywords';
export const COLUMN_DEFINITION_CONST_KEYWORD_REST = 'keywords_rest_array';
export const COLUMN_DEFINITION_CONST_KEYWORD_REST_NAME_IN_FRONTEND = 'Keyword-Rest';
export const COLUMN_DEFINITION_CONST_IMAGE_DIMENSIONS = 'image_size';
export const COLUMN_DEFINITION_CONST_IMAGE_DIMENSIONS_NAME_IN_FRONTEND = 'Dateigröße';
export const COLUMN_DEFINITION_CONST_FILE_SIZE = 'file_size';
export const COLUMN_DEFINITION_CONST_FILE_SIZE_NAME_IN_FRONTEND = 'FileSize';
export const COLUMN_DEFINITION_CONST_APERTURE = 'aperture';
export const COLUMN_DEFINITION_CONST_APERTURE_NAME_IN_FRONTEND = 'Blende';
export const COLUMN_DEFINITION_CONST_SHUTTER_SPEED = 'shutter_speed';
export const COLUMN_DEFINITION_CONST_SHUTTER_SPEED_NAME_IN_FRONTEND = 'Verschlusszeit';
export const COLUMN_DEFINITION_CONST_FLASH = 'flash';
export const COLUMN_DEFINITION_CONST_FLASH_NAME_IN_FRONTEND = 'Blitz';
export const COLUMN_DEFINITION_CONST_ISO = 'iso';
export const COLUMN_DEFINITION_CONST_ISO_NAME_IN_FRONTEND = 'ISO';
export const COLUMN_DEFINITION_CONST_CAMERA_MAKE = 'camera_make';
export const COLUMN_DEFINITION_CONST_CAMERA_MAKE_NAME_IN_FRONTEND = 'Kamera-Marke';
export const COLUMN_DEFINITION_CONST_CAMERA_MODEL = 'camera_model';
export const COLUMN_DEFINITION_CONST_CAMERA_MODEL_NAME_IN_FRONTEND = 'Kamera-Model';
export const COLUMN_DEFINITION_CONST_LENS = 'lens';
export const COLUMN_DEFINITION_CONST_LENS_NAME_IN_FRONTEND = 'Linse';
export const COLUMN_DEFINITION_CONST_LENS_ID = 'lens_id';
export const COLUMN_DEFINITION_CONST_LENS_ID_NAME_IN_FRONTEND = 'Linse ID';
export const COLUMN_DEFINITION_CONST_DATE_TIME_CREATED = 'date_time_created';
export const COLUMN_DEFINITION_CONST_DATE_TIME_CREATED_NAME_IN_FRONTEND = 'Erstell-datum';
export const COLUMN_DEFINITION_CONST_SUB_SEC_CREATE_DATE = 'sub_sec_create_date';
export const COLUMN_DEFINITION_CONST_SUB_SEC_CREATE_DATE_NAME_IN_FRONTEND = 'Erstell-datum (SubSec)';
export const COLUMN_DEFINITION_CONST_DATE_TIME_ORIGINAL = 'date_time_original';
export const COLUMN_DEFINITION_CONST_DATE_TIME_ORIGINAL_NAME_IN_FRONTEND = 'Original-Datum';
export const COLUMN_DEFINITION_CONST_SUB_SEC_DATE_TIME_ORIGINAL = 'sub_sec_date_time_original';
export const COLUMN_DEFINITION_CONST_SUB_SEC_DATE_TIME_ORIGINAL_NAME_IN_FRONTEND = 'Original-Datum (SubSec)';
export const COLUMN_DEFINITION_CONST_GPS_LATITUDE = 'gps_latitude';
export const COLUMN_DEFINITION_CONST_GPS_LATITUDE_NAME_IN_FRONTEND = 'GPS Latitude';
export const COLUMN_DEFINITION_CONST_GPS_LONGITUDE = 'gps_longitude';
export const COLUMN_DEFINITION_CONST_GPS_LONGITUDE_NAME_IN_FRONTEND = 'GPS Longitude';
export const COLUMN_DEFINITION_CONST_IMAGE_PREVIEW = 'image_preview';
export const COLUMN_DEFINITION_CONST_IMAGE_PREVIEW_NAME_IN_FRONTEND = 'Bild-Vorschau';
export const COLUMN_DEFINITION_CONST_SOURCE_FILE = 'sourcefile';
export const COLUMN_DEFINITION_CONST_SOURCE_FILE_NAME_IN_FRONTEND = 'SourceFile';
export const COLUMN_DEFINITION_CONST_MD5 = 'md5';
export const COLUMN_DEFINITION_CONST_MD5_NAME_IN_FRONTEND = 'MD5';


export const FILTER_COLUMN_ID = 'FILTER_COLUMN_ID';
export const FILTER_COLUMN_DIRECTORY = 'FILTER_COLUMN_DIRECTORY';
export const FILTER_COLUMN_FILENAME = 'FILTER_COLUMN_FILENAME';
export const FILTER_COLUMN_KEYWORDS = 'FILTER_COLUMN_KEYWORDS';
export const FILTER_COLUMN_FILE_EXT = 'FILTER_COLUMN_FILE_EXT';


export const FILTER_COLUMN_MAP = {
    //FILTER_COLUMN_ID: 'Id',
    FILTER_COLUMN_DIRECTORY: 'Ordnername',
    FILTER_COLUMN_FILENAME: 'Dateiname',
    FILTER_COLUMN_KEYWORDS: 'Keywords',
    FILTER_COLUMN_FILE_EXT: 'Dateiendung',
}

export const FILTER_CONVERTER_MAP = {
    FILTER_COLUMN_ID: COLUMN_DEFINITION_CONST_ID,
    FILTER_COLUMN_FILENAME: COLUMN_DEFINITION_CONST_FILENAME,
    //FILTER_COLUMN_FILE_EXT: COLUMN_DEFINITION_CONST_FILE_EXT,
    FILTER_COLUMN_KEYWORDS: COLUMN_DEFINITION_CONST_KEYWORDS_STRING,
    FILTER_COLUMN_DIRECTORY: COLUMN_DEFINITION_CONST_DIRECTORY,
};

export const ELEMENTS_PER_PAGE_1 = 1;
export const ELEMENTS_PER_PAGE_15 = 15;
export const ELEMENTS_PER_PAGE_30 = 30;
export const ELEMENTS_PER_PAGE_50 = 50;
export const ELEMENTS_PER_PAGE_100 = 100;
export const ELEMENTS_PER_PAGE_1000 = 1000;
export const ELEMENTS_PER_PAGE_10000 = 10000;
export const ELEMENTS_PER_PAGE_100000 = 100000;

export const COLUMN_DEFINITION_PRESET_ALL_COLUMNS = 'COLUMN_DEFINITION_PRESET_0';
export const COLUMN_DEFINITION_PRESET_NO_COLUMNS = 'COLUMN_DEFINITION_PRESET_1';
export const COLUMN_DEFINITION_PRESET_FOR_PUBLIC = 'COLUMN_DEFINITION_PRESET_2';
export const COLUMN_DEFINITION_PRESET_WITH_SUMMARIES = 'COLUMN_DEFINITION_PRESET_3';
export const COLUMN_DEFINITION_PRESET_WITH_SUMMARIES_AND_WARNINGS = 'COLUMN_DEFINITION_PRESET_4';
export const COLUMN_DEFINITION_PRESET_HIDE_KEYWORDS = 'COLUMN_DEFINITION_PRESET_5';

export const COLUMN_DEFINITION_PRESETS_ARRAY : ColumnDefinitionPreset[] = [
    { nameInFrontend: 'Alle Spalten', constant: COLUMN_DEFINITION_PRESET_ALL_COLUMNS },
    //{ nameInFrontend: 'Keine Spalten', constant: COLUMN_DEFINITION_PRESET_NO_COLUMNS },
    { nameInFrontend: 'Für alle verständlich', constant: COLUMN_DEFINITION_PRESET_FOR_PUBLIC },
    { nameInFrontend: 'Kompakt', constant: COLUMN_DEFINITION_PRESET_WITH_SUMMARIES },
    { nameInFrontend: 'Kompakt (mit Warnungen)', constant: COLUMN_DEFINITION_PRESET_WITH_SUMMARIES_AND_WARNINGS },
    { nameInFrontend: 'Nur Keywords und Warnungen', constant: COLUMN_DEFINITION_PRESET_HIDE_KEYWORDS },
];

export const PIE_CHART_BACKGROUND_COLORS_ARRAY = [
    '#B21F00',
    '#C9DE00',
    '#2FDE00',
    '#00A6B4',
    '#6800B4'
];

export const PIE_CHART_HOVER_COLORS_ARRAY = [
    '#B21F00',
    '#C9DE00',
    '#2FDE00',
    '#00A6B4',
    '#6800B4'
];

export const FORMATFUNCTION_DEFAULT_KEY = 'FORMATFUNCTION_DEFAULT';
export const FORMATFUNCTION_DEFAULT_NAME = 'Standard';
export const FORMATFUNCTION_LOWERCASE_KEY = 'FORMATFUNCTION_LOWERCASE';
export const FORMATFUNCTION_LOWERCASE_NAME = 'Alles in Kleinbuchstaben';
export const FORMATFUNCTION_UPPERCASE_KEY = 'FORMATFUNCTION_UPPERCASE';
export const FORMATFUNCTION_UPPERCASE_NAME = 'Alles in Grossbuchstaben';
export const FORMATFUNCTION_KHS_DATE_KEY = 'FORMATFUNCTION_KHS_DATE';
export const FORMATFUNCTION_KHS_DATE_NAME = 'KHS Datumsformatierung';
export const FORMATFUNCTION_KHS_LOKNR_KEY = 'FORMATFUNCTION_KHS_LOKNR';
export const FORMATFUNCTION_KHS_LOKNR_NAME = 'KHS LokNr.-Formatierung';

export const FORMATFUNCTIONS_ARRAY = [
    {
        name: FORMATFUNCTION_DEFAULT_NAME,
        constant: FORMATFUNCTION_DEFAULT_KEY,
    },
    {
        name: FORMATFUNCTION_LOWERCASE_NAME,
        constant: FORMATFUNCTION_LOWERCASE_KEY,
    },
    {
        name: FORMATFUNCTION_UPPERCASE_NAME,
        constant: FORMATFUNCTION_UPPERCASE_KEY,
    },
    {
        name: FORMATFUNCTION_KHS_DATE_NAME,
        constant: FORMATFUNCTION_KHS_DATE_KEY,
    },
    {
        name: FORMATFUNCTION_KHS_LOKNR_NAME,
        constant: FORMATFUNCTION_KHS_LOKNR_KEY,
    },
];

export const RENDER_CONTAINER_ARRAY_JOIN_KEY = 'render_container_array_join'
export const RENDER_CONTAINER_ARRAY_JOIN_NAME = 'Kommagetrennt'
export const RENDER_CONTAINER_BADGE_KEY = 'render_container_badge'
export const RENDER_CONTAINER_BADGE_NAME = 'Badge'
export const RENDER_CONTAINER_BADGE_FULL_WIDTH_KEY = 'Badge_full_width'
export const RENDER_CONTAINER_BADGE_FULL_WIDTH_NAME = 'Badge volle Breite'

export const RENDERCONTAINER_ARRAY = [
    {
        key: RENDER_CONTAINER_ARRAY_JOIN_KEY,
        name: RENDER_CONTAINER_ARRAY_JOIN_NAME,
    },
    {
        key: RENDER_CONTAINER_BADGE_KEY,
        name: RENDER_CONTAINER_BADGE_NAME,
    },
    {
        key: RENDER_CONTAINER_BADGE_FULL_WIDTH_KEY,
        name: RENDER_CONTAINER_BADGE_FULL_WIDTH_NAME,
    },
] as RenderContainer[];

export const COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION = 1;
export const COLUMN_DEFINITION_TYPE_CUSTOM_COLUMN_DEFINITION = 2;
export const COLUMN_DEFINITION_TYPE_REMARKS_COLUMN_DEFINITION = 3;

// Properties of ImageWithEnrichedMetadataObject
export const COLUMNS_ARRAY_MAP_FOR_IMAGE_WITH_ENRICHED_METADATA_OBJECT = {
    1: 'baseColumns',
    2: 'customColumns',
    3: 'remarksColumns',
}

export const baseColumnsMappingArray = [
    {
        columnDefinitionConstant: COLUMN_DEFINITION_CONST_SOURCE_FILE,
        ihkhsjsonField: IHKHSJSON_FIELD_SOURCEFILE,
    },
    {
        columnDefinitionConstant: COLUMN_DEFINITION_CONST_FILENAME,
        ihkhsjsonField: IHKHSJSON_FIELD_FILENAME,
    },
    {
        columnDefinitionConstant: COLUMN_DEFINITION_CONST_MIME_TYPE,
        ihkhsjsonField: IHKHSJSON_FIELD_MIME_TYPE,
    },
    {
        columnDefinitionConstant: COLUMN_DEFINITION_CONST_DIRECTORY,
        ihkhsjsonField: IHKHSJSON_FIELD_DIRECTORY,
    },
    {
        columnDefinitionConstant: COLUMN_DEFINITION_CONST_KEYWORDS,
        ihkhsjsonField: IHKHSJSON_FIELD_KEYWORDS,
    },
    {
        columnDefinitionConstant: COLUMN_DEFINITION_CONST_IMAGE_DIMENSIONS,
        ihkhsjsonField: IHKHSJSON_FIELD_IMAGESIZE,
    },
    {
        columnDefinitionConstant: COLUMN_DEFINITION_CONST_FILE_SIZE,
        ihkhsjsonField: IHKHSJSON_FIELD_FILESIZE,
    },
    {
        columnDefinitionConstant: COLUMN_DEFINITION_CONST_APERTURE,
        ihkhsjsonField: IHKHSJSON_FIELD_APERTURE,
    },
    {
        columnDefinitionConstant: COLUMN_DEFINITION_CONST_SHUTTER_SPEED,
        ihkhsjsonField: IHKHSJSON_FIELD_SHUTER_SPEED,
    },
    {
        columnDefinitionConstant: COLUMN_DEFINITION_CONST_FLASH,
        ihkhsjsonField: IHKHSJSON_FIELD_FLASH,
    },
    {
        columnDefinitionConstant: COLUMN_DEFINITION_CONST_ISO,
        ihkhsjsonField: IHKHSJSON_FIELD_ISO,
    },
    {
        columnDefinitionConstant: COLUMN_DEFINITION_CONST_CAMERA_MAKE,
        ihkhsjsonField: IHKHSJSON_FIELD_CAMERA_MAKE,
    },
    {
        columnDefinitionConstant: COLUMN_DEFINITION_CONST_CAMERA_MODEL,
        ihkhsjsonField: IHKHSJSON_FIELD_CAMERA_MODEL,
    },
    {
        columnDefinitionConstant: COLUMN_DEFINITION_CONST_LENS,
        ihkhsjsonField: IHKHSJSON_FIELD_LENS,
    },
    {
        columnDefinitionConstant: COLUMN_DEFINITION_CONST_LENS_ID,
        ihkhsjsonField: IHKHSJSON_FIELD_LENS_ID,
    },
    {
        columnDefinitionConstant: COLUMN_DEFINITION_CONST_DATE_TIME_CREATED,
        ihkhsjsonField: IHKHSJSON_FIELD_DATE_TIME_CREATED,
    },
    {
        columnDefinitionConstant: COLUMN_DEFINITION_CONST_SUB_SEC_CREATE_DATE,
        ihkhsjsonField: IHKHSJSON_FIELD_SUB_SEC_CREATE_DATE,
    },
    {
        columnDefinitionConstant: COLUMN_DEFINITION_CONST_DATE_TIME_ORIGINAL,
        ihkhsjsonField: IHKHSJSON_FIELD_DATE_TIME_ORIGINAL,
    },
    {
        columnDefinitionConstant: COLUMN_DEFINITION_CONST_SUB_SEC_DATE_TIME_ORIGINAL,
        ihkhsjsonField: IHKHSJSON_FIELD_SUB_SEC_DATE_TIME_ORIGINAL,
    },
    {
        columnDefinitionConstant: COLUMN_DEFINITION_CONST_GPS_LATITUDE,
        ihkhsjsonField: IHKHSJSON_FIELD_GPS_LATITUDE,
    },
    {
        columnDefinitionConstant: COLUMN_DEFINITION_CONST_GPS_LONGITUDE,
        ihkhsjsonField: IHKHSJSON_FIELD_GPS_LONGITUDE,
    },
    {
        columnDefinitionConstant: COLUMN_DEFINITION_CONST_MD5,
        ihkhsjsonField: IHKHSJSON_FIELD_MD5,
    },
 ] as ColumnDefinitionMappingObject[];

export const DEFAULT_WITH_FOR_NEW_COLUMNS = 200;

export const RENDER_OBJECT_CSS_CLASS_PREFIX = 'render_config_object-';
export const RENDER_OBJECT_MAP_DEFAULT_KEY = 'default';

export const DEFAULT_RENDER_CONFIG_OBJECT = {
    constant: RENDER_OBJECT_MAP_DEFAULT_KEY,
    name: 'Standard',
    renderContainer: RENDER_CONTAINER_ARRAY_JOIN_KEY,
    colorText: '#333333',
    colorElement: '#888888',
    colorBackground: null,
    showInList: false,
    bold: false,
    italic: false,
} as RenderConfigObject;

export const initialBaseColumnDefinitionArray : ColumnDefinition[] = [
    { active:true, columnDefinitionType: COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, sortPosition: 10, nameInFrontend: COLUMN_DEFINITION_CONST_ID_NAME_IN_FRONTEND, width: 130, selectable: false, columnDefinitionConstant: COLUMN_DEFINITION_CONST_ID, renderConfigsMapKey: RENDER_OBJECT_MAP_DEFAULT_KEY, formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY}, // TODO: Set nameInFrontend to Id again when database-implementation is better
    { active:true, columnDefinitionType: COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, sortPosition: 20, nameInFrontend: COLUMN_DEFINITION_CONST_SOURCE_FILE_NAME_IN_FRONTEND, width: 200, selectable: true, columnDefinitionConstant: COLUMN_DEFINITION_CONST_SOURCE_FILE, renderConfigsMapKey: RENDER_OBJECT_MAP_DEFAULT_KEY, formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY},
    { active:true, columnDefinitionType: COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, sortPosition: 30, nameInFrontend: COLUMN_DEFINITION_CONST_FILENAME_NAME_IN_FRONTEND, width: 200, selectable: true, columnDefinitionConstant: COLUMN_DEFINITION_CONST_FILENAME, renderConfigsMapKey: RENDER_OBJECT_MAP_DEFAULT_KEY, formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY},
    { active:true, columnDefinitionType: COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, sortPosition: 37, nameInFrontend: COLUMN_DEFINITION_CONST_MIME_TYPE_NAME_IN_FRONTEND, width: 130, selectable: true, columnDefinitionConstant: COLUMN_DEFINITION_CONST_MIME_TYPE, renderConfigsMapKey: RENDER_OBJECT_MAP_DEFAULT_KEY, formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY},
    { active:true, columnDefinitionType: COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, sortPosition: 40, nameInFrontend: COLUMN_DEFINITION_CONST_DIRECTORY_NAME_IN_FRONTEND, width: 220, selectable: true, columnDefinitionConstant: COLUMN_DEFINITION_CONST_DIRECTORY, renderConfigsMapKey: RENDER_OBJECT_MAP_DEFAULT_KEY, formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY},
    { active:true, columnDefinitionType: COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, sortPosition: 110, nameInFrontend: COLUMN_DEFINITION_CONST_KEYWORD_REST_NAME_IN_FRONTEND, width: 200, selectable: true, columnDefinitionConstant: COLUMN_DEFINITION_CONST_KEYWORD_REST, renderConfigsMapKey: RENDER_OBJECT_MAP_DEFAULT_KEY, formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY},
    { active:true, columnDefinitionType: COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, sortPosition: 130, nameInFrontend: COLUMN_DEFINITION_CONST_KEYWORDS_NAME_IN_FRONTEND, width: 200, selectable: true, columnDefinitionConstant: COLUMN_DEFINITION_CONST_KEYWORDS, renderConfigsMapKey: RENDER_OBJECT_MAP_DEFAULT_KEY, formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY},
    { active:false, columnDefinitionType: COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, sortPosition: 135, nameInFrontend: COLUMN_DEFINITION_CONST_KEYWORDS_CORRECTION_NAME_IN_FRONTEND, width: 200, selectable: true, columnDefinitionConstant: COLUMN_DEFINITION_CONST_KEYWORDS_CORRECTION, renderConfigsMapKey: RENDER_OBJECT_MAP_DEFAULT_KEY, formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY},
    { active:true, columnDefinitionType: COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, sortPosition: 140, nameInFrontend: COLUMN_DEFINITION_CONST_IMAGE_DIMENSIONS_NAME_IN_FRONTEND, width: 200, selectable: true, columnDefinitionConstant: COLUMN_DEFINITION_CONST_IMAGE_DIMENSIONS, renderConfigsMapKey: RENDER_OBJECT_MAP_DEFAULT_KEY, formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY},
    { active:true, columnDefinitionType: COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, sortPosition: 150, nameInFrontend: COLUMN_DEFINITION_CONST_FILE_SIZE_NAME_IN_FRONTEND, width: 200, selectable: true, columnDefinitionConstant: COLUMN_DEFINITION_CONST_FILE_SIZE, renderConfigsMapKey: RENDER_OBJECT_MAP_DEFAULT_KEY, formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY},
    { active:true, columnDefinitionType: COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, sortPosition: 160, nameInFrontend: COLUMN_DEFINITION_CONST_APERTURE_NAME_IN_FRONTEND, width: 200, selectable: true, columnDefinitionConstant: COLUMN_DEFINITION_CONST_APERTURE, renderConfigsMapKey: RENDER_OBJECT_MAP_DEFAULT_KEY, formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY},
    { active:true, columnDefinitionType: COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, sortPosition: 170, nameInFrontend: COLUMN_DEFINITION_CONST_SHUTTER_SPEED_NAME_IN_FRONTEND, width: 200, selectable: true, columnDefinitionConstant: COLUMN_DEFINITION_CONST_SHUTTER_SPEED, renderConfigsMapKey: RENDER_OBJECT_MAP_DEFAULT_KEY, formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY},
    { active:true, columnDefinitionType: COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, sortPosition: 180, nameInFrontend: COLUMN_DEFINITION_CONST_FLASH_NAME_IN_FRONTEND, width: 200, selectable: true, columnDefinitionConstant: COLUMN_DEFINITION_CONST_FLASH, renderConfigsMapKey: RENDER_OBJECT_MAP_DEFAULT_KEY, formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY},
    { active:true, columnDefinitionType: COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, sortPosition: 190, nameInFrontend: COLUMN_DEFINITION_CONST_ISO_NAME_IN_FRONTEND, width: 200, selectable: true, columnDefinitionConstant: COLUMN_DEFINITION_CONST_ISO, renderConfigsMapKey: RENDER_OBJECT_MAP_DEFAULT_KEY, formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY},
    { active:true, columnDefinitionType: COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, sortPosition: 200, nameInFrontend: COLUMN_DEFINITION_CONST_CAMERA_MAKE_NAME_IN_FRONTEND, width: 200,  selectable: true, columnDefinitionConstant: COLUMN_DEFINITION_CONST_CAMERA_MAKE, renderConfigsMapKey: RENDER_OBJECT_MAP_DEFAULT_KEY, formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY},
    { active:true, columnDefinitionType: COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, sortPosition: 210, nameInFrontend: COLUMN_DEFINITION_CONST_CAMERA_MODEL_NAME_IN_FRONTEND, width: 200, selectable: true, columnDefinitionConstant: COLUMN_DEFINITION_CONST_CAMERA_MODEL, renderConfigsMapKey: RENDER_OBJECT_MAP_DEFAULT_KEY, formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY},
    { active:true, columnDefinitionType: COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, sortPosition: 220, nameInFrontend: COLUMN_DEFINITION_CONST_LENS_NAME_IN_FRONTEND, width: 200, selectable: true, columnDefinitionConstant: COLUMN_DEFINITION_CONST_LENS, renderConfigsMapKey: RENDER_OBJECT_MAP_DEFAULT_KEY, formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY},
    { active:true, columnDefinitionType: COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, sortPosition: 230, nameInFrontend: COLUMN_DEFINITION_CONST_LENS_ID_NAME_IN_FRONTEND, width: 200, selectable: true, columnDefinitionConstant: COLUMN_DEFINITION_CONST_LENS_ID, renderConfigsMapKey: RENDER_OBJECT_MAP_DEFAULT_KEY, formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY},
    { active:true, columnDefinitionType: COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, sortPosition: 240,  nameInFrontend: COLUMN_DEFINITION_CONST_DATE_TIME_CREATED_NAME_IN_FRONTEND, width: 200, selectable: true, columnDefinitionConstant: COLUMN_DEFINITION_CONST_DATE_TIME_CREATED, renderConfigsMapKey: RENDER_OBJECT_MAP_DEFAULT_KEY, formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY},
    { active:true, columnDefinitionType: COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, sortPosition: 250,  nameInFrontend: COLUMN_DEFINITION_CONST_SUB_SEC_CREATE_DATE_NAME_IN_FRONTEND, width: 300, selectable: true, columnDefinitionConstant: COLUMN_DEFINITION_CONST_SUB_SEC_CREATE_DATE, renderConfigsMapKey: RENDER_OBJECT_MAP_DEFAULT_KEY, formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY},
    { active:true, columnDefinitionType: COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, sortPosition: 260,  nameInFrontend: COLUMN_DEFINITION_CONST_DATE_TIME_ORIGINAL_NAME_IN_FRONTEND, width: 200, selectable: true, columnDefinitionConstant: COLUMN_DEFINITION_CONST_DATE_TIME_ORIGINAL, renderConfigsMapKey: RENDER_OBJECT_MAP_DEFAULT_KEY, formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY},
    { active:true, columnDefinitionType: COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, sortPosition: 270,  nameInFrontend: COLUMN_DEFINITION_CONST_SUB_SEC_DATE_TIME_ORIGINAL_NAME_IN_FRONTEND, width: 300, selectable: true, columnDefinitionConstant: COLUMN_DEFINITION_CONST_SUB_SEC_DATE_TIME_ORIGINAL, renderConfigsMapKey: RENDER_OBJECT_MAP_DEFAULT_KEY, formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY},
    { active:true, columnDefinitionType: COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, sortPosition: 280,  nameInFrontend: COLUMN_DEFINITION_CONST_GPS_LATITUDE_NAME_IN_FRONTEND, width: 200, selectable: true, columnDefinitionConstant: COLUMN_DEFINITION_CONST_GPS_LATITUDE, renderConfigsMapKey: RENDER_OBJECT_MAP_DEFAULT_KEY, formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY},
    { active:true, columnDefinitionType: COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, sortPosition: 290,  nameInFrontend: COLUMN_DEFINITION_CONST_GPS_LONGITUDE_NAME_IN_FRONTEND, width: 200, selectable: true, columnDefinitionConstant: COLUMN_DEFINITION_CONST_GPS_LONGITUDE, renderConfigsMapKey: RENDER_OBJECT_MAP_DEFAULT_KEY, formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY},
    { active:true, columnDefinitionType: COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, sortPosition: 302,  nameInFrontend: COLUMN_DEFINITION_CONST_MD5_NAME_IN_FRONTEND, width: 200, selectable: true, columnDefinitionConstant: COLUMN_DEFINITION_CONST_MD5, renderConfigsMapKey: RENDER_OBJECT_MAP_DEFAULT_KEY, formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY},
    { active:false, columnDefinitionType: COLUMN_DEFINITION_TYPE_COLUMN_DEFINITION, sortPosition: 310,  nameInFrontend: COLUMN_DEFINITION_CONST_IMAGE_PREVIEW_NAME_IN_FRONTEND, width: 200, selectable: false, columnDefinitionConstant: COLUMN_DEFINITION_CONST_IMAGE_PREVIEW, renderConfigsMapKey: RENDER_OBJECT_MAP_DEFAULT_KEY, formatFunctionConstant: FORMATFUNCTION_DEFAULT_KEY},
];

export const WARNING_CONFIG_GROUP_CONNECT_TYPE_AND_NAME = 'and';
export const WARNING_CONFIG_GROUP_CONNECT_TYPE_AND_DESCRIPTION = 'Alle Bedingungen müssen erfüllt sein';
export const WARNING_CONFIG_GROUP_CONNECT_TYPE_OR_NAME = 'or';
export const WARNING_CONFIG_GROUP_CONNECT_TYPE_OR_DESCRIPTION = 'Mindestens eine Bedingung muss erfüllt sein';


  const WarningConfigGroupConnectTypeAnd = {
    name: WARNING_CONFIG_GROUP_CONNECT_TYPE_AND_NAME,
    description: WARNING_CONFIG_GROUP_CONNECT_TYPE_AND_DESCRIPTION,
  } as WarningConfigGroupConnectType;

  const WarningConfigGroupConnectTypeOr = {
    name: WARNING_CONFIG_GROUP_CONNECT_TYPE_OR_NAME,
    description: WARNING_CONFIG_GROUP_CONNECT_TYPE_OR_DESCRIPTION,
  } as WarningConfigGroupConnectType;

export const WARNING_CONFIG_GROUP_CONNECT_TYPES = [
    WarningConfigGroupConnectTypeAnd,
    WarningConfigGroupConnectTypeOr,
]

// TODO: Rename this
export const WARNING_CONDITION_TYPE_IS_EMPTY_KEY = 'is_empty';
export const WARNING_CONDITION_TYPE_IS_EMPTY_NAME = 'Ist leer';
export const WARNING_CONDITION_TYPE_IS_NOT_EMPTY_KEY = 'is_not_empty';
export const WARNING_CONDITION_TYPE_IS_NOT_EMPTY_NAME = 'Ist nicht leer';
export const WARNING_CONDITION_TYPE_DOES_MATCH_KEY = 'does_match';
export const WARNING_CONDITION_TYPE_DOES_MATCH_NAME = 'entspricht';
export const WARNING_CONDITION_TYPE_DOES_MATCH_EXACT_KEY = 'does_match_exact';
export const WARNING_CONDITION_TYPE_DOES_MATCH_EXACT_NAME = 'entspricht exakt';
export const WARNING_CONDITION_TYPE_DOES_NOT_MATCH_KEY = 'does_not_match';
export const WARNING_CONDITION_TYPE_DOES_NOT_MATCH_NAME = 'entspricht nicht';
export const WARNING_CONDITION_TYPE_MORE_THAN_ONE_VALUE_KEY = 'more_than_one_value';
export const WARNING_CONDITION_TYPE_MORE_THAN_ONE_VALUE_NAME = 'Hat mehr als einen Wert';

export const WARNING_CONDITIONS_ARRAY = {
    [WARNING_CONDITION_TYPE_IS_EMPTY_KEY]: WARNING_CONDITION_TYPE_IS_EMPTY_NAME,
    [WARNING_CONDITION_TYPE_IS_NOT_EMPTY_KEY]: WARNING_CONDITION_TYPE_IS_NOT_EMPTY_NAME,
    [WARNING_CONDITION_TYPE_MORE_THAN_ONE_VALUE_KEY]: WARNING_CONDITION_TYPE_MORE_THAN_ONE_VALUE_NAME,
    [WARNING_CONDITION_TYPE_DOES_MATCH_KEY]: WARNING_CONDITION_TYPE_DOES_MATCH_NAME,
    [WARNING_CONDITION_TYPE_DOES_MATCH_EXACT_KEY]: WARNING_CONDITION_TYPE_DOES_MATCH_EXACT_NAME,
    [WARNING_CONDITION_TYPE_DOES_NOT_MATCH_KEY]: WARNING_CONDITION_TYPE_DOES_NOT_MATCH_NAME,
}

export const FILENAME_FOR_EMPTY_COLUMNS_PRESET = 'filename_for_empty_columns_preset';

export const emptyImageWithMetadataObject = {
    [COLUMN_DEFINITION_CONST_SOURCE_FILE]: 'sourcefile',
    [COLUMN_DEFINITION_CONST_FILENAME]: 'file_name',
    [COLUMN_DEFINITION_CONST_DIRECTORY]: 'directory',
    [COLUMN_DEFINITION_CONST_KEYWORDS_STRING]: 'a, b, c',
    [COLUMN_DEFINITION_CONST_KEYWORDS]: ['a', 'b', 'c'],
    [COLUMN_DEFINITION_CONST_KEYWORD_REST]: [],
    [COLUMN_DEFINITION_CONST_IMAGE_DIMENSIONS]: '4711',
} as ImageWithMetadataObject;

export const requiredPropertiesOfImageWithMetadataObjectArray = [
    COLUMN_DEFINITION_CONST_SOURCE_FILE,
    COLUMN_DEFINITION_CONST_FILENAME,
    COLUMN_DEFINITION_CONST_DIRECTORY,
    COLUMN_DEFINITION_CONST_KEYWORDS_STRING,
    COLUMN_DEFINITION_CONST_KEYWORDS,
    COLUMN_DEFINITION_CONST_KEYWORD_REST,
    COLUMN_DEFINITION_CONST_IMAGE_DIMENSIONS
];



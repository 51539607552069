import React from 'react';
import { FC, useState } from 'react';
import { Alert, Button, Col, Form, Row, Table } from 'react-bootstrap';
import { DB_Comment} from './custom_types';
import { serialize } from './Helpers';

interface CommentsOverviewProps {
    databaseHost: string,
    csrfToken: string,
    formValueSourcefile: string,
    settingNameForSavingComments: string,
    setSettingNameForSavingComments: any,
    dataSourceOnlyFilesystemAndJSONFilename: string,
}

export const CommentsOverview: FC<CommentsOverviewProps> = ({
    databaseHost,
    csrfToken,
    formValueSourcefile,
    settingNameForSavingComments,
    setSettingNameForSavingComments,
    dataSourceOnlyFilesystemAndJSONFilename,
}) => {
    const FORM_ID = 'add-comment-form';
    const [formValueCommentText, setFormValueCommentText] = useState('');
    const [formValueAuthor, setFormValueAuthor] = useState(settingNameForSavingComments);
    const [saveSuccessful, setSaveSuccessful] = useState(false);
    
    // @ts-ignore
    const submitCommentForm = (event) => {
        setSettingNameForSavingComments(formValueAuthor);
        let form = document.getElementById(FORM_ID);; 
        const formPostUrl = `${databaseHost}/comment/comments/`;
            fetch(formPostUrl, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: serialize(form)
            })
            .then(response => {
                if (response.ok) {
                    setSaveSuccessful(true);
                } else {
                    alert('Speichern fehlgeschlagen');
                    console.error('Speichern fehlgeschlagen');
                    console.error(response);
                } 
            });
    }

    return (
        <>
            <Row>
                <Col md={4}>
                <Form
                    id={FORM_ID}
                >
                    <Form.Control
                        name='csrfmiddlewaretoken'
                        type="hidden"
                        value={csrfToken}
                    />

                    <Form.Control
                        size="sm"
                        name='sourcefile'
                        type='hidden'
                        value={formValueSourcefile}
                    />
                    <Form.Control
                        size="sm"
                        name='ihkhsjson_file'
                        type='hidden'
                        value={dataSourceOnlyFilesystemAndJSONFilename}
                    />

                    <Form.Label>Mein Name</Form.Label>
                    <Form.Control
                        size="sm"
                        name='author'
                        type="text"
                        required={true}
                        value={formValueAuthor}
                        onChange={(e) => setFormValueAuthor(e.target.value)}
                    />
                    <Form.Label>Meine Anmerkung</Form.Label>
                    <Form.Control
                        size="sm"
                        name='comment_text'
                        as='textarea'
                        required={true}
                        rows={3}
                        value={formValueCommentText}
                        onChange={(e) => setFormValueCommentText(e.target.value)}
                    />
                    
                    {saveSuccessful &&
                        <Alert variant='success' dismissible onClose={() => setSaveSuccessful(false)}>
                            Der Kommentar wurde erfolgreich gespeichert.
                        </Alert>
                    }
                    <Button
                        size='sm'
                        disabled={formValueAuthor.length === 0 || formValueCommentText.length === 0}
                        variant='secondary'
                        type='button'
                        onClick={submitCommentForm}
                    >
                        Kommentar speichern
                    </Button>
                </Form>
                </Col>
                <Col md={8}>
                    
                </Col>
            </Row>
        </>
    )
}
